import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { validateEmail } from 'common/validator';
import { useStyles } from 'marketplace/components/Modal/style.js';
import closeBtn from 'assets/img/close-btn-red.svg';

const ModalAddAdmin = ({ open, isLoading, onClose, onConfirm, emailToEdit, currentEmails = [] }) => {
    const [errorMsg, setErrorMsg] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [email, setEmail] = useState(emailToEdit);
    const classes = useStyles();

    useEffect(() => {
        if (!email) {
            setIsDisabled(true);
            return;
        }
        const validationMsg = validateEmail(email);
        if (validationMsg) {
            setIsDisabled(true);
            setErrorMsg(validationMsg);
            return;
        }

        if (emailToEdit || !currentEmails.includes(email)) {
            setIsDisabled(false);
            setErrorMsg(null);
            return;
        }

        setErrorMsg('Admin with this email already exists');
    }, [email, emailToEdit]);

    console.debug(email, emailToEdit, errorMsg);

    return (
        <div
            className={clsx({
                [classes.modalAddAdmin]: true,
                [classes.showModalAddAdmin]: open,
            })}
            style={{ padding: 0 }}
            onClick={(e) => e.stopPropagation()}
        >
            <div className={classes.modalAddAdminWrapper} onClick={(e) => e.stopPropagation()}>
                <div className={classes.modalAddAdminCloseBtn} onClick={() => onClose()}>
                    <img className={classes.modalSetPriceImg} src={closeBtn} alt="close" />
                </div>
                <div className={classes.modalAddAdminInputBlock}>
                    <div className={classes.setAddAdminForm}>
                        <div className={classes.modalAddAdminInputField}>
                            <label className={classes.modalAddAdminInputLabel} htmlFor="Admin">
                                Admin
                                <input
                                    id="email"
                                    type="email"
                                    maxLength="255"
                                    name="Admin"
                                    autoComplete="off"
                                    disabled={isLoading}
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    className={classes.modalAddAdminInput}
                                />
                            </label>
                        </div>
                        <button
                            type="submit"
                            disabled={isDisabled || isLoading}
                            onClick={() => onConfirm(!!emailToEdit, email)}
                            className={classes.modalAddAdminCreatBtn}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ModalAddAdmin);
