import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './style.js';

const SkeletonRows = () => {
    const classes = useStyles();

    return (
        <>
            {
                Array(1).fill().map((item,index) => (
                    <div key={index} className={classes.skeletonTableRow}>
                        <div className={`${classes.skeletonTableTd} ${classes.skeletonTableTdFirst}`}>
                            <Skeleton variant="text" animation="wave" className={classes.skeletonText} />
                        </div>
                        {/*<div className={classes.skeletonTableTd}>*/}
                        {/*    <Skeleton variant="text" animation="wave" className={classes.skeletonText} />*/}
                        {/*</div>*/}
                        {/*<div className={classes.skeletonTableTd}>*/}
                        {/*    <Skeleton variant="text" animation="wave" className={classes.skeletonText} />*/}
                        {/*</div>*/}
                        {/*/!*<td className={classes.skeletonTableTd}>*!/*/}
                        {/*/!*    <Skeleton variant="text" animation="wave" className={classes.skeletonText} />*!/*/}
                        {/*/!*</td>*!/*/}
                        {/*<div className={classes.skeletonTableTd}>*/}
                        {/*    <Skeleton variant="text" animation="wave" className={classes.skeletonText} />*/}
                        {/*</div>*/}
                    </div>
                ))
            }
        </>
    );
};

export default SkeletonRows;
