import React, { useMemo, useRef, useState } from 'react';
import {Bar} from 'react-chartjs-2';
import CustomerOwnBar from './CustomerOwnBar';
import {format, isAfter, isBefore, isEqual} from 'date-fns';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartTooltip from "./ChartTooltip";

import {useStyles} from './style.js';
import {formatNum} from "../../../common/util";

const defaultData = {
    propertyData: {
        labels: [],
        datasets: []
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                top: 62,
                bottom: 78,
            },
        },
        scales: {
            x: {
                display: false,
                grid: {
                    display: false,
                },
            },
            y: {
                display: false,
                beginAtZero: false,
                grid: {
                    display: false,
                },
            },
        },
    }
}

const prepareData = (graphItems, currentDate) => {
    return graphItems.reduce(
        (acc, graphItem, index) => {
            const date = new Date(graphItem.date);
            const month = format(date, 'MMMM');
            const val = graphItem.value;
            const curElDate = new Date(graphItem.date);

            const hasOnlyOneElement = graphItems.length === 1;
            const isLatestElement = graphItems.length - 1 === index;
            const hasPointDate = acc.pointDate !== null;

            if (hasOnlyOneElement || (isLatestElement && !hasPointDate)) {
                acc.pointDate = new Date(graphItem.date);
            } else {
                const hasNextElement = graphItems[index + 1] !== undefined;
                if (hasNextElement) {
                    const nexElDate = new Date(graphItems[index + 1].date);

                    const isCurrentDateInPast = isBefore(curElDate, currentDate);
                    const isNextDateInFuture = isAfter(nexElDate, currentDate);

                    if (isCurrentDateInPast && isNextDateInFuture) {
                        acc.pointDate = curElDate;
                    }
                }
            }

            acc.items.push(val);
            acc.labels.push(month);

            return acc;
        },
        {labels: [], items: [], pointDate: null})
}

const PropertyGraph = ({graph = [], percent = 0}) => {
    const classes = useStyles();
    const toolTipRef = useRef(null);
    const [toolTipStyles, setToolTipStyles] = useState({ display: "none" });

    const [currentCaretPos, setCurrentCaretPos] = useState();
    const [dataDates, setDataDates] = useState([]);
    const [toolTipTitle, setToolTipTitle] = useState("");
    const [toolTipPrice, setToolTipPrice] = useState("");
    const [toolTipCirculation, setToolTipCirculation] = useState("");
    const [toolTipDisplayed, setToolTipDisplayed] = useState(false);
    const [graphLabels, setGraphLabels] = useState();

    const tooltipFn = function(context) {
        const dataIndex = context.tooltip.dataPoints[0].dataIndex;
        const position = context.chart.canvas.getBoundingClientRect();
        const caretX = context.tooltip.caretX;
        const caretY = context.tooltip.caretY;
        const tooltipEl = toolTipRef.current;
        tooltipEl.style.display = ""; // need to do this to get dimemsions
        const styleX = caretX - tooltipEl.offsetWidth/2 + 24;
        const styleY = caretY - tooltipEl.offsetHeight - 20;
        // Hide if no tooltip
        let display = "";
        if (context.tooltip.opacity === 0) {
            display = "none";
        }
        if (caretX !== currentCaretPos || context.tooltip.opacity === 0) {
            setToolTipStyles({
              left: `${styleX}px`,
              top: `${styleY}px`,
              display,
            })
        }
        setCurrentCaretPos(caretX);
        setToolTipPrice(`$${graph[dataIndex].value.toLocaleString()}`);
        setToolTipTitle(graphLabels[dataIndex]);
    }

    const {propertyData, options} = useMemo(() => {
        const data = defaultData;
        if (graph.length === 0) {
            return data;
        }
        const currentDate = new Date();
        let {labels, items, pointDate} = prepareData(graph, currentDate);
        if (!graphLabels) {
          setGraphLabels(labels);
        }
        data.propertyData.labels = labels;
        data.propertyData.datasets = [
            {
                type: 'line',
                label: 'Home Value',
                data: items,
                backgroundColor: ['rgba(0, 0, 0, 100)'],
                borderColor: ['rgba(0, 0, 0, 100)'],
                zIndex: '99',
                borderWidth: 2,
                hoverRadius: 2,
                hoverBorderWidth: 2,
                pointRadius: (ctx) => {
                    if (graph.length === 0) {
                        return 0;
                    }
                    const currentElDate = new Date(graph[ctx.index].date);
                    return isEqual(currentElDate, pointDate) ? 5 : 2;
                },
                pointBackgroundColor: ['rgba(255, 255, 255, 100)'],
                pointBorderColor: 'rgb(177, 78, 79)',
                segment: {
                    borderColor: (ctx) => {
                        const element = graph[ctx.p1DataIndex];
                        const elDate = new Date(element.date);
                        const isBeforeCurrentDate = isBefore(elDate, currentDate);
                        const isEqualToCurrentDate = isEqual(elDate, currentDate);
                        return isBeforeCurrentDate || isEqualToCurrentDate ? 'rgb(0, 0, 0, 100)' : undefined;
                    },
                },
            },
        ]
        data.options.plugins = {
            datalabels: {
                anchor: 'end',
                align: 'top',
                color: '#0650CF',
                display: function (context) {
                    // return (context.dataIndex === context.dataset.data.length - 1);
                    if (graph.length === 0) {
                        return 0;
                    }
                    const currentElDate = new Date(graph[context.dataIndex].date);
                    return isEqual(currentElDate, pointDate) ? 5 : 0;
                },
                formatter: function (value, context) {
                    // return context.chart.data.labels[context.dataIndex];
                    if (context.chart.data.labels[context.dataIndex]) {
                      return 'TODAY';
                    }
                    return '';
                },
                font: function (context) {
                    const displaySize = context.chart.width;
                    if (displaySize <= 440) {
                        return {
                            family: 'Basis Grotesque Pro-Black',
                            weight: 'bold',
                            size: 8
                        }
                    }
                    return {
                        family: 'Basis Grotesque Pro-Black',
                        weight: 'bold',
                        size: 12
                    };
                },
            },
            legend: {
                display: false,
            },
            tooltip: {
              external: tooltipFn,
              enabled: false,
              events: ['click'],
            },
        }
        return data;
    }, [graph, percent, graphLabels]);

    return (
        <div className={classes.propertyGraphWrapper}>
            <Bar
                data={propertyData}
                plugins={[ChartDataLabels]}
                options={options}
            />
            <div className={classes.customerWrapper}>
                <CustomerOwnBar percent={percent}/>
            </div>
            {/*<ChartTooltip*/}
            {/*  title={toolTipTitle}*/}
            {/*  price={toolTipPrice}*/}
            {/*  tooltipStyles={toolTipStyles}*/}
            {/*  tooltipRef={toolTipRef}*/}
            {/*  styleOptions={{ priceColor: 'black' }}*/}
            {/*  toolTipClass={classes.propertyGraphToolTip}*/}
            {/*/>*/}
        </div>
    );
};

export default React.memo(PropertyGraph);
