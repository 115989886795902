import React from 'react';
import {useStyles} from "./style";

const BlueLabel = ({labelClassName, text}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.blueLabel} ${labelClassName}`}>
      {text}
    </div>
  );
};

export default BlueLabel;
