import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './styles';

const TransactionItemPreloader = () => {
    const classes = useStyles();

    return (
        <tr className={classes.adminTableTrChild}>
            <td className={classes.adminTableTd}>
                <Skeleton animation="wave" />
            </td>
            <td className={classes.adminTableTd}>
                <Skeleton animation="wave" />
            </td>
            <td className={classes.adminTableTd}>
                <Skeleton animation="wave" />
            </td>
            <td className={classes.adminTableTd}>
                <Skeleton animation="wave" />
            </td>
            <td className={classes.adminTableTd}>
                <Skeleton animation="wave" />
            </td>
            <td className={classes.adminTableTd}>
                <Skeleton animation="wave" />
            </td>
            <td className={classes.adminTableTd}>
                <div className={classes.adminTableTrChildBtn}>
                    <Skeleton animation="wave" />
                </div>
                <div className={classes.adminTableTrChildBtn}>
                    <Skeleton animation="wave" />
                </div>
            </td>
        </tr>
    );
};

export default React.memo(TransactionItemPreloader);
