import {makeStyles} from '@material-ui/core/styles';

const drawerWidth = '100%';

export const useStyles = makeStyles((theme) => ({
  burgerMenuBtn: {
    display: 'none',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginRight: '27px',
    zIndex: '1599',
  },
  burgerMenuBtnSpan: {
    height: '5px',
    width: '22px',
    margin: '7px 0',
    backgroundColor: theme.palette.bgColor.black,
    display: 'block',
  },
  burgerMenuBtnSpanBlack : {
      backgroundColor: theme.palette.bgColor.black,
  },
  burgerCloseBtn: {
    width: '30px',
    height: '30px',
    position: 'absolute',
    top: '22px',
    left: '22px',
  },
  drawer: {
    width: drawerWidth,
    display: 'none',
  },
  drawerPaper: {
    width: drawerWidth,
    justifyContent: 'center',
    backgroundColor: 'rgba(0,75,210,0.90)',
    zIndex: 1000,
  },
  drawerMenuItem: {
    textAlign: 'left',
    fontFamily: theme.typography.fontFamily.black,
    color: theme.palette.text.white,
    fontSize: '40px',
    marginBottom: '20px',
  },
  active: {
    position: 'relative',
    textAlign: 'left',
    fontFamily: theme.typography.fontFamily.black,
    color: theme.palette.text.white,
    fontSize: '40px',
    marginBottom: '20px',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: '0',
      right: '0',
      left: '0',
      height: '4px',
      backgroundColor: 'white'
    }
  },
  listItem: {
    marginLeft: '20px',
    paddingBottom: '0',
    paddingTop: '0'
  },
  '@media (max-width: 900px)': {
    drawer: {
      display: 'block',
    },
    burgerMenuBtn: {
      display: 'block'
    }
  },
  '@media (max-width: 480px)': {
    burgerMenuBtn: {
      marginRight: '17px'
    }
  },
  '@media (max-width: 320px)': {
    // burgerMenuBtn: {
    //   marginRight: '15px'
    // }
  },

  // LANDSCAPE ORIENTATION
  '@media (max-width: 670px) and (orientation: landscape)': {
    listItem: {
      marginLeft: '95px'
    }
  }
}))
