import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import {AppContext, appInitialState, appStateReducer, useAppState} from 'marketplace/app-state';
import {ACTION_TYPES, ROUTES} from 'marketplace/constans';
import ApiClient from 'marketplace/lib/ApiClient';
import CognitoInvestorPool from '../lib/CognitoInvestorPool';
import {useHistory} from "react-router-dom";
import {COIN_TYPE} from "../../common/constants";


const ContextProvider = (props) => {
    const [ state, dispatch ] = useReducer(appStateReducer, appInitialState);
    const history = useHistory();


    const redirectOnError = () => {
        updateState({currentUser: null, isAuth: false});
        cognitoClient.clearUserDataInLS();
        cognitoClient.logout();
        history.push(ROUTES.HOME + ROUTES.LOGIN);
    };

    const { apiClient, cognitoClient } = useMemo(() => {
        const apiClient = new ApiClient(redirectOnError);
        const cognitoClient = new CognitoInvestorPool(apiClient);
        apiClient.setCoinId(COIN_TYPE.HAUS)

        return { apiClient, cognitoClient };
    }, []);

    const updateState = (changedState) => {
        dispatch({
            type: ACTION_TYPES.UPDATE_STATE,
            payload: changedState,
        });
    };

    const fetchInvestorData = useCallback(() => {
        return apiClient.getCurrentInvestor().then((currentUser) => {
            updateState({
                currentUser,
            });
        });
    }, [ apiClient, cognitoClient ]);

    useEffect(() => {
        const data = cognitoClient.getUserDataFromLS();
        const newData = { isSyncWithLS: true };
        if (data) {
            newData.currentUser = data;
            newData.isAuth = true;
            apiClient.useUserId(data.id);
        }
        updateState({ ...newData });
    }, []);

    return (
        <AppContext.Provider
            value={{
                state,
                dispatch,
                actions: {
                    updateState,
                    fetchInvestorData,
                },
                apiClient,
                cognitoClient,
            }}
        >
            {state.isSyncWithLS && props.children}
        </AppContext.Provider>
    );
};

export default React.memo(ContextProvider);
