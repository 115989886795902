import React from 'react';
import {useStyles} from "./style";

const HousesSvg = ({
                     colorOne,
                     colorTwo,
                     colorThree,
                     classNameWrapper
                   }) => {
  const classes = useStyles();

  return (
    <svg className={`${classes.svgHouses} ${classNameWrapper}`} width="440" height="215" viewBox="0 0 440 215" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M192 215V123.081L314.418 0L440 126.262V215H192Z"
            fill={colorOne}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M122.464 70L83.6096 108.512L83.6363 108.538L0.0200076 191.418L0.0422383 191.44H0V213.719H54.9476V214L122.489 147.049L189.937 213.903V213.719H245V191.44L122.464 70Z"
            fill={colorTwo}/>
      <path fillRule="evenodd" clipRule="evenodd"
            d="M122.464 0L83.6096 38.4618L83.6363 38.4882L0.0200076 121.261L0.0422383 121.283H0V215H54.9476V143.814L122.489 76.9501L189.937 143.717V215H245V121.283L122.464 0Z"
            fill={colorThree}/>
    </svg>
  );
};

export default HousesSvg;
