import React from 'react';
import clsx from 'clsx';
import { useStyles } from '../style';

const Tab = ({ isActive, children }) => {
    const classes = useStyles();

    return (
        <div
            className={clsx({
                [classes.adminTabActive]: isActive,
                [classes.adminTabInActive]: !isActive,
            })}
        >
            {isActive ? children : null}
        </div>
    );
};

export default React.memo(Tab);
