import React, { useEffect, useReducer } from 'react';
import { useStyles } from '../style';
import ModalSetPrice from 'admin/components/ModalSetPrice';
import useApi from 'admin/hooks/useApi';
import { SETTING_GROUPS, SETTING_KEYS, SETTING_VAL_TYPES } from 'admin/constants';
import { useCustomSnackbar } from 'common/hooks/custom-snackbars';
import Skeleton from '@material-ui/lab/Skeleton';

const initState = {
    adminCoinPrice: null,
    calculatedCoinPrice: null,
    isAutoPricingMode: false,
    isLoading: true,
    isUpdatingPricingMode: false,
    isUpdatingPrice: false,
    showPriceDialog: false,
};

const PricingTab = () => {
    const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), initState);

    const api = useApi();
    const { showError, showInfo, showSuccess } = useCustomSnackbar();

    useEffect(() => {
        setState({ isLoading: true });
        api.getSettings({ group: SETTING_GROUPS.COIN })
            .then((sets) => handleSettings(sets))
            .catch((err) => showError('Could not get pricing settings', err))
            .finally(() => setState({ isLoading: false }));
    }, []);

    const handleSettings = (settings) => {
        const newState = {
            adminCoinPrice: 0,
            calculatedCoinPrice: 0,
            isAutoPricingMode: false,
        };

        for (const setting of settings) {
            // eslint-disable-next-line default-case
            switch (setting.key) {
                case SETTING_KEYS.ADMIN_COIN_PRICE:
                    newState.adminCoinPrice = setting.value;
                    break;

                case 'CALCULATED_COIN_PRICE': // NOTE: This key is read only!
                    newState.calculatedCoinPrice = setting.value;
                    break;

                case SETTING_KEYS.AUTO_PRICING:
                    newState.isAutoPricingMode = setting.value === 'true';
                    break;
            }
        }

        setState(newState);
    };

    const classes = useStyles();
    const switchHandler = (e) => {
        setState({ isAutoPricingMode: e.target.checked, isUpdatingPricingMode: true });
        showInfo('Updating pricing mode...');
        api.createSetting({
            group: SETTING_GROUPS.COIN,
            key: SETTING_KEYS.AUTO_PRICING,
            value: e.target.checked,
            dataType: SETTING_VAL_TYPES.BOOLEAN,
        })
            .then(() => showSuccess('Updated successfully!'))
            .catch((err) => showError('An error happened while updating pricing mode', err))
            .finally(() => setState({ isUpdatingPricingMode: false }));
    };

    const updatePrice = (price) => {
        setState({ isUpdatingPrice: true });
        showInfo('Updating coin price...');
        api.createSetting({
            group: SETTING_GROUPS.COIN,
            key: SETTING_KEYS.ADMIN_COIN_PRICE,
            value: price,
            dataType: SETTING_VAL_TYPES.DECIMAL,
        })
            .then((setting) => {
                setState({ adminCoinPrice: setting.value, showPriceDialog: false });
                showSuccess('Updated successfully!');
            })
            .catch((err) => showError('An error happened while updating coin price', err))
            .finally(() => setState({ isUpdatingPrice: false }));
    };

    return (
        <>
            <div className={classes.adminTabWrapper}>
                <p className={classes.adminTabPricing}>Pricing</p>
                <label className={classes.adminSwitchBtn}>
                    <input
                        checked={state.isAutoPricingMode}
                        disabled={state.isLoading || state.isUpdatingPricingMode}
                        className={classes.adminSwitchInput}
                        type="checkbox"
                        onChange={switchHandler}
                    />
                    <span className={classes.adminSwitchSpan} />
                </label>
                {state.isAutoPricingMode ? (
                    <p className={classes.adminTabPricing}>Automatic (Equity pricing)</p>
                ) : (
                    <ul className={classes.adminTabBtn}>
                        <li className={classes.adminTabBtnTitle}>Manual</li>
                        <li
                            className={classes.adminTabBtnTitle}
                            onClick={() => {
                                if (!state.isUpdatingPricingMode) {
                                    setState({ showPriceDialog: true });
                                }
                            }}
                        >
                            Edit
                        </li>
                    </ul>
                )}
            </div>
            <span className={classes.adminTabLine} />
            {state.isLoading ? (
                <div className={classes.adminTabTotalPrice}>
                    <Skeleton variant="text" width={250} />
                </div>
            ) : (
                <p className={classes.adminTabTotalPrice}>
                    ${Math.round((state.isAutoPricingMode ? state.calculatedCoinPrice : state.adminCoinPrice)*100)/100}
                </p>
            )}

            {state.showPriceDialog && (
                <ModalSetPrice
                    open={state.showPriceDialog}
                    isLoading={state.isUpdatingPrice}
                    onClose={() => setState({ showPriceDialog: false })}
                    onConfirm={updatePrice}
                />
            )}
        </>
    );
};

export default React.memo(PricingTab);
