import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  imgCard: {
    maxWidth: '408px',
    height: '524px',
    boxSizing: 'border-box',
    borderRadius: '18px',
    backgroundColor: theme.palette.bgColor.white,
    position: 'relative',
  },
  imgCardBg: {
    height: '200px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '50% 85%',
    borderRadius: '18px 18px 0 0',
  },
  imgCardTag: {
    position: 'absolute',
    top: '25px',
    right: '30px',
    display: 'block',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.bgColor.lightBlue,
    borderRadius: '100px',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: '8px',
    lineHeight: '8px',
    letterSpacing: '0.1em',
    color: theme.palette.text.white,
    padding: '8px 12px',
    textAlign: 'center',
  },
  imgCardInfo: {
    padding: '25px 30px 30px'
  },
  imgCardInfoTitle: {
    maxWidth: '345px',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.semiMain,
    lineHeight: '48px',
    letterSpacing: '-1.5px',
  },
  imgCardInfoText: {
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: theme.fontSize.text.medium,
    margin: '25px 0 54px',
    lineHeight: '22px',
    letterSpacing: '-0.2475px',
  },
  imgCardInfoLine: {
    display: 'block',
    height: '1px',
    backgroundColor: '#C4C4C4',
  },
  imgCardInfoBtn: {
    width: 'fit-content',
    display: 'block',
    margin: '30px auto 0',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.small,
    textAlign: 'center',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: theme.palette.text.main,
    borderBottom: '2px solid black',
    cursor: 'pointer',
  },
  '@media (max-width: 480px)': {
    imgCard: {
      maxWidth: '340px',
      // maxHeight: '437px',
      height: 'fit-content',
    },
    imgCardBg: {
      height: '166px',
    },
    imgCardInfo: {
      padding: '22px 25px'
    },
    imgCardTag: {
      position: 'absolute',
      top: '20px',
      right: '25px',
    },
    imgCardInfoTitle: {
      fontSize: '40px',
      lineHeight: '40px',
      letterSpacing: '-1px',
    },
    imgCardInfoText: {
      margin: '20px 0 37px',
    },
    imgCardInfoBtn: {
      margin: '25px auto 0',
    },
  },
  '@media (max-width: 375px)': {

  }
}))
