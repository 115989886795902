import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const PrivatRouter = ({ children, redirectPath, isAuth, ...routeProps }) => {

    if (!isAuth && !redirectPath) {
        return null;
    }

    if (!isAuth) {
        return <Redirect to={redirectPath} />;
    }

    return <Route {...routeProps}>{children}</Route>;
};

export default React.memo(PrivatRouter);
