import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { useStyles } from './style.js';

const ToolTipBtn = ({children, title, placement, className}) => {
    const classes = useStyles();

    return (
        <Tooltip classes={{
            tooltip: `${classes.customTooltip} ${className}`,
            arrow: classes.customArrow,
            }}
             title={title}
             placement={placement}
             arrow
             interactive
        >
            {children}
        </Tooltip>
    )
};

export default ToolTipBtn;