import React from 'react';
import { useStyles } from './style.js';

const FactsTable = () => {
    const classes = useStyles();

    return (
        <>
            <table className={`${classes.tablePrivacy} ${classes.tableFacts}`} frame='none' cellSpacing="0" >
                <tbody>
                    <tr>
                        <th className={classes.factsTh}>Facts</th>
                        <th className={classes.factsTh}>WHAT DOES HAUSCOIN LLC ("HAUSCOIN") DO WITH YOUR PERSONAL INFORMATION?</th>
                    </tr>
                    <tr>
                        <td className={classes.factsTd}>Why?</td>
                        <td className={classes.factsTd}>
                            Financial companies choose how they share your personal information. Federal law gives consumers
                            the right to limit some but not all sharing. Federal law also requires us to tell you how we collect,
                            share, and protect your personal information. Please read this notice carefully to understand what we do.
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.factsTd}>What?</td>
                        <td className={classes.factsTd}>
                            The types of personal information we collect and share depend on the product or service you have with us.
                            This information can include:
                            <ul>
                                <li>
                                    Social Security number and account balances
                                </li>
                            </ul>
                            When you are no longer our customer, we continue to share your information as described in this notice.
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.factsTd}>How?</td>
                        <td className={classes.factsTd}>
                            All financial companies need to share customers' personal information to run their everyday business.
                            In the section below, we list the reasons financial companies can share their customers' personal
                            information; the reasons HausCoin chooses to share; and whether you can limit this sharing.
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    );
};

export default FactsTable;
