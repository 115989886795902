import React, { useMemo, useState } from 'react';
import useApi from 'admin/hooks/useApi';
import { useCustomSnackbar } from 'common/hooks/custom-snackbars';
import { useStyles } from './styles';
import { TRANSACTION_PROCESSING_TYPE, TRANSACTION_STATUS } from 'common/constants';
import { format } from 'date-fns';

const TransactionItem = ({ data, onActionDone }) => {
    const [isApprovingRequest, setIsApprovingRequest] = useState(false);
    const [isCancellingRequest, setIsCancellingRequest] = useState(false);
    const classes = useStyles();
    const api = useApi();
    const { showError } = useCustomSnackbar();

    const { isPending, isApproved, isApprovingStatus, isCancelled, isCancellingStatus, isFailed } = useMemo(() => {
        return {
            isPending: data.status === TRANSACTION_STATUS.PENDING,
            isApproved: data.status === TRANSACTION_STATUS.APPROVED,
            isApprovingStatus: data.processingType === TRANSACTION_PROCESSING_TYPE.APPROVING,
            isCancelled: data.status === TRANSACTION_STATUS.CANCELLED,
            isCancellingStatus: data.processingType === TRANSACTION_PROCESSING_TYPE.CANCELLING,
            isFailed: data.status === TRANSACTION_STATUS.FAILED,
        };
    }, [data.status, data.processingType]);

    const handleApprove = () => {
        setIsApprovingRequest(true);
        api.approveTransaction(data.id)
            .then(() => {
                onActionDone(data.id, TRANSACTION_PROCESSING_TYPE.APPROVING);
                // showSuccess('Approving');
            })
            .catch((err) => showError('Could not approve transaction!', err))
            .finally(() => setIsApprovingRequest(false));
    };

    const handleDeny = () => {
        setIsCancellingRequest(true);
        api.denyTransaction(data.id)
            .then(() => {
                onActionDone(data.id, TRANSACTION_PROCESSING_TYPE.CANCELLING);
                // showSuccess('Denying');
            })
            .catch((err) => showError('Could not deny transaction!', err))
            .finally(() => setIsCancellingRequest(false));
    };

    const getResultLabel = () => {
        if (isApproved) {
            return `APPROVED ${data.updatedAtLabel}`;
        } else if (isFailed) {
            return `FAILED ${data.updatedAtLabel}`;
        }
        return `DENIED ${data.updatedAtLabel}`;
    };

    return (
        <tr className={classes.adminTableTrChild}>
            <td className={classes.adminTableTd}>{`${data.createdAtLabel}`}</td>
            <td className={classes.adminTableTd}>{`${data.investor.firstName} ${data.investor.lastName}`}</td>
            <td className={classes.adminTableTd}>{data.type}</td>
            <td className={classes.adminTableTd}>{data.coinsAmount}</td>
            <td className={classes.adminTableTd}>${parseFloat(data.coinPrice).toFixed(6)}</td>
            <td className={classes.adminTableTd}>{data.coinType}</td>
            <td className={classes.adminTableTd}>
                {isApprovingRequest || isApprovingStatus || isCancellingRequest || isCancellingStatus ? (
                    <span className={classes.statusMessage}>
                        <em>Processing...</em>
                    </span>
                ) : isApproved || isCancelled || isFailed ? (
                    <span className={classes.statusMessage}>{getResultLabel()}</span>
                ) : (
                    <>
                        <button onClick={handleApprove} className={classes.adminTableTrChildBtn}>
                            Approve
                        </button>
                        <button onClick={handleDeny} className={classes.adminTableTrChildBtn}>
                            {isCancellingRequest || isCancellingStatus ? 'Processing' : 'Deny'}
                        </button>
                    </>
                )}
            </td>
        </tr>
    );
};

export default React.memo(TransactionItem);
