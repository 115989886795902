import axios from 'axios';
import { TRANSACTION_ACTIONS } from 'common/constants';
import { getBackendURL } from 'common/config';

export default class ApiAdmin {
    constructor() {
        this.client = axios.create({ baseURL: getBackendURL() });
    }

    useToken(token) {
        this.client = axios.create({
            baseURL: getBackendURL(),
            headers: { Authorization: token },
        });
    }

    useAdminId(id) {
        this.adminId = id;
    }

    async getTransactions(page = 1, status) {
        const search = new URLSearchParams();

        page && search.set('page', page.toString());
        status && search.set('status', status);

        const url = `/marketplace/transactions?${search.toString()}`;
        const response = await this.client.get(url);
        return response.data;
    }

    async approveTransaction(transactionID) {
        const url = '/marketplace/transactions/' + transactionID;
        const response = await this.client.post(url, {
            action: TRANSACTION_ACTIONS.APPROVE,
        });
        return response.data;
    }

    async denyTransaction(transactionID) {
        const url = '/marketplace/transactions/' + transactionID;
        const response = await this.client.post(url, {
            action: TRANSACTION_ACTIONS.DENY,
        });
        return response.data;
    }

    /**
     * @typedef {Object} NewSettingData
     * @property {SETTING_GROUPS} group
     * @property {SETTING_KEYS} key
     * @property {string} value
     * @property {string} dataType
     */

    /**
     * @param {NewSettingData} data
     * @returns {Promise<void>}
     */
    async createSetting(data) {
        const url = '/settings';
        const response = await this.client.put(url, data);
        return response.data;
    }

    /** @param {SETTING_KEYS} key */
    async getSettingByKey(key) {
        const url = `/settings/${key}`;
        const response = await this.client.get(url);
        return response.data;
    }

    /**
     * @typedef {Object} SettingsFilter
     * @property {SETTING_GROUPS|string} group
     */

    /**
     *
     * @param {SettingsFilter} filter
     * @returns {Promise<any>}
     */
    async getSettings(filter) {
        const url = `/settings`;
        const response = await this.client.post(url, filter);
        return response.data;
    }

    async getAdmins(page = 1) {
        const url = `/admins?page=${page}`;
        const response = await this.client.get(url);
        return response.data;
    }

    async getCurrentAdmin() {
        const url = `/admins/${this.adminId}`;
        const response = await this.client.get(url);
        return response.data;
    }

    async addAdmin(email) {
        const url = `/admins`;
        const response = await this.client.post(url, { email });
        return response.data;
    }

    async deleteAdmin(adminId) {
        const url = `/admins/${adminId}`;
        const response = await this.client.delete(url);
        return response.data;
    }

    async editAdmin(adminId, email) {
        const url = `/admins/${adminId}`;
        const response = await this.client.patch(url, { email });
        return response.data;
    }

    async getEvents(page = 1) {
        const url = `/events?page=${page}`;
        const response = await this.client.post(url);
        return response.data;
    }
}
