import React from "react";
import Container from '@material-ui/core/Container';
import CustomLink from "../../components/CustomLink/CustomLink";

import { useStyles } from '../../style.js';

const TermsOfService = () => {
    const classes = useStyles();

    return (
        <section className={classes.contentSection}>
            <Container className={classes.contentContainer} fixed>
                <h4 className={classes.contentPageTitle}>HausCoin Terms of Service</h4>
                <div>
                    <div className={classes.contentTitle}>IMPORTANT, PLEASE READ:</div>
                    <ul className={classes.contentTextList}>
                        <li>
                            PLEASE READ THESE TERMS OF SERVICE CAREFULLY, YOU AGREE TO BE BOUND BY
                            THESE TERMS OF SERVICE AND ALL TERMS INCORPORATED HEREIN BY REFERENCE.
                        </li>
                        <li>
                            ALL INVESTMENTS INVOLVE SUBSTANTIAL RISK OF LOSS. ALL TRADING STRATEGIES
                            ARE USED AT YOUR OWN RISK AND YOU ARE RESPONSIBLE FOR THE FINANCIAL RESOURCES
                            YOU UTILIZE. IF THE MARKET MOVES AGAINST YOU, YOU MAY SUSTAIN A TOTAL LOSS OF
                            THE INITIAL AMOUNT YOU ALLOCATED TO THE INVESTMENT. YOU SHOULD NOT ENGAGE IN
                            TRADING UNLESS YOU FULLY UNDERSTAND THE NATURE OF THE TRANSACTION YOU ARE
                            ENTERING INTO AND THE EXTENT OF YOUR EXPOSURE TO LOSS. IF YOU DO NOT FULLY
                            UNDERSTAND THESE RISKS, YOU SHOULD SEEK INDEPENDENT ADVICE FROM YOUR FINANCIAL ADVISOR.
                        </li>
                    </ul>
                    <div className={classes.contentTitle}>Introduction</div>
                    <p className={classes.contentText}>
                        Welcome to HausCoin. These Terms of Service, along with any other documents referenced herein
                        as applicable to your use of the products and services, features, technologies, and/or
                        functionalities
                        offered by HausCoin, LLC (“HausCoin”) on its website or in a mobile application (collectively,
                        the
                        “Platform”), or through any other means (collectively, the “Services”) are a contract between
                        you and
                        HausCoin, that establishes rules that control your use of the Services.
                    </p>
                    <p className={classes.contentText}>
                        You must be 18 years of age, in the United States, and have a U.S. bank account to use the
                        Services.
                        Your use of the Services remains subject to the HausCoin privacy policy as updated from time to
                        time
                        (the “HausCoin Privacy Policy”) and any other agreements between you and HausCoin that apply to
                        your
                        use of the Services. These Terms of Service are provided to you in electronic format pursuant to
                        the
                        Consent to Receive Electronic Disclosures that you accepted and agreed to when you opened your
                        Account
                        on the Platform (“Account”).
                    </p>
                    <p className={classes.contentText}>
                        “You” and “your” means the person who is authorized to use the Services as provided in these
                        Terms of
                        Service. “We,” “us,” and “our” mean HausCoin, and our successors, affiliates, assignees, and
                        third-party
                        service providers.
                    </p>
                    <p className={classes.contentText}>
                        If there is a conflict between these Terms of Service and any other document or statement made
                        to you
                        concerning the Services, these Terms of Service will govern. If there is a conflict between
                        these Terms
                        of Service and any other document or statement made to you concerning any other service or
                        product, the
                        separate terms, and conditions applicable to that service or product will govern.
                    </p>
                    <p className={classes.contentText} style={{textTransform: 'uppercase'}}>
                        AS SET FORTH BELOW, ANY CLAIM, DISPUTE, OR CONTROVERSY OF WHATEVER NATURE ARISING OUT OF OR
                        RELATING TO
                        THIS AGREEMENT WILL BE RESOLVED BY FINAL AND BINDING ARBITRATION IN ACCORDANCE WITH THE “DISPUTE
                        RESOLUTION”
                        PROVISION CONTAINED IN THIS AGREEMENT. THIS AGREEMENT ALSO INCLUDES A WAIVER OF CLASS ACTION AND
                        YOUR RIGHT
                        TO A JURY TRIAL. PLEASE READ THE “DISPUTE RESOLUTION” SECTION CAREFULLY AS IT AFFECTS YOUR LEGAL
                        RIGHTS AND
                        MAY BE ENFORCED BY US. BY ENTERING INTO THIS AGREEMENT, YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE
                        READ AND
                        UNDERSTAND ALL OF THE TERMS OF THIS AGREEMENT AND HAVE TAKEN TIME TO CONSIDER THE CONSEQUENCES
                        OF THIS IMPORTANT
                        DECISION.
                    </p>
                    <p className={classes.contentText}>
                        You agree to comply with all of the terms and conditions in these Terms of Service. In addition,
                        when using certain
                        Services, you will be subject to any additional terms applicable to such Services that may be
                        posted on the Platform.
                        Continued use of the Services means your ongoing agreement to these Terms of Service. By
                        continuing to use the Services,
                        you further agree to pay fees due and outstanding associated with the Services. You are
                        responsible for the accuracy
                        and completeness of all information supplied to us.
                    </p>
                    <p className={classes.contentText}>
                        These Terms of Service may be amended or changed at any time by posting the amended documents on
                        the Platform, and any
                        such amendment will be effective upon such posting. The current Terms of Service are available
                        at hauscoin.com. Reasonable
                        notice in writing or by any method permitted by law will be provided if there is an adverse
                        change to these Terms of Service.
                        However, if a change is made for security purposes, such change can be implemented without prior
                        notice. When these Terms of
                        Service are changed, the updated version supersedes all prior versions and will govern the
                        Services. Your continued maintenance
                        or use of the Services after the change will be deemed acceptance of any change and you will be
                        bound by it. If you do not agree
                        with a change, you may cease use of the Services. Your termination of these Terms of Service
                        does not affect any of our rights or
                        your obligations arising under these Terms of Service prior to such termination.
                    </p>
                    <p className={classes.contentText}>
                        Your privacy is very important to us. All services offered by HausCoin, including the Platform,
                        are subject to HausCoin’s Privacy
                        Policy. By using the Service, you consent to our collection and use of personal data as outlined
                        therein. Please carefully review
                        HausCoin’s Privacy Policy for more information.
                    </p>
                    <div className={classes.contentTitle}>Access to the Service</div>
                    <ul className={classes.contentTextList}>
                        <li>
                            <span className={classes.contentTextUnderline}>Services Description:</span> HausCoin provides a set
                            of technology solutions to allow investors to purchase HausCoin.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Eligibility:</span> You must be an individual of at
                            least 18 years of age who resides in the United States or one of
                            its territories to use the Service. Use of certain Services may have further eligibility
                            requirements that will
                            need to be verified prior to you using such Services or from time to time in order to
                            continue your use of the
                            Services. Certain features of the Services may be limited depending on the state in which
                            you reside, the date
                            on which you created your Account or other factors.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Your Registration Obligations:</span> You are
                            required to register on the Platform in order to access and use certain
                            features of the Service. Your acceptance of the Consent to Receive Electronic Disclosures
                            (E-sign Disclosure and Consent)
                            is required to create an Account. If you choose to register for the Service, you agree to
                            provide and maintain true, accurate,
                            current, and complete information about yourself as prompted by the Service’s registration
                            form. If any of this information
                            changes, it is your obligation to provide updated information as soon as possible. You are
                            responsible for providing HausCoin
                            with a correct and operational email address. You must notify HausCoin of any change to your
                            email or postal mailing address,
                            or if you are unable to access Account information through our Platform. HausCoin will not
                            be liable for any adverse effects
                            as a result of undelivered mail or email or your inability to access Account information
                            through the Platform due to a failure
                            to promptly notify us of a change to your email or postal mailing address. HausCoin is not
                            responsible for any costs you incur
                            to maintain internet access or an email account. From time to time, we may also require you
                            to provide further information as
                            a condition for continued use of the Services. We reserve the right to maintain your Account
                            registration information after you
                            close your Account for business and regulatory compliance purposes. You may register for
                            only one Account. If you open additional
                            Accounts, we reserve the right to immediately close such additional Accounts.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Important Information About Procedures for Opening a New Account:</span> To
                            help the government fight the funding of terrorism and money
                            laundering activities, Federal law requires all financial institutions to obtain, verify,
                            and record information that identifies
                            each person who opens an Account. What this means for you: When you open an Account, we will
                            ask for your name, address, date of
                            birth, and other information that will allow us to identify you. We may also ask to see your
                            driver’s license or other identifying
                            documents.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Member Account, Password and Security:</span> You
                            are responsible for maintaining the confidentiality of your password and Account, if any,
                            and are fully responsible for any and all activities that occur in connection with your
                            password or Account. You agree to (a) immediately
                            notify us of any unauthorized use of your password or Account or any other breach of
                            security, and (b) ensure that you exit from your
                            Account at the end of each session when accessing the Service. We will not be liable for any
                            loss or damage arising from your failure
                            to comply with this Section.
                        </li>
                    </ul>
                    <div className={classes.contentTitle}>Modifications to Service</div>
                    <p className={classes.contentText}>
                        We reserve the right to modify or discontinue, temporarily or permanently, the Service (or any
                        part thereof) with or without notice. You agree that we will not be liable to you or to any
                        third party for any modification, suspension, or discontinuance of the Service.
                    </p>
                    <div className={classes.contentTitle}>Use and Storage</div>
                    <p className={classes.contentText}>
                        You acknowledge that we may establish general practices and limits concerning use of the
                        Service, including, without limitation, the maximum period of time that data or other content
                        will
                        be retained by the Service and the maximum storage space that will be allotted on our servers on
                        your behalf. You agree that we have no responsibility or liability for the deletion or failure
                        to store any data or other content maintained or uploaded by the Service. You acknowledge that
                        we reserve the right to terminate Accounts that are inactive for an extended period of time, and
                        that we may require any balances in those Accounts to be transferred to another provider. You
                        further acknowledge that we reserve the right to change these general practices and limits at
                        any
                        time, in our sole discretion, with or without notice.
                    </p>
                    <div className={classes.contentTitle}>Mobile Services</div>
                    <p className={classes.contentText}>
                        Some of the Services are available via a mobile device, including (a) the ability to upload
                        content to the Service via a mobile device, (b) the ability to browse the Service and the site
                        from
                        a mobile device and (c) the ability to access certain features through an application downloaded
                        and installed on a mobile device (collectively, the “Mobile Services”). To the extent you access
                        the Service via a mobile device, your wireless service carrier’s standard charges, data rates
                        and other fees may apply. In addition, downloading, installing, or using certain Mobile Services
                        may
                        be prohibited or restricted by your carrier, and not all Mobile Services may work with all
                        carriers or devices. You authorize your wireless operator to disclose your mobile number, name,
                        address,
                        email, network status, customer type, customer role, billing type, mobile device identifiers
                        (IMSI and IMEI) and other subscriber and device details, if available, to us and our Service
                        providers
                        for the duration of the business relationship, solely for identity verification and fraud
                        avoidance.
                    </p>
                    <div className={classes.contentTitle}>Consent to Receive Communications</div>
                    <p className={classes.contentText}>
                        To provide you with the best possible service in our ongoing business relationship, we may need
                        to contact you about your Account or the Services from time to time by text messaging, push
                        notifications
                        and/or email. However, we must first obtain your consent to contact you about your Account or
                        the Services because we must comply with the consumer protection provisions in the federal
                        Telephone Consumer
                        Protection Act of 1991 (TCPA), CAN-SPAM Act, and their related federal regulations and orders
                        issued by the Federal Communications Commission (FCC).
                    </p>
                    <p className={classes.contentText}>
                        Your consent is limited to your Account and the Services, and as authorized by applicable law
                        and regulations. Your consent does not authorize us to contact you for telemarketing purposes
                        (unless you
                        otherwise agreed elsewhere). You certify, warrant, and represent that the telephone numbers that
                        you have provided to us are your contact numbers. You represent that you are permitted to
                        receive calls
                        at each of the telephone numbers you have provided to us. You agree to promptly alert us
                        whenever you stop using a particular telephone number.
                    </p>
                    <p className={classes.contentText}>
                        With the above understandings, you authorize us to contact you regarding your Account and/or the
                        Services until you terminate your Account using any telephone numbers or email addresses that
                        you have
                        previously provided to us or that you may subsequently provide to us. You understand that you
                        are not required to provide consent to marketing SMS messages as a condition to accessing our
                        Services or
                        products. You may withdraw your consent to SMS communications by replying STOP to the SMS
                        message, or by contacting us at <CustomLink href="info@HausCoin.com">info@HausCoin.com</CustomLink>.
                    </p>
                    <p className={classes.contentText}>
                        You are responsible for any and all charges, including fees associated with text messaging,
                        imposed by your communications service provider. You also agree to receive alerts about your
                        Account activity,
                        balances, payments, suspicious activities, and other matters involving your use of the Platform
                        or the Services through push notifications to your smartphone or other device. Receipt of push
                        notifications
                        may be delayed or prevented by factors beyond our control, including those affecting your
                        internet/phone provider. We are not liable for losses or damages arising from non-delivery,
                        delayed delivery, or
                        the erroneous delivery of any push notification; inaccurate push notification content; or your
                        use or reliance on the content of any push notification for any purposes. Each push notification
                        may not be
                        encrypted and may include your name and information pertaining to your Account or use of the
                        Platform. We may terminate your use of push notifications at any time without notice. You may
                        choose to discontinue
                        receiving push notifications by updating your preferences on your smartphone or device.
                    </p>
                    <p className={classes.contentText}>
                        This consent is regardless of whether the number we use to contact you is assigned to a
                        landline, a paging service, a cellular wireless service, a specialized mobile radio service,
                        other radio common carrier
                        service or any other service for which you may be charged for the call. You further authorize us
                        to contact you through the use of voice, voicemail, and text messaging, including the use of
                        pre-recorded or
                        artificial voice messages and an automated dialing device. If necessary, you may change or
                        remove any of the telephone numbers or email addresses at any time using any reasonable means to
                        notify us. To revoke
                        the consent provided pursuant to this subsection, you must send 10 days’ prior written notice of
                        such revocation to <CustomLink href="info@HausCoin.com">info@HausCoin.com</CustomLink>.
                    </p>
                    <p className={classes.contentText}>
                        By accepting these Terms of Service, you expressly consent to be contacted by us or our
                        affiliates at any telephone number, e-mail address, mailing address, Account with us, or
                        physical or electronic address
                        you provide or at which you may be reached. You agree we, our affiliates, agents, or service
                        providers may contact you in any way, including by e-mail, SMS messages (including text
                        messages) and calls using
                        prerecorded messages or artificial voice at any phone number you have provided to us, including
                        any mobile phone number, as well as any address in our records or in public or nonpublic
                        databases. You understand
                        that you are not required to provide consent to marketing SMS messages as a condition to
                        accessing our Services or products. You may withdraw your consent to SMS communications by
                        replying STOP to the SMS message,
                        or by contacting us at <a href="mailto:info@hauscoin.com">info@HausCoin.com</a> You agree that we and our agents, representatives,
                        affiliates, or anyone calling on our behalf may contact you on a recorded or monitored line and
                        that any incoming calls
                        may also be recorded and monitored.
                    </p>
                    <p className={classes.contentText}>
                        We may monitor or record phone calls for security reasons, to maintain a record, and to ensure
                        that you receive courteous and efficient service. You consent in advance to any such recording.
                        We need not remind you
                        of our recording before each phone conversation. We are not required to act upon instructions
                        you give us by voice mail or on a telephone answering machine.
                    </p>
                    <div className={classes.contentTitle}>Conditions of Use</div>
                    <ul className={classes.contentTextList}>
                        <li>
                            <span className={classes.contentTextUnderline}>Account Suspension & Closure:</span> We may, in our
                            sole and absolute discretion, without liability to you or any third party, refuse to let you
                            open an Account, suspend
                            your Account, or terminate your Account or your use of one or more of the Services. Such
                            actions may be taken as a result of Account inactivity, failure to respond to customer
                            support requests, failure to
                            positively identify you, or your violation of these Terms of Service. If you have a balance
                            remaining in an Account which has been suspended or closed, you will be able to recover such
                            funds, unless prohibited
                            by law or a court order or where we have determined that such funds were obtained
                            fraudulently. We have full discretion as to the means by which you will receive such funds
                            following our decision to return such
                            funds. We reserve the right to require you to provide further identifying information before
                            processing such withdrawal or transfer requests and to delay or prohibit such withdrawal or
                            transfer if we believe
                            fraudulent or illegal activity has occurred. If you are unable to login to your Account, you
                            will need to contact us at <a href="info@hauscoin.com">info@HausCoin.com</a> to process such withdrawal.
                            You may terminate this agreement at any time
                            by closing your Account and discontinuing use of the Services. Upon termination of this
                            agreement and your Account, you remain liable for all transactions made while your Account
                            was open.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>User Conduct:</span> You are solely responsible for
                            all code, video, images, information, data, text, software, music, sound, photographs,
                            graphics, messages, or other
                            materials (“content”) that you upload, post, publish or display (hereinafter, “upload”) or
                            email or otherwise use via the Service. The following are examples of the kind of content
                            and/or use that is illegal
                            or that we prohibit. You agree to not use the Service to:
                            <ul className={classes.contentTextList}>
                                <li>
                                    violate (or assist any other party in violating) any applicable law, statute,
                                    ordinance, or regulation;
                                </li>
                                <li>
                                    intentionally try to defraud (or assist in the defrauding of) HausCoin or other
                                    HausCoin users;
                                </li>
                                <li>
                                    provide false, inaccurate, or misleading information;
                                </li>
                                <li>
                                    take any action that interferes with, intercepts, or expropriates any system, data,
                                    or information;
                                </li>
                                <li>
                                    partake in any transaction involving the proceeds of illegal activity;
                                </li>
                                <li>
                                    transmit or upload any virus, worm, or other malicious software or program;
                                </li>
                                <li>
                                    attempt to gain unauthorized access to other Accounts, the Platform, or any related
                                    networks or systems;
                                </li>
                                <li>
                                    use the Services on behalf of any third party or otherwise act as an intermediary
                                    between HausCoin and any third parties;
                                </li>
                                <li>
                                    collect any user information from other HausCoin users, including, without
                                    limitation, email addresses;
                                </li>
                                <li>
                                    defame, harass, or violate the privacy or intellectual property rights of HausCoin
                                    or any other HausCoin users;
                                </li>
                                <li>
                                    upload, display or transmit any messages, photos, videos, or other media that
                                    contain illegal goods, pornographic, violent,
                                    obscene, or copyrighted images or materials for use as an avatar, in connection with
                                    a payment or payment request, or otherwise.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Fees:</span> To the extent the Service or any
                            portion thereof is made available for any fee, you will be required to select a form of
                            payment and provide information to
                            us regarding your form of payment. You represent and warrant to us that such information is
                            true, correct, and accurate, and that you are authorized to use the payment instrument. You
                            will promptly update your
                            Account information with any changes (for example, a change in your billing address) that
                            may occur. We reserve the right to change our prices and any applicable waivers at any time.
                            If we do change our prices,
                            we will provide notice of the change on the Platform or in an email to you, at our option,
                            at least thirty (30) days before the change is to take effect. Your continued use of the
                            Service after the price change
                            becomes effective constitutes your agreement to pay the changed amount. You are responsible
                            for paying any tax, legal or financial advisors, all taxes associated with the Services
                            other than U.S. taxes based on
                            our net income and any fees charged by third parties for you to access or use the Services.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Special Notice for International Use; Export Controls:</span> Software
                            (defined below) available in connection with the Service and the transmission of applicable
                            data,
                            if any, is subject to United States export controls. No Software may be downloaded from the
                            Service or otherwise exported or re-exported in violation of U.S. export laws. Downloading
                            or using the Software is
                            at your sole risk. Recognizing the global nature of the Internet, you agree to comply with
                            all local rules and laws regarding your use of the Service, including as it concerns to
                            online conduct and acceptable content.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Commercial Use:</span> Unless otherwise expressly
                            authorized herein or in the Service, you agree not to display, distribute, license, perform,
                            publish, reproduce, duplicate,
                            copy, create derivative works from, modify, sell, resell, exploit, transfer or upload for
                            any commercial purposes, any portion of the Service, use of the Service, or access to the
                            Service.
                        </li>
                        <li>
                            <span
                                className={classes.contentTextUnderline}>Illegal Transactions and Internet Gambling:</span> You
                            must not use our Services or your Account for any illegal purpose, for internet gambling or
                            to fund any account that
                            is set up to facilitate internet gambling. We may deny transactions or authorizations from
                            merchants or other sources that are apparently engaged in or are identified as engaged in
                            the internet gambling business.
                        </li>
                    </ul>
                    <div className={classes.contentTitle}>Third Parties</div>
                    <ul className={classes.contentTextList}>
                        <li>
                            <span className={classes.contentTextUnderline}>Third-Party Account Information:</span> To use the
                            Services, you may direct HausCoin to retrieve your transaction history, balance information,
                            and/or other information maintained
                            by third parties with which you have relationships, maintain accounts, or engage in
                            financial transactions (“Third-Party Account Information”). HausCoin works with one or more
                            third-party service providers to access
                            this Third-Party Account Information. We will use this information to provide you with the
                            Services you request, for our own internal business purposes and to offer you other HausCoin
                            products and services that may
                            be of interest to you. By using the Services, you authorize HausCoin to access this
                            information maintained by such third parties, on your behalf as your agent, and you
                            expressly authorize such third parties to disclose
                            your information to us. HausCoin may, but is not required to, review the Third-Party Account
                            Information for accuracy, legality, or non-infringement, and HausCoin is not responsible for
                            your Third-Party Account Information
                            or products and services offered by or on third-party sites. You acknowledge that any
                            Third-Party Account Information that is displayed through the Services will correspond to
                            the information provided by third parties, and
                            that this information may not reflect pending transactions or other recent activity.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Third Party Material:</span> Under no circumstances
                            will we be liable in any way for any content or materials of any third parties (including
                            users), including, but not limited to,
                            for any errors or omissions in any content, or for any loss or damage of any kind incurred
                            as a result of the use of any such content. You acknowledge that we do not pre-screen
                            content, but that HausCoin and our designees
                            will have the right (but not the obligation) in our sole discretion to refuse or remove any
                            content that is available via the Service. Without limiting the foregoing, we and our
                            designees will have the right to remove any
                            content that violates these Terms of Service or that we deem, in our sole discretion, to be
                            otherwise objectionable. You agree that you must evaluate, and bear all risks associated
                            with, the use of any content, including
                            any reliance on the accuracy, completeness, or usefulness of such content.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Third-Party Websites:</span> The Service may
                            provide, or third parties may provide, links or other access to other sites and resources on
                            the Internet. We have no control over such
                            sites and resources and are not responsible for and do not endorse such sites and resources.
                            You further acknowledge and agree that we will not be responsible or liable, directly, or
                            indirectly, for any damage or loss caused
                            or alleged to be caused by or in connection with use of or reliance on any content, events,
                            goods, or services available on or through any such site or resource. Any dealings you have
                            with third parties found while using the
                            Service are between you and the third party, and you agree that we are not liable for any
                            loss or claim that you may have against any such third party.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Third-Party Information:</span> HausCoin makes no
                            representation as to the completeness or accuracy of the information provided by any third
                            party on the HausCoin Platform. HausCoin
                            does not endorse or represent the reliability or accuracy of any content or information
                            distributed through or accessed from the HausCoin Platform and has not performed any
                            investigation into such information. By using the
                            HausCoin Platform, each Investor acknowledges and agrees that HausCoin does not provide any
                            representation, warranty, or assurance of any kind with respect to the nature, quality,
                            validity, or legality of any information
                            posted to the HausCoin Platform or offering of securities by any Issuer. HausCoin shall have
                            no liability for any investment decisions made based upon such information. Any reliance
                            upon any content or information distributed
                            through or accessed from the HausCoin Platform is at the User’s sole risk.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Third-Party Errors:</span> HausCoin is not
                            responsible for errors made by Investors or third parties when onboarding, offboarding, or
                            otherwise interacting with HausCoin services.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Third-Party Distribution Channels:</span> We may
                            offer Software applications that may be made available through the Apple App Store, Android
                            Marketplace, or other distribution channels (“Distribution Channels”). If you obtain such
                            Software through a Distribution Channel, you may be subject to additional terms of the
                            Distribution Channel. These Terms of Service are between you and us only, and not with the
                            Distribution Channel. To the extent that you utilize any other third-party products and
                            services in connection with your use of our Services, you agree to comply with all
                            applicable terms of any agreement for such third-party products and services. Further, you
                            may be consenting to share certain personal information with us when you install one of our
                            Software applications through a distribution channel.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Apple-Enabled Software:</span> With respect to
                            Software that is made available for your use in connection with an Apple-branded product
                            (such Software, “Apple-Enabled Software”), in addition to the other terms and conditions set
                            forth in these Terms of Service, the following terms and conditions apply:
                            <ul className={classes.contentTextList}>
                                <li>
                                    HausCoin and you acknowledge that these Terms of Service are concluded between
                                    HausCoin and you only, and not with Apple Inc. (“Apple”), and that as between
                                    HausCoin and Apple, HausCoin, not Apple, is solely responsible for the Apple-Enabled
                                    Software and the content thereof.
                                </li>
                                <li>
                                    You may not use the Apple-Enabled Software in any manner that is in violation of or
                                    inconsistent with the “Usage Rules” set forth for Apple-Enabled Software in, or
                                    otherwise be in conflict with, the applicable App Store Terms of Service.
                                </li>
                                <li>
                                    Your license to use the Apple-Enabled Software is limited to a non-transferable
                                    license to use the Apple-Enabled Software on an iOS product that you own or control,
                                    as permitted by the Usage Rules set forth in the App Store Terms of Service.
                                </li>
                                <li>
                                    Apple has no obligation whatsoever to provide any maintenance or support services
                                    with respect to the Apple-Enabled Software.
                                </li>
                                <li>
                                    Apple is not responsible for any product warranties, whether express or implied by
                                    law. In the event of any failure of the Apple-Enabled Software to conform to any
                                    applicable warranty, you may notify Apple, and Apple will refund the purchase price
                                    for the Apple-Enabled Software to you, if any; and, to the maximum extent permitted
                                    by applicable law, Apple will have no other warranty obligation whatsoever with
                                    respect to the Apple-Enabled Software, or any other claims, losses, liabilities,
                                    damages, costs or expenses attributable to any failure to conform to any warranty,
                                    which will be HausCoin’s sole responsibility, to the extent it cannot be disclaimed
                                    under applicable law.
                                </li>
                                <li>
                                    HausCoin and you acknowledge that HausCoin, not Apple, is responsible for addressing
                                    any claims of you or any third party relating to the Apple-Enabled Software or your
                                    possession and/or use of that Apple-Enabled Software, including, but not limited to:
                                    (i) product liability claims; (ii) any claim that the Apple-Enabled Software fails
                                    to conform to any applicable legal or regulatory requirement; and (iii) claims
                                    arising under consumer protection or similar legislation.
                                </li>
                                <li>
                                    In the event of any third-party claim that the Apple-Enabled Software or the
                                    end-user’s possession and use of that Apple-Enabled Software infringes that third
                                    party’s intellectual property rights, as between HausCoin and Apple, HausCoin, not
                                    Apple, will be solely responsible for the investigation, defense, settlement, and
                                    discharge of any such intellectual property infringement claim.
                                </li>
                                <li>
                                    You represent and warrant that (i) you are not located in a country that is subject
                                    to a U.S. Government embargo, or that has been designated by the U.S. Government as
                                    a “terrorist supporting” country; and (ii) you are not listed on any U.S. Government
                                    list of prohibited or restricted parties.
                                </li>
                                <li>
                                    If you have any questions, complaints or claims with respect to the Apple-Enabled
                                    Software, they should be directed to HausCoin as follows: info@HausCoin.com.
                                </li>
                                <li>
                                    HausCoin and you acknowledge and agree that Apple, and Apple’s subsidiaries, are
                                    third party beneficiaries of these Terms of Service with respect to the
                                    Apple-Enabled Software, and that, upon your acceptance of the terms and conditions
                                    of these Terms of Service, Apple will have the right (and will be deemed to have
                                    accepted the right) to enforce these Terms of Service against you with respect to
                                    the Apple-Enabled Software as a third party beneficiary thereof.
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <div className={classes.contentTitle}>Intellectual Property Rights</div>
                    <ul className={classes.contentTextList}>
                        <li>
                            <span className={classes.contentTextUnderline}>Service Content, Software and Trademarks:</span> You
                            acknowledge and agree that the Service may contain content or features (“Service Content”)
                            that are protected by copyright, patent, trademark, trade secret or other proprietary rights
                            and laws. Except as expressly authorized by us, you agree not to modify, copy, frame,
                            scrape, rent, lease, loan, sell, distribute, or create derivative works based on the Service
                            or the Service Content, in whole or in part, except that the foregoing does not apply to
                            your own User Content (as defined below) that you legally upload to the Service. In
                            connection with your use of the Service you will not engage in or use any data mining,
                            robots, scraping, or similar data gathering or extraction methods. If you are blocked by us
                            from accessing the Service (including by blocking your IP address), you agree not to
                            implement any measures to circumvent such blocking (e.g., by masking your IP address or
                            using a proxy IP address). Any use of the Service or the Service Content other than as
                            specifically authorized herein is strictly prohibited. The technology and software
                            underlying the Service or distributed in connection therewith are the property of HausCoin,
                            and our partners (the “Software”). You agree not to copy, modify, create a derivative work
                            of, reverse engineer, reverse assemble or otherwise attempt to discover any source code,
                            sell, assign, sublicense, or otherwise transfer any right in the Software. Any rights not
                            expressly granted herein are reserved by HausCoin. The HausCoin name and logos are
                            trademarks and service marks of HausCoin (collectively, the “HausCoin Trademarks”). Other
                            HausCoin product, and Service names and logos used and displayed via the Service may be
                            trademarks or service marks of their respective owners who may or may not endorse or be
                            affiliated with or connected to HausCoin. Nothing in these Terms of Service or the Service
                            should be construed as granting, by implication, estoppel, or otherwise, any license or
                            right to use any of HausCoin Trademarks displayed on the Service, without our prior written
                            permission in each instance. All goodwill generated from the use of the HausCoin Trademarks
                            will inure to our exclusive benefit.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>User Content:</span> With respect to the content or
                            other materials you upload through the Service or share with other users or recipients
                            (collectively, “User Content”), you represent and warrant that you own all right, title and
                            interest in and to such User Content, including, without limitation, all copyrights and
                            rights of publicity contained therein. By uploading any User Content, you hereby grant and
                            will grant to us and its affiliated companies a nonexclusive, worldwide, royalty free, fully
                            paid up, transferable, sublicensable, perpetual, irrevocable license to copy, display,
                            upload, perform, distribute, store, modify and otherwise use your User Content in connection
                            with the operation of the Service or the promotion, advertising or marketing thereof, in any
                            form, medium or technology now known or later developed. You acknowledge and agree that any
                            questions, comments, suggestions, ideas, feedback, or other information about the Service
                            (“Submissions”), provided by you to us are non-confidential and we will be entitled to the
                            unrestricted use and dissemination of these Submissions for any purpose, commercial or
                            otherwise, without acknowledgment or compensation to you. You acknowledge and agree that we
                            may preserve content and may also disclose content if required to do so by law or in the
                            good faith belief that such preservation or disclosure is reasonably necessary to: (a)
                            comply with legal process, applicable laws or government requests; (b) enforce these Terms
                            of Service; (c) respond to claims that any content violates the rights of third parties; or
                            (d) protect the rights, property, or personal safety of HausCoin, its users and the public.
                            You understand that the technical processing and transmission of the Service, including your
                            content, may involve (a) transmissions over various networks; and (b) changes to conform and
                            adapt to technical requirements of connecting networks or devices.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Copyright Complaints:</span> We respect the
                            intellectual property of others, and we ask our users to do the same. If you believe that
                            your work has been copied in a way that constitutes copyright infringement, or that your
                            intellectual property rights have been otherwise violated, you should notify us of your
                            infringement claim in accordance with the procedure set forth below. We will process and
                            investigate notices of alleged infringement and will take appropriate actions under the
                            Digital Millennium Copyright Act (“DMCA”) and other applicable intellectual property laws
                            with respect to any alleged or actual infringement. A notification of claimed copyright
                            infringement should be emailed to HausCoin’s Copyright Agent at info@HausCoin.com (Subject
                            line: “DMCA Takedown Request”). You may also contact us by mail at: HausCoin, LLC, 660 4th
                            Street, #418, San Francisco, CA 94107.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Copyright Notices:</span> To be effective, the
                            notification must be in writing and contain the following information:
                            <ul className={classes.contentTextList}>
                                <li>
                                    an electronic or physical signature of the person authorized to act on behalf of the
                                    owner of the copyright or other intellectual property interest;
                                </li>
                                <li>
                                    a description of the copyrighted work or other intellectual property that you claim
                                    has been infringed;
                                </li>
                                <li>
                                    a description of where the material that you claim is infringing is located on the
                                    Service, with enough detail that we may find it on the Service;
                                </li>
                                <li>
                                    your address, telephone number, and email address;
                                </li>
                                <li>
                                    a statement by you that you have a good faith belief that the disputed use is not
                                    authorized by the copyright or intellectual property owner, its agent, or the law;
                                </li>
                                <li>
                                    a statement by you, made under penalty of perjury, that the above information in
                                    your notice is accurate and that you are the copyright or intellectual property
                                    owner or authorized to act on the copyright or intellectual property owner’s behalf.
                                </li>
                            </ul>
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Counter-Notice:</span> If you believe that your User
                            Content that was removed (or to which access was disabled) is not infringing, or that you
                            have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to
                            the law, to upload and use the content in your User Content, you may send a written
                            counter-notice containing the following information to the Copyright Agent:
                            <ul className={classes.contentTextList}>
                                <li>
                                    your physical or electronic signature;
                                </li>
                                <li>
                                    identification of the content that has been removed or to which access has been
                                    disabled and the location at which the content appeared before it was removed or
                                    disabled;
                                </li>
                                <li>
                                    a statement that you have a good faith belief that the content was removed or
                                    disabled as a result of mistake or a misidentification of the content;
                                </li>
                                <li>
                                    your name, address, telephone number, and email address, a statement that you
                                    consent to the jurisdiction of the federal court located within the Northern
                                    District of California and a statement that you will accept service of process from
                                    the person who provided notification of the alleged infringement.
                                </li>
                            </ul>
                            If a counter-notice is received by the Copyright Agent, we will send a copy of the
                            counter-notice to the original complaining party informing that person that it may replace
                            the removed content or cease disabling it in ten (10) business days. Unless the copyright
                            owner files an action seeking a court order against the content provider, member or user,
                            the removed content may be replaced, or access to it restored, in ten (10) to fourteen (14)
                            business days or more after receipt of the counter-notice, at our sole discretion.
                        </li>
                        <li>
                            <span className={classes.contentTextUnderline}>Repeat Infringer Policy:</span> In accordance with
                            the DMCA and other applicable law, we have adopted a policy of terminating, in appropriate
                            circumstances and at our sole discretion, users who are deemed to be repeat infringers. We
                            may also in our sole discretion limit access to the Service and/or terminate the memberships
                            of any users who infringe any intellectual property rights of others, whether or not there
                            is any repeat infringement.
                        </li>
                    </ul>
                    <div className={classes.contentTitle}>Indemnity and Release</div>
                    <p className={classes.contentText}>
                        You agree to release, indemnify and hold HausCoin, and its officers, directors, shareholders,
                        employees, successors, predecessors, representatives, principals, agents, assigns, parents,
                        subsidiaries and/or insurers harmless for any losses, damages, suits and expenses, including
                        reasonable attorneys’ fees, that we may incur, without regard to the merit or lack thereof,
                        arising out of, or related in any way to (1) the matters set forth herein; (2) our taking any
                        action or not taking any action that we are entitled to take pursuant to these Terms of Service
                        and applicable law; (3) any action or omission by you in violation of these Terms of Service or
                        applicable law; or (4) our action or inaction in reliance upon oral, written or electronic
                        instructions or information from you. If you are a California resident, you waive California
                        Civil Code Section 1542, which says: “A general release does not extend to claims which the
                        creditor does not know or suspect to exist in his favor at the time of executing the release,
                        which if known by him must have materially affected his settlement with the debtor.” If you are
                        a resident of another jurisdiction, you waive any comparable statute or doctrine.
                    </p>
                    <div className={classes.contentTitle}>DISCLAIMER OF WARRANTIES</div>
                    <p className={classes.contentText}>
                        YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS
                        AVAILABLE” BASIS. HAUSCOIN EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS,
                        IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY,
                        FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT. HAUSCOIN MAKES NO WARRANTY THAT
                        (I) THE SERVICE WILL MEET YOUR REQUIREMENTS, (II) THE SERVICE WILL BE UNINTERRUPTED, TIMELY,
                        SECURE, OR ERROR-FREE, (III) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE WILL
                        BE ACCURATE OR RELIABLE, OR (IV) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER
                        MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET YOUR EXPECTATIONS.
                    </p>
                    <div className={classes.contentTitle}>LIMITATION OF LIABILITY</div>
                    <p className={classes.contentText}>
                        YOU EXPRESSLY UNDERSTAND AND AGREE THAT HAUSCOIN WILL NOT BE LIABLE FOR ANY INDIRECT,
                        INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS,
                        INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE
                        LOSSES (EVEN IF HAUSCOIN HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON
                        CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM: (I) THE USE OR THE
                        INABILITY TO USE THE SERVICE; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
                        RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES
                        RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE; (III) UNAUTHORIZED ACCESS TO
                        OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON
                        THE SERVICE; OR (V) ANY OTHER MATTER RELATING TO THE SERVICE. IN NO EVENT WILL HAUSCOIN’S TOTAL
                        LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID
                        HAUSCOIN IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE HUNDRED DOLLARS ($100). SOME
                        JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OR EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION
                        OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE
                        ABOVE LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU OR BE ENFORCEABLE WITH RESPECT TO YOU. IF
                        YOU ARE DISSATISFIED WITH ANY PORTION OF THE SERVICE OR WITH THESE TERMS OF SERVICE, YOUR SOLE
                        AND EXCLUSIVE REMEDY IS TO DISCONTINUE USE OF THE SERVICE. IF YOU ARE A USER FROM NEW JERSEY,
                        THE FOREGOING SECTIONS TITLED “DISCLAIMER OF WARRANTIES” AND “LIMITATION OF LIABILITY” ARE
                        INTENDED TO BE ONLY AS BROAD AS IS PERMITTED UNDER THE LAWS OF THE STATE OF NEW JERSEY. IF ANY
                        PORTION OF THESE SECTIONS IS HELD TO BE INVALID UNDER THE LAWS OF THE STATE OF NEW JERSEY, THE
                        INVALIDITY OF SUCH PORTION WILL NOT AFFECT THE VALIDITY OF THE REMAINING PORTIONS OF THE
                        APPLICABLE SECTIONS.
                    </p>
                    <h4 className={classes.contentPageTitle}>DISPUTE RESOLUTION BY BINDING ARBITRATION</h4>
                    <ul className={classes.contentTextList}>
                        <li>
                            <span className={classes.contentTitle}>PLEASE READ THIS SECTION CAREFULLY AS IT AFFECTS YOUR RIGHTS.</span>
                            <p className={classes.contentText}>
                                This Dispute Resolution provision (this “Dispute Resolution Provision”) sets forth the
                                circumstances and procedures under which Claims (as defined below) will be arbitrated
                                instead of litigated in court upon the election of either party. Neither you nor we will
                                have the right to: (1) have a court or a jury decide the dispute; (2) engage in
                                information-gathering (discovery) to the same extent as in court; (3) participate in a
                                class
                                action in court or in class arbitration; or (4) join or consolidate a claim with claims
                                of
                                any other persons. Arbitration procedures are simpler and more limited than rules
                                applicable
                                in court. The decision of the arbitrator is final and binding.
                            </p>
                            <p className={classes.contentText}>
                                As used in this Dispute Resolution Provision, the term “Claim” means any claim,
                                dispute, or
                                controversy between you and HausCoin, or any of its agents or retailers, arising from or
                                relating to these Terms of Service or the Services as well as any related or prior
                                agreement
                                that you may have had with us, including the validity, enforceability or scope of this
                                Dispute Resolution Provision or the Terms of Service. “Claim” includes claims of every
                                kind
                                and nature, including, but not limited to, initial claims, counterclaims, crossclaims
                                and
                                third-party claims and claims based upon contract, tort, fraud and other intentional
                                torts,
                                statutes, regulations, common law, and equity. The term “Claim” is to be given the
                                broadest
                                possible meaning that will be enforced. We will not elect to use arbitration under the
                                Dispute Resolution Provision for any Claim that you properly file and pursue in a small
                                claims court of your state or municipality so long as the Claim is individual and
                                pending
                                only in that court; any appeals from that court will be pursued only in arbitration. As
                                used
                                in this Dispute Resolution Provision, the terms “we” and “us” will for all purposes mean
                                HausCoin, our wholly or majority owned subsidiaries, affiliates, licensees,
                                predecessors,
                                successors, and assigns, and our agents, employees, directors, and representatives. In
                                addition, “we” or “us” will include any third party using or providing any product,
                                service
                                or benefit in connection with the Services (including, but not limited to, third parties
                                who
                                use or provide services, debt collectors and all of their agents, employees, directors
                                and
                                representatives) if, and only if, such third party is named as a co-party with us (or
                                files
                                a Claim with or against us) in connection with a Claim asserted by you. As solely used
                                in
                                this Dispute Resolution Provision, the terms “you” or “yours” will mean all persons or
                                entities using the Services.
                            </p>
                        </li>
                        <li>
                            <span className={classes.contentTitle}>NEITHER YOU NOR WE ARE ENTITLED TO JOIN OR CONSOLIDATE
                                DISPUTES BY OR AGAINST OTHERS IN ANY COURT ACTION OR ARBITRATION, OR TO INCLUDE IN ANY
                                COURT ACTION OR ARBITRATION ANY DISPUTE AS A REPRESENTATIVE OR MEMBER OF A CLASS, OR TO
                                ACT IN ANY ARBITRATION IN THE INTEREST OF THE GENERAL PUBLIC OR IN A PRIVATE ATTORNEY
                                GENERAL CAPACITY, UNLESS THOSE PERSONS ARE BENEFICIARIES ON YOUR ACCOUNT. THIS IS SO
                                WHETHER OR NOT THE CLAIM HAS BEEN ASSIGNED. FURTHER, NEITHER YOU NOR WE HAVE THE RIGHT
                                TO LITIGATE A CLAIM IN COURT, SUBJECT TO THE SECTION TITLED EXCLUDED CLAIMS, OR HAVE A
                                JURY TRIAL ON A CLAIM, OR TO ENGAGE IN DISCOVERY, EXCEPT AS PROVIDED FOR IN THE CODE OF
                                PROCEDURES OF JAMS OR AAA.
                            </span>
                            <p className={classes.contentText}>
                                Any Claim will be resolved, upon the election by you or us, by arbitration pursuant to
                                this Dispute Resolution Provision and the code of procedures of the national arbitration
                                organization to which the Claim is referred in effect at the time the Claim is filed.
                                Claims will be referred to either Judicial Arbitration and Mediation Services (“JAMS”)
                                or the American Arbitration Association (“AAA”), as selected by the party electing to
                                use arbitration. If a selection by us of one of these organizations is unacceptable to
                                you, you will have the right within thirty (30) days after you receive notice of our
                                election to select the other organization listed to serve as arbitrator administrator.
                                For a copy of the procedures, to file a Claim or for other information about these
                                organizations, contact them as follows: (i) JAMS at 1920 Main Street, Suite 300, Los
                                Angeles, CA 92614; website at <CustomLink href="http://www.jamsadr.com">www.jamsadr.com</CustomLink> and (ii) AAA at 335 Madison Avenue, New
                                York, NY 10017; website at <CustomLink href="http://www.adr.org">www.adr.org</CustomLink>.
                            </p>
                            <p className={classes.contentText}>
                                If either party elects to resolve a Claim by arbitration, that Claim will be arbitrated
                                on an individual basis. There is no right or authority for any Claims to be arbitrated
                                on a class action basis or on bases involving Claims brought in a purported
                                representative capacity on behalf of the general public, other Account holders or other
                                persons similarly situated. The arbitrator’s authority to resolve Claims is limited to
                                Claims between you and us alone, and the arbitrator’s authority to make awards is
                                limited to you and us alone. Furthermore, Claims brought by you against us or by us
                                against you may not be joined or consolidated in arbitration with Claims brought by or
                                against someone other than you, unless otherwise agreed to in writing by all parties.
                            </p>
                            <p className={classes.contentText}>
                                Any arbitration hearing that you attend will take place in the federal judicial district
                                of your residence. At your written request, we will consider in good faith making a
                                temporary advance of all or part of the filing administrative and/or hearing fees for
                                any Claim you initiate as to which you or we seek arbitration. At the conclusion of the
                                arbitration (or any appeal thereof), the arbitrator (or panel) will decide who will
                                ultimately be responsible for paying the filing, administrative and/or hearing fees in
                                connection with the arbitration (or appeal). If and to the extent you incur filing,
                                administrative and/or hearing fees in arbitration, including for any appeal, exceeding
                                the amount they would have been if the Claim had been brought in the state or federal
                                court which is closest to your billing address and would have had jurisdiction over the
                                Claim, we will reimburse you to that extent unless the arbitrator (or panel) determines
                                that the fees were incurred without any substantial justification.
                            </p>
                            <p className={classes.contentText}>
                                This Dispute Resolution Provision is made pursuant to a transaction involving interstate
                                commerce, and will be governed by the Federal Arbitration Act, 9 U.S.C. Sections 1-16,
                                as it may be amended (the “FAA”). The arbitration will be governed by the applicable
                                Code, except that (to the extent enforceable under the FAA) this Dispute Resolution
                                Provision will control if it is inconsistent with the applicable Code. The arbitrator
                                will apply applicable substantive law consistent with the FAA and applicable statutes of
                                limitations and will honor claims of privilege recognized at law and, at the timely
                                request of either party, will provide a brief written explanation of the basis for the
                                decision. In conducting the arbitration proceeding, the arbitrator will not apply the
                                Federal or any state rules of civil procedure or rules of evidence. Either party may
                                submit a request to the arbitrator to expand the scope of discovery allowable under the
                                applicable Code. The party submitting such a request must provide a copy to the other
                                party, who may submit objections to the arbitrator with a copy of the objections
                                provided to the other party, within fifteen (15) days of receiving the requesting
                                party’s notice. The granting or denial of such request will be at the sole discretion of
                                the arbitrator who will notify the parties of his/her decision within twenty (20) days
                                of the objecting party’s submission. The arbitrator will take reasonable steps to
                                preserve the privacy of individuals, and of business matters. Judgment upon the award
                                rendered by the arbitrator may be entered in any court having jurisdiction. The
                                arbitrator’s decision will be final and binding, except for any right of appeal provided
                                by the FAA. However, any party can appeal that award to a three-arbitrator panel
                                administered by the same arbitration organization, which will consider anew any aspect
                                of the initial award objected to by the appealing party. The appealing party will have
                                thirty (30) days from the date of entry of the written arbitration award to notify the
                                arbitration organization that it is exercising the right of appeal. The appeal will be
                                filed with the arbitration organization in the form of a dated writing. The arbitration
                                organization will then notify the other party that the award has been appealed. The
                                arbitration organization will appoint a three-arbitrator panel, which will conduct
                                arbitration pursuant to its Code and issue its decision within one hundred twenty (120)
                                days of the date of the appellant’s written notice. The decision of the panel will be by
                                majority vote and will be final and binding.
                            </p>
                            <p className={classes.contentText}>
                                This Dispute Resolution Provision will survive termination of your Account, your use of
                                the Services, these Terms of Service and any bankruptcy by you or us. If any portion of
                                this Dispute Resolution Provision is deemed invalid or unenforceable under any principle
                                or provision of law or equity, consistent with the FAA, it will not invalidate the
                                remaining portions of this Dispute Resolution Provision, these Terms of Service, or any
                                prior agreement you may have had with us, each of which will be enforceable regardless
                                of such invalidity.
                            </p>
                        </li>
                    </ul>
                    <div className={classes.contentTitle}>Termination</div>
                    <p className={classes.contentText}>
                        This Dispute Resolution Provision will survive termination of your Account, your use of
                        the Services, these Terms of Service and any bankruptcy by you or us. If any portion of
                        this Dispute Resolution Provision is deemed invalid or unenforceable under any principle
                        or provision of law or equity, consistent with the FAA, it will not invalidate the
                        remaining portions of this Dispute Resolution Provision, these Terms of Service, or any
                        prior agreement you may have had with us, each of which will be enforceable regardless
                        of such invalidity.
                    </p>
                    <div className={classes.contentTitle}>User Disputes</div>
                    <p className={classes.contentText}>
                        You agree that you are solely responsible for your interactions with any other user in
                        connection with the Service and we will have no liability or responsibility with respect
                        thereto. We reserve the right, but have no obligation, to become involved in any way with
                        disputes between you and any other user of the Service.
                    </p>
                    <div className={classes.contentTitle}>General</div>
                    <p className={classes.contentText}>
                        These Terms of Service constitute the entire agreement between you and HausCoin and govern your
                        use of the Service, superseding any prior agreements between you and HausCoin with respect to
                        the Service; provided, however, that you may also be subject to additional terms and conditions
                        for products or Services provided by HausCoin, as applicable. These Terms of Service will be
                        governed by the laws of the State of California without regard to its conflict of law
                        provisions. With respect to any disputes or claims not subject to arbitration, as set forth
                        above, you and HausCoin agree to submit to the personal and exclusive jurisdiction of the state
                        and federal courts located within San Francisco County, California. The failure of HausCoin to
                        exercise or enforce any right or provision of these Terms of Service will not constitute a
                        waiver of such right or provision. If any provision of these Terms of Service is found by a
                        court of competent jurisdiction to be invalid, the parties nevertheless agree that the court
                        should endeavor to give effect to the parties’ intentions as reflected in the provision, and the
                        other provisions of these Terms of Service remain in full force and effect. You agree that
                        regardless of any statute or law to the contrary, any claim or cause of action arising out of or
                        related to use of the Service, or these Terms of Service must be filed within one (1) year after
                        such claim or cause of action arose or be forever barred. A printed version of this agreement
                        and of any notice given in electronic form will be admissible in judicial or administrative
                        proceedings based upon or relating to this agreement to the same extent and subject to the same
                        conditions as other business documents and records originally generated and maintained in
                        printed form. You may not assign these Terms of Service without the prior written consent of
                        HausCoin, but HausCoin may assign or transfer these Terms of Service, in whole or in part,
                        without restriction. The section titles in these Terms of Service are for convenience only and
                        have no legal or contractual effect. Notices to you may be made via either email or regular
                        mail. The Service may also provide notices to you of changes to these Terms of Service or other
                        matters by displaying notices or links to notices generally on the Service.
                    </p>
                    <div className={classes.contentTitle}>Notice for California Users</div>
                    <p className={classes.contentText}>
                        Under California Civil Code Section 1789.3, users of the service from California are entitled to
                        the following specific consumer rights notice: The Complaint Assistance Unit of the Division of
                        Consumer Services of the California Department of Consumer Affairs may be contacted in writing
                        at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (916) 445-1254
                        or (800) 952-5210. You may contact us at HausCoin, LLC, 660 4th Street, #418, San Francisco, CA
                        94107.
                    </p>
                    <div className={classes.contentTitle}>Questions? Concerns? Suggestions?</div>
                    <p className={classes.contentText}>
                        Please contact us at <CustomLink href="#">info@HausCoin.com</CustomLink> to report any violations of these Terms of Service or to
                        pose any questions regarding these Terms of Service or the Service.
                    </p>
                </div>
            </Container>
        </section>
    )
};

export default TermsOfService;
