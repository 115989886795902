import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    backgroundColor: 'white',
    padding: '16px 0',
    fontFamily: theme.typography.fontFamily.bold,
    zIndex: 1,
  },
  wrapperGrey: {
      backgroundColor: theme.palette.bgColor.grey,
  },
  // navBarContainer: {
  //   padding: '0 20px'
  // },
  nav: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: theme.fontSize.text.medium,
    color: theme.palette.text.main
  },
  navLogoWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '235px',
    textAlign: 'right',
  },
  navLogoLink: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.main,
    fontFamily: theme.typography.fontFamily.bold,
    fontSize: theme.fontSize.text.medium,
    lineHeight: '32px',
  },
  navLogo: {
    display: "block",
    width: '48px',
    height: '48px'
  },
  navLogoText: {
    marginLeft: '10px'
  },
  navMenu: {
    display: 'flex',
    flexGrow: '1',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navMenuList: {
    margin: '0px 15px 0px 15px',
  },
  navMenuListImg: {
    marginRight: '6px',
  },
  navMenuListItem: {
    color: theme.palette.text.main,
    fontSize: theme.fontSize.text.medium,
    lineHeight: '32px',
    '&:visited': {
      color: theme.palette.text.main,
    }
  },
  navMenuExternalLink: {
    '&:visited': {
      color: theme.palette.text.main
    }
  },
  activePageBlack: {
    borderBottom: '2px solid black'
  },
  navUser: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    width: '235px',
    justifyContent: 'flex-end',
  },
  navUserAccount: {
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: '150px',
    textOverflow: 'ellipsis',
    '&:nth-child(1)': {
      marginRight: '30px'
    }
  },
  navUserAccountItem: {
    color: theme.palette.text.main,
    fontSize: theme.fontSize.text.medium,
    lineHeight: '32px'
  },
  navProfilePopup: {
    display: 'none',
    position: 'absolute',
    top: '45px',
    right: 0,
    padding: '29px 28px 27px',
    backgroundColor: theme.palette.bgColor.white,
    border: '3px solid black',
    zIndex: 99
  },
  navProfileActive: {
    display: 'block'
  },
  navProfilePopupContent: {
    width: '247px',
    minWidth: '150px',
  },
  navProfilePopupLine: {
    display: 'block',
    height: '3px',
    backgroundColor: theme.palette.bgColor.black
  },
  navProfilePopupLink: {
    display: 'block',
    whiteSpace: 'nowrap',
    margin: '22px 0',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.medium,
    color: theme.palette.text.main,
    letterSpacing: '-0.44px',
    cursor: 'pointer',
    transition: 'all .8s ease',
    '&:hover': {
      color: theme.palette.text.grey
    }
  },
  navUserAccountLogo: {
    boxSizing: 'border-box',
    marginLeft: '5px',
    // marginRight: '-12px',
    display: "block",
    padding: '5px 0px',
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
  // navUserAccountLogoBlack: {
  //     boxSizing: 'border-box',
  //     marginLeft: '16px',
  //     display: "block",
  //     padding: '6px 12px',
  //     width: '40px',
  //     height: '40px',
  //     borderRadius: '50%',
  //     backgroundColor: theme.palette.bgColor.black
  // },
  // navUserAccountLogoBlue: {
  //     boxSizing: 'border-box',
  //     marginLeft: '16px',
  //     display: "block",
  //     padding: '6px 12px',
  //     width: '40px',
  //     height: '40px',
  //     borderRadius: '50%',
  //     backgroundColor: theme.palette.bgColor.white
  // },
  // '@media (max-width: 1280px)': {
  //     navBarContainer : {
  //         padding: '0 15px'
  //     },
  // },
  '@media (max-width: 960px)': {
    navMenu: {
      marginLeft: '30px'
    },
    navMenuList: {
      marginRight: '25px'
    }
  },
  '@media (max-width: 900px)': {
    nav: {
      justifyContent: 'space-between',
    },
    navMenu: {
      display: 'none'
    },
  },
  '@media (max-width: 768px)': {
    navLogoWrapper: {
      flexGrow: '1'
    }
  },
  '@media (max-width: 480px)': {
    navLogo: {
      width: '36px',
      height: '36px',
    },
    navUserAccount: {
      '&:nth-child(1)': {
        display: 'none',
      }
    },
  },
  '@media (max-width: 380px)': {
    navUserAccountLogo: {
      // width: '60%'
    },
    navProfilePopupContent: {
      width: '150px'
    },
  },
  '@media (max-width: 320px)': {
    navUserAccountItem: {
      fontSize: '12px',
    }
  }
}))
