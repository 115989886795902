import React, {memo, useEffect, useState} from 'react';
import {Bar} from 'react-chartjs-2';

import {useStyles} from './style.js';

const HomesGraph = ({houses}) => {
  const classes = useStyles();
  const [homesData, setHomesData] = useState({
    labels: [],
    datasets: []
  });

  const dashGraph = () => {
    const labels = [];
    const states = [];

    houses?.history?.forEach(h => {
      const date = new Date(h.date);
      labels.push(date.toLocaleString('default', {month: 'long'}));
    });

    houses?.history?.forEach(s => {
      states.push(s.value);
    });

    setHomesData({
      labels,
      datasets: [
        {
          type: 'bar',
          label: 'Homes',
          data: states,
          backgroundColor: ['rgba(151, 151, 151, 100)'],
        },
      ],
    });
  };

  useEffect(() => {
    dashGraph();
  }, [houses]);

  return (
    <div className={classes.landingGraphsWrapper}>
      <Bar data={homesData}
           options={
             {
               plugins: {
                 legend: {
                   display: false
                 },
                 tooltip: {
                   enabled: true,
                   cornerRadius: '0',
                   events: ['click'],
                 },
               },
               responsive: true,
               maintainAspectRatio: false,
               scales: {
                 x: {
                   ticks: {
                     display: false
                   },
                   grid: {
                     display: false,
                     borderWidth: 3,
                     borderColor: '#000000',
                   },
                 },
                 y: {
                   ticks: {
                     display: false
                   },
                   grid: {
                     display: false,
                     borderWidth: 3,
                     borderColor: '#000000',
                   },
                 },
               },
             }
           }
      />
    </div>
  );
};

export default memo(HomesGraph);
