import isEmpty from 'validator/es/lib/isEmpty';
import isEmail from 'validator/es/lib/isEmail';
import isAlpha from "validator/es/lib/isAlpha";
import isStrongPassword from "validator/es/lib/isStrongPassword";
import isNumeric from 'validator/es/lib/isNumeric';
import isFloat from 'validator/es/lib/isFloat';

const emailValidationRules = [
    {
        validate: (value) => !isEmpty(value),
        message: 'Please enter an email address',
    },
    {
        validate: (value) => isEmail(value),
        message: 'Email is not valid',
    },
];

const passwordValidationRules = [
    {
        validate: (value) => !isEmpty(value),
        message: 'Please enter your password',
    },
    {
        validate: (value) => isStrongPassword(value),
        message: 'Min length 8, min 1 lowercase, uppercase, numbers, symbols'
    }
];

const usernameValidationRules = [
    {
        validate: (value) => !isEmpty(value),
        message: 'Please enter your first name',
    },
    {
        validate: (value) => isAlpha(value),
        message: 'Should contains only letters',
    }
]

const userLastNameValidationRules = [
    {
        validate: (value) => !isEmpty(value),
        message: 'Please enter your last name',
    },
    {
        validate: (value) => isAlpha(value),
        message: 'Should contains only letters',
    }
]

const numberValidationRules = [
    {
        validate: (value) => !isEmpty(value),
        message: 'Field can not be empty',
    },
    {
        validate: (value) => isNumeric(value) || isFloat(value),
        message: 'Value is not number',
    },
    {
        validate: (value) => {
            if (isFloat(value)) {
                return parseFloat(value) > 0
            }

            return parseInt(value) > 0;
        },
        message: 'Number should be positive',
    },
]

export function validateEmail(value) {
    for (const rule of emailValidationRules) {
        const isValid = rule.validate(value);
        if (!isValid) {
            return rule.message;
        }
    }
}

export function validatePassword(value) {
    for (const rule of passwordValidationRules) {
        const isValid = rule.validate(value);
        if (!isValid) {
            return rule.message;
        }
    }
}

export function validateUserName(value) {
    for (const rule of usernameValidationRules) {
        const isValid = rule.validate(value);
        if (!isValid) {
            return rule.message;
        }
    }
}

export function validateUserLastName(value) {
    for (const rule of userLastNameValidationRules) {
        const isValid = rule.validate(value);
        if (!isValid) {
            return rule.message;
        }
    }
}

export function validateNumber(value) {
    for (const rule of numberValidationRules) {
        const isValid = rule.validate(value);
        if (!isValid) {
            return rule.message;
        }
    }
}
