import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dashboard: {
    padding: '143px 0 0',
    backgroundColor: theme.palette.bgColor.grey
  },
  dashboardContainer: {
    position: 'relative',
    padding: '0 20px'
  },
  dashboardHeaderData: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '69px',
  },
  dashboardHeaderDataBlockWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  dashboardHeaderDataTitle: {
    fontSize: theme.fontSize.text.small,
    color: theme.palette.text.main,
    letterSpacing: '1.2px',
    lineHeight: '18px',
    margin: '0 0 15px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase'
  },
  dashboardHeaderDataMainTitleSpan: {
    display: 'flex',
    color: theme.palette.text.grey,
    alignItems: 'center',
    textTransform: 'uppercase'
  },
  dashboardHeaderDataMainImg: {
    margin: '0 4px 0 10px',
  },
  arrowDown: {
    transform: 'rotate(90deg)'
  },
  dashboardHeaderDataText: {
    fontSize: theme.fontSize.text.main,
    lineHeight: theme.fontSize.text.main + 'px',
    letterSpacing: '-1.17px',
    whiteSpace: 'nowrap',
    margin: 0,
    textTransform: 'uppercase'
  },
  dashboardCoinsBlockWrapper : {
    marginTop: '20px',
  },
  dashboardGraph: {
    height: '370px',
    position: 'relative',
    paddingBottom: '45px',
    marginTop: '82px',
  },
  dashboardHeaderDataBlock: {
    marginLeft: '0',
    marginRight: '0',
    backgroundColor: "transparent",
    textAlign: 'left',
    color: theme.palette.text.lightBlue,
  },
  dashboardHeaderDataBlockMain: {
    textAlign: 'left',
    marginLeft: '195px',
  },
  dashboardGraphData: {
    visibility: 'hidden',
    // display: 'flex',
    // justifyContent: 'flex-end',
    // margin: '15px 0'
  },
  dashboardGraphDataWrapper: {
    display: 'flex',
  },
  dashboardGraphDataBlock: {
    '&:nth-child(2)': {
      marginLeft: '45px'
    }
  },
  dashboardGraphDataBlockTitle: {
    margin: '0 0 8px 0',
    textTransform: 'uppercase',
    color: theme.palette.text.grey
  },
  dashboardGraphDataBlockText: {
    position: 'relative',
    display: 'inline-block',
    margin: '0 0 10px',
    textTransform: 'uppercase',
    color: theme.palette.text.grey,
    fontSize: theme.fontSize.text.subHeader
  },
  dashboardGraphDataFilterSelect: {
    minWidth: '184px',
    boxSizing: 'border-box',
    position: 'relative',
    padding: '14px 20px',
    fontSize: theme.fontSize.text.medium,
    fontFamily: theme.typography.fontFamily.medium,
    color: theme.palette.text.main,
    backgroundColor: theme.palette.bgColor.white,
  },
  dashboardGraphDataFilterImg: {
    position: 'absolute',
    right: '20px',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  dashboardGraphBottomTitles: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10px 10px 0 0',
  },
  dashboardGraphBottomTitle: {
    color: theme.palette.text.main,
    fontSize: theme.fontSize.text.small,
    margin: '0 0 0 20px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    '&:nth-child(1)': {
      '& span': {
        height: '2px',
        width: '30px',
        marginRight: '10px',
        display: 'inline-block',
        backgroundColor: '#468AFF'
      }
    },
    '&:nth-child(2)': {
      '& span': {
        height: '6px',
        width: '30px',
        marginRight: '10px',
        display: 'inline-block',
        backgroundColor: 'rgba(70, 138, 255, 0.25)'
      }
    }
  },
  dashboardGraphBtnWrapper: {
    maxHeight: '56px',
    display: 'flex',
    position: 'absolute',
    bottom: '-30px',
  },
  dashboardGraphBtn: {
    display: 'block',
    boxSizing: "border-box",
    width: '174px',
    textTransform: 'uppercase',
    textAlign: 'center',
    backgroundColor: theme.palette.bgColor.orange,
    outline: 'none',
    padding: '20px 60px',
    color: theme.palette.text.white,
    fontSize: theme.fontSize.text.small,
    cursor: 'pointer',
    letterSpacing: '1px',
  },
  dashboardTooltip: {
    maxWidth: '190px',
  },
  tooltipLink: {
    textDecoration: "underline",
    color: theme.palette.bgColor.white,
  },
  // dashboardGraphBtnLedger: {
  //   width: '204px',
  //   display: 'block',
  //   boxSizing: "border-box",
  //   marginLeft: '20px',
  //   border: '1px solid black',
  //   whiteSpace: 'nowrap',
  //   textTransform: 'uppercase',
  //   textAlign: 'center',
  //   outline: 'none',
  //   padding: '20px 60px',
  //   backgroundColor: theme.palette.bgColor.white,
  //   color: theme.palette.text.main,
  //   fontSize: theme.fontSize.text.small,
  //   cursor: 'pointer'
  // },
  dashboardCustomerWrapper: {
    backgroundColor: theme.palette.bgColor.white,
    padding: '160px 0px'
  },
  dashboardCustomerDataBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  dashboardCustomerDataInfo: {
    '&:nth-child(1)': {
      // padding: '20px 150px 20px 0px'
    },
    '&:nth-child(2)': {
      margin: '0 174px',
    }
  },
  dashboardDataBlockItemsTitle: {
    textTransform: 'uppercase',
    margin: ' 0 0 12px',
    lineHeight: '14px',
    fontSize: theme.fontSize.text.small,
    letterSpacing: '1.2px',
  },
  dashboardDataBlockItemsSpan: {
    display: 'block',
    color: theme.palette.text.grey,
    marginTop: '5px',
    fontFamily: theme.typography.fontFamily.bold,
    fontSize: theme.fontSize.text.medium
  },
  dashboardCustomerDataInfoText: {
    fontSize: theme.fontSize.text.main,
    lineHeight: theme.fontSize.text.main + 'px',
    letterSpacing: '-1.17px',
    margin: '0'
  },
  dashboardActivity: {
    backgroundColor: theme.palette.bgColor.grey,
    position: 'relative',
    padding: '65px 0',
  },
  dashboardActivityWrapper: {

  },
  dashboardCustomerActivity: {
    width: '100%',
  },
  dashboardCustomerActivityImg: {
    position: 'absolute',
    top: '-158px',
  },
  dashboardActivityHeaderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dashboardCustomerActivityTitle: {
    fontSize: theme.fontSize.text.big,
    letterSpacing: '-1.17px',
    margin: '0',
  },
  dashboardActivitySelectWrapper: {
    visibility: 'hidden',
  },
  dashboardActivitySelect: {
    color: '#000000',
    padding: '17px 20px',
    position: 'relative',
    fontFamily: theme.typography.fontFamily.bold,
    fontSize: theme.fontSize.text.medium,
    minWidth: '230px',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.bgColor.white,
  },
  dashboardActivitySelectImg: {
    top: '50%',
    right: '20px',
    position: 'absolute',
    transform: 'translateY(-50%)',
  },
  dashboardActivityTable: {
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.grey
  },
  dashboardActivityTableRow: {
    borderBottom: '1px solid #979797',
    '& > td:nth-child(1)': {
      textAlign: 'left',
      paddingLeft: '0',
      fontSize: theme.fontSize.text.semiHeader,
    },
    '& > td:nth-child(2)': {
      textAlign: 'left',
      paddingLeft: '0',
      fontSize: theme.fontSize.text.mediumHeader
    },
    '& > td:nth-child(3)': {
      fontSize: theme.fontSize.text.mediumHeader
    },
    '& > td:nth-child(4)': {
      textAlign: 'right',
      paddingRight: '0',
      fontSize: theme.fontSize.text.semiHeader
    }
  },
  dashboardActivityTableSpan: {
    display: 'block'
  },
  dashboardActivityTableLink: {
    color: theme.palette.bgColor.orange,
  },
  dashboardActivityRowWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #979797',
    padding: '25px 0'
  },
  dashboardActivityColumn: {
    fontFamily: theme.typography.fontFamily.bold,
    width: '20%',
  },
  dashboardActivityColumnTokenPurchase: {
    fontFamily: theme.typography.fontFamily.bold,
    width: '20%',
  },
  dashboardActivityColSpan: {
    display: 'block'
  },
  dashboardActivityColumnLogo: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily.black,
    width: '20%',
  },
  dashboardActivityColumnLogoWrapper: {
    width: '48px',
    height: '48px',
    marginRight: '5px',
    backgroundColor: theme.palette.bgColor.lightBlue,
    borderRadius: '50%',
    position: 'relative',
  },
  dashboardActivityColumnAccount: {
    width: '48px',
    height: '48px',
    marginRight: '5px',
    borderRadius: '50%',
  },
  dashboardActivityColumnLogoImg: {
    width: '28px',
    height: '28px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  dashboardActivityColTrans: {
    display: 'flex',
    width: '40%',
    alignItems: 'center',
  },
  dashboardActivityColPrice: {
    width: '50%',
    textAlign: 'right',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.mediumHeader,
    lineHeight: '30px',
    letterSpacing: '-1.17px',
    color: theme.palette.text.lightBlue,
  },
  dashboardActivityColLast: {
    width: '50%',
    textAlign: 'right',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.mediumHeader,
    lineHeight: '30px',
    letterSpacing: '-1.17px',
  },
  dashboardActivityTableLink: {
    fontSize: '18px',
    color: theme.palette.text.orange,
    letterSpacing: '0px',
  },
  '@media (max-width: 960px)': {
    dashboardHeaderDataText: {
      fontSize: '42px',
      lineHeight: '42px',
    },
    dashboardHeaderData: {
      justifyContent: 'space-between'
    },
    dashboardCustomerDataBlock: {
      justifyContent: 'space-between'
    },
    dashboardHeaderDataBlock: {
      marginRight: 0,
      marginLeft: 0,
      padding: '20px 80px 20px 0px'
    },
    dashboardHeaderDataBlockMain: {
      width: '45%',
      marginLeft: 0,
      padding: '20px 80px 20px 18px'
    },
    dashboardCustomerWrapper: {
      padding: '120px 0px'
    },
    dashboardCustomerDataInfoText: {
      fontSize: '42px',
      lineHeight: '42px'
    },
    dashboardDataBlockItemsTitle: {
      marginBottom: '9px',
    },
    dashboardCustomerActivityTitle: {
      fontSize: '60px'
    },
    dashboardActivityWrapper: {
      padding: '120px 0'
    },
    dashboardActivityTableRow: {
      '& > td': {
        padding: '10px',
      },
      '& > td:nth-child(1)': {
        fontSize: '12px'
      },
      '& > td:nth-child(2)': {
        fontSize: '16px'
      },
      '& > td:nth-child(3)': {
        fontSize: '16px'
      },
      '& > td:nth-child(4)': {
        fontSize: '12px'
      },
    }
  },
  '@media (max-width: 740px)': {
    dashboardActivityWrapper: {
      padding: '120px 0 30px'
    },
    dashboardActivityTableRow: {
      '& > td': {
        padding: '5px 10px',
      },
      '& > td:nth-child(1)': {
        fontSize: '14px'
      },
      '& > td:nth-child(2)': {
        fontSize: '16px'
      },
      '& > td:nth-child(3)': {
        fontSize: '16px'
      },
      '& > td:nth-child(4)': {
        fontSize: '14px'
      }
    }
  },
  '@media (max-width: 600px)': {
    dashboardCustomerWrapper: {
      padding: '90px 0px 150px'
    },
    dashboardHeaderData: {
      flexDirection: 'column',
      justifyContent: 'space-between'
    },
    dashboardCustomerDataBlock: {
      flexWrap: 'wrap',
      justifyContent: 'space-around'
    },
    dashboardHeaderDataBlock: {
      width: '100%',
      boxSizing: 'border-box',
      marginRight: 0,
      marginLeft: 0,
      padding: '10px 50px 10px 15px'
    },
    dashboardHeaderDataBlockMain: {
      '&:nth-child(2)': {
        marginTop: '24px'
      },
      width: '100%',
      boxSizing: 'border-box',
      marginRight: 0,
      marginLeft: 0,
      padding: '10px 50px 10px 15px'
    },
    dashboardCustomerDataInfo: {
      boxSizing: 'border-box',
      marginRight: 0,
      marginLeft: 0,
      '&:nth-child(1)': {
        paddingBottom: 0,
      },
      '&:nth-child(2)': {
        margin: '0',
      }
    },
  },
  '@media (max-width: 480px)': {
    buttonWrapper: {
      position: 'relative',
      width: '100%',
    },
    dashboardWrapper: {
      overflow: 'hidden',
    },
    dashboard: {
      paddingTop: '94px',
    },
    dashboardGraph: {
      height: '250px',
      paddingBottom: '75px',
      marginTop: '30px',
    },
    dashboardCustomerWrapper: {
      paddingBottom: '120px',
      paddingTop: '184px',
    },
    dashboardCoinBtn : {
      '&:nth-child(1)': {
        paddingLeft: '0'
      }
    },
    dashboardHeaderData: {
      marginTop: '35px',
    },
    dashboardCustomerDataBlock: {
      justifyContent: 'flex-start',
    },
    dashboardHeaderDataBlockWrapper: {
      flexFlow: 'column',
      marginRight: 'auto',
    },
    dashboardHeaderDataBlock: {
      padding: '0',
    },
    dashboardHeaderDataBlockMain: {
      padding: '0'
    },
    dashboardGraphBottomTitle: {
      fontSize: '10px',
      letterSpacing: '0.5px',
    },
    dashboardActivityTableMob: {
      maxHeight: '370px',
      width: '100%',
      overflow: 'scroll'
    },
    dashboardGraphData: {
      width: '100%',
      marginTop: '20px',
    },
    // dashboardGraphDataFilterSelect: {
    //   fontSize: '8px'
    // },
    dashboardGraphBtnWrapper: {
      width: '100%',
      flexFlow: 'column',
    },
    dashboardGraphBtn: {
      width: '100%',
    },
    // dashboardGraphBtnLedger: {
    //   width: '100%',
    //   marginLeft: '0',
    //   marginTop: '20px',
    // },
    dashboardCustomerDataInfoText: {
      margin: '0 0 30px 0'
    },
    dashboardDataBlockItemsTitle: {
      marginBottom: '9px'
    },
    dashboardActivity: {
      paddingTop: '80px',
    },
    dashboardActivitySelect: {
      display: 'none',
    },
    dashboardActivityWrapper: {
      paddingTop: '0'
    },
    dashboardCustomerActivityTitle: {
      fontSize: '40px',
      marginTop: '15px',
    },
    dashboardActivityTableRow: {
      '& > td': {
        padding: '5px 10px',
      },
      '& > td:nth-child(1)': {
        fontSize: '12px'
      },
      '& > td:nth-child(2)': {
        fontSize: '14px'
      },
      '& > td:nth-child(3)': {
        fontSize: '14px'
      },
      '& > td:nth-child(4)': {
        fontSize: '12px'
      }
    },
    dashboardActivityTableLink: {
      fontSize: '12px',
    },
    dashboardActivityRowWrapper: {
      paddingBottom: '15px',
    },
    dashboardActivityColWrapper: {
      fontFamily: theme.typography.fontFamily.medium,
      flexFlow: 'column',
    },
    dashboardActivityColumn: {
      fontFamily: theme.typography.fontFamily.medium,
      marginRight: '20px',
      minWidth: '115px',
    },
    dashboardActivityColumnTokenPurchase: {
      fontFamily: theme.typography.fontFamily.medium,
      display: 'none',
    },
    dashboardActivityColumnLogo: {
      marginRight: 'auto',
      width: '30%',
    },
    dashboardActivityColTrans: {
      width: '30%',
      display: 'block',
    },
    dashboardActivityColLast: {
      fontSize: theme.fontSize.text.medium,
      lineHeight: '19px',
      letterSpacing: '-0.33px',
      width: '100%',
    },
    dashboardActivityColPrice: {
      fontSize: theme.fontSize.text.medium,
      lineHeight: '19px',
      letterSpacing: '-0.33px',
      width: '100%',
    },
    dashboardCustomerActivityImg: {
      width: '100px',
      top: '-135px',
    },

  },
  '@media (max-width: 380px)': {
    // dashboardHeaderDataBlock: {
    //   padding: '5px 20px 5px 10px'
    // },
    // dashboardHeaderDataBlockMain : {
    //     padding: '5px 20px 5px 10px'
    // },
    dashboardGraphBottomTitle: {
      margin: '8px 0 0 20px'
    },
    dashboardCustomerActivity: {
      top: '-188px'
    },
    // dashboardActivityTableRow : {
    //     padding: 0,
    //     '& > td:nth-child(1)': {
    //         padding: 0,
    //     },
    //     '& > td:nth-child(2)': {
    //         padding: 0,
    //     },
    //     '& > td:nth-child(3)': {
    //         padding: 0,
    //     },
    //     '& > td:nth-child(4)': {
    //         padding: 0,
    //     }
    // },
  },
  // '@media (max-width: 320px)': {
  //     dashboardHeaderDataTitle : {
  //         fontSize: '7px',
  //         marginBottom: '4px',
  //     },
  //     dashboardHeaderDataText : {
  //         fontSize: '15px',
  //         lineHeight: '15px',
  //     },
  //     dashboardHeaderDataMainImg : {
  //         margin: '0 4px 0'
  //     },
  //     dashboardHeaderDataBlock : {
  //         padding: '5px 10px 5px 5px'
  //     },
  //     dashboardHeaderDataBlockMain : {
  //         padding: '5px 10px 5px 5px'
  //     },
  //     dashboardCustomerWrapper : {
  //         padding: '60px 0px 100px'
  //     },
  //     dashboardDataBlockItemsTitle : {
  //         fontSize: '7px',
  //         marginBottom: '4px',
  //     },
  //     dashboardCustomerDataInfoText : {
  //         fontSize: '15px',
  //         lineHeight: '15px'
  //     },
  //     dashboardCustomerActivityTitle : {
  //         fontSize: '25px'
  //     },
  //     dashboardActivityTableRow : {
  //         '& > td': {
  //             padding: '2px 6px',
  //         },
  //         '& > td:nth-child(1)' : {
  //             fontSize: '8px'
  //         },
  //         '& > td:nth-child(2)' : {
  //             fontSize: '8px'
  //         },
  //         '& > td:nth-child(3)' : {
  //             fontSize: '8px'
  //         },
  //         '& > td:nth-child(4)' : {
  //             fontSize: '8px'
  //         }
  //     },
  // },
  '@media (max-width: 670px) and (orientation: landscape)': {
    dashboardActivityTableMob: {
      maxHeight: '370px',
      width: '100%',
      overflow: 'scroll'
    },
  }
}))
