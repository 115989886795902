import React from 'react';
import { useStyles } from './styles';
import Skeleton from '@material-ui/lab/Skeleton';

const EventItemPreloader = () => {
    const classes = useStyles();
    return (
        <tr className={classes.adminTableTrChild} style={{ width: '100%' }}>
            <td className={classes.adminTableTd}>
                <Skeleton animation="wave" />
            </td>
            <td className={classes.adminTableTd}>
                <Skeleton animation="wave" />
            </td>
            <td className={classes.adminTableTd}>
                <Skeleton animation="wave" />
            </td>
            <td className={classes.adminTableTd}>
                <Skeleton animation="wave" />
            </td>
            <td className={classes.adminTableTd}>
                <button disabled className={classes.adminTableTrChildBtn}>
                    Show
                </button>
            </td>
        </tr>
    );
};

export default React.memo(EventItemPreloader);
