import React, {useEffect, useState} from 'react';
import {matchPath, NavLink, useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';

import { useStyles } from './style.js';
import {useAppState} from "marketplace/app-state";
import {ROUTES} from "marketplace/constans";
import cross from "assets/img/cross.svg";

const BurgerMenu = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const { state, actions } = useAppState();
    const [stateDrawer, setStateDrawer] = useState(false);
    const whiteBg = useRouteMatch(ROUTES.ALL);
    const path = location.pathname;
    const matchHome = matchPath(path, { path: ROUTES.HOME });
    const matchTokens = matchPath(path, { path: ROUTES.TOKENS });
    const matchDashboard = matchPath(path, { path: ROUTES.DASHBOARD });
    const matchAbout = matchPath(path, { path: ROUTES.ABOUT });
    const matchContact = matchPath(path, { path: ROUTES.CONTACT });

    const toggleDrawer = (open) => (event) => {
        setStateDrawer(open);
    };

    return (
        <>
            <div className={classes.burgerMenuBtn} onClick={toggleDrawer(!stateDrawer)}>
                {!stateDrawer ?
                    <>
                      <span className={classes.burgerMenuBtnSpan} />
                      <span className={classes.burgerMenuBtnSpan} />
                    </>
                    :
                  <>
                    <span style={{visibility: 'hidden'}} className={classes.burgerMenuBtnSpan} />
                    <span style={{visibility: 'hidden'}} className={classes.burgerMenuBtnSpan} />
                  </>
                }
            </div>
            <Drawer
                className={classes.drawer}
                anchor="left"
                open={stateDrawer}
                onClose={toggleDrawer(false)}
                classes={{ paper: classes.drawerPaper }}
                transitionDuration={750}
            >
              <img className={classes.burgerCloseBtn} onClick={toggleDrawer(!stateDrawer)} src={cross} alt='close' />
                <List>
                    <ListItem className={classes.listItem} button>
                      <NavLink to={ROUTES.TOKENS}
                               className={classes.drawerMenuItem}
                               activeClassName={clsx({
                                   [classes.active]:  matchTokens?.isExact,
                               })}
                               onClick={toggleDrawer(false)}
                      >
                          Tokens
                      </NavLink>
                    </ListItem>
                    <ListItem className={classes.listItem} button>
                      <NavLink to={ROUTES.FAQ}
                               className={classes.drawerMenuItem}
                               activeClassName={clsx({
                                   [classes.active]:  matchAbout?.isExact,
                               })}
                               onClick={toggleDrawer(false)}
                      >
                          FAQ
                      </NavLink>
                    </ListItem>
                    <ListItem className={classes.listItem} button>
                      <a
                        className={classes.drawerMenuItem}
                        href="https://haus.com"
                      >
                        haus.com
                      </a>
                    </ListItem>
                    {state.isAuth ? (
                        <ListItem className={classes.listItem} button>
                          <NavLink to={ROUTES.DASHBOARD}
                                   className={classes.drawerMenuItem}
                                   activeClassName={clsx({
                                       [classes.active]:  matchDashboard?.isExact,
                                   })}
                                   onClick={toggleDrawer(false)}
                          >
                              Dashboard
                          </NavLink>
                        </ListItem>
                    ) : null }
                </List>
            </Drawer>
        </>
    )
}

export default BurgerMenu;
