import React from 'react';
import { useStyles } from './styles';
import { formatNum } from '../../../common/util';

const EventItem = ({ data, onClick: showEventJSON }) => {
    const classes = useStyles();

    const getChangesLabel = () => {
        if (!data.supplyChange.type) {
            return '-';
        }

        if (data.supplyChange.type === 'MINT') {
            return `Minted +${formatNum(data.supplyChange.amount)}`;
        }

        return `Burned -${formatNum(data.supplyChange.amount)}`;
    };

    const getPriceChangesLabel = () => {
        if (!data.equityPriceChange.new) {
            return `+$${formatNum(data.equityPriceChange.old)}`;
        }

        if (data.equityPriceChange.old === data.equityPriceChange.new) {
            return `$0`;
        }

        if (data.equityPriceChange.old > data.equityPriceChange.new) {
            return `-$${formatNum(data.equityPriceChange.old - data.equityPriceChange.new)}`;
        }

        return `+$${formatNum(data.equityPriceChange.new - data.equityPriceChange.old)}`;
    };

    return (
        <tr className={classes.adminTableTrChild}>
            <td className={classes.adminTableTd}>{data.eventType}</td>
            <td className={classes.adminTableTd}>{data.eventStatus}</td>
            <td className={classes.adminTableTd}>{getChangesLabel()}</td>
            <td className={classes.adminTableTd}>{getPriceChangesLabel()}</td>
            <td className={classes.adminTableTd}>
                <button onClick={() => showEventJSON(data)} className={classes.adminTableTrChildBtn}>
                    Show
                </button>
            </td>
        </tr>
    );
};

export default React.memo(EventItem);
