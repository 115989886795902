export const ADMIN_STATE_ACTIONS = {
    UPDATE_STATE: 'UPDATE_STATE',
};

export const ADMIN_ROUTES = {
    HOME: '/',
    LOGIN: '#login',
    CONFIRM_USER: '#confirm-user',
};

export const SETTING_GROUPS = {
    COIN: 'COIN',
};

export const SETTING_KEYS = {
    STARTING_COIN_PRICE: 'STARTING_COIN_PRICE',
    ADMIN_COIN_PRICE: 'ADMIN_COIN_PRICE',
    AUTO_PRICING: 'AUTO_PRICING',
};

export const SETTING_VAL_TYPES = {
    DECIMAL: 'decimal',
    BOOLEAN: 'boolean',
};
