import React, { useEffect } from 'react';
import { useCustomSnackbar } from 'common/hooks/custom-snackbars';
import useApi from 'admin/hooks/useApi';
import TransactionItem, { TransactionItemPreloader } from 'admin/components/TransactionItem';
import ViewMoreButton from 'admin/components/ViewMoreButton';
import { useStyles } from '../style';
import useComplexState from 'common/hooks/use-complex-state';

const preloaderItems = [1, 2, 3];
const initState = {
    isLoading: true,
    isLoadingMore: false,
    hasMore: false,
    recordsLeft: 0,
    records: [],
    page: 1,
};

const RequestTab = () => {
    const classes = useStyles();
    const [state, setState] = useComplexState(initState);
    const api = useApi();
    const { showError } = useCustomSnackbar();

    useEffect(() => {
        state.page > 1 ? setState({ isLoadingMore: true }) : setState({ isLoading: true });
        api.getTransactions(state.page)
            .then((data) => {
                setState({
                    hasMore: data.hasMore,
                    recordsLeft: data.recordsLeft,
                    records: state.records?.length ? state.records.concat(data.records) : data.records,
                });
            })
            .catch((err) => showError('Could not get pending transactions', err))
            .finally(() => {
                state.page > 1 ? setState({ isLoadingMore: false }) : setState({ isLoading: false });
            });
    }, [state.page]);

    const updateItem = (itemId, newProcessingType) => {
        const records = [...state.records];
        const index = records.findIndex((item) => item.id === itemId);
        if (index < 0) {
            return;
        }

        const item = {
            ...records[index],
            processingType: newProcessingType,
        };

        records.splice(index, 1, item);
        setState({ records });
    };

    return (
        <>
            <table className={classes.adminTable} frame="none" rules="none" cellPadding="25">
                <tbody>
                    <tr className={classes.adminTableTr}>
                        <th className={classes.adminTableTrTitle}>
                            <div className={classes.adminTableTrTitleWrapper}>Date</div>
                        </th>
                        <th className={classes.adminTableTrTitle}>
                            <div className={classes.adminTableTrTitleWrapper}>Investor</div>
                        </th>
                        <th className={classes.adminTableTrTitle}>
                            <div className={classes.adminTableTrTitleWrapper}>Type</div>
                        </th>
                        <th className={classes.adminTableTrTitle}>
                            <div className={classes.adminTableTrTitleWrapper}>Amount</div>
                        </th>
                        <th className={classes.adminTableTrTitle}>
                            <div className={classes.adminTableTrTitleWrapper}>Price</div>
                        </th>
                        <th className={classes.adminTableTrTitle}>
                            <div className={classes.adminTableTrTitleWrapper}>Token</div>
                        </th>
                        <th className={classes.adminTableTrTitle}>
                            <div className={classes.adminTableTrTitleWrapper}>Status</div>
                        </th>
                    </tr>
                    {state.isLoading && preloaderItems.map((item) => <TransactionItemPreloader key={item} />)}
                    {!state.isLoading &&
                        state.records?.map((transaction) => (
                            <TransactionItem key={transaction.id} data={transaction} onActionDone={updateItem} />
                        ))}
                </tbody>
            </table>
            {!state.isLoading && !state.records?.length && (
                <div className={classes.noRecordsLabel}>
                    <span>No Records</span>
                </div>
            )}
            {state.hasMore && (
                <ViewMoreButton
                    isLoading={state.isLoadingMore}
                    recordsLeft={state.recordsLeft}
                    onClick={() => setState({ page: state.page + 1 })}
                />
            )}
        </>
    );
};

export default React.memo(RequestTab);
