import axios from 'axios';
import AuthenticationError from './AuthenticationError';

const ALLOW_REFRESH_FOR_CODES = [ 401, 403 ];

export default class BaseApiClient {
    #refreshToken;
    #authInterceptor = null;
    #baseURL = null;
    #privateClient;
    redirectOnError;
    coinType;
    coinId;

    constructor(baseURL, redirectOnError, cognitoPool) {
        this.redirectOnError = redirectOnError;
        this.#baseURL = baseURL;
        this.#privateClient = this.#createPrivateClient();
        this.publicClient = axios.create({ baseURL: this.#baseURL });
        this.pool = cognitoPool;
    }

    /**
        getSessionToken()
        user.getSession will get the id token from local storage or go out to
        the backend using the refresh token if the id token is expired
        returns valid jwt id token
    **/
    getSessionToken() {
        const user = this.pool.getCurrentUser();
        if (!user) {
            throw new AuthenticationError();
        }
        return new Promise((resolve, reject) => {
            user.getSession((err, session) => {
                const idToken = session.idToken;
                if (err) {
                    reject(new AuthenticationError());
                } else {
                    resolve(idToken.jwtToken);
                }
            });
        })
    }

    setCoinId(coinId) {
      this.coinId = coinId
    }

    /** @param {AxiosRequestConfig} config */
    async request(config) {
        const requestParams = config;
        requestParams.headers = config.headers || {};
        try {
            const authToken = await this.getSessionToken();
            requestParams.headers = {
                ...requestParams.headers,
                Authorization: authToken,
            }
            return await this.#privateClient.request(config);
        } catch (e) {
            this.redirectOnError();
        }
    }

    useToken(token) {
        this.#privateClient = this.#createPrivateClient(token);
    }

    useExpTokenInterceptor(refreshTokenFn) {
        this.#refreshToken = () => {
            return refreshTokenFn();
        };
    }

    useAuthTokenInterceptor(getAuthTokenFn) {
        this.#authInterceptor = async (config) => {
            if (!config.headers.hasOwnProperty('Authorization')) {
                config.headers['Authorization'] = await getAuthTokenFn();
            }

            return config;
        };

        this.#applyAuthTokenInterceptor();
    }

    #createPrivateClient(token) {
        const config = {};
        if (this.#privateClient) {
            Object.assign(config, this.#privateClient.defaults);
        }

        config.baseURL = this.#baseURL;
        if (token) {
            config.headers['Authorization'] = token;
        }
        const instance = axios.create(config);
        this.#applyAuthTokenInterceptor(instance);
        return instance;
    }

    #applyAuthTokenInterceptor(client = this.#privateClient) {
        if (!this.#authInterceptor) {
            return;
        }

        const onFulfilled = this.#authInterceptor;
        const onRejected = (error) => error;
        client.interceptors.request.use(onFulfilled, onRejected);
    }
}
