import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  skeletonTableTd: {
    padding: '25px 10px',
  },
  skeletonTableTdFirst: {
    minWidth: '70px'
  },
  skeletonText: {
    width: '100%',
    minWidth: '35px',
    height: '60px'
  },
  skeletonCardPageHeaderValue: {
    height: '60px',
    width: '300px',
  },
  cardPageHeaderValue: {
    height: '60px',
    width: '300px',
  },  

  //SKELETON CARD

  skeletonCard: {
    maxHeight: '505px',
    width: '100%',
    maxWidth: '378px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxSizing: 'border-box',
    padding: '20px',
    backgroundColor: theme.palette.bgColor.white,
    borderRadius: '18px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  },
  skeletonCardTitle: {
    height: '120px',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
  skeletonCardTitleText: {
    marginLeft: '20px',
  },
  skeletonCardValuesWrapper: {
    height: '48px',
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  skeletonCardDescription: {
    height: '45px',
    marginTop: '20px',
  },
  skeletonCardGraphWrapper: {
    marginTop: '30px',
    height: '144px',
  },
  skeletonCardGraphDescription: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  skeletonCardGraphDescriptionText: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  skeletonCardBlock: {
    marginTop: '60px',
  },
  skeletonCardsTable: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: '30px',
    margin: '60px auto 0',
    justifyContent: 'center',
  },
  skeletonCards: {
    position: 'inherit',
    top: 'inherit',
    left: 'inherit',
    transform: 'inherit',
  },
  skeletonCardPageWrapper: {
    padding: '30px 30px 60px',
    backgroundColor: theme.palette.bgColor.white,
    borderRadius: '18px',
  },
  skeletonCardPageTitle: {
    width: '220px',
    height: '120px',
  },
  skeletonCardPageValues: {
    width: '50%',
    height: '86px',
    marginTop: '30px',
  },
  skeletonCardPageGraph: {
    height: '370px',
    marginTop: '60px',
  },
  skeletonCardPageButtons: {
    display: 'flex',
    marginTop: '30px',
  },
  skeletonCardPageBtn: {
    width: '174px',
    '&:nth-child(2)': {
      width: '204px',
      marginLeft: '20px',
    }
  },
  skeletonCardPageValuesBottom: {
    display: 'flex',
    marginTop: '80px',
    justifyContent: 'space-between',
  },

  // MEDIA

  '@media (max-width: 960px)': {
    skeletonTableTd: {
      padding: '15px 5px',
    },
    skeletonText: {
      height: '40px',
      width: '90px',
    },
    skeletonTableTdFirst: {
      width: '154px',
    }
  },
  '@media (max-width: 560px)': {
    skeletonText: {
      width: '80px',
    },
    skeletonTableTdFirst: {
      width: '134px',
    }
  },
  '@media (max-width: 480px)': {
    skeletonText: {
      width: '60px',
    },
    skeletonTableTdFirst: {
      width: '110px',
    },
    skeletonCardPageWrapper: {
      padding: '130px 0 60px',
    },
    skeletonCardPageTitle: {
      height: '100px',
    },
    skeletonCard: {
      maxWidth: '338px',
    },
    skeletonCardTitle: {
      height: '100px',
    },
    skeletonCardValuesWrapper: {
      height: '40px',
      marginTop: '57px',
    },
    skeletonCardTitleText: {
      fontSize: '20px',
      marginLeft: '16px',
    },
    skeletonCardValuesBig: {
      fontSize: '40px',
      lineHeight: '40px',
    },
    skeletonCardPageButtons: {
      flexFlow: 'column',
    },
    skeletonCardPageBtn: {
      width: '100%',
      '&:nth-child(2)': {
        width: '100%',
        marginTop: '20px',
        marginLeft: '0',
      }
    },
  },
  '@media (max-width: 380px)': {
    skeletonTableTd: {
      padding: '8px 1px',
    },
    skeletonText: {
      width: '40px',
    },
    skeletonTableTdFirst: {
      width: '110px',
    },
    skeletonCard: {
      maxWidth: '300px',
    },
  },
  '@media (max-width: 320px)': {

    skeletonText: {
      width: '35px',
    },
    skeletonTableTdFirst: {
      width: '70px',
    }
  }
}))
