import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  listWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '30px',
    paddingBottom: '29px',
    borderBottom: '1px solid #979797',
    fontFamily: theme.typography.fontFamily.medium,
  },
  listTitleWrapper: {
    display: 'flex',
  },
  listTitleImg: {
    width: '120px',
    height: '120px',
  },
  listTitleText: {
    position: 'relative',
    fontSize: theme.fontSize.text.subHeader,
    lineHeight: '24px',
    marginLeft: '30px',
  },
  listTitleTextSpan: {
    display: 'block',
    textTransform: 'uppercase',
    lineHeight: '24px',
  },
  listDescription: {
    maxWidth: '270px',
    marginTop: '10px',
    fontSize: theme.fontSize.text.medium,
    lineHeight: '22px',
    letterSpacing: '-0.2475px',
  },
  listTitleTextTag: {
    position: 'absolute',
    top: '-10px',
    right: '20px',
    display: 'block',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.bgColor.orange,
    borderRadius: '100px',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: '8px',
    lineHeight: '8px',
    letterSpacing: '0.1em',
    color: theme.palette.text.white,
    padding: '8px 12px',
    textAlign: 'center'
  },
  listPercent: {
    fontSize: theme.fontSize.text.mediumHeader,
  },
  listCost: {
    fontSize: theme.fontSize.text.mediumHeader,
    color: theme.palette.text.blue,
  },
  listGraphWrapper: {
    width: '276px',
    height: '120px',
  }
}))
