const CONFIG = {
  BACKEND_URL: extractEnv('REACT_APP_BACKEND_URL'),
  STAGE: extractEnv('REACT_APP_STAGE_NAME'),
  INVESTOR_POOL_ID: extractEnv('REACT_APP_INVESTOR_POOL_ID'),
  INVESTOR_POOL_CLIENT_ID: extractEnv('REACT_APP_INVESTOR_POOL_CLIENT_ID'),
  ADMIN_POOL_ID: extractEnv('REACT_APP_ADMIN_POOL_ID'),
  ADMIN_POOL_CLIENT_ID: extractEnv('REACT_APP_ADMIN_POOL_CLIENT_ID'),
  HELLO_SIGN_CLIENT_ID: extractEnv('REACT_APP_HELLOSIGN_CLIENT_ID'),
  SECURITIZE_KEY: extractEnv('SECURITIZE_KEY'),
  SECURITIZE_BASE_URL: extractEnv('SECURITIZE_BASE_URL'),
};

export const FEATURE_FLAGS = (() => {
  if (CONFIG.STAGE === 'local' || CONFIG.STAGE === 'dev') {
    return {
      PROPERTY_PAGE: true,
    };
  }
  if (CONFIG.STAGE === 'prod') {
    return {
      PROPERTY_PAGE: false,
    };
  }
  if (CONFIG.STAGE === 'stage') {
    return {
      PROPERTY_PAGE: false,
    };
  }
})();

function extractEnv(key) {
  if (!process.env[key]) {
    throw new Error(`There is no key with name ${key}`);
  }

  return process.env[key];
}

function getBackendURL() {
  return CONFIG.BACKEND_URL.concat('/', CONFIG.STAGE);
}

function getHelloSignTestMode() {
  return CONFIG.STAGE === 'dev' || CONFIG.STAGE === 'local' || CONFIG.STAGE === 'stage';
}

function getTransactable() {
  return CONFIG.STAGE !== 'prod';
}

export default CONFIG;
export { getBackendURL, getHelloSignTestMode, getTransactable };
