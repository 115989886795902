import { createContext, useContext } from 'react';
import { ACTION_TYPES } from './constans';
import CONFIG_COIN from "./CoinConfig/CoinConfig";

export const appInitialState = {
    modalSignInActive: false,
    modalForgotPass: false,
    modalActive: false,
    buySellActive: false,
    modalSetPrice: false,
    modalAddAdmin: false,
    modalResetPass: false,
    modalConfirmUser: false,
    modalWaitList: false,
    modalWaitListId: null,
    currentUser: null,
    rowId: null,
    messages: null,
    messagesCtaTo: null,
    messagesCtaLabel: null,
    isAuth: false,
    isSyncWithLS: false, // Required for auth. First the app should check data in LS
    coinType: null,
    configCoin: CONFIG_COIN
};

export function appStateReducer(state, action) {
    switch (action.type) {
        case ACTION_TYPES.UPDATE_STATE:
            return { ...state, ...action.payload };

        case ACTION_TYPES.SET_CURRENT_USER:
            return { ...state, currentUser: action.payload };

        default:
            throw new Error(`Unknown action type ${action.type}`);
    }
}

export const AppContext = createContext({
    state: appInitialState, actions: {}, dispatch: () => {
    },
});

export function useAppState() {
    return useContext(AppContext);
}
