import React from 'react';
import { useStyles } from './styles';
import clsx from 'clsx';

// variant: "ledger" | "property" | "dashboard"
const ViewMoreButton = ({ variant = 'ledger', hasMore, isLoading, recordsLeft, label = 'View more', onClick }) => {
    const classes = useStyles();

    if (!hasMore) return null;

    const getLabel = () => {
        if (isLoading) {
            return 'LOADING...';
        }

        let str = label;
        if (recordsLeft) {
            str = `${str} (${recordsLeft})`;
        }

        return str;
    };

    return (
        <div className={clsx({
            [classes.ledgerMore]: variant === 'ledger',
            [classes.propertyActivityBtnWrapper]: variant === 'property',
            [classes.dashboardActivityBtnWrapper]: variant === 'dashboard',
        })}>
            <a className={clsx({
                [classes.ledgerMoreBtn]: variant === 'ledger',
                [classes.propertyActivityBtn]: variant === 'property',
                [classes.dashboardActivityBtn]: variant === 'dashboard',
            })}
               onClick={() => !isLoading && onClick()}>
                {getLabel()}
            </a>
        </div>
    );
};

export default React.memo(ViewMoreButton);
