import React from 'react';
import Container from '@material-ui/core/Container';
import CustomLink from "../../components/CustomLink/CustomLink";

import { useStyles } from '../../style.js';

const ContactPage = () => {
    const classes = useStyles();

    return (
        <section className={classes.contentSection}>
            <Container fixed className={classes.contentContainer}>
                <h4 className={classes.contentPageTitle}>Contact</h4>
                <p className={classes.contentText}>
                    555 Mission Street, Suite 2700
                    San Francisco, Ca 94105
                </p>
                <p className={classes.contentText}>
                    415.367.0792
                </p>
                <p className={classes.contentText}>
                    <CustomLink className={classes.contentTextLink} href="mailto:info@hauscoin.com">info@hauscoin.com</CustomLink>
                </p>
            </Container>
        </section>
    )
}

export default ContactPage;
