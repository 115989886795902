import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  landingHeroSlider: {
    width: '50%',
    height: 'calc(100vh - 80px)',
    position: 'absolute',
    top: '0',
    right: '0',
  },
  skeletonBgImg: {
    width: '100%',
    height: 'calc(100vh - 80px)',
    backgroundColor: 'rgba(0, 0, 0, 0.11)',
    position: 'relative',
    top: '0',
    right: '0',
  },
  // background: {
  //   position: 'inherit',
  //   width: '100%',
  //   height: '100vh',
  //   top: '0',
  //   right: '0',
  //   backgroundSize: 'cover',
  //   backgroundRepeat: 'no-repeat',
  //   backgroundPosition: 'center',
  // },
  landingHeroSliderImg: {
    height: 'calc(100vh - 80px)',
    width: 'inherit',
    filter: 'brightness(80%)',
  },
  landingHeroCardWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: '1',
  },
  landingHeroDotsWrapper: {
    // height: '12px',
    // width: '12px',
    // display: 'flex',
    // alignItems: 'center'
  },
  landingHeroDots: {
    backgroundColor: 'white',
    height: '6px',
    width: '6px',
    borderRadius: '50%',
    transition: 'all .5s ease-out',
  },
  '@media (max-width: 960px)': {
    landingHeroSlider: {
      width: '100%',
      position: 'inherit',
      marginTop: '60px',
    },
  },
  '@media (max-width: 480px)': {
  },
}))
