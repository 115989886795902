import React from 'react';
import {useStyles} from "./style";
import ListGraph from "../Сharts/ListGraph";

const List = ({cityLogo, alt, city, usState, percent, coinCost, description, tag}) => {
  const classes = useStyles();

  return (
    <div className={classes.listWrapper}>
      <div className={classes.listTitleWrapper}>
        <img className={classes.listTitleImg} src={cityLogo} alt={alt} />
        <div className={classes.listTitleText}>
          {city}
          <span className={classes.listTitleTextSpan}>{usState}</span>
          <div className={classes.listDescription}>
            {description}
          </div>
          <span className={classes.listTitleTextTag}>{tag}</span>
        </div>
      </div>
      <div className={classes.listPercent}>{percent}</div>
      <div className={classes.listCost}>{coinCost}</div>
      <div className={classes.listGraphWrapper}>
        <ListGraph />
      </div>
    </div>
  );
};

export default List;
