import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import CONFIG from 'common/config';

export default class CognitoAdminPool {
    #unconfirmedUser = null;

    constructor(adminApi) {
        const poolData = {
            UserPoolId: CONFIG.ADMIN_POOL_ID,
            ClientId: CONFIG.ADMIN_POOL_CLIENT_ID,
        };

        this.pool = new CognitoUserPool(poolData);
        this.api = adminApi;
    }

    get currentUser() {
        return this.pool.getCurrentUser();
    }

    login(email, password) {
        this.#unconfirmedUser = new CognitoUser({
            Username: email,
            Pool: this.pool,
        });

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        return new Promise((resolve, reject) => {
            this.#unconfirmedUser.authenticateUser(authDetails, {
                onSuccess: (session) => {
                    this.#handleLogin(session);
                    resolve(session);
                },
                onFailure: (err) => {
                    console.error('onFailure: ', err.message);
                    reject(err);
                },
                newPasswordRequired: () => {
                    resolve({
                        isNewUser: true,
                    });
                },
            });
        });
    }

    logout() {
        if (this.currentUser) {
            this.currentUser.signOut();
            console.log('User Sign Out!');
        }
    }

    /**
     * @typedef {Object} ConfirmRegistration
     * @property {string} newPassword
     * @property {string} firstName
     * @property {string} lastName
     */

    /**
     * @param {ConfirmRegistration} data
     */
    confirmRegistration(data) {
        const attrs = {
            ['given_name']: data.firstName,
            ['family_name']: data.lastName,
        };
        return new Promise(async (resolve, reject) => {
            this.#unconfirmedUser.completeNewPasswordChallenge(data.newPassword, attrs, {
                onSuccess: (session) => {
                    this.#handleLogin(session);
                    resolve(session);
                },
                onFailure: (err) => {
                    console.error('onFailure: ', err.message);
                    reject(err);
                },
            });
        });
    }

    restoreSession() {
        const user = this.pool.getCurrentUser();

        return new Promise((resolve, reject) => {
            user?.getSession(async (err, session) => {
                if (err) {
                    console.error(err);
                    reject(reject);
                    return;
                }

                this.#handleLogin(session);
                resolve();
            });
        });
    }

    #handleLogin(session) {
        const idToken = session.getIdToken();
        const token = idToken.getJwtToken();
        this.api.useToken(token);
        this.api.useAdminId(idToken.payload.sub);
    }
}
