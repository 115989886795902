import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  propertyDataBlockItems: {
    width: '30%',
    textAlign: 'left',
  },
  propertyDataBlockItemsTitle: {
    marginBottom: '12px',
    marginTop: 0,
    fontSize: theme.fontSize.text.small,
    lineHeight: '14px',
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
  },
  propertyDataBlockItemsText: {
    fontSize: theme.fontSize.text.total,
    letterSpacing: '-1.17px',
    lineHeight: '36px',
  },
  propertyDataBlockItemsTextSpan: {
    marginTop: '12px',
    display: 'block',
    fontFamily: theme.typography.fontFamily.black,
    textTransform: 'uppercase',
    fontSize: theme.fontSize.text.small,
    letterSpacing: '1.2px',
    color: theme.palette.text.grey,
    lineHeight: '22px'
  },
  propertyDataBlockItemsDateSpan: {
    marginTop: '20px',
    display: 'block',
    fontFamily: theme.typography.fontFamily.black,
    textTransform: 'uppercase',
    fontSize: theme.fontSize.text.small,
    letterSpacing: '1.2px',
    color: theme.palette.text.grey,
    lineHeight: '22px'
  },
  '@media (max-width: 480px)': {
    propertyDataBlockItems: {
      width: '100%',
      textAlign: 'center',
    },
    propertyDataBlockItemsTitle: {
      fontSize: '12px',
      marginBottom: '9px'
    },
    propertyDataBlockItemsText: {
      fontSize: '30px',
      lineHeight: '35px',
      letterSpacing: '-1.5px',
      '& span': {
        lineHeight: '18px'
      }
    },
  }
}))
