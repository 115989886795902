import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  coinBtn: {
    borderRadius: '0',
    padding: '0 11px',
    '&:nth-child(1)': {
      padding: '0 11px 0 0'
    },
    fontSize: '13px',
    letterSpacing: '1px'
  },
  coinBtnLogoImgWrapper: {
    position: 'relative',
    width: '48px',
    height: '48px',
    marginRight: '5px',
    backgroundColor: theme.palette.bgColor.lightBlue,
    borderRadius: '50%',
  },
  notActiveCoinBtn: {
    backgroundColor: theme.palette.bgColor.white,
  },
  notActiveCoinBtnGrey: {
    backgroundColor: theme.palette.bgColor.lightGrey,
  },
  coinBtnLogoImg: {
    width: '28px',
    height: '28px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  coinBtnLogoImgGrey: {
    filter: 'brightness(0.6)',
  },
  coinBtnLogoImgWhite: {
    filter: 'brightness(1)',
  },
  '@media (max-width: 480px)': {
    coinBtnWrapper: {
      overflowX: 'scroll',
      scrollBehavior: 'smooth',
    },
    coinBtnGroup: {
      paddingBottom: '20px',
    },
  }
}))
