import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ModalSignIn from 'common/modals/ModalSignIn';
import { ROUTES } from 'marketplace/constans';
import { useAppState } from 'marketplace/app-state';

const MarketplaceModalSignIn = () => {
    const history = useHistory();
    const { state, actions, cognitoClient } = useAppState();
    const [isLoading, setIsLoading] = useState(false);
    const [isLogError, setIsLogError] = useState(null);

    const submitHandler = (email, password) => {
        setIsLoading(true);
        cognitoClient
            .login(email, password)
            .then((data) => {
                if (data.isNewUser) {
                    history.push(ROUTES.HOME.concat(ROUTES.CONFIRM_USER));
                    actions.updateState({
                        modalSignInActive: false,
                        modalConfirmUserActive: true,
                    });
                    return;
                }

                actions.fetchInvestorData();
                actions.updateState({
                    currentUser: data.user,
                    isAuth: true,
                    modalSignInActive: false,
                    modalConfirmUserActive: false,
                });
                history.push(ROUTES.DASHBOARD);
            })
            .catch((err) => {
                console.error('Failed to login', err.message);
                setIsLogError('Incorrect username or password.');
                // actions.updateState({ modalSignInActive: true });
                history.push(ROUTES.HOME + ROUTES.LOGIN);
            })
            .finally(() => setIsLoading(false));
    };

    const moveForgotPasswordHandler = () => {
        actions.updateState({
            modalForgotPass: true,
            modalSignInActive: false,
        });
    };

    const moveSignUpHandler = () => {
        actions.updateState({
            modalActive: true,
            modalSignInActive: false,
        });
    };

    const signInCloseHandler = () => {
        actions.updateState({ modalSignInActive: false });
        history.push(window.location.pathname);
        setIsLoading(false);
    };

    return (
        <ModalSignIn
            withSignUp={true}
            withForgotPassword={true}
            open={state.modalSignInActive}
            isLoading={isLoading}
            onSubmit={submitHandler}
            onClose={signInCloseHandler}
            onSignUpClick={moveSignUpHandler}
            onForgotPasswordClick={moveForgotPasswordHandler}
            isLogError={isLogError}
        />
    );
};

export default React.memo(MarketplaceModalSignIn);
