import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  propertyActivityBtnWrapper: {
    display: 'flex',
    marginTop: '40px'
  },
  dashboardActivityBtnWrapper: {
    display: 'flex',
    marginTop: '40px'
  },
  dashboardActivityBtn: {
    display: 'block',
    padding: '20px 40px',
    border: '1px solid black',
    color: theme.palette.text.main,
    fontSize: theme.fontSize.text.small,
    cursor: 'pointer'
  },
  propertyActivityBtn: {
    width: '150px',
    display: 'block',
    padding: '20px 0px',
    textAlign: 'center',
    border: '1px solid black',
    color: theme.palette.text.main,
    fontSize: theme.fontSize.text.small,
    cursor: 'pointer'
  },
  ledgerMore: {
    marginTop: '120px',
    borderBottom: '1px solid #E0E0E0',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily.black,
  },
  ledgerMoreBtn: {
    display: 'block',
    color: theme.palette.text.main,
    marginBottom: '20px',
    textTransform: 'uppercase',
    cursor: 'pointer',
  },
  '@media (max-width: 480px)': {
    ledgerMore: {
      marginTop: '50px',
    },
    propertyActivityBtnWrapper: {
      marginTop: '35px',
    },
    propertyActivityBtn: {
      width: '100%',
      padding: '20px',
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '1.2px',
    },
    // dashboardActivityBtn : {
    //     padding: '15px 30px'
    // }
  },
  '@media (max-width: 320px)': {
    ledgerMoreBtn: {
      marginBottom: '10px',
    },
    ledgerMore: {
      marginTop: '30px',
    },
    propertyActivityBtnWrapper: {
      marginTop: '25px',
    },
    propertyActivityBtn: {
      padding: '10px'
    },
  },
}));
