import React, {useEffect, useRef, useState} from 'react';
import clsx from "clsx";
import {useStyles} from "./style";
import selectArrow from "../../../assets/img/select-arrow.svg";

const DropDown = ({titleClassName,title, fieldClassName}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    document.addEventListener("click", close);
    return () => document.removeEventListener("click", close);
  },[]);

  function close(e) {
    setOpen(e && e.target === ref.current)
  };

  return (
    <div className={classes.dropDownWrapper}>
      <div className={`${classes.dropDownTitle} ${titleClassName}`} ref={ref}>
        {title}
        <img className={classes.dropDownImg} src={selectArrow} alt="arrow"/>
      </div>
      <div className={`${classes.dropDownSelectField} ${fieldClassName}`} onClick={() => setOpen(!open)}>
        <div className={classes.dropDownBtn}>
          State
        </div>
      </div>
    </div>
  );
};

export default DropDown;
