import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.header,
    lineHeight: '72px',
    letterSpacing: '-1.5px',
  }
}))
