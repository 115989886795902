import React from 'react';
import { useStyles } from '../../components/PrivacyTables/style.js';

const ReasonsTable = () => {
    const classes = useStyles();

    return (
        <>
        <table className={`${classes.tablePrivacy} ${classes.reasonsTable}`} frame='none' cellSpacing="0">
            <tbody>
            <tr>
                <th className={classes.reasonsTh}>Reasons we can share your personal information</th>
                <th className={classes.reasonsTh}>Does HausCoin share?</th>
                <th className={classes.reasonsTh}>Can you limit this sharing?</th>
            </tr>
            <tr>
                <td className={classes.reasonsTd}>
                    For our everyday business purposes — such as to process your transactions, maintain your account balances,
                    respond to court orders and legal investigations, or report to credit bureaus
                </td>
                <td className={classes.reasonsTd}>
                    Yes
                </td>
                <td className={classes.reasonsTd}>
                    No
                </td>
            </tr>
            <tr>
                <td className={classes.reasonsTd}>
                    For our marketing purposes — to offer our products and services to you
                </td>
                <td className={classes.reasonsTd}>
                    Yes
                </td>
                <td className={classes.reasonsTd}>
                    No
                </td>
            </tr>
            <tr>
                <td className={classes.reasonsTd}>
                    For joint marketing with other ﬁnancial companies
                </td>
                <td className={classes.reasonsTd}>
                    Yes
                </td>
                <td className={classes.reasonsTd}>
                    No
                </td>
            </tr>
            <tr>
                <td className={classes.reasonsTd}>
                    For our affiliates’' everyday business purposes — information about your transactions and experiences
                </td>
                <td className={classes.reasonsTd}>
                    No
                </td>
                <td className={classes.reasonsTd}>
                    We don’t share
                </td>
            </tr>
            <tr>
                <td className={classes.reasonsTd}>
                    For our affiliates’' everyday business purposes — information about your creditworthiness
                </td>
                <td className={classes.reasonsTd}>
                    No
                </td>
                <td className={classes.reasonsTd}>
                    We don’t share
                </td>
            </tr>
            <tr>
                <td className={classes.reasonsTd}>
                    For our afﬁliates to market to you
                </td>
                <td className={classes.reasonsTd}>
                    No
                </td>
                <td className={classes.reasonsTd}>
                    We don’t share
                </td>
            </tr>
            <tr>
                <td className={classes.reasonsTd}>
                    For nonafﬁliates to market to you
                </td>
                <td className={classes.reasonsTd}>
                    No
                </td>
                <td className={classes.reasonsTd}>
                    We don’t share
                </td>
            </tr>
            </tbody>
        </table>
        <table className={`${classes.tablePrivacy} ${classes.reasonsTable}`} frame='none' cellSpacing="0">
            <tbody>
                <tr>
                    <td className={`${classes.reasonsTd} ${classes.greyBgTd}`}>
                        Questions?
                    </td>
                    <td className={classes.reasonsTd}>
                        Go to <a href="https://HausCoin.com/">https://HausCoin.com/</a>
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.reasonsTd} ${classes.greyBgTd}`} colSpan="2">Who we are</td>
                </tr>
                <tr>
                    <td className={`${classes.reasonsTd} ${classes.boldTd}`}>Who is providing this notice?</td>
                    <td className={classes.reasonsTd}>This notice is being provided on behalf of HausCoin, LLC.</td>
                </tr>
            </tbody>
        </table>
    </>
    )
};

export default ReasonsTable;