import React, {useEffect, useState} from 'react';
import { sortBy, uniqBy } from "lodash";
import Heading from "../../components/Heading/Heading";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import DropDown from "../../components/Dropdown/DropDown";
import Card from "../../components/Card/Card";
import List from "../../components/List/List";
import {useStyles} from "./style";
import {useAppState} from "../../app-state";
import BlueLabel from "../../components/BlueLabel/BlueLabel";
import LargeTitle from "../../components/LargeTitle/LargeTitle";
import CONFIG_COIN from "../../CoinConfig/CoinConfig";
import { formatTokenData, initialCoinData } from "../../lib/tokens";

const TokensPage = () => {
  const classes = useStyles();
  const { apiClient } = useAppState();
  const [cardData, setCardData] = useState([]);
  const [cardDataLoaded, setCardDataLoaded] = useState(false);

  const formatBlocks = coinData => {
    let currentState = null;
    let currentBlock = null;
    const cardData = [];
    coinData.forEach((f, idx) => {
      if (f.usState !== currentState) {
        if (currentBlock) {
          cardData.push(currentBlock)
        }
        currentBlock = { state: f.usState, cards: []}
        currentState = f.usState;
      }
      currentBlock.cards.push(f);
      if (idx === coinData.length - 1) {
        cardData.push(currentBlock);
      }
    })
    setCardData(cardData);
  }

  useEffect(() => {
    if (!cardData.length) {
      const initialFormatted = formatTokenData(initialCoinData, CONFIG_COIN);
      formatBlocks(initialFormatted);
    }
  }, [cardData]);


  useEffect(async () => {
    if (cardData.length && !cardDataLoaded) {
      const formatted = await apiClient.getActiveTokens();
      setCardDataLoaded(true);
      formatBlocks(formatted);
    }
  }, [cardData]);

  return (
    <section className={classes.tokens}>
      <ContentContainer className={classes.tokensContainer}>
        <BlueLabel
          labelClassName={classes.tokensLabel}
          text={"Tokens"}
        />
        <LargeTitle
          titleClassName={classes.tokensTitle}
          title={"Equity backed by location"}
        />
        <div className={classes.tokensText}>
          HausCoin is a security token backed by single family owner occupied homes in the best markets, providing
          transparency to investors and affordable home ownership to consumers.
        </div>
      </ContentContainer>
      <div className={classes.tokensCardBg}>
      <ContentContainer className={classes.tokensContainer}>
        {cardData.map((cardBlock, idx) => (
          <div className={classes.tokensCardBlock} key={`state-block-${idx}`}>
          <LargeTitle
            titleClassName={classes.tokensCardsTitle}
            title={cardBlock.state}
          />
          <div className={classes.tokensCardTable}>
            {cardBlock.cards.map((card, idx) => (
              <Card
                id={card.id}
                city={card.title}
                cityLogo={card.logo}
                tag={card.status}
                title={card.title}
                alt={card.alt}
                coinCost={card.coinCost}
                percent={card.percent}
                usState={card.usState}
                stateCode={card.stateCode}
                price={card.price}
                circulation={card.circulation}
                dates={card.dates}
                key={`block-card-${idx}`}
                graphColumns={12}
                active={card.active}
              />
            ))}
          </div>
        </div>))}
      </ContentContainer>
      </div>
    </section>
  )
    ;
};

export default TokensPage;
