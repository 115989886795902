import {makeStyles} from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  containerFilter: {
    position: 'relative'
  },
  filterWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.medium,
    cursor: 'pointer'
  },
  filterBtn: {
    padding: '15px 10px',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.medium,
    whiteSpace: 'nowrap',
    textAlign: 'left',
    border: 'none',
    borderBottom: '1px solid #E5E5E5',
    outline: 'none',
    backgroundColor: '#cfcfcf',
    color: theme.palette.text.main,
    cursor: 'pointer',
    transition: 'all .5s ease',
    '&:hover': {
      backgroundColor: theme.palette.bgColor.white,
    }
  },
  filterBtnSpanImg: {
    marginLeft: '16px'
  },
  filterWrapperList: {
    position: 'absolute',
    right: 0,
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.bgColor.white,
    border: 'none',
    overflow: 'scroll',
    overflowX: 'hidden',
    zIndex: 1
  },
  filterClose: {
    display: 'none'
  },
  '@media (max-width: 960px)': {
    filterWrapper: {
      fontSize: theme.fontSize.text.small
    },
  },
  '@media (max-width: 480px)': {
    filterWrapperList: {
      height: '200px'
    },
    // filterBtn : {
    //     padding: '5px 2px',
    //     fontSize: '8px'
    // },
  },
  '@media (max-width: 320px)': {
    // filterWrapper : {
    //     fontSize: '6px'
    // },
    // filterBtn : {
    //     padding: '2px',
    //     fontSize: '6px'
    // },
    // filterBtnSpanImg : {
    //     marginLeft: '6px',
    //     width: '20%'
    // }
  }
}))
