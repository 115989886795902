import { useSnackbar } from 'notistack';
import { AUTH_ERRORS } from '../../marketplace/constans';

export function useCustomSnackbar() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const showConnectionError = () => {
        // if (...) {
        //     showError(ERROR_MSG.UNKNOWN_ERROR);
        // } else if (...) {
        //     showError(ERROR_MSG.NO_CONNECTION);
        // } else if (...) {
        //     showError(ERROR_MSG.INCORRECT_NETWORK);
        // } else {
        //     showError(ERROR_MSG.COMMON);
        // }
    };

    const showError = (msgType = AUTH_ERRORS.LOGIN_REQUEST, error = null) => {
        enqueueSnackbar(msgType, { variant: 'error' });
        error && console.error(msgType, error);
    };

    const showSuccess = (msgType) => {
        enqueueSnackbar(msgType, { variant: 'success' });
    };

    const showInfo = (msgType) => {
        enqueueSnackbar(msgType, { variant: 'info' });
    }

    return {
        enqueueSnackbar,
        closeSnackbar,
        showError,
        showConnectionError,
        showSuccess,
        showInfo,
        AUTH_ERRORS,
    };
}
