import React from 'react';
import Container from '@material-ui/core/Container';
import FactsTable from '../../components/PrivacyTables/FactsTable';
import ReasonsTable from '../../components/PrivacyTables/ReasonsTable';
import WeDoTable from '../../components/PrivacyTables/WeDoTable';
import CustomLink from "../../components/CustomLink/CustomLink";

import { useStyles } from '../../style.js';

const blockTitles = {
    titleOne: "OUR COMMITMENT TO PRIVACY",
    titleTwo: "APPLICABILITY OF PRIVACY POLICY",
    titleThree: "PERSONAL DATA WE COLLECT",
    titleFour: "LINKS TO OTHER WEBSITES",
    titleFive: "HOW WE USE PERSONAL DATA",
    titleSix: "SHARING AND DISCLOSURE OF PERSONAL DATA",
    titleSeven: "DATA RETENTION",
    titleEight: "UPDATE YOUR INFORMATION",
    titleNine: "CHILDREN",
    titleTen: "SECURITY",
    titleEleven: "YOUR CHOICES",
    titleTwelve: "CHANGES TO THE PRIVACY POLICY",
    titleThirteen: "CALIFORNIA PRIVACY RIGHTS - DO NOT TRACK SIGNALS",
    titleFourteen: "CONTACT US"
};

const PrivacyPolicy = () => {
    const classes = useStyles();

    return (
        <section className={classes.contentSection}>
            <Container className={classes.contentContainer} fixed>
                <h4 className={classes.contentPageTitle}>HAUSCOIN PRIVACY POLICY</h4>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleOne}</div>
                    <div className={classes.contentText}>
                        HausCoin, LLC (“HausCoin”) knows that you care about how your personal information is used and shared,
                        and we take your privacy seriously. Our primary goal is to provide you with exceptional service, and we
                        understand that you may have questions or concerns regarding your personal information and how it will be
                        used. To make this privacy policy easy to find, we make it available from the home page of the HausCoin
                        website at hauscoin.com (the "Site") and through our mobile applications. You may also email us
                        at <a href="mailto:info@hauscoin.com">info@hauscoin.com</a> with any privacy-related questions you have.
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleTwo}</div>
                    <div className={classes.contentText}>
                        This privacy policy applies to all information we collect through our web and mobile applications from
                        current and former HausCoin users, including you. When you are no longer our customer, we may continue to
                        share your information as described in this policy.Throughout this policy, we use the term "personal information"
                        to describe information that can be associated with a specific person and can be used to identify that person. We
                        do not consider personal information to include information that has been aggregated and/or anonymized so that it
                        does not identify a specific user.By using the service, you agree to the practices described in this Privacy Policy.
                        If you do not agree to this Privacy Policy, please do not access the Site or otherwise use the products or services.
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleThree}</div>
                    <div className={classes.contentText}>
                        <span className={classes.contentTextTitle}>Personal Data You Provide</span>
                        We collect personal information when you create an account, link your bank account(s) to the service, purchase or transfer
                        HausCoin, or take other actions. The personal information collected during these interactions may vary based on what you
                        choose to share with us, but it will generally include your name, email address, phone number, bank account information
                        (such as account/routing numbers and transaction data), and a log of the transactions you authorize. We may need to collect
                        additional information, such as your street address, zip code, date of birth, certain digits of your social security number,
                        and government-issued form of identification to fulfill our legal obligations related to providing the service to you. In
                        addition, while you are using the App, we may collect your location information.Personal Data We Collect Through Our Social
                        Media Pages We may have pages on social media sites like Facebook, Instagram, and Twitter (“Social Media Pages”). When you
                        interact with our Social Media Pages, we will collect personal information that you elect to provide to us, such as your contact
                        details. In addition, the companies that host our Social Media Pages may provide us with aggregate information and analytics
                        regarding the use of our Social Media Pages.
                    </div>
                    <div className={classes.contentText}>
                        <span className={classes.contentTextTitle}>Personal Data We Receive Automatically from Your Use of the Service</span>
                        When you visit, use and interact with the service, we may receive certain information about your visit, use or interactions. For
                        example, we may monitor the number of people that visit the service, peak hours of visits, which page(s) are visited, the domains
                        our visitors come from (e.g., <CustomLink href="https://google.com">google.com</CustomLink>, <CustomLink href="https://yahoo.com">yahoo.com</CustomLink>, etc.), and which browsers people use to access the service (e.g., Firefox,
                        Microsoft Internet Explorer, etc.), broad geographical information, and navigation pattern. In particular, the following information
                        is created and automatically logged in our systems:
                        <ul className={classes.contentTextList}>
                            <li>
                                Log data: Information that your browser automatically sends whenever you visit the Site (“log data”). Log data includes your Internet
                                Protocol address, browser type and settings, the date and time of your request, and how you interacted with the service.
                            </li>
                            <li>
                                Cookies: Please see the “Cookies” section below to learn more about how we use cookies.
                            </li>
                            <li>
                                Device information: Includes name of the device, operating system, and browser you are using. Information collected may depend on the
                                type of device you use and its settings.
                            </li>
                            <li>
                                Usage Information: We collect information about how you use our service, such as the types of content that you view or engage with, the
                                features you use, the actions you take, and the time, frequency and duration of your activities.
                            </li>
                        </ul>
                    </div>
                    <div className={classes.contentText}>
                        <span className={classes.contentTextTitle}>Third Party Credentials, Account Numbers and Other Account Information</span>
                        When you use certain services, we may collect from you your usernames, passwords, account numbers, and other account information and use or share
                        that information with third-party websites and Internet banking services that are not operated or controlled by HausCoin (“Third-Party Sites”) in
                        order to provide the services to you. We also collect account information from you when you open a HausCoin and we may share that information with
                        third-party providers in order to offer the services to you. Information you provide to us may be used to obtain your account, transaction, and other
                        banking information from the relevant organization on your behalf, to display the information to you, or to fulfill your requests for certain products,
                        services, or transactions through the service.By using our services, you grant HausCoin and each Third-Party Site the right, power, and authority to act
                        on your behalf to access and transmit your information in order to provide the services. In addition, you agree that to the extent that your information
                        is in the possession of a Third-Party Site, such information is subject to the privacy policy of that Third-Party Site.
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleFour}</div>
                    <div className={classes.contentText}>
                        The Service may contain links to other websites not operated or controlled by HausCoin, including social media services.  The information that you share with
                        those third parties will be governed by the specific privacy policies and terms of service of the third party sites, respectively and not by this Privacy Policy.
                        By providing such links we do not imply that we endorse or have reviewed the third party sites. Please contact such third party sites directly for information on
                        their privacy practices and policies.Cookies We use cookies to operate and administer our Site, gather usage data on our Site and improve your experience on it.
                        A “cookie” is a piece of information sent to your browser by a website you visit. Cookies can be stored on your computer for different periods of time. Some cookies
                        expire after a certain amount of time, or upon logging out (session cookies), others survive after your browser is closed until a defined expiration date set in the
                        cookie (as determined by the third party placing it), and help recognize your computer when you open your browser and browse the Internet again (persistent cookies).
                        For more details on cookies please visit <CustomLink href="https://haus.com/legal/privacy-preferences">All About Cookies</CustomLink>.
                    </div>
                    <div className={classes.contentText}>
                        <span className={classes.contentTextTitle}>Analytics</span>
                        We may use Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses cookies to help us analyze how users use the Site and
                        enhance your experience when you use the Site. For more information on how Google uses this data, go to <CustomLink href="https://google.com/policies/privacy/partners/">www.google.com/policies/privacy/partners/</CustomLink>.
                    </div>
                    <div className={classes.contentText}>
                        <span className={classes.contentTextTitle}>Online Tracking and Do Not Track Signals</span>
                        We and our third-party service providers may use cookies or other tracking technologies to collect information about your browsing activities over time and across
                        different websites following your use of the Site. Our Site currently does not respond to “Do Not Track” (“DNT”) signals and operates as described in this Privacy
                        Policy whether or not a DNT signal is received. If we do respond to DNT signals in the future, we will update this Privacy Policy to describe how we do so.
                    </div>
                    <div className={classes.contentText}>
                        <span className={classes.contentTextTitle}>Your Choices</span>
                        On most web browsers, you will find a “help” section on the toolbar. Please refer to this section for information on how to receive a notification when you are
                        receiving a new cookie and how to turn cookies off. Please see the links below for guidance on how to modify your web browser’s settings on the most popular browsers:
                        <ul className={classes.contentTextList}>
                            <li>
                                <CustomLink href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">Internet Explorer</CustomLink>
                            </li>
                            <li>
                                <CustomLink href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox">Mozilla Firefox</CustomLink>
                            </li>
                            <li>
                                <CustomLink href="https://support.google.com/chrome/answer/95647?hl=en&co=GENIE.Platform%3DDesktop">Google Chrome</CustomLink>
                            </li>
                            <li>
                                <CustomLink href="https://support.apple.com/guide/safari/manage-cookies-sfri11471/mac">Apple Safari</CustomLink>
                            </li>
                        </ul>
                        Please note that if you limit the ability of websites to set cookies, you may be unable to access certain parts of the Site and you may not be able to benefit from the
                        full functionality of the Site.Advertising networks may collect personal information through the use of cookies. Most advertising networks offer you a way to opt out of
                        targeted advertising. If you would like to find out more information, please visit the Network Advertising Initiative’s online resources at <CustomLink href="https://networkadvertising.org">http://www.networkadvertising.org</CustomLink> 
                        and follow the opt-out instructions there.If you access the Site on your mobile device,
                        you may not be able to control tracking technologies through the settings.
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleFive}</div>
                    <div className={classes.contentText}>
                        We may use personal information for the following purposes:
                        <ul className={classes.contentTextList}>
                            <li>
                                To help you access and use the service;
                            </li>
                            <li>
                                To respond to your inquiries, comments, feedback or questions;
                            </li>
                            <li>
                                To send administrative information to you, for example, information regarding the service,
                                and changes to our terms, conditions, and policies;
                            </li>
                            <li>
                                To analyze how you interact with our service;
                            </li>
                            <li>
                                To maintain and improve the content and functionality of the service (please see the “Cookies” section
                                above to learn how we use cookies);
                            </li>
                            <li>
                                To develop new products and services;
                            </li>
                            <li>
                                To market our services or the services of our affiliates or business partners to you;
                            </li>
                            <li>
                                To prevent fraud, criminal activity, or misuses of our service, and to ensure the security of our IT systems,
                                architecture and networks;
                            </li>
                            <li>
                                To comply with legal obligations and legal process and to protect our rights, privacy, safety or property,
                                and/or that of our affiliates, you or other third parties.
                            </li>
                        </ul>
                    </div>
                    <div className={classes.contentText}>
                        <span className={classes.contentTextTitle}>Aggregated Information</span>
                        We may aggregate personal information and use the aggregated information to analyze the effectiveness of our service,
                        to improve and add features to our service, and for other similar purposes. In addition, from time to time, we may
                        analyze the general behavior and characteristics of users of our services and share aggregated information like general
                        user statistics with prospective business partners. We may collect aggregated information through features of the software
                        that supports our service, through cookies, and through other means described in this Privacy Policy.
                    </div>
                    <div className={classes.contentText}>
                        <span className={classes.contentTextTitle}>Marketing</span>
                        We may contact you to tell you about services we believe will be of interest to you. For instance, if you elect to provide
                        your email or telephone number, we may use that information to send you special offers. You may opt out of receiving emails
                        by following the instructions contained in each promotional email we send you. You can also control the marketing emails
                        and/or text messages you receive by updating your settings through your account. In addition, if at any time you do not wish
                        to receive future marketing communications, please contact us. If you unsubscribe from our marketing lists, you will no longer
                        receive marketing communications but we will continue to contact you regarding our Site and services and to respond to your requests.
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleSix}</div>
                    <div className={classes.contentText}>
                        In certain circumstances we may share your personal information with third parties without further notice to you, unless required
                        by the law, as set forth below:
                        <ul className={classes.contentTextList}>
                            <li>
                                <span className={classes.contentTextBold}>Vendors and Service Providers:</span> To assist us in meeting business operations needs and to perform certain services and functions,
                                we may share personal information with vendors and service providers, including providers of hosting services, cloud services
                                and other information technology services providers, email communication software and email newsletter services, advertising
                                and marketing services, financial service providers (including payment card processors, banks, and API services), customer
                                relationship management and customer support services, and web analytics services (for more details on the third parties that
                                place cookies through the Site, please see the “Cookies” section above). Pursuant to our instructions, these parties will access,
                                process or store personal information in the course of performing their duties to us. We take commercially reasonable steps to ensure
                                our service providers adhere to the Security standards we apply to your personal information.
                            </li>
                            <li>
                                <span className={classes.contentTextBold}>Business Transfers:</span> If we are involved in a merger, acquisition, financing due diligence, reorganization, bankruptcy, receivership,
                                sale of all or a portion of our assets, or transition of service to another provider, your personal information and other information
                                may be shared in the diligence process with counterparties and others assisting with the transaction and transferred to a successor or
                                affiliate as part of that transaction along with other assets.
                            </li>
                            <li>
                                <span className={classes.contentTextBold}>Legal Requirements:</span> If required to do so by law or in the good faith belief that such action is necessary to (i) comply with a legal
                                obligation, including to meet national security or law enforcement requirements, (ii) protect and defend our rights or property, (iii)
                                prevent fraud, (iv) act in urgent circumstances to protect the personal safety of users of the services, or the public, or (v) protect
                                against legal liability.
                            </li>
                            <li>
                                <span className={classes.contentTextBold}>Other users:</span> Your personal information may be accessible to other users of the service with whom you interact. For example, where you
                                share a sub-account with other users (such as if you create a sub-account to share expenses related to a family vacation), the other
                                users with access to the sub-account will have access to the transactions you authorize within that sub-account.
                            </li>
                            <li>
                                <span className={classes.contentTextBold}>Affiliates:</span> We may share personal information with our affiliates, meaning an entity that controls, is controlled by, or is under common
                                control with HausCoin. Our affiliates may use the personal information we share in a manner consistent with this Privacy Policy.
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleSeven}</div>
                    <div className={classes.contentText}>
                        We keep personal information for as long as reasonably necessary for the purposes described in this Privacy Policy,
                        while we have a business need to do so, or as required by law (e.g. for tax, legal, accounting or other purposes), whichever is the longer.
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleEight}</div>
                    <div className={classes.contentText}>
                        Please log in to your account or <CustomLink href="mailto:info@hauscoin.com">contact us</CustomLink> if you need to change or correct your personal information.
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleNine}</div>
                    <div className={classes.contentText}>
                        Our service is not directed to children who are under the age of 13. HausCoin does not knowingly collect personal information from
                        children under the age of 13. If you have reason to believe that a child under the age of 13 has provided personal information to
                        HausCoin through the service please <CustomLink href="mailto:info@hauscoin.com">contact us</CustomLink> and we will endeavor to delete that information from our databases.
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleTen}</div>
                    <div className={classes.contentText}>
                        You use the service at your own risk. We implement reasonable technical, administrative, and organizational measures to protect personal information
                        both online and offline from loss, misuse, and unauthorized access, disclosure, alteration or destruction. However, no Internet or e-mail transmission
                        is ever fully secure or error free. In particular, e-mail sent to or from us may not be secure. Therefore, you should take special care in deciding what
                        information you send to us via the service or e-mail. Please keep this in mind when disclosing any personal information to HausCoin via the Internet.
                        In addition, we are not responsible for circumvention of any privacy settings or security measures contained on the service, or third party websites.
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleEleven}</div>
                    <div className={classes.contentText}>
                        In certain circumstances providing personal information is optional. However, if you choose not to provide personal information that is needed to use
                        some features of our services, you may be unable to use those features. You can also contact us to ask us to update or correct your personal information.
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleTwelve}</div>
                    <div className={classes.contentText}>
                        The service, and our business may change from time to time. As a result, we may change this Privacy Policy at any time. When we do we will post an
                        updated version on this page, unless another type of notice is required by the applicable law. By continuing to use our service or providing us with
                        personal information after we have posted an updated Privacy Policy, or notified you if applicable, you consent to the revised Privacy Policy
                        and practices described in it.
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleThirteen}</div>
                    <div className={classes.contentText}>
                        Our Site currently does not respond to “Do Not Track” (DNT) signals and operates as described in this Privacy Policy whether or not a DNT signal
                        is received. If we do so in the future, we will describe how we do so in this Privacy Policy.
                    </div>
                </div>
                <div>
                    <div className={classes.contentTitle}>{blockTitles.titleFourteen}</div>
                    <div className={classes.contentText}>
                        If you have any questions about our Privacy Policy or information practices, please feel free to contact us
                        at <a href="mailto:info@hauscoin.com">info@hauscoin.com</a>.
                    </div>
                </div>
                <div>
                    <h4 className={classes.contentTableTitle}>HAUSCOIN PRIVACY NOTICE</h4>
                    <FactsTable />
                    <ReasonsTable />
                    <WeDoTable />
                </div>
            </Container>
        </section>
    )
}

export default PrivacyPolicy;
