import { Hidden } from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  dashboardGraphWrapper: {
    height: '350px'
  },
  landingGraphsWrapper: {
    height: '200px',
    width: '350px'
  },
  propertyGraphWrapper: {
    padding: '0 20px',
    height: '536px',
    // width: '100vw',
    position: 'relative',
    backgroundColor: theme.palette.bgColor.grey,
    marginTop: '85px',
    zIndex: 0,
  },
  customerWrapper: {
    position: 'absolute',
    bottom: '-5px',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: -1
  },
  customerProgress: {
    background: '#FFFFFF',
    display: 'inline-block',
    width: '509px',
    height: '398px',
    marginTop: '55px',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      borderBottom: '380px solid #F2F2F2',
      borderLeft: '260px solid transparent',
      right: 0,
      top: '-66px',
      transform: 'rotateX(132deg)',
      zIndex: 9
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: 0,
      height: 0,
      borderBottom: '380px solid #F2F2F2',
      borderRight: '260px solid transparent',
      left: 0,
      top: '-66px',
      transform: 'rotateX(132deg)',
      zIndex: 9
    }
  },
  progressDone: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    background: '#0650CF',
    height: 0,
  },
  customerProgressText: {
    position: 'absolute',
    margin: 0,
    bottom: '65px',
    left: '50%',
    transform: 'translate(-50%)',
    color: theme.palette.text.white,
    textShadow: '0 0 2px rgba(6, 80, 207, 100)',
    textAlign: 'center',
    fontSize: theme.fontSize.text.medium,
    fontWeight: theme.fontWeight.text.semiBold
  },
  customerProgressSpan: {
    position: 'absolute',
    bottom: '0',
    left: '50%',
    transform: 'translate(-50%)',
    color: theme.palette.text.white,
    textShadow: '0 0 2px rgba(6, 80, 207, 100)',
    textAlign: 'center',
    fontSize: theme.fontSize.text.subHeader,
    fontWeight: theme.fontWeight.text.bold
  },
  cardGraphContainer: {
    width: '100%',
    height: 'inherit',
    // height: '144px',
  },
  listGraphContainer: {
    width: '100%',
    height: '120px',
  },
  chartToolTip: {
    opacity: '1',
    background: '#eaeaea',
    position: 'absolute',
    width: '160px',
    height: '100px',
    padding: '15px',
  },
  chartToolTipWhite: {
    background: '#ffffff',
  },
  leftArrowBox: {
    left: '5px',
  },
  rightArrowBox: {
    left: '165px',
  },
  centerArrowBox: {
    left: '80px',
  },
  arrowBox: {
  	background: 'transparent',
  	top: '100%',
  	position: 'absolute',
  	pointerEvents: 'none',
  	borderColor: '#eaeaea transparent transparent transparent',
    border: '10px solid',
  },
  arrowBoxWhite: {
    background: 'transparent',
    top: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    borderColor: '#ffffff transparent transparent transparent',
    border: '10px solid',
  },
  toolTipTitle: {
    fontSize: '14px',
    fontFamily: 'Basis Grotesque Pro',
    fontWeight: 'bold',
    lineHeight: '22px',
  },
  toolTipBody: {
    marginTop: '10px',
    fontSize: '16px',
    color: '#468AFF',
  },
  toolTipLegend: {
    width: '40px',
    display: 'inline-block',
    marginRight: '5px',
    background: '#468AFF',
  },
  priceLegend: {
    height: '4px',
    marginBottom: '3px'
  },
  circulationLegend: {
    opacity: '0.5',
    height: '8px',
    marginBottom: '1px'
  },
  propertyGraphToolTip: {
    height: '50px',
  },
  '@media (max-width: 600px)': {
    customerProgress: {
      width: '314px',
      height: '200px',
      '&:before': {
        borderBottom: '220px solid #F2F2F2',
        borderLeft: '190px solid transparent',
        right: 0,
        top: '-63px',
      },
      '&:after': {
        borderBottom: '220px solid #F2F2F2',
        borderRight: '190px solid transparent',
        left: 0,
        top: '-63px',
      }
    },
    customerProgressText: {
      fontSize: '8px',
      bottom: '45px'
    },
    customerProgressSpan: {
      bottom: '-10px'
    },
    propertyGraphWrapper: {
      height: '300px'
    },
  },
  '@media (max-width: 480px)': {
    propertyGraphWrapper: {
      height: '250px',
    },
    customerProgressText: {
      bottom: '35px'
    },
    customerProgressSpan: {
      fontSize: '12px',
      bottom: 0
    }
  },
  '@media (max-width: 380px)': {
    landingGraphsWrapper: {
      width: '100%',
    },
    propertyGraphWrapper: {
      height: '190px'
    },
    customerProgress: {
      width: '210px',
      height: '160px',
      '&:before': {
        borderBottom: '200px solid #F2F2F2',
        borderLeft: '190px solid transparent',
        right: 0,
        top: '-63px',
      },
      '&:after': {
        borderBottom: '200px solid #F2F2F2',
        borderRight: '190px solid transparent',
        left: 0,
        top: '-63px',
      }
    },
  },
  '@media (max-width: 320px)': {
    propertyGraphWrapper: {
      marginTop: '30px'
    }
  }
}))
