import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useStyles } from './style.js';

const SkeletonTable = () => {
  const classes = useStyles();

  return (
    <>
      {
        Array(5).fill().map((item,index) => (
          <tr key={index} className={classes.skeletonTableRow}>
            <td className={`${classes.skeletonTableTd} ${classes.skeletonTableTdFirst}`}>
              <Skeleton variant="text" animation="wave" className={classes.skeletonText} />
            </td>
            <td className={classes.skeletonTableTd}>
              <Skeleton variant="text" animation="wave" className={classes.skeletonText} />
            </td>
            <td className={classes.skeletonTableTd}>
              <Skeleton variant="text" animation="wave" className={classes.skeletonText} />
            </td>
            {/*<td className={classes.skeletonTableTd}>*/}
            {/*    <Skeleton variant="text" animation="wave" className={classes.skeletonText} />*/}
            {/*</td>*/}
            <td className={classes.skeletonTableTd}>
              <Skeleton variant="text" animation="wave" className={classes.skeletonText} />
            </td>
          </tr>
        ))
      }
    </>
  );
};

export default SkeletonTable;
