import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import CONFIG from 'common/config';
import BaseCognitoPool from 'common/lib/BaseCognitoPool';

export default class CognitoInvestorPool extends BaseCognitoPool {
    #unconfirmedUser = null;

    constructor(investorApi) {
        super();
        const poolData = {
            UserPoolId: CONFIG.INVESTOR_POOL_ID,
            ClientId: CONFIG.INVESTOR_POOL_CLIENT_ID,
        };

        this.pool = new CognitoUserPool(poolData);
        this.api = investorApi;
        this.api.useAuthTokenInterceptor(() => this.#getAuthToken());
        this.api.useExpTokenInterceptor(() => this.#refreshIdToken());
    }

    get currentUser() {
        return this.pool.getCurrentUser();
    }

    login(email, password) {
        this.#unconfirmedUser = new CognitoUser({
            Username: email,
            Pool: this.pool,
        });

        // set to enable the user migration trigger
        this.#unconfirmedUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');

        const authDetails = new AuthenticationDetails({
            Username: email,
            Password: password,
        });

        return new Promise((resolve, reject) => {
            this.#unconfirmedUser.authenticateUser(authDetails, {
                onSuccess: (session) => {
                    this.#handleLogin(session);
                    const basicData = this.#getBasicData(session);
                    this.saveUserDataToLS(basicData);
                    resolve({ session, user: basicData });
                },
                onFailure: (err) => {
                    console.error('onFailure: ', err.message);
                    reject(err);
                },
                newPasswordRequired: () => {
                    resolve({
                        isNewUser: true,
                    });
                },
            });
        });
    }

    logout() {
        this.clearUserDataInLS();
        if (this.currentUser) {
            this.currentUser.signOut();
            console.log('User Sign Out!');
        }
    }

    /**
     * @typedef {Object} ConfirmInvestorRegistration
     * @property {string} newPassword
     */

    /**
     * @param {ConfirmInvestorRegistration} data
     */
    confirmRegistration(data) {
        return new Promise(async (resolve, reject) => {
            this.#unconfirmedUser.completeNewPasswordChallenge(data.newPassword, null, {
                onSuccess: (session) => {
                    this.#handleLogin(session);
                    const basicData = this.#getBasicData(session);
                    this.saveUserDataToLS(basicData);

                    resolve({ session, user: basicData });
                },
                onFailure: (err) => {
                    console.error('onFailure: ', err.message);
                    reject(err);
                },
            });
        });
    }

    /**
     * @returns {Promise<{session: CognitoUserSession, user: object}>}
     */
    restoreSession() {
        const user = this.pool.getCurrentUser();
        return new Promise((resolve, reject) => {
            if (!user) {
                this.clearUserDataInLS();
                reject(reject);
                return;
            }
            user.getSession(async (err, session) => {
                if (err) {
                    console.error(err);
                    reject(reject);
                    return;
                }
                this.#handleLogin(session);
                const user = this.#getBasicData(session);
                resolve({ session, user });
            });
        });
    }

    #handleLogin(session) {
        const idToken = session.getIdToken();
        const token = idToken.getJwtToken();
        this.api.useToken(token);
        this.api.useUserId(idToken.payload.sub);
    }

    #getBasicData(session) {
        return {
            id: session.idToken.payload.sub,
            firstName: session.idToken.payload['given_name'],
            lastName: session.idToken.payload['family_name'],
            ppmAcceptedAt: null,
            bankAccount: null,
            kycProviders: [],
        };
    }

    #getAuthToken() {
        const user = this.pool.getCurrentUser();
        return new Promise((resolve, reject) => {
            user?.getSession(async (err, session) => {
                if (err) {
                    reject(reject);
                    return;
                }

                const idToken = session.getIdToken();
                resolve(idToken.getJwtToken());
            });
        });
    }

    #refreshIdToken() {
        return new Promise(async (resolve, reject) => {
            try {
                const { session } = await this.restoreSession();
                const refreshToken = session.getRefreshToken();
                this.pool.getCurrentUser().refreshSession(refreshToken, (err, result) => {
                    if (err) {
                        reject(err);
                    }

                    // TODO: Use access token instead
                    resolve(result.idToken.jwtToken);
                });
            } catch (err) {
                reject(err);
            }
        });
    }
}
