import React from 'react';

import { useStyles } from './style.js';

const CustomLink = ({children, href, className, onClick}) => {
    const classes = useStyles();

    return (
        <a className={`${classes.customLink} ${className}`} href={href} onClick={onClick}>
            {children}
        </a>
    )
};

export default CustomLink;
