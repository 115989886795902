import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    tablePrivacy : {
        width: '100%',
        border: '1px solid black',
        borderCollapse: 'collapse',
        '& th, td' : {
            border: '1px solid black',
        },
        '& ul' : {
            listStyle: 'inside'
        }
    },
    reasonsTable : {
        marginTop: '20px'
    },
    factsTh : {
        textTransform: 'uppercase',
        '&:first-child' : {
            padding: '10px 5px',
            backgroundColor: theme.palette.bgColor.black,
            color: theme.palette.text.white
        }
    },
    factsTd : {
        '&:first-child' : {
            padding: '10px 30px 0 10px',
            backgroundColor: theme.palette.bgColor.darkGrey,
        },
        '&:nth-child(2)' : {
            padding: '5px',
            fontFamily: theme.typography.fontFamily.medium
        },
        '& ul' : {
            listStyle: 'inside'
        }
    },
    reasonsTh : {
        backgroundColor: theme.palette.bgColor.darkGrey,
    },
    reasonsTd : {
        fontFamily: theme.typography.fontFamily.medium,
        padding: '5px',
    },
    greyBgTd : {
        backgroundColor: theme.palette.bgColor.darkGrey,
        fontFamily: theme.typography.fontFamily.black
    },
    boldTd : {
        fontFamily: theme.typography.fontFamily.black
    }
}))