import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    admin: {},
    adminContainer: {
        padding: '0 20px',
    },
    adminTitle: {
        fontWeight: theme.fontWeight.text.bold,
        fontSize: theme.fontSize.text.semiMain,
        marginBottom: 0,
    },
    adminTabsHeaders: {
        display: 'flex',
        textTransform: 'uppercase',
        marginBottom: '110px',
        fontSize: theme.fontSize.text.small,
    },
    adminTabsHeader: {
        marginRight: '83px',
        marginTop: '71px',
        cursor: 'pointer',
    },
    adminTabsActive: {
        borderBottom: '2px solid black',
    },
    adminTabActive: {
        display: 'block',
    },
    adminTabInActive: {
        display: 'none',
    },
    adminTabWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    adminTabPricing: {
        marginRight: '33px',
        fontSize: theme.fontSize.text.small,
    },

    //// SWITCH BTN START
    adminSwitchBtn: {
        position: 'relative',
        marginRight: '13px',
    },
    adminSwitchInput: {
        position: 'absolute',
        display: 'none',
        // opacity: 0,
        // left: '-9999px',
        // top: '-9999px',
        '&:checked + span': {
            '&:before': {
                left: '39px',
                backgroundColor: '#49AE2F',
            },
        },
    },
    adminSwitchSpan: {
        display: 'flex',
        cursor: 'pointer',
        width: '72px',
        height: '24px',
        border: '2px solid black',
        position: 'relative',
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '29px',
            height: '16px',
            top: '4px',
            left: '4px',
            transition: '0.2s',
            backgroundColor: '#FF4E4A',
        },
    },
    //// SWITCH BTN END

    adminTabBtn: {
        display: 'flex',
        // marginLeft: '13px',
        fontSize: theme.fontSize.text.small,
    },
    adminTabBtnTitle: {
        cursor: 'pointer',
        '&:nth-child(2)': {
            marginLeft: '33px',
            color: theme.palette.text.orange,
            borderBottom: '2px solid #FF4E4A',
        },
    },
    adminTabLine: {
        width: '100%',
        height: '4px',
        display: 'block',
        marginTop: '22px',
        border: 'none',
        backgroundColor: theme.palette.bgColor.black,
    },
    adminTabTotalPrice: {
        marginTop: '74px',
        fontWeight: theme.fontWeight.text.bold,
        fontSize: theme.fontSize.text.main,
    },
    adminTable: {
        width: '100%',
        textAlign: 'left',
    },
    adminTableTr: {
        borderBottom: '4px solid black',
    },
    adminTableTrTitle: {
        fontWeight: theme.fontWeight.text.bold,
        fontSize: theme.fontSize.text.small,
        textTransform: 'uppercase',
        '&:nth-child(1)': {
            paddingLeft: 0,
        },
    },
    adminTableTrChild: {
        borderBottom: '1px solid #979797',
    },
    adminTableTd: {
        fontSize: theme.fontSize.text.semiHeader,
        fontWeight: theme.fontWeight.text.normal,
        '&:nth-child(1)': {
            paddingLeft: 0,
        },
        '&:nth-child(5)': {
            paddingRight: 0,
            paddingLeft: 0,
            display: 'flex',
            justifyContent: 'space-around',
        },
    },
    adminTableTrChildBtn: {
        width: '94px',
        fontSize: theme.fontSize.text.small,
        textTransform: 'uppercase',
        padding: '15px 10px',
        border: '1px solid black',
        textAlign: 'center',
    },
    adminTabTitleWrapper: {
        display: 'flex',
    },
    adminTabTitleAdd: {
        marginLeft: '20px',
        color: theme.palette.text.orange,
        borderBottom: '2px solid #FF4E4A',
        cursor: 'pointer',
    },
    adminTabAdminsList: {
        display: 'flex',
        alignItems: 'center',
    },
    adminTabAdminsEmail: {
        marginRight: '11px',
        fontSize: theme.fontSize.text.semiHeader,
    },
    adminTabAdminsBtn: {
        marginRight: '20px',
        fontSize: theme.fontSize.text.small,
        color: theme.palette.text.grey,
        cursor: 'pointer',
    },
    '@media (max-width: 960px)': {
        adminTableTd: {
            fontSize: '12px',
        },
        adminTableTrChildBtn: {
            width: '50px',
            fontSize: '8px',
            padding: '10px 5px',
        },
    },
    '@media (max-width: 540px)': {
        adminTabsHeaders: {
            marginBottom: '60px',
        },
        adminTabPricing: {
            marginRight: '20px',
        },
        adminTitle: {
            fontSize: '35px',
            marginTop: 0,
        },
        adminTableTd: {
            padding: '10px 10px',
        },
        adminTableTrTitle: {
            padding: '10px',
        },
        adminTabAdminsEmail: {
            fontSize: '16px',
        },
    },
    '@media (max-width: 380px)': {
        admin: {
            padding: '120px 0',
        },
        adminTabsHeader: {
            marginRight: '35px',
        },
        adminTabTotalPrice: {
            fontSize: '35px',
            marginTop: '30px',
        },
        adminTableTd: {
            padding: '5px',
            fontSize: '8px',
        },
        adminTableTrChildBtn: {
            width: '25px',
            fontSize: '5px',
            padding: '5px 3px',
            '&:nth-child(2)': {
                marginLeft: '5px',
            },
        },
        adminTableTrTitle: {
            fontSize: '8px',
        },
        adminTabAdminsEmail: {
            fontSize: '10px',
        },
        adminTabAdminsBtn: {
            fontSize: '8px',
        },
    },
    noRecordsLabel: {
        width: '100%',
        minHeight: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    eventsFilterBtn: {
        marginLeft: '12px'
    }
}));
