import React from 'react';
import { useStyles } from '../../components/PrivacyTables/style.js';

const WeDoTable = () => {
    const classes = useStyles();

    return (
        <>
            <table className={`${classes.tablePrivacy} ${classes.reasonsTable}`} frame='none' cellSpacing="0">
                <tbody>
                <tr>
                    <td className={`${classes.reasonsTd} ${classes.greyBgTd}`} colSpan="2">What we do</td>
                </tr>
                <tr>
                    <td className={`${classes.reasonsTd} ${classes.boldTd}`}>How does HausCoin protect my personal information?</td>
                    <td className={classes.reasonsTd}>
                        To protect your personal information from unauthorized access and use, we use security measures that comply with
                        applicable law. These measures include computer safeguards and secured ﬁles and buildings.
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.reasonsTd} ${classes.boldTd}`}>How does HausCoin collect my personal information?</td>
                    <td className={classes.reasonsTd}>
                        We collect your personal information, for example, when you
                        <ul>
                            <li>
                                Open an account or provide account information
                            </li>
                            <li>
                                Give us your contact information
                            </li>
                        </ul>
                        We also collect your personal information from other companies.
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.reasonsTd} ${classes.boldTd}`}>Why can't I limit all sharing?</td>
                    <td className={classes.reasonsTd}>
                        Federal law gives you the right to limit only
                        <ul>
                            <li>
                                sharing for affiliates’ everyday business purposes
                            </li>
                            <li>
                                information about your creditworthiness
                            </li>
                            <li>
                                afﬁliates from using your information to market to you
                            </li>
                            <li>
                                sharing for nonafﬁliates to market to you
                            </li>
                        </ul>
                        State laws and individual companies may give you additional rights to limit sharing. See below
                        for more on your rights under state law.
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.reasonsTd} ${classes.greyBgTd}`} colSpan="2">Definitions</td>
                </tr>
                <tr>
                    <td className={`${classes.reasonsTd} ${classes.boldTd}`}>Affiliates</td>
                    <td className={classes.reasonsTd}>
                        Companies related by common ownership or control. They can be ﬁnancial and nonﬁnancial companies.
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.reasonsTd} ${classes.boldTd}`}>Nonaffiliates</td>
                    <td className={classes.reasonsTd}>
                        Companies not related by common ownership or control. They can be ﬁnancial and nonﬁnancial companies.
                        <ul>
                            <li>
                                HausCoin does not share with nonaffiliates so they can market to you.
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td className={`${classes.reasonsTd} ${classes.boldTd}`}>Joint marketing</td>
                    <td className={classes.reasonsTd}>
                        A formal agreement between nonafﬁliated ﬁnancial companies that together market ﬁnancial products or services to you.
                    </td>
                </tr>
                </tbody>
            </table>
            <table className={`${classes.tablePrivacy} ${classes.reasonsTable}`} frame='none' cellSpacing="0">
                <tbody>
                    <tr>
                        <td className={`${classes.reasonsTd} ${classes.greyBgTd}`} colSpan="2">Other important information</td>
                    </tr>
                    <tr>
                        <td className={classes.reasonsTd} colSpan="2">
                            California Residents Only: We will not share information we collect about you with nonaffiliates, except as permitted
                            by law, including, for example, with your consent or to provide financial services you have requested.
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
};

export default WeDoTable;