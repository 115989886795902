import React, { memo, useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ContentContainer from "../../../marketplace/components/ContentContainer/ContentContainer";
// import ContentContainer from '@material-ui/core/ContentContainer';
import BurgerMenu from 'marketplace/components/BurgerMenu/BurgerMenu';
import { ROUTES } from 'marketplace/constans';
import logoWhite from 'assets/img/logoWhite.svg';
import logoBlack from 'assets/img/logoBlack.svg';
import logoBlue from 'assets/img/logoBlue.svg';
import userWhite from 'assets/img/userWhite.svg';
import userBlack from 'assets/img/userBlack.svg';
import userBlue from 'assets/img/userBlue.svg';
import { useStyles } from './style.js';
import clsx from 'clsx';


const NavBar = ({ isAuth, userName, withWhiteBg, withProfileInfo, isAdmin, ...props }) => {
    const classes = useStyles();
    const [openProfilePopup, setOpenProfilePopup] = useState(false);
    const closeDropDown = useRef();

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown);
        return () => {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    }, []);

    const handleMouseDown = (e) => {
        if (closeDropDown.current && !closeDropDown.current.contains(e.target)) {
            setOpenProfilePopup(false);
        }
    };

    const signOutHandler = () => {
        setOpenProfilePopup(false);
        props.onSignOut();
    };

    const signInHandler = () => {
        props.onSignIn();
    };

    const openProfileHandler = (e) => {
        setOpenProfilePopup(!openProfilePopup);
    };

    return (
        <div className={clsx(classes.wrapper, {
          [classes.wrapperGrey]: withWhiteBg,
        })}>
            <ContentContainer>
                <nav className={classes.nav}>
                    <div className={classes.navLogoWrapper}>
                        <BurgerMenu />
                        <NavLink className={classes.navLogoLink} to={ROUTES.HOME}>
                            <img
                                className={classes.navLogo}
                                src={logoBlue}
                                alt="HausCoin"
                            />
                            <div className={classes.navLogoText}>HausCoin</div>
                        </NavLink>
                    </div>
                    <ul className={classes.navMenu}>{props.children}</ul>
                    <ul className={classes.navUser} onClick={isAuth ? openProfileHandler : signInHandler}>
                        <li className={classes.navUserAccount}>
                            {!isAuth && !isAdmin &&
                            <a
                                className={classes.navUserAccountItem}
                                href="#sign-up"
                            >
                                Create account
                            </a>}
                        </li>
                        <li className={classes.navUserAccount}>
                            {!isAuth ? (
                                <a
                                    className={classes.navUserAccountItem}
                                    href="#login"
                                >
                                    Login
                                </a>
                            ) : (
                                <span className={classes.navUserAccountItem}>
                                    {userName ? 'Hi' : ''} {userName}
                                </span>
                            )}
                        </li>
                        <li className={classes.navUserAccount}>
                            {isAuth ? (
                                <img className={classes.navUserAccountLogo}
                                     src={userBlack}
                                     alt="avatar" />
                            ) : (
                                <img
                                    className={classes.navUserAccountLogo}
                                    src={userBlack}
                                    alt="avatar"
                                />
                            )}
                        </li>
                        <div
                            className={clsx(classes.navProfilePopup, {
                                [classes.navProfileActive]: openProfilePopup,
                            })}
                            ref={closeDropDown}
                        >
                            <div className={classes.navProfilePopupContent}>
                                {withProfileInfo && (
                                    <>
                                        <span className={classes.navProfilePopupLine} />
                                        <NavLink to={ROUTES.SETTINGS} className={classes.navProfilePopupLink}>
                                            Profile info
                                        </NavLink>
                                    </>
                                )}
                                <span className={classes.navProfilePopupLine} />
                                <a className={classes.navProfilePopupLink} onClick={signOutHandler}>
                                    Sign out
                                </a>
                                <span className={classes.navProfilePopupLine} />
                            </div>
                        </div>
                    </ul>
                </nav>
            </ContentContainer>
        </div>
    );
};

export default memo(NavBar);
