import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  modalsInputField: {
    marginBottom: '40px',
    '&::after': {
      content: '""',
      display: 'block',
      height: '12px',
      borderRight: '4px solid black',
      borderLeft: '4px solid black',
      borderBottom: '4px solid black',
      marginTop: '-12px',
    }
  },
  modalsInputLabel: {
    display: 'flex',
    letterSpacing: '1.2px',
    flexDirection: 'column',
    textTransform: 'uppercase',
    color: theme.palette.text.main,
    fontSize: theme.fontSize.text.small
  },
  modalsInput : {
    backgroundColor: 'transparent',
    border: 'none',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    outline: 'none',
    fontFamily: theme.typography.fontFamily.black,
    lineHeight: '24px',
    fontSize: theme.fontSize.text.total,
    padding: '0 15px',
    marginTop: '14px',
    color: theme.palette.text.black,
    '&::placeholder': {
      color: '#000000'
    }
  },

  '@media (max-width: 1280px)': {
    modalsInputField: {
      '&::after': {
        marginTop: '-12px',
      }
    }
  },
  '@media (max-width: 480px)': {
    modalsInputField: {
      marginBottom: '30px'
    }
  },
}))
