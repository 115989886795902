import React from 'react';
import {useStyles} from "./style";

const ImgCard = ({cardClassName, inLineStyle, cardTitle, cardText, cardBtnText, cardBtnLink}) => {
  const classes = useStyles();
  return (
    <div className={`${classes.imgCard} ${cardClassName}`}>
      <div className={classes.imgCardTag}>
        New
      </div>
      <div style={inLineStyle} className={classes.imgCardBg}/>
      <div className={classes.imgCardInfo}>
        <div className={classes.imgCardInfoTitle}>{cardTitle}</div>
        <div className={classes.imgCardInfoText}>{cardText}</div>
        <span className={classes.imgCardInfoLine}/>
        <a target="_blank" href={cardBtnLink} className={classes.imgCardInfoBtn}>{cardBtnText}</a>
      </div>
    </div>
  );
};

export default ImgCard;
