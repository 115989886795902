import React from 'react';
import {ROUTES} from "../../constans";
import {NavLink} from "react-router-dom";
import {useStyles} from "./style";

const LedgerButton = () => {
  const classes = useStyles();

  return (
      <NavLink to={ROUTES.LEDGER} className={classes.dashboardGraphBtnLedger}>
        View ledger
      </NavLink>
  );
};

export default LedgerButton;
