import React, {useEffect, useState} from 'react';

import {useStyles} from './style.js';

const CustomerOwnBar = ({percent}) => {
  const classes = useStyles();
  const [style, setStyle] = useState({});

  useEffect(() => {
    const newStyle = {
      height: `${percent}%`
    }
    setStyle(newStyle)
  }, [percent])


  return (
    <div className={classes.customerProgress}>
      <div className={classes.progressDone} style={style}>
        <p className={classes.customerProgressText}>
          Customer currently owns
        </p>
        <p className={classes.customerProgressSpan}>{percent}%</p>
      </div>
    </div>
  )
}

export default CustomerOwnBar;
