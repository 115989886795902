import React from "react";
import {useStyles} from './style.js';
import {useRouteMatch} from "react-router-dom";
import {ROUTES} from "../../constans";

const ChartTooltip = ({
    title,
    price,
    circulation,
    tooltipRef,
    styleOptions,
    toolTipClass,
    arrowPosition,
}) => {
  const classes = useStyles();
  const whiteArrowBox = useRouteMatch(ROUTES.DASHBOARD);
  const priceColor = styleOptions ? styleOptions.priceColor : undefined;
  let arrowClass = classes.centerArrowBox;
  if (arrowPosition === 'left') {
    arrowClass = classes.leftArrowBox;
  }
  if (arrowPosition === 'right') {
    arrowClass = classes.rightArrowBox;
  }
  return (
    <div className={`${classes.chartToolTip} ${toolTipClass}`} ref={tooltipRef} style={{ display: 'none' }}>
      <div className={classes.toolTipTitle}>{title}</div>
      {price && <div className={classes.toolTipBody} style={{ color: priceColor }}>
        <div
          className={`${classes.toolTipLegend} ${classes.priceLegend}`}
          style={{ backgroundColor: priceColor }}
        />
        {price}
      </div>}
      {circulation && <div className={`${classes.toolTipBody} ${classes.circulationLegend}`}>
        <div className={`${classes.toolTipLegend} ${classes.circulationLegend}`} />
        {parseInt(circulation).toLocaleString()}
      </div>}
      <div className={`${whiteArrowBox ? classes.arrowBoxWhite : classes.arrowBox} ${arrowClass}`} />
    </div>
  )
}

export default ChartTooltip;
