import React from 'react';
import Skeleton from "@material-ui/lab/Skeleton";
import {useStyles} from "./style";

const SkeletonCard = ({classNameSkeletonCard}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.skeletonCard} ${classNameSkeletonCard}`}>
      <div className={classes.skeletonCardTitle}>
        <Skeleton variant="rect" animation="wave" width={"100%"} height={"100%"} />
      </div>
      <div className={classes.skeletonCardValuesWrapper}>
        <Skeleton variant="rect" animation="wave" width={"50%"} height={"100%"} />
        <Skeleton variant="rect" animation="wave" width={"40%"} height={"50%"} />
      </div>
      <div className={classes.skeletonCardDescription}>
        <Skeleton variant="rect" animation="wave" width={"100%"} height={"100%"} />
      </div>
      <div className={classes.skeletonCardGraphWrapper}>
        <Skeleton variant="rect" animation="wave" height={"100%"} />
      </div>
      <div className={classes.skeletonCardGraphDescription}>
        <div className={classes.skeletonCardGraphDescriptionText}>
          <Skeleton variant="rect" animation="wave" width={69} height={"100%"} />
        </div>
        <div className={classes.skeletonCardGraphDescriptionText}>
          <Skeleton variant="rect" animation="wave" width={69} height={"100%"} />
        </div>
        <div className={classes.skeletonCardGraphDescriptionText}>
          <Skeleton variant="rect" animation="wave" width={69} height={"100%"} />
        </div>
      </div>
    </div>
  );
};

export default SkeletonCard;
