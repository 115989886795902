import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    adminTableTrChild: {
        borderBottom: '1px solid #979797',
    },
    adminTableTd: {
        fontSize: theme.fontSize.text.semiHeader,
        fontWeight: theme.fontWeight.text.normal,
        '&:nth-child(1)': {
            paddingLeft: 0,
        },
        '&:nth-child(7)': {
            paddingRight: 0,
            paddingLeft: 0,
            display: 'flex',
            justifyContent: 'space-around',
        },
    },
    adminTableTrChildBtn: {
        width: '102px',
        fontSize: theme.fontSize.text.small,
        textTransform: 'uppercase',
        padding: '15px 10px',
        border: '1px solid black',
        textAlign: 'center',
        backgroundColor: 'white',
    },
    statusMessage: {
        fontSize: '16px',
        color: '#777',
        lineHeight: '49px',
    }
}));
