import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  tokens: {
    paddingTop: '173px',
  },
  // tokensContainer: {
  //
  // },
  tokensCardBlock: {
    marginTop: '60px',
  },
  tokensTitle: {
    maxWidth: '646px',
    textAlign: 'center',
    margin: '40px auto 0',
  },
  tokensText: {
    maxWidth: '526px',
    margin: '30px auto 0',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: theme.fontSize.text.semiTotal,
    lineHeight: '22px',
    letterSpacing: '-0.2475px',
  },
  tokensOptionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '85px',
    alignItems: 'center',
  },
  tokensTabsWrapper: {
    display: 'flex',
    fontSize: theme.fontSize.text.small,
    textTransform: 'uppercase',
  },
  tokensTabs: {
    cursor: 'pointer',
    '&:nth-child(1)': {
      marginRight: '30px',
    }
  },
  activeTab: {
    borderBottom: '2px solid black',
  },
  tokensCardBg: {
    marginTop: '130px',
    paddingTop: '132px',
    paddingBottom: '130px',
    backgroundColor: theme.palette.bgColor.grey,
  },
  tokensCardsTitle: {
    textAlign: 'center',
  },
  tokensCardTable: {
    display: 'flex',
    flexFlow: 'row wrap',
    gap: '30px',
    margin: '60px auto 0',
    justifyContent: 'center',
  },
  listBlock: {
    marginTop: '64px',
  },
  '@media (max-width: 480px)': {
    tokens: {
      paddingTop: '112px',
    },
    tokensTitle: {
      margin: '13px auto 0',
    },
    tokensText: {
      margin: '37px auto 0',
    },
    tokensCardBg: {
      marginTop: '60px',
      paddingTop: '30px',
    },
    tokensCardTable: {
      margin: '40px auto 0',
    },
  },
}))
