import React, {memo, useEffect, useState} from 'react';
import {useAppState} from '../../app-state';
import Container from '@material-ui/core/Container';
import Tooltip from '@material-ui/core/Tooltip';
import DashboardChart from '../../components/Сharts/CardGraph';
import arrowUp from '../../../assets/img/arrowUp.svg';
import activity from '../../../assets/img/activity.svg';
import useApi from '../../hooks/useApi';
import {isValidated} from '../../util/helpers';
import {NavLink, useHistory} from 'react-router-dom';
import {ROUTES} from '../../constans';
import {format} from 'date-fns';
import SkeletonRows from '../../components/Skeleton/SkeletonRows';
import Skeleton from '@material-ui/lab/Skeleton';
import DataItem from '../../../common/components/DataItem';
import {roundAndFormatNumber, roundFourAndFormatNumber} from '../../../common/util';
import ViewMoreButton from '../../components/ViewMoreButton/ViewMoreButton';
import CoinButtons from "../../components/CoinButtons/CoinButtons";
import {useStyles} from './style.js';
import ToolTipBtn from "../../components/TooltipBtn/ToolTipBtn";
import selectArrow from "../../../assets/img/select-arrow.svg";
import user from "../../../assets/img/avatar.svg";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import LedgerButton from "../../components/LedgerButton/LedgerButton";
import CONFIG_COIN from "../../CoinConfig/CoinConfig"
import Decimal from 'decimal.js';


const ACTIVITY_TYPES = {
  BUY: 'BUY',
  SELL: 'SELL',
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
};

const DashboardPage = () => {
  const classes = useStyles();
  const {state, actions} = useAppState();
  const [yourActivityItems, setYourActivityItems] = useState([]);
  const [investorData, setInvestorData] = useState({});
  const [graph, setGraph] = useState();
  const [investorActivity, setInvestorActivity] = useState();
  const [holdingsChange, setHoldingsChange] = useState([]);
  const [activityPage, setActivityPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [dates, setDates] = useState([]);
  const [price, setPrice] = useState([]);
  const [circulation, setCirculation] = useState([]);
  const [currentPrice, setCurrentPrice] = useState();
  const [coin, setCoin] = useState();
  const api = useApi();
  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    if (!state.currentUser) {
      return;
    }
    const selectedCoin = CONFIG_COIN.find(coin => coin.id === state.coinType);
    if (selectedCoin) {
        setCoin(selectedCoin);
        api.getInvestorDashboard(selectedCoin.coinType).then((data) => {
            setInvestorData(data);
            setIsLoading(false);
        });
        api.getGraph(selectedCoin.coinType).then((data) => {
            setGraph(data);
            setDates(data.price.map(p => p.date));
            setPrice(data.price.map(p => parseFloat(p.value)));
            setCirculation(data.circulation.map(c => parseFloat(c.value)));
            setIsLoading(false);
            setCurrentPrice(Number(data.price[data.price.length - 1].value));
        });
    }
  }, [state.coinType]);

  useEffect(() => {
    setIsLoadingMore(true);
    // NOTE: Delete function's status of transaction if you want to get all transactions
    api.getInvestorActivity(activityPage).then((data) => {
      setInvestorActivity(data);
      setIsLoadingMore(false);
      if (yourActivityItems?.length) {
        setYourActivityItems(yourActivityItems.concat(data.records));
      } else {
        setYourActivityItems(data.records);
      }
    });
  }, [activityPage]);

  useEffect(() => {
    if (!(yourActivityItems?.length && investorData.holdings && investorData.tokens)) return;
    setHoldingsChange(getHoldingsChange(yourActivityItems[0], investorData));
  }, [yourActivityItems, investorData]);

  const getHoldingsChange = (lastTransaction, investorData) => {
    // const prevCoinPrice = lastTransaction.payload.coinsInMoney / lastTransaction.payload.coinsAmount;
    // const priceChange = investorData.hausCoinEquityValue - prevCoinPrice;
    // const holdingsChange = investorData.hausCoinOwned * priceChange;
    return [investorData.holdingsChange, Decimal.abs(investorData.holdingsChange).toFixed(2)];
  };

  const showYourActivity = () => {
    setActivityPage(activityPage + 1);
  };

  const renderActivityItem = (item) => {
    const date = format(new Date(item.createdAt), 'LLLL d');
    const year = format(new Date(item.createdAt), 'yyyy');
    // Temporary hide all transactions which are not confirmed
    // TODO: Implement render function for CANCELED and PENDING transaction type
    // if (item.status !== 'APPROVED') return null;
    const coin = CONFIG_COIN.find(c => c.coinType === item.payload.coinType);

    if (item.status === 'PENDING') return (
      <>
        <div className={classes.dashboardActivityColumn}>
          {date}
          <span className={classes.dashboardActivityColSpan}>{year}</span>
        </div>

        <div className={classes.dashboardActivityColumnLogo}>
          <div className={classes.dashboardActivityColumnLogoWrapper}>
            <img className={classes.dashboardActivityColumnLogoImg} src={coin?.logo} alt={coin?.id}/>
          </div>
          {coin?.id}
        </div>

        <div className={classes.dashboardActivityColumnTokenPurchase}>
          <em>Pending</em>
        </div>

        <div className={classes.dashboardActivityColTrans}>
          <div className={classes.dashboardActivityColPrice}>
            {roundAndFormatNumber(item.payload.coinsAmount)}
          </div>
          <div className={classes.dashboardActivityColLast}>
            <NavLink className={classes.dashboardActivityTableLink} to={ROUTES.INSTRUCTIONS}>View Wire Instructions</NavLink>
          </div>
        </div>
      </>
    );

    switch (item.type) {
      case ACTIVITY_TYPES.ACCOUNT_CREATED:
        return (
          <>
            <div className={classes.dashboardActivityColumn}>
              {date}
              <span className={classes.dashboardActivityColSpan}>{year}</span>
            </div>

            <div className={classes.dashboardActivityColumnLogo}>
              <img className={classes.dashboardActivityColumnAccount} src={user} alt={"avatar"}/>
              <div style={{visibility: 'hidden'}}>SF</div>
            </div>

            <div className={classes.dashboardActivityColumnTokenPurchase}>
              Account creation
              <span className={classes.dashboardActivityColSpan}>{}</span>
            </div>

            <div className={classes.dashboardActivityColTrans}>
              <div className={classes.dashboardActivityColPrice}>
              </div>
              <div className={classes.dashboardActivityColLast}>
              </div>
            </div>
          </>
        );
      case ACTIVITY_TYPES.BUY:
        return (
          <>
            <div className={classes.dashboardActivityColumn}>
              {date}
              <span className={classes.dashboardActivityColSpan}>{year}</span>
            </div>

            <div className={classes.dashboardActivityColumnLogo}>
              <div className={classes.dashboardActivityColumnLogoWrapper}>
                <img className={classes.dashboardActivityColumnLogoImg} src={coin?.logo} alt={coin?.id}/>
              </div>
              {coin?.id}
            </div>

            <div className={classes.dashboardActivityColumnTokenPurchase}>
              Investment
              <span className={classes.dashboardActivityColSpan}>Token purchase</span>
            </div>

            <div className={classes.dashboardActivityColTrans}>
              <div className={classes.dashboardActivityColPrice}>
                {'$' + roundFourAndFormatNumber(item.payload.coinPrice)}
              </div>
              <div className={classes.dashboardActivityColLast}>
                {roundAndFormatNumber(item.payload.coinsAmount)}
              </div>
            </div>
          </>
        );
      case ACTIVITY_TYPES.SELL:
        return (
          <>
            <div className={classes.dashboardActivityColumn}>
              {date}
              <span className={classes.dashboardActivityColSpan}>{year}</span>
            </div>
            <div className={classes.dashboardActivityColumnLogo}>
              <div className={classes.dashboardActivityColumnLogoWrapper}>
                <img className={classes.dashboardActivityColumnLogoImg} src={coin?.logo} alt={coin?.id}/>
              </div>
              {coin?.id}
            </div>
            <div className={classes.dashboardActivityColumnTokenPurchase}>
              Sale
              <span className={classes.dashboardActivityColSpan}>{}</span>
            </div>

            <div className={classes.dashboardActivityColTrans}>
              <div className={classes.dashboardActivityColPrice} style={{visibility: 'hidden'}}>
                {'$' + roundFourAndFormatNumber(item.payload.coinPrice)}
              </div>
              <div className={classes.dashboardActivityColLast} style={{visibility: 'hidden'}}>
                {roundAndFormatNumber(item.payload.coinsAmount)}
              </div>
            </div>
          </>
        );
    }
  };

  function TooltipText(props) {
    return (
      <React.Fragment>
        To purchase HausCoin, validate your accreditation <a href={'/account'} className={classes.tooltipLink}>here</a>.
      </React.Fragment>
    );
  }

  return (
    <>
      <section className={classes.dashboard}>
        <Container fixed className={classes.dashboardContainer}>
          <CoinButtons classNameCoinBtn={classes.dashboardCoinBtn} />
          <div className={classes.dashboardWrapper}>
            <div className={classes.dashboardHeaderData}>
              <div className={classes.dashboardHeaderDataBlockWrapper}>
                <div className={classes.dashboardHeaderDataBlock}>
                  <div className={classes.dashboardHeaderDataTitle}>
                    {coin?.title} TOKEN VALUE
                  </div>
                  <div className={classes.dashboardHeaderDataText}>
                    <DataItem value={currentPrice} isLoading={isLoading} roundFour={true}
                              startCharacter={'$'}/>
                  </div>
                </div>
                <div className={classes.dashboardHeaderDataBlockMain}>
                  <div className={classes.dashboardHeaderDataTitle}>
                    <span style={!holdingsChange[0] ? {paddingRight: '5px'} : null}>CURRENT HOLDINGS</span>
                    <span className={classes.dashboardHeaderDataMainTitleSpan}>
                                        {holdingsChange[0] ? <img
                                          className={classes.dashboardHeaderDataMainImg + ' ' + (holdingsChange[0] < 0 ? classes.arrowDown : '')}
                                          src={arrowUp} alt="UP"/> : null}
                      <DataItem value={holdingsChange[1]} isLoading={isLoading} startCharacter={'$'}/>
                    </span>
                  </div>
                  <div className={classes.dashboardHeaderDataText}>
                    <DataItem value={investorData?.holdings} isLoading={isLoading} 
                              startCharacter={'$'}/>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.dashboardGraph}>
              {isLoading && <Skeleton variant="rect" height={"100%"} animation="wave"/>}
              {!isLoading && <DashboardChart
                  price={price}
                  circulation={circulation}
                  dates={dates}
                  showToolTip
              />}
              <div className={classes.dashboardGraphBottomTitles}>
                <div className={classes.dashboardGraphBottomTitle}>
                  <span className={classes.dashboardGraphBottomLine}></span>
                  Equity price
                </div>
                <div className={classes.dashboardGraphBottomTitle}>
                  <span className={classes.dashboardGraphBottomLine}></span>
                  Hauscoin issued
                </div>
              </div>
            </div>
          </div>

          <div className={classes.buttonWrapper}>
            <div className={classes.dashboardGraphBtnWrapper}>
              {
                !isValidated(state.currentUser) ?
                  <ToolTipBtn
                    className={classes.dashboardTooltip}
                    title={<TooltipText/>}
                    placement="top"
                  >
                    <a
                      className={classes.dashboardGraphBtn}
                      onClick={() => {
                        if (isValidated(state.currentUser)) {
                          actions.updateState({buySellActive: true});
                        } else {
                          history.push(ROUTES.SETTINGS);
                        }
                      }}
                    >
                      BUY
                    </a>
                  </ToolTipBtn>
                  :
                  <a
                    className={classes.dashboardGraphBtn}
                    onClick={() => {
                      if (isValidated(state.currentUser)) {
                        actions.updateState({buySellActive: true});
                      } else {
                        history.push(ROUTES.SETTINGS);
                      }
                    }}
                  >
                    BUY
                  </a>
              }
              <LedgerButton />
            </div>
          </div>
        </Container>
      </section>
      <section className={classes.dashboardCustomerWrapper}>
        <Container fixed className={classes.dashboardContainer}>
          <div className={classes.dashboardCustomerDataBlock}>
            <div className={classes.dashboardCustomerDataInfo}>
              <h6 className={classes.dashboardDataBlockItemsTitle}>Average annual appreciation</h6>
              <div className={classes.dashboardCustomerDataInfoText}>
                <DataItem value={graph?.avgAppreciation.toFixed(2)} endCharacter={'%'}
                  isLoading={isLoading} roundNum={true}/>
              </div>
            </div>
            <div className={classes.dashboardCustomerDataInfo}>
              <h6 className={classes.dashboardDataBlockItemsTitle}>Average customer ownership</h6>
              <div className={classes.dashboardCustomerDataInfoText}>
                <DataItem value={graph?.avgOwnership.toFixed(2)} endCharacter={'%'}/>
              </div>
            </div>
            <div className={classes.dashboardCustomerDataInfo}>
              <h6 className={classes.dashboardDataBlockItemsTitle}>Average home value</h6>
              <div className={classes.dashboardCustomerDataInfoText}>
                <DataItem value={Number(parseInt(graph?.avgValue)).toLocaleString()} isLoading={isLoading} startCharacter={'$'} />
              </div>
            </div>
          </div>
          {/*<div>*/}
          {/*    <h6 className={classes.dashboardDataBlockItemsTitle}>*/}
          {/*        AVERAGE PURCHASE PRICE*/}
          {/*        <span className={classes.dashboardDataBlockItemsSpan}>*/}
          {/*            <DataItem value={investorData?.averagePurchasePrice} isLoading={isLoading} startCharacter={'$'} />*/}
          {/*        </span>*/}
          {/*    </h6>*/}
          {/*</div>*/}
        </Container>
      </section>
      <section className={classes.dashboardActivity}>
        <ContentContainer className={classes.dashboardContainer}>
          <div className={classes.dashboardActivityWrapper}>
            <div className={classes.dashboardCustomerActivity}>
              <img className={classes.dashboardCustomerActivityImg} src={activity} alt="activity"/>
              <div className={classes.dashboardActivityHeaderWrapper}>
                <h3 className={classes.dashboardCustomerActivityTitle}>Recent activity</h3>
                <div className={classes.dashboardActivitySelectWrapper}>
                  <div className={classes.dashboardActivitySelect}>
                    Most recent
                    <img className={classes.dashboardActivitySelectImg} src={selectArrow} alt={"Arrow"} />
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.dashboardActivityTableMob}>
                {isLoading && <SkeletonRows/>}
                {!isLoading &&
                yourActivityItems?.map((item) => (
                  <div className={classes.dashboardActivityRowWrapper} key={item.id}>
                    {renderActivityItem(item)}
                  </div>
                ))}
              <ViewMoreButton
                variant={"dashboard"}
                hasMore={investorActivity?.hasMore}
                isLoading={isLoadingMore}
                onClick={showYourActivity}
                label={'VIEW MORE'}
              />
            </div>
          </div>
        </ContentContainer>
      </section>
    </>
  );
};
export default memo(DashboardPage);
