import React, { useEffect, useState } from 'react';
import useApi from '../../hooks/useApi';
import { useAppState } from '../../app-state';
import PropertyGraph from '../../components/Сharts/PropertyGraph';
import { useStyles } from './style.js';
import Container from '@material-ui/core/Container';
import activity from '../../../assets/img/activity.svg';
import DataItem from '../../../common/components/DataItem';
import Skeleton from '@material-ui/lab/Skeleton';
import DataBlock from '../../components/DataBlock/DataBlock';
import RenderTag from '../../components/RenderTag/RenderTag';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '../../constans';
import ViewMoreButton from '../../components/ViewMoreButton/ViewMoreButton';
import { formatNum, fullRoundAndFormatNum, roundAndFormatNumber } from '../../../common/util';
import { format } from 'date-fns';
import SkeletonRows from '../../components/Skeleton/SkeletonRows';
import { ADDRESS_STATUS } from '../../../common/constants';
import { FEATURE_FLAGS } from '../../../common/config';

const ACTIVITY_TYPES = {
  BUY_IN: 'BUY_IN',
  MONTHLY_PAYMENT: 'MONTHLY_PAYMENT',
  CASH_OUT: 'CASH_OUT',
  COVENANT_FEE: 'COVENANT_FEE',
};

const PropertyPage = () => {
  const classes = useStyles();
  const { state, actions } = useAppState();
  const [activityItems, setActivityItems] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [activitiesItems, setActivitiesItems] = useState();
  const [propertyData, setPropertyData] = useState();
  const [activityItemsPage, setActivityItemsPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const api = useApi();

  const params = useParams();
  const history = useHistory();

  useEffect(() => {
    if (!state.currentUser || propertyData) {
      return;
    }
    setIsLoading(true);
    api
      .getLedgerRecord(params.id)
      .then((data) => {
        setPropertyData(data);
      })
      .catch(() => {
        history.push(ROUTES.LEDGER);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [state.currentUser]);

  useEffect(() => {
    setIsLoadingMore(true);
    api
      .getLedgerRecordActivity(params.id, activityItemsPage)
      .then((data) => {
        setActivitiesItems(data.records);
        if (activityItems?.length) {
          setActivityItems(activityItems.concat(data.records));
          setHasMore(data.hasMore);
        } else {
          setActivityItems(data.records);
          setHasMore(data.hasMore);
        }
      })
      .finally(() => {
        setIsLoadingMore(false);
      });
  }, [activityItemsPage]);

  const renderUserActivity = (item) => {
    const date = format(new Date(item.createdAt), 'MM/dd/yyyy');
    switch (item.type) {
      case ACTIVITY_TYPES.BUY_IN:
        return (
          <>
            <td>{date}</td>
            <td>Equity Purchase</td>
            {/*<td>{item.amount >= 0 ? '+$'+fullRoundAndFormatNum(item.amount) : '-$'+fullRoundAndFormatNum(item.amount).slice(1)}</td>*/}
            <td>${fullRoundAndFormatNum(item.homeownerEquity)}</td>
          </>
        );

      case ACTIVITY_TYPES.MONTHLY_PAYMENT:
        return (
          <>
            <td>{date}</td>
            <td>Monthly Equity Purchase</td>
            {/*<td>{item.amount >= 0 ? '+$'+fullRoundAndFormatNum(item.amount) : '-$'+fullRoundAndFormatNum(item.amount).slice(1)}</td>*/}
            <td>${fullRoundAndFormatNum(item.homeownerEquity)}</td>
          </>
        );
      case ACTIVITY_TYPES.CASH_OUT:
        return (
          <>
            <td>{date}</td>
            <td>Equity Sale</td>
            {/*<td>{item.amount >= 0 ? '+$'+fullRoundAndFormatNum(item.amount) : '-$'+fullRoundAndFormatNum(item.amount).slice(1)}</td>*/}
            <td>
              <span className={classes.propertyActivityTableSpan}>${fullRoundAndFormatNum(item.homeownerEquity)}</span>
            </td>
          </>
        );
      case ACTIVITY_TYPES.COVENANT_FEE:
        return (
          <>
            <td>{date}</td>
            <td>Covenant Fee</td>
            {/*<td>{'-$'+fullRoundAndFormatNum(item.amount).slice(1)}</td>*/}
            <td>
              <span className={classes.propertyActivityTableSpan}>${roundAndFormatNumber(item.homeownerEquity)}</span>
            </td>
          </>
        );
    }
  };

  const showMoreActivity = () => {
    setActivityItemsPage((prevActivity) => prevActivity + 1);
  };

  return (
    <section className={classes.property}>
      <Container fixed className={classes.propertyContainer}>
        <div className={classes.propertyTitleBlock}>
          <h4 className={classes.propertyTitle}>
            {propertyData?.property?.streetAddress.replace(/[0-9]th|rd/g, '').replace(/[0-9]/g, '')}{' '}
          </h4>
          {isLoading && <Skeleton width={100} />}
          {!isLoading && (
            <>
              <p className={classes.propertyText}>
                {propertyData?.property.city}, {propertyData?.property.stateCode} {propertyData?.property.zip}
              </p>

              {FEATURE_FLAGS.PROPERTY_PAGE && (
                <div className={classes.propertyTagWrapper}>
                  <RenderTag tag={ADDRESS_STATUS.INDIVIDUAL} tagClassName={classes.propertyTag} />
                  <RenderTag tag={ADDRESS_STATUS.LOCATION} tagClassName={classes.propertyTag} />
                </div>
              )}
            </>
          )}
        </div>
        <div className={classes.propertyDataBlock}>
          <DataBlock
            mainChildren={'Home value'}
            loadingState={isLoading}
            value={propertyData?.homeValue}
            roundNum={true}
            showSmallValue={false}
          />
          <DataBlock
            mainChildren={'Customer equity'}
            loadingState={isLoading}
            value={propertyData?.customerEquityValue}
            roundNum={true}
            smallValue={propertyData?.customerEquityPercent}
            fullRound={true}
          />
          <DataBlock
            mainChildren={'HAUS equity'}
            loadingState={isLoading}
            value={propertyData?.hausEquityValue}
            roundNum={true}
            smallValue={propertyData?.hausEquityPercent}
            fullRound={true}
          />
          {FEATURE_FLAGS.PROPERTY_PAGE ? (
            <>
              <DataBlock
                mainChildren={'CUSTOMER AVAILABLE TO SELL'}
                loadingState={isLoading}
                value={propertyData?.homeValue}
                roundNum={true}
                showSmallValue={false}
              />
              <DataBlock
                mainChildren={'CUSTOMER CREDIT SCORE'}
                loadingState={isLoading}
                value={propertyData?.customerEquityValue}
                roundNum={true}
                smallValue={propertyData?.customerEquityPercent}
                fullRound={true}
              />
              <DataBlock
                mainChildren={'CUSTOMER INCOME'}
                loadingState={isLoading}
                value={propertyData?.hausEquityValue}
                roundNum={true}
                smallValue={propertyData?.hausEquityPercent}
                fullRound={true}
              />
              <DataBlock
                mainChildren={'PROPERTY TAXES'}
                loadingState={isLoading}
                status={true}
                statusChildren={'Confirmed'}
                showSmallValue={false}
                showDates={true}
                datesChildren={'5/21/2022'}
              />
              <DataBlock
                mainChildren={'INSURANCE'}
                loadingState={isLoading}
                status={true}
                statusChildren={'Confirmed'}
                showSmallValue={false}
                showDates={true}
                datesChildren={'5/21/2022'}
              />
              <DataBlock
                mainChildren={'HOA PAYMENT'}
                loadingState={isLoading}
                status={true}
                statusChildren={'Confirmed'}
                showSmallValue={false}
                showDates={true}
                datesChildren={'5/21/2022'}
              />
            </>
          ) : (
            <div className={classes.propertyDataBlock}></div>
          )}
        </div>
        {FEATURE_FLAGS.PROPERTY_PAGE && (
          <>
            <div className={classes.pastBlockTitle}>Past 12 month</div>
            <div className={classes.pastBlock}>
              <div className={classes.pastBlockDataWrapper}>
                <div className={classes.pastBlockDataTitle}>Equity sales</div>
                <div className={classes.pastBlockDataValue}>3</div>
              </div>
              <div className={classes.pastBlockDataWrapper}>
                <div className={classes.pastBlockDataTitle}>Equity purchases</div>
                <div className={classes.pastBlockDataValue}>6</div>
              </div>
              <div className={classes.pastBlockDataWrapper}>
                <div className={classes.pastBlockDataTitle}>Skipped payments</div>
                <div className={classes.pastBlockDataValue}>2</div>
              </div>
            </div>
          </>
        )}
      </Container>
      {FEATURE_FLAGS.PROPERTY_PAGE && (
        <PropertyGraph
          graph={propertyData?.graph}
          percent={fullRoundAndFormatNum(propertyData?.customerEquityPercent)}
        />
      )}
      {/*<div className={classes.propertyCustomerDataWrapper}>*/}
      {/*  <Container fixed className={classes.propertyContainer}>*/}
      {/*    <div className={classes.propertyCustomerDataBlock}>*/}
      {/*      <div className={classes.propertyCustomerDataInfo}>*/}
      {/*        <div className={classes.propertyDataBlockItemsTitle}>Average annual appreciation</div>*/}
      {/*        <div className={classes.propertyCustomerDataInfoText}>*/}
      {/*          <DataItem*/}
      {/*            replaceEmptyWithDash={true}*/}
      {/*            value={propertyData?.availableForWithdrawal}*/}
      {/*            isLoading={isLoading}*/}
      {/*            startCharacter={'$'}*/}
      {/*            roundNum={true}*/}
      {/*          />*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className={classes.propertyCustomerDataInfo}>*/}
      {/*        <div className={classes.propertyDataBlockItemsTitle}>Customer since</div>*/}
      {/*        <div className={classes.propertyCustomerDataInfoText}>-</div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </Container>*/}
      {/*</div>*/}
      <div className={classes.propertyActivityWrapper}>
        <Container fixed className={classes.propertyContainer}>
          <div className={classes.propertyCustomerActivity}>
            <img className={classes.propertyCustomerActivityImg} src={activity} alt="activity" />
            <div className={classes.propertyCustomerActivityTitle}>Activity</div>
          </div>
          {isLoading && <Skeleton variant="text" animation="wave" height={50} />}
          {!isLoading && (
            <table className={classes.propertyActivityTable} frame="none" rules="none" cellPadding="25">
              <tbody>
                <tr className={classes.propertyActivityHeaderWrapper}>
                  <th>Date</th>
                  <th>Action</th>
                  <th>Customer equity</th>
                </tr>
                {activityItems?.map((item) => (
                  <tr key={item.id} className={classes.propertyActivityTableRow}>
                    {renderUserActivity(item)}
                  </tr>
                ))}
                <tr className={classes.propertyActivityTableRow}></tr>
              </tbody>
            </table>
          )}
          <ViewMoreButton
            variant={'property'}
            hasMore={hasMore}
            isLoading={isLoadingMore}
            onClick={showMoreActivity}
            label={'VIEW MORE'}
          />
        </Container>
      </div>
    </section>
  );
};

export default PropertyPage;
