import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Tab from './components/Tab';
import TabItem from './components/TabItem';
import PricingTab from './components/PricingTab';
import RequestTab from './components/RequestTab';
import { useStyles } from './style.js';
import AdminsTab from './components/AdminsTab';
import EventsTab from './components/EventsTab';

const TABS = {
    PRICING: 0,
    REQUESTS: 1,
    ADMINS: 2,
    EVENTS: 3,
};

const AdminPage = () => {
    const classes = useStyles();
    const [activeTab, setActiveTab] = useState(TABS.REQUESTS);

    let showTabs = (index) => {
        setActiveTab(index);
    };

    return (
        <>
            <div className={classes.admin}>
                <Container fixed className={classes.adminContainer}>
                    <h2 className={classes.adminTitle}>Admin</h2>
                    <ul className={classes.adminTabsHeaders}>
                        <TabItem onClick={() => showTabs(TABS.REQUESTS)} isActive={activeTab === TABS.REQUESTS}>
                            Requests
                        </TabItem>
                        <TabItem onClick={() => showTabs(TABS.ADMINS)} isActive={activeTab === TABS.ADMINS}>
                            Admins
                        </TabItem>
                    </ul>
                    <div className={classes.adminTabs}>
                        <Tab isActive={activeTab === TABS.REQUESTS}>
                            <RequestTab />
                        </Tab>
                        <Tab isActive={activeTab === TABS.ADMINS}>
                            <AdminsTab />
                        </Tab>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default AdminPage;
