import React from 'react';
import { useStyles } from 'admin/pages/AdminPage/style';
import { Skeleton } from '@material-ui/lab';

const AdminItem = () => {
    const classes = useStyles();
    return (
        <div className={classes.adminTabAdminsList}>
            <p className={classes.adminTabAdminsEmail}>
                <Skeleton animation="wave" width={200} height={18} />
            </p>
            <Skeleton animation="wave" width={40} height={18} className={classes.adminTabAdminsBtn} />
            <Skeleton animation="wave" width={40} height={18} className={classes.adminTabAdminsBtn} />
        </div>
    );
};

export default React.memo(AdminItem);
