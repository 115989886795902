import React, { useEffect, useReducer } from 'react';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import closeBtn from 'assets/img/close-btn.svg';
import { useStyles } from './style';
import { useAppState } from 'marketplace/app-state';
import { AUTH_ERRORS, ROUTES } from '../../constans';
import { useHistory } from 'react-router-dom';
import { validatePassword } from 'common/validator';
import Fade from '@material-ui/core/Fade';

const initState = {
    newPassword: '',
    confirmNewPassword: '',
    isNewPasswordValid: false,
    isConfirmPasswordValid: false,
    newPasswordError: null,
    confirmPasswordError: null,
    isLoading: false,
    isDisabled: true,
};

const ModalConfirmUser = () => {
    const classes = useStyles();
    const { state: globalState, actions, cognitoClient } = useAppState();
    const signInClasses = useStyles();
    const [ state, setState ] = useReducer((state, newState) => ({ ...state, ...newState }), initState);
    const history = useHistory();

    useEffect(() => {
        validatePasswordField('newPassword', 'isNewPasswordValid', 'newPasswordError', false);
    }, [ state.newPassword ]);

    useEffect(() => {
        validatePasswordField('confirmNewPassword', 'isConfirmPasswordValid', 'confirmPasswordError', false);
    }, [ state.confirmNewPassword ]);

    useEffect(() => {
        if (state.isLoading) {
            setState({ isDisabled: true });
            return;
        }
        setState({ isDisabled: !state.isNewPasswordValid || !state.isConfirmPasswordValid });
    }, [ state.isNewPasswordValid, state.isConfirmPasswordValid, state.isLoading ]);

    const validatePasswordField = (valueName, validationName, errorName, showErrorMsg = true) => {
        const password = state[valueName];
        const validationPassMsg = validatePassword(password);

        if (validationPassMsg) {
            setState({
                [errorName]: showErrorMsg && validationPassMsg,
                [validationName]: false,
            });
            return;
        }

        if (valueName === 'confirmNewPassword' && state.newPassword !== state.confirmNewPassword) {
            setState({
                [errorName]: showErrorMsg && 'Passwords are not matching',
                [validationName]: false,
            });
            return;
        }
        setState({
            [errorName]: null,
            [validationName]: true,
        });
    };

    const handleSubmit = () => {
        setState({ isLoading: true });
        cognitoClient
            .confirmRegistration({ newPassword: state.confirmNewPassword })
            .then(({ user }) => {
                actions.fetchInvestorData();
                actions.updateState({
                    currentUser: user,
                    modalConfirmUser: false,
                    isAuth: true,
                });
                history.push(ROUTES.DASHBOARD);
            })
            .catch((err) => {
                console.error(err);
                actions.updateState({
                    modalConfirmUser: false,
                    messages: AUTH_ERRORS.USER_CONFIRM_FAILED,
                });
            })
            .finally(() => setState({ isLoading: true }));
    };

    const handleClose = () => {
        actions.updateState({ modalConfirmUser: false });
    };

    const onBlurHandler = (e) => {
        switch (e.target.name) {
            case 'new-password':
                validatePasswordField('newPassword', 'isNewPasswordValid', 'newPasswordError');
                break;

            case 'confirm-password':
                validatePasswordField('confirmNewPassword', 'isConfirmPasswordValid', 'confirmPasswordError');
                break;
        }
    };

    return (
        <div>
            <Fade in={open} timeout={200}>
                <div
                    className={clsx(classes.modal, {[classes.showModal]: open})}
                    onClick={(e) => e.stopPropagation()}>
                    <div className={signInClasses.modalSignInWrapper} onClick={(e) => e.stopPropagation()}>
                        <div className={signInClasses.modalSignInCloseBtn} onClick={handleClose}>
                            <img className={signInClasses.modalSignInImg} src={closeBtn} alt='close' />
                        </div>
                        <div className={signInClasses.modalSignInInputBlock}>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmit();
                                }}
                                className={signInClasses.signInForm}
                            >
                                <div className={signInClasses.modalSignInInputField}>
                                    <label className={signInClasses.modalSignInInputLabel} htmlFor='password'>
                                        New password
                                        <input
                                            className={signInClasses.modalSignInInput}
                                            onChange={(e) =>
                                                setState({
                                                    newPassword: e.target.value,
                                                })
                                            }
                                            onBlur={onBlurHandler}
                                            value={state.newPassword}
                                            type='password'
                                            name='new-password'
                                            autoComplete='off'
                                            placeholder='Enter here'
                                        />
                                    </label>
                                </div>
                                <div className={signInClasses.wrapperForMsg}>
                                    {state.newPasswordError && <div className={signInClasses.errMsg}>{state.newPasswordError}</div>}
                                </div>
                                <div className={signInClasses.modalSignInInputField}>
                                    <label className={signInClasses.modalSignInInputLabel} htmlFor='password'>
                                        Confirm new password
                                        <input
                                            className={signInClasses.modalSignInInput}
                                            onChange={(e) =>
                                                setState({
                                                    confirmNewPassword: e.target.value,
                                                })
                                            }
                                            onBlur={onBlurHandler}
                                            value={state.confirmNewPassword}
                                            type='password'
                                            name='confirm-password'
                                            autoComplete='off'
                                            placeholder='Enter here'
                                        />
                                    </label>
                                </div>
                                <div className={signInClasses.wrapperForMsg}>
                                    {state.confirmPasswordError && <div className={signInClasses.errMsg}>{state.confirmPasswordError}</div>}
                                </div>
                                <button
                                    className={signInClasses.modalSignInCreatBtn}
                                    disabled={state.isDisabled}
                                    type='submit'
                                >
                                    {state.isLoading ? <CircularProgress size={18} /> : 'Confirm'}
                                </button>
                            </form>
                        </div>
                    </div>

                </div>
            </Fade>
        </div>

    );
};

export default React.memo(ModalConfirmUser);
