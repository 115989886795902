import React, {useEffect, useState, useCallback} from 'react';

import {useStyles} from "./style";
import { uniq } from "lodash";
import { useAppState } from 'marketplace/app-state';
import Slider from "react-slick";
import Card from "../Card/Card";

import CONFIG_COIN from "../../CoinConfig/CoinConfig";
import { formatTokenData, initialCoinData } from "../../lib/tokens";
import {generatePath, useHistory} from "react-router-dom";
import SkeletonCard from "../Skeleton/SkeletonCard";

const LandingHeroSlider = () => {

  const coins = uniq(
    CONFIG_COIN.filter(coin => coin.featured)
  );

  const initialFormatted = formatTokenData(initialCoinData, coins);

  const classes = useStyles();
  const history = useHistory();
  const { apiClient } = useAppState();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState(initialFormatted);
  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(async () => {
    if (isInitialized) {
      return;
    }
    setIsInitialized(true)
    const coinData = await apiClient.getHomeStats(coins.map(coin => coin.coinType).join(','))
    setSlides(formatTokenData(coinData, coins))
    setIsLoading(false)
  }, [slides]);

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    fade: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    appendDots: dots => (
      <ul style={{
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        bottom: window.innerHeight < 1080 ? '30px' : '59px',
        margin: '0',
        zIndex: 1
      }}
      >
        {dots}
      </ul>
    ),
    beforeChange: (prev, next) => {
      setCurrentSlide(next);
    },
    customPaging: index => {
      return (
        <div className={classes.landingHeroDotsWrapper}>
          <div className={classes.landingHeroDots} style={index === currentSlide ? {transform: 'scale(2)'} : null}/>
        </div>
      )
    },
  };

  return (
    <section className={classes.landingHeroSlider}>
      {<Slider {...settings}>
          {slides?.map((slide) => (
              <div className={classes.landingHeroBackground} key={slide.id}>
                <img className={classes.landingHeroSliderImg} src={slide.background} alt={slide.alt}/>
                <div className={classes.landingHeroCardWrapper}>
                  <Card
                    id={slide.id}
                    cityLogo={slide.logo}
                    alt={slide.alt}
                    city={slide.title}
                    tag={slide.status}
                    usState={slide.usState}
                    stateCode={slide.stateCode}
                    percent={slide.percent}
                    circulation={slide.circulation}
                    coinCost={slide.coinCost}
                    title={slide.title}
                    price={slide.price}
                    dates={slide.dates}
                    graphColumns={12}
                    active={slide.active}
                  />
                </div>
              </div>
            )
          )}
        </Slider>}
    </section>
  );
};

export default LandingHeroSlider;
