/**
 * @param {object[]} currentItems
 * @param {object} selectedItem
 * @param {object=} property
 * @returns {{records: Object[], injectedIndex: number}}
 */
export function injectProperty(currentItems, selectedItem, property = {}) {
    const records = [...currentItems];
    const itemIndex = records.findIndex((item) => item.id === selectedItem.id);

    records.splice(itemIndex, 1, { ...selectedItem, ...property });
    return { records, injectedIndex: itemIndex };
}

export function formatNum(num) {
    if (num === null || num === undefined) {
        return '0';
    }
    let [numBase, numDigs] = num.toString().split('.');
    return numBase.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (numDigs ? `.${numDigs}` : '');
}

export function roundAndFormatNumber(num) {
    if (num === null || num === undefined) {
        return '0';
    }

    const roundedNum = Math.round(num * 100) / 100;
    return formatNum(roundedNum);
}

export function fullRoundAndFormatNum(num) {
    if (num === null || num === undefined) {
        return '0';
    }

    const roundedNum = Math.round(num);
    return formatNum(roundedNum);
}

// let roundedNum = Math.round(num);
// roundedNum =  formatNum(roundedNum);
// console.log(roundedNum);
// roundedNum < 0 ? roundedNum = '-$'+roundedNum.slice(1) : roundedNum = '$'+roundedNum;
// return roundedNum

export function roundFourAndFormatNumber(num) {
    if (num === null || num === undefined) {
        return '0';
    }


    const roundedNum = Number(num).toFixed(4);
    return formatNum(roundedNum);
}
