import React, { useEffect } from 'react';
import NavBar from 'common/components/NavBar';
import { useAppState } from '../../app-state';
import { ROUTES } from '../../constans';
import {NavLink, useHistory, useRouteMatch} from 'react-router-dom';
import NavBarItem from 'common/components/NavBar/NavBarItem';
import haus from '../../../assets/img/Haus-link.svg';

const MarketplaceNavBar = () => {
    const { state, actions, cognitoClient } = useAppState();
    const history = useHistory();

    const whiteBg = useRouteMatch(ROUTES.ALL);

    useEffect(() => {
        let urlHash = window.location.hash;

        urlHash = urlHash.split('?')[0];
        switch (urlHash) {
            case ROUTES.LOGIN:
                actions.updateState({
                    modalSignInActive: true,
                    modalActive: false,
                    modalForgotPass: false,
                    modalConfirmUser: false,
                });
                break;

            case ROUTES.FORGOT_PASSWORD:
                actions.updateState({
                    modalSignInActive: false,
                    modalActive: false,
                    modalForgotPass: true,
                    modalConfirmUser: false,
                });
                break;

            case ROUTES.RESET_PASSWORD:
                actions.updateState({
                    modalSignInActive: false,
                    modalActive: false,
                    modalForgotPass: false,
                    modalResetPass: true,
                    modalConfirmUser: false,
                });
                break;

            case ROUTES.SIGNUP:
                actions.updateState({
                    modalSignInActive: false,
                    modalActive: true,
                    modalForgotPass: false,
                    modalConfirmUser: false,
                });
                break;
            case ROUTES.CONFIRM_USER:
                actions.updateState({
                    modalSignInActive: false,
                    modalActive: false,
                    modalForgotPass: false,
                    modalConfirmUser: true,
                });
                break;

            default:
                actions.updateState({
                    modalSignInActive: false,
                    modalActive: false,
                    modalForgotPass: false,
                    modalResetPass: false,
                    modalConfirmUser: false,
                });
        }
    }, [ window.location.hash ]);

    const signOutHandler = () => {
        actions.updateState({ isAuth: false });
        history.push(ROUTES.HOME);
        cognitoClient.logout();
    };

    const signInHandler = () => {
        actions.updateState({ modalSignInActive: true });
    };

    return (
        <NavBar
            isAuth={state.isAuth}
            onSignIn={signInHandler}
            onSignOut={signOutHandler}
            withWhiteBg={whiteBg}
            withProfileInfo={true}
            userName={state.currentUser?.firstName}
        >
            {/*<NavBarItem visible label='How it works' routePath={ROUTES.HOME} withWhiteBg={whiteBg} />*/}
            <NavBarItem visible label='Tokens' routePath={ROUTES.TOKENS} />
            <NavBarItem visible label='FAQ' routePath={ROUTES.FAQ} />
            <NavBarItem visible label='Haus' iconImage={haus} href="https://haus.com" />
            <NavBarItem visible={state.isAuth} label='Dashboard' routePath={ROUTES.DASHBOARD} withWhiteBg={whiteBg} />
            {/*<NavBarItem visible label='About us' routePath={ROUTES.ABOUT} withWhiteBg={whiteBg} />*/}
            {/*<NavBarItem visible label='Contact' routePath={ROUTES.CONTACT} withWhiteBg={whiteBg} />*/}
        </NavBar>
    );
};

export default React.memo(MarketplaceNavBar);
