import React, { useState } from 'react';
import isEmpty from 'validator/es/lib/isEmpty';
import { useStyles } from 'marketplace/components/Modal/style.js';
import closeBtn from 'assets/img/close-btn-red.svg';
import CircularProgress from '@material-ui/core/CircularProgress';

const ModalSetPrice = ({ open, isLoading, onClose, onConfirm }) => {
    const classes = useStyles();
    const [newPrice, setNewPrice] = useState('');
    const [confirmNewPrice, setConfirmNewPrice] = useState('');

    const priceHandler = (e) => {
        setNewPrice(e.target.value);
    };

    const confirmHandler = (e) => {
        setConfirmNewPrice(e.target.value);
    };

    const closeHandler = () => {
        onClose();
        setConfirmNewPrice('');
        setNewPrice('');
    };

    const shouldDisableConfirm = () => {
        if (isEmpty(newPrice) || isEmpty(confirmNewPrice)) {
            return true;
        }

        return newPrice !== confirmNewPrice;
    };

    return (
        <div
            className={open ? `${classes.modalSetPrice} ${classes.showModalSetPrice}` : `${classes.modalSetPrice}`}
            style={{ padding: 0 }}
            onClick={(e) => e.stopPropagation()}
        >
            <div className={classes.modalSetPriceWrapper} onClick={(e) => e.stopPropagation()}>
                <div className={classes.modalSetPriceCloseBtn} onClick={closeHandler}>
                    <img className={classes.modalSetPriceImg} src={closeBtn} alt="close" />
                </div>
                <div className={classes.modalSetPriceInputBlock}>
                    <div className={classes.setPriceForm}>
                        <div className={classes.modalSetPriceInputField}>
                            <label className={classes.modalSetPriceInputLabel} htmlFor="price">
                                Pricing
                                <input
                                    className={classes.modalSetPriceInput}
                                    onChange={priceHandler}
                                    value={newPrice}
                                    type="number"
                                    name="changePrice"
                                    autoComplete="off"
                                />
                            </label>
                        </div>
                        <div className={classes.modalSetPriceInputField}>
                            <label className={classes.modalSetPriceInputLabel} htmlFor="confirmPrice">
                                Confirm pricing
                                <input
                                    className={classes.modalSetPriceInput}
                                    onChange={confirmHandler}
                                    value={confirmNewPrice}
                                    type="number"
                                    name="confirmPrice"
                                    autoComplete="off"
                                />
                            </label>
                        </div>
                        <button
                            disabled={shouldDisableConfirm() || isLoading}
                            onClick={() => onConfirm(newPrice)}
                            className={classes.modalSetPriceCreatBtn}
                            type="submit"
                        >
                            {isLoading ? <CircularProgress size={10} /> : 'Set Price'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(ModalSetPrice);
