import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  modal: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    backgroundColor: 'rgba(0,75,210,0.85)',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.5s',
    pointerEvents: 'none',
    opacity: 0,
    zIndex: 9999
  },
  showModal: {
    opacity: '1',
    pointerEvents: 'all'
  },
  modalWrapper: {
    position: 'relative',
    backgroundColor: theme.palette.bgColor.white,
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '60px',
    borderRadius: '18px',
    overflow: 'auto'
  },
  modalCloseBtn: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    cursor: 'pointer',
  },
  modalInputBlock: {
    display: 'flex',
    maxWidth: '289px',
    flexDirection: 'column',
    marginRight: '70px',
    height: 'fit-content'
  },
  modalInputField: {
    '&::after': {
      content: '""',
      display: 'block',
      height: '12px',
      borderRight: '4px solid black',
      borderLeft: '4px solid black',
      borderBottom: '4px solid black',
      marginBottom: '10px',
    }
  },
  modalInputLabel: {
    display: 'flex',
    fontSize: '12px',
    flexDirection: 'column',
    textTransform: 'uppercase'
  },
  modalInput: {
    backgroundColor: 'transparent',
    border: 'none',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    outline: 'none',
    fontFamily: theme.typography.fontFamily.black,
    lineHeight: '24px',
    fontSize: theme.fontSize.text.total,
    padding: '0 15px',
    marginTop: '14px',
    marginBottom: '-10px',
    color: theme.palette.text.main,
  },
  modalCreatBtn: {
    display: 'block',
    width: '100%',
    height: '56px',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.small,
    '&:enabled': {
      border: '2px solid #000',
      backgroundColor: 'transparent',
      color: theme.palette.text.main,
      outline: 'none',
      cursor: 'pointer',
    },
    '&:disabled': {
      border: '2px solid #979797',
      backgroundColor: 'transparent',
      outline: 'none',
      cursor: 'not-allowed'
    }
  },
  modalSignInText: {
    marginTop: '30px',
    marginBottom: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: theme.fontSize.text.small
  },
  modalSignInTextLink: {
    margin: '0 15px',
    textTransform: 'uppercase',
    fontSize: theme.fontSize.text.small,
    color: theme.palette.text.main,
    borderBottom: '1px solid black'
  },

  //// MODAL BUY SELL

  modalBuySell: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    backgroundColor: 'rgba(0,75,210,0.85)',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1600
  },
  modalBuySellWrapper: {
    width: '438px',
    height: '598px',
    backgroundColor: theme.palette.bgColor.white,
    position: 'relative',
    padding: '63px 30px 40px',
    boxSizing: 'border-box',
    borderRadius: '18px',
  },
  modalBuySellCloseBtn: {
    position: 'absolute',
    right: '15px',
    top: '17px',
    cursor: 'pointer',
    zIndex: '1',
  },
  modalBuySellInputBlock: {
    display: 'flex',
    maxWidth: '438px',
    height: 'fit-content',
    flexDirection: 'column',
  },
  // modalBuySellInputField: {
  //   marginBottom: '40px',
  //   '&::after': {
  //     content: '""',
  //     display: 'block',
  //     height: '12px',
  //     borderRight: '4px solid white',
  //     borderLeft: '4px solid white',
  //     borderBottom: '4px solid white',
  //     marginTop: '-12px',
  //   }
  // },
  modalSellInputField: {
    marginTop: '29px',
    '&::after': {
      content: '""',
      display: 'block',
      height: '12px',
      borderRight: '4px solid white',
      borderLeft: '4px solid white',
      borderBottom: '4px solid white',
      marginBottom: '42px',
    }
  },
  modalBuySellMUIBoxRoot: {
    marginBottom: '30px',
  },
  modalBuySellMUIFormControlRoot: {
    '& .MuiInput-underline': {
      width: '174px',
      marginTop: '16px',
      '&.Mui-disabled::before': {
        borderBottomStyle: 'solid',
      },
      '&::before': {
        content: '""',
        display: 'block',
        height: '12px',
        borderRight: '4px solid black',
        borderLeft: '4px solid black',
        borderBottom: '4px solid black',
      },
      '&::after': {
        content: '""',
        border: 'none'
      }
    },
    '&:hover': {
      '& .MuiInput-underline': {
        '&::before': {
          borderRight: '4px solid black',
          borderLeft: '4px solid black',
          borderBottom: '4px solid black!important',
        },
      },
    }
  },
  modalBuySellMUICoinControlRoot: {
    position: 'relative',
    '& .MuiInput-underline': {
      marginTop: '16px',
      '&.Mui-disabled::before': {
        borderBottomStyle: 'solid',
      },
      '&::before': {
        content: '""',
        display: 'block',
        height: '12px',
        borderRight: '4px solid black',
        borderLeft: '4px solid black',
        borderBottom: '4px solid black',
      },
      '&::after': {
        content: '""',
        border: 'none'
      }
    },
    '&:hover': {
      '& .MuiInput-underline': {
        '&::before': {
          borderRight: '4px solid black',
          borderLeft: '4px solid black',
          borderBottom: '4px solid black!important',
        },
      },
    }
  },
  coinLogoWrapper: {
    width: '36px',
    height: '36px',
    position: 'relative',
    backgroundColor: theme.palette.bgColor.lightBlue,
    borderRadius: '50%',
    marginRight: '5px',
  },
  modalBuySellCityLogo: {
    width: '25px',
    height: '25px',
    top: '50%',
    left: '50%',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  modalBuySellInputLabel: {
    width: '158px',
    display: 'flex',
    letterSpacing: '1.2px',
    flexDirection: 'column',
    textTransform: 'uppercase',
    color: theme.palette.text.main,
    fontSize: theme.fontSize.text.small
  },
  modalBuySellMUISelectIcon: {
    width: '16px',
    height: '13px',
    right: '10px!important',
  },
  modalBuySellMUISelectDropdown: {
    borderRadius: '0!important',
    // transition: 'none!important',
    boxShadow: 'none!important',
    '& .MuiList-root': {
      padding: '0!important',
      backgroundColor: theme.palette.bgColor.white,
      '& li': {
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)!important',
        }
      }
    }
  },
  modalBuySellMUIInputLabel: {
    width: 'fit-content',
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
    color: theme.palette.text.main + '!important',
    fontSize: theme.fontSize.text.small + 'px!important',
    marginBottom: '0!important',
    lineHeight: theme.fontSize.text.small + 'px!important',
    position: 'relative!important',
    '&.MuiInputLabel-shrink': {
      transform: 'none',
      transition: 'none',
      marginBottom: '0!important',
      color: theme.palette.text.main + '!important',
      fontSize: theme.fontSize.text.small + 'px!important',
      lineHeight: theme.fontSize.text.small + 'px!important',
    }
  },
  modalBuySellMUISelectRoot: {
    display: 'flex',
    alignItems: 'center',
    '&.MuiInputBase-input': {
      display: 'flex',
      color: theme.palette.text.main + '!important',
      fontSize: theme.fontSize.text.mediumHeader,
      fontFamily: theme.typography.fontFamily.black,
      padding: '0 15px 6px',
      lineHeight: '30px',
      letterSpacing: '-1.17px',
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
    },
    '&:focus': {
      backgroundColor: 'transparent!important',
    }
  },
  modalBuySellFilter: {
    marginTop: '18px',
    padding: '0 10px',
    backgroundColor: 'transparent',
    fontSize: theme.fontSize.text.total,
    color: theme.palette.text.main,
    fontFamily: theme.typography.fontFamily.black,
    lineHeight: '42px',
    border: 'none',
    outline: 'none',
    '& option': {
      color: theme.palette.text.main
    }
  },
  inputNumberWrapper: {
    width: '180px',
  },
  // modalBuySellInput: {
  //   backgroundColor: 'transparent',
  //   border: 'none',
  //   borderTop: 'none',
  //   borderLeft: 'none',
  //   borderRight: 'none',
  //   outline: 'none',
  //   fontFamily: theme.typography.fontFamily.black,
  //   lineHeight: '24px',
  //   fontSize: theme.fontSize.text.total,
  //   padding: '0 15px',
  //   marginTop: '14px',
  //   color: theme.palette.text.white,
  //   '&::placeholder': {
  //     color: '#003594'
  //   }
  // },
  modalBuySellValuesWrapper: {
    display: 'flex',
    marginBottom: '40px',
  },
  modalBuySellCurrent: {
    // marginBottom: '40px'
  },
  modalBuySellTotal: {
    display: 'flex',
    marginLeft: '46px',
    whiteSpace: 'nowrap',
    justifyContent: 'space-between',
    flexFlow: 'column',
  },
  modalBuySellTotalText: {
    fontSize: theme.fontSize.text.mediumHeader,
    marginTop: '20px',
    lineHeight: '30px',
    letterSpacing: '-1.17px',
  },
  modalBuySellBtnContainer: {
    opacity: 0,
    width: 0,
    height: 0,
    display: 'none',
  },
  modalBuySellBtnContainerTransitioned: {
    opacity: 1,
    display: "block",
    flexGrow: '1',
    transition: 'opacity 0.2s'
  },
  modalBuySellCreatBtn: {
    display: 'block',
    height: '56px',
    width: '174px',
    margin: '0 auto',
    '&:enabled': {
      // padding: '20px 25px',
      backgroundColor: theme.palette.bgColor.orange,
      color: theme.palette.text.white,
      border: 'none',
      fontSize: theme.fontSize.text.small,
      outline: 'none',
      cursor: 'pointer',
    },
    '&:disabled': {
      // padding: '20px 25px',
      backgroundColor: 'rgba(255, 78, 74, 0.5)',
      color: theme.palette.text.white,
      border: 'none',
      fontSize: theme.fontSize.text.small,
      outline: 'none',
      cursor: 'not-allowed'
    }
  },
  loadingBtn: {
    display: 'block',
    // width: '100%',
    height: '56px',
    width: '174px',
    margin: '0 auto',
    backgroundColor: theme.palette.bgColor.orange,
    color: theme.palette.text.white,
    border: 'none',
    fontSize: theme.fontSize.text.small,
    outline: 'none',
    cursor: 'pointer',
  },
  confirmBlock: {
    display: 'flex',
    height: 0,
    width: 0,
    opacity: '0',
    justifyContent: 'space-between',
  },
  confirmBlockTransitioned: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    opacity: '1',
    transition: 'opacity 0.2s'
  },
  confirmBlockActive: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  modalConfirmBtn: {
    width: '45%',
    height: '56px',
    // padding: '20px 15px',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.bgColor.orange,
    color: theme.palette.text.white,
    border: 'none',
    fontSize: theme.fontSize.text.small,
    outline: 'none',
    cursor: 'pointer',

  },
  modalConfirmCancelBtn: {
    width: '45%',
    height: '56px',
    // padding: '20px 15px',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.bgColor.orange,
    color: theme.palette.text.white,
    border: 'none',
    fontSize: theme.fontSize.text.small,
    outline: 'none',
    cursor: 'pointer',
  },
  //MODAL SIGN IN

  // modalSignIn: {
  //   width: '100vw',
  //   height: '100vh',
  //   position: 'fixed',
  //   backgroundColor: 'rgba(0,0,0,0.4)',
  //   top: 0,
  //   left: 0,
  //   padding: 0,
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   transition: '0.5s',
  //   pointerEvents: 'none',
  //   opacity: 0,
  //   zIndex: 9999
  // },
  // showModalSignIn: {
  //   opacity: '1',
  //   pointerEvents: 'all'
  // },
  modalSignInWrapper: {
    position: 'relative',
    backgroundColor: theme.palette.bgColor.white,
    display: 'flex',
    flexDirection: 'row-reverse',
    borderRadius: '18px',
    boxSizing: 'border-box',
    padding: '60px',
  },
  modalSignInCloseBtn: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    cursor: 'pointer',
  },
  modalSignInInputBlock: {
    display: 'flex',
    maxWidth: '289px',
    height: 'fit-content',
    flexDirection: 'column',
    marginRight: '70px'
  },
  modalSignInInputField: {
    '&::after': {
      content: '""',
      display: 'block',
      height: '12px',
      borderRight: '4px solid black',
      borderLeft: '4px solid black',
      borderBottom: '4px solid black',
      marginBottom: '10px',
    }
  },
  modalSignInInputLabel: {
    display: 'flex',
    fontSize: '12px',
    flexDirection: 'column',
    textTransform: 'uppercase'
  },
  modalSignInInput: {
    backgroundColor: 'transparent',
    border: 'none',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    outline: 'none',
    fontFamily: theme.typography.fontFamily.black,
    lineHeight: '24px',
    fontSize: theme.fontSize.text.total,
    padding: '0 15px',
    marginTop: '14px',
    marginBottom: '-10px',
    color: theme.palette.text.main,
  },
  modalSignInCreatBtn: {
    width: '100%',
    height: '56px',
    marginTop: '7px',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.small,
    textTransform: 'uppercase',
    '&:enabled': {
      border: '2px solid #000',
      backgroundColor: 'transparent',
      color: theme.palette.text.main,
      outline: 'none',
      cursor: 'pointer',
    },
    '&:disabled': {
      border: '2px solid #979797',
      backgroundColor: 'transparent',
      outline: 'none',
      cursor: 'not-allowed'
    }
  },
  modalSignInSignInText: {
    marginTop: '30px',
    marginBottom: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: theme.fontSize.text.small
  },
  modalSignInSignInTextLink: {
    margin: '0 5px',
    textTransform: 'uppercase',
    fontSize: theme.fontSize.text.small,
    color: theme.palette.text.main,
    borderBottom: '1px solid black',
    cursor: 'pointer'
  },

  //// MODAL SET PRICE

  modalSetPrice: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    backgroundColor: 'transparent',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.5s',
    pointerEvents: 'none',
    opacity: 0,
    zIndex: 9999
  },
  showModalSetPrice: {
    opacity: '1',
    pointerEvents: 'all'
  },
  modalSetPriceWrapper: {
    backgroundColor: theme.palette.bgColor.orange,
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '60px',
  },
  modalSetPriceCloseBtn: {
    height: '100%',
    cursor: 'pointer'
  },
  modalSetPriceInputBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '70px',
    color: theme.palette.text.white
  },
  modalSetPriceInputField: {
    '&::after': {
      content: '""',
      display: 'block',
      height: '12px',
      borderRight: '4px solid white',
      borderLeft: '4px solid white',
      borderBottom: '4px solid white',
      marginBottom: '48px',
    }
  },
  modalSetPriceInputLabel: {
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'uppercase',
    fontSize: theme.fontSize.text.small
  },
  modalSetPriceInput: {
    backgroundColor: 'transparent',
    border: 'none',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    outline: 'none',
    fontFamily: theme.typography.fontFamily.black,
    lineHeight: '24px',
    fontSize: theme.fontSize.text.total,
    padding: '0 15px',
    marginTop: '14px',
    marginBottom: '-10px',
    color: theme.palette.text.white
  },
  modalSetPriceCreatBtn: {
    width: '100%',
    padding: '20px 25px',
    border: '1px solid white',
    color: theme.palette.text.white,
    textTransform: 'uppercase',
    backgroundColor: 'transparent',
    fontSize: theme.fontSize.text.small,
    outline: 'none',
    cursor: 'pointer'
  },

  //// MODAL ADD ADMIN

  modalAddAdmin: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    backgroundColor: 'transparent',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.5s',
    pointerEvents: 'none',
    opacity: 0,
    zIndex: 9999
  },
  showModalAddAdmin: {
    opacity: '1',
    pointerEvents: 'all'
  },
  modalAddAdminWrapper: {
    backgroundColor: theme.palette.bgColor.orange,
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '65px 64px 107px 78px'
  },
  modalAddAdminCloseBtn: {
    height: '100%',
    cursor: 'pointer'
  },
  modalAddAdminInputBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '70px',
    color: theme.palette.text.white
  },
  modalAddAdminInputField: {
    '&::after': {
      content: '""',
      display: 'block',
      height: '12px',
      borderRight: '4px solid white',
      borderLeft: '4px solid white',
      borderBottom: '4px solid white',
      marginBottom: '48px',
    }
  },
  modalAddAdminInputLabel: {
    display: 'flex',
    flexDirection: 'column',
    textTransform: 'uppercase',
    fontSize: theme.fontSize.text.small
  },
  modalAddAdminInput: {
    backgroundColor: 'transparent',
    border: 'none',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    outline: 'none',
    fontFamily: theme.typography.fontFamily.black,
    lineHeight: '24px',
    fontSize: theme.fontSize.text.total,
    padding: '0 15px',
    marginTop: '14px',
    marginBottom: '-10px',
    color: theme.palette.text.white
  },
  modalAddAdminCreatBtn: {
    width: '100%',
    padding: '20px 25px',
    border: '1px solid white',
    color: theme.palette.text.white,
    textTransform: 'uppercase',
    backgroundColor: 'transparent',
    fontSize: theme.fontSize.text.small,
    outline: 'none',
    cursor: 'pointer'
  },

  // FORGOT PASS

  // modalForgotPass: {
  //   width: '100vw',
  //   height: '100vh',
  //   position: 'fixed',
  //   backgroundColor: 'rgba(0,0,0,0.4)',
  //   top: 0,
  //   left: 0,
  //   display: 'flex',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   transition: '0.5s',
  //   pointerEvents: 'none',
  //   opacity: 0,
  //   zIndex: 9999
  // },
  // showModalForgotPass: {
  //   opacity: '1',
  //   pointerEvents: 'all'
  // },
  modalForgotPassWrapper: {
    position: 'relative',
    backgroundColor: theme.palette.bgColor.white,
    display: 'flex',
    flexDirection: 'row-reverse',
    boxSizing: 'border-box',
    justifyContent: 'space-around',
    borderRadius: '18px',
    padding: '60px',
  },
  modalForgotPassCloseBtn: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    cursor: 'pointer',
  },
  modalForgotPassInputBlock: {
    display: 'flex',
    maxWidth: '289px',
    flexDirection: 'column',
    marginRight: '70px'
  },
  modalForgotPassInputField: {
    '&::after': {
      content: '""',
      display: 'block',
      height: '12px',
      borderRight: '4px solid black',
      borderLeft: '4px solid black',
      borderBottom: '4px solid black',
      marginBottom: '10px',
    }
  },
  modalForgotPassInputLabel: {
    display: 'flex',
    fontSize: '12px',
    flexDirection: 'column',
    textTransform: 'uppercase'
  },
  modalForgotPassInput: {
    backgroundColor: 'transparent',
    border: 'none',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    outline: 'none',
    fontFamily: theme.typography.fontFamily.black,
    lineHeight: '24px',
    fontSize: theme.fontSize.text.total,
    padding: '0 15px',
    marginTop: '14px',
    marginBottom: '-10px',
    color: theme.palette.text.main
  },
  modalForgotPassCreatBtn: {
    width: '100%',
    height: '56px',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.small,
    textTransform: 'uppercase',
    '&:enabled': {
      border: '2px solid #000',
      backgroundColor: 'transparent',
      color: theme.palette.text.main,
      outline: 'none',
      cursor: 'pointer',
    },
    '&:disabled': {
      border: '2px solid #979797',
      backgroundColor: 'transparent',
      outline: 'none',
      cursor: 'not-allowed'
    }
  },
  modalForgotPassSignInText: {
    marginBottom: 0,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: theme.fontSize.text.small
  },
  modalForgotPassTextLink: {
    margin: '0 5px',
    textTransform: 'uppercase',
    fontSize: theme.fontSize.text.small,
    color: theme.palette.text.main,
    borderBottom: '1px solid black'
  },

  //MODAL WAITLIST

  modalWaitlistWrapper: {
    backgroundColor: theme.palette.bgColor.white,
    position: 'relative',
    padding: '45px 67px 58px',
    boxSizing: 'border-box',
    borderRadius: '18px',
  },
  modalWaitListTitleWrapper: {
    textAlign: 'center',
  },
  modalWaitListTitleImgWrapper: {
    width: '120px',
    height: '120px',
    position: 'relative',
    backgroundColor: theme.palette.bgColor.lightBlue,
    borderRadius: '50%',
    margin: '0 auto',
  },
  modalWaitListTitleImg: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  modalWaitListTitle: {
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: '24px',
    lineHeight: '24px',
    marginTop: '9px',
  },
  modalWaitListTitleSpan: {
    width: 'fit-content',
    margin: '11px auto 0',
    borderRadius: '100px',
    padding: '8px 12px',
    display: 'block',
    fontFamily: theme.typography.fontFamily.black,
    backgroundColor: theme.palette.bgColor.orange,
    color: theme.palette.text.white,
    fontSize: '8px',
    lineHeight: '8px',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  modalWaitListText: {
    maxWidth: '222px',
    margin: '37px auto 26px',
    textAlign: 'center',
    fontFamily: theme.typography.fontFamily.medium,
    color: theme.palette.bgColor.black,
    fontSize: '18px',
    lineHeight: '22px',
    letterSpacing: '-0.2475px',
  },
  modalWaitListForm: {
    textAlign: 'center',
  },
  modalWaitListInputWrapper: {
    position: 'relative',
    marginBottom: '10px',
    '&::after': {
      content: '""',
      display: 'block',
      height: '4px',
      borderRight: '4px solid black',
      borderLeft: '4px solid black',
      borderBottom: '4px solid black',
      marginTop: '5px',
    }
  },
  modalWaitListInput: {
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    outline: 'none',
    fontFamily: theme.typography.fontFamily.black,
    lineHeight: '24px',
    letterSpacing: '-0.027875px',
    fontSize: '20px',
    padding: '0 25px 0 15px',
    marginTop: '14px',
    color: theme.palette.text.main,
    '&::placeholder': {
      color: theme.palette.text.grey,
    }
  },
  modalWaitListInputImg: {
    width: '24px',
    height: '18px',
    position: 'absolute',
    right: '0',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  modalWaitListBtn: {
    width: 'fit-content',
    padding: '20px 16px',
    margin: '0 auto',
    border: 'none',
    outline: 'none',
    textTransform: 'uppercase',
    fontSize: theme.fontSize.text.small,
    lineHeight: '16px',
    letterSpacing: '1.2px',
    '&:enabled': {
      backgroundColor: theme.palette.bgColor.orange,
      color: theme.palette.text.white,
      outline: 'none',
      cursor: 'pointer',
      transition: 'all .5s ease',
      '&:hover': {
        backgroundColor: theme.palette.bgColor.active,
      },
    },
    '&:disabled': {
      backgroundColor: 'rgba(255, 78, 74, 0.5)',
      color: theme.palette.text.white,
      outline: 'none',
      cursor: 'not-allowed'
    }
  },

  //INPUT ERROR MESSAGE

  wrapperForMsg: {
    position: 'relative',
    textAlign: 'right',
    marginBottom: '48px',
  },
  errMsg: {
    position: 'absolute',
    right: 0,
    color: 'red',
    fontSize: '14px',
  },


  '@media (max-width: 1280px)': {
    modalWrapper: {
      boxSizing: 'border-box',
      padding: '50px'
    },
    modalSignInWrapper: {
      padding: '50px'
    },
    modalForgotPassWrapper: {
      padding: '50px'
    },
    modalBuySellWrapper: {
      padding: '50px'
    },
    // modalBuySellInputField: {
    //   '&::after': {
    //     marginTop: '-12px',
    //   }
    // }
  },
  '@media (max-width: 720px)': {
    modalWrapper: {
      boxSizing: 'border-box',
      padding: '45px',
      // width: '80%'
    },
    modalInputBlock: {
      marginRight: '20px'
    },
    modalInput: {
      marginTop: '10px',
      padding: '0 10px',
      fontSize: '25px',
      marginBottom: '-8px'
    },
    modalInputField: {
      '&::after': {
        marginBottom: '20px'
      }
    },
    modalSignInWrapper: {
      padding: '45px',
      // width: '80%'
    },
    modalSignInInputBlock: {
      marginRight: '20px'
    },
    modalSignInInput: {
      fontSize: '25px',
      marginTop: '10px',
      marginBottom: '-8px'
    },
    modalSignInSignInTextLink: {
      whiteSpace: 'nowrap',
      lineHeight: '2em'
    },
    modalForgotPassWrapper: {
      padding: '45px',
      // width: '80%'
    },
    modalForgotPassInputBlock: {
      marginRight: '20px',
      // width: '80%'
    },
    modalForgotPassInput: {
      fontSize: '25px',
      marginTop: '10px',
      marginBottom: '-8px'
    },
    modalForgotPassTextLink: {
      fontSize: '9px'
    },
    modalAddAdminWrapper: {
      padding: '45px',
      // width: '80%'
    },
    modalAddAdminInput: {
      fontSize: '25px'
    },
    modalSetPriceWrapper: {
      padding: '45px',
      // width: '80%'
    },
    modalSetPriceInput: {
      fontSize: '20px'
    }
  },
  '@media (max-height: 530px)': {
    modalWrapper: {
      overflow: 'scroll',
      maxHeight: '90%'
    },
    modalSignInWrapper: {
      overflow: 'scroll',
      maxHeight: '90%'
    },
    modalForgotPassWrapper: {
      overflow: 'scroll',
      maxHeight: '90%'
    },
    modalBuySellWrapper: {
      overflow: 'scroll',
      maxHeight: '90%'
    },
  },
  '@media (max-width: 480px)': {
    modalWrapper: {
      width: '90%',
      padding: '60px 20px',
      justifyContent: 'space-around',
    },
    modalInputBlock: {
      width: '90%',
      marginRight: '0',
    },
    modalInput: {
      marginTop: '10px',
      padding: '0 10px',
    },
    wrapperForMsg: {
      marginBottom: '30px'
    },
    modalInputField: {
      '&::after': {
        marginBottom: '7px'
      }
    },
    modalCreatBtn: {
      height: '48px'
    },
    loadingBtn: {
      height: '48px'
    },
    modalConfirmBtn: {
      height: '48px'
    },
    modalConfirmCancelBtn: {
      height: '48px'
    },
    modalSignInText: {
      marginTop: '12px'
    },
    modalSignInTextLink: {
      fontSize: '12px',
    },
    modalSignInInputBlock: {
      width: '90%',
    },
    modalForgotPassInputBlock: {
      width: '90%',
      marginRight: '0',
    },
    modalSignInInputField: {
      '&::after': {
        marginBottom: '7px',
      }
    },
    modalSignInInput: {
      marginTop: '5px'
    },
    modalSignInSignInText: {
      marginTop: '10px',
      fontSize: '12px'
    },
    modalSignInSignInTextLink: {
      fontSize: '12px',
    },
    modalSignInCreatBtn: {
      height: '48px'
    },
    modalForgotPassCreatBtn: {
      height: '48px'
    },
    modalSignInWrapper: {
      width: '90%',
      justifyContent: 'space-around',
      padding: '60px 20px',
    },
    modalForgotPassWrapper: {
      width: '90%',
      padding: '60px 20px',
    },
    modalBuySellWrapper: {
      width: '90%',
      padding: '30px 20px 0',
      borderRadius: '18px 18px 0 0',
    },
    modalBuySellInputLabel: {
      fontSize: '12px'
    },
    modalBuySellMUISelectRoot: {
      '&.MuiInputBase-input': {
        fontSize: theme.fontSize.text.bigHeader,
      },
    },
    // modalBuySellMUIInputLabel: {
    //   fontSize: '12px!important',
    //   marginBottom: '0!important',
    //   lineHeight: theme.fontSize.text.small + 'px!important',
    //   '&.MuiInputLabel-shrink': {
    //     fontSize: '12px!important',
    //     marginBottom: '0!important',
    //     lineHeight: theme.fontSize.text.small + 'px!important',
    //   }
    // },
    modalBuySellFilter: {
      padding: '5px 10px',
      fontSize: '36px'
    },
    modalBuySellValuesWrapper: {
      flexFlow: 'column',
      marginBottom: '10px',
    },
    // modalBuySellCurrent : {
    //     marginTop: '14px'
    // },
    modalBuySellTotal : {
      marginLeft: '0',
      marginTop: '30px'
    },
    modalBuySellTotalText: {
      fontSize: '36px'
    },
    modalBuySellCreatBtn: {
      width: '100%',
      position: 'absolute',
      bottom: '0',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    modalBuySellInputBlock: {
      width: '100%',
      marginRight: '10px'
    },
    inputNumberWrapper: {
      maxWidth: '180px',
    },
    // modalBuySellInputField: {
    //   // '&::after' : {
    //   //     height: '5px'
    //   // }
    // },
    modalSetPriceWrapper: {
      padding: '25px 25px 25px 20px'
    },
    modalSetPriceInputBlock: {
      marginRight: '5px'
    },
    modalSetPriceInputLabel: {
      fontSize: '15px'
    },
    modalSetPriceInput: {
      fontSize: '12px',
      marginTop: '5px'
    },
    modalSetPriceSignInTextLink: {
      fontSize: '12px'
    },
    modalAddAdminWrapper: {
      padding: '25px 25px 25px 20px'
    },
    modalAddAdminInputBlock: {
      marginRight: '5px'
    },
    modalAddAdminInputLabel: {
      fontSize: '15px'
    },
    modalAddAdminInput: {
      fontSize: '12px',
      marginTop: '5px'
    },
    modalAddAdminSignInTextLink: {
      fontSize: '12px'
    },
    // modalForgotPassWrapper: {
    //     padding: '25px 25px 25px 20px'
    // },
    modalForgotPassInput: {
      marginTop: '5px'
    },
    modalForgotPassTextLink: {
      fontSize: '12px'
    },
    errMsg: {
      fontSize: '12px'
    },
    modalWaitlistWrapper: {
      padding: '95px 35px 92px',
      position: 'relative',
      borderRadius: '18px 18px 0 0',
    },
    modalWaitListBtn: {
      width: '100%',
      position: 'absolute',
      bottom: '0',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  },
  '@media (max-width: 380px)': {
    modalBuySellCloseBtn: {
      width: '30px',
      height: '30px',
    },
    modalBuySellImg: {
      width: '100%'
    },
  },
  '@media (max-width: 320px)': {
    // modalBuySellImg: {
    //   width: '30px'
    // },
    modalSignInText: {
      fontSize: '10px'
    },
    // modalSignInTextLink: {
    //   fontSize: '10px'
    // },
    modalSignInSignInText: {
      fontSize: '10px'
    },
    modalWaitlistWrapper: {
      padding: '95px 10px 92px',
    },
  },


  // LANDSCAPE ORIENTATION
  '@media (max-width: 815px) and (orientation: landscape)': {
    modal: {
      padding: '10px',
    },
    modalSignIn: {
      overflowY: 'scroll'
    },
    modalBuySell: {
      overflowY: 'scroll',
    },
    modalForgotPass: {
      overflowY: 'scroll'
    },

    modalWrapper: {
      justifyContent: 'space-around',
      overflowY: 'scroll'
    },
    modalSignInWrapper: {
      justifyContent: 'space-around',
      overflowY: 'scroll'
    },
    modalForgotPassWrapper: {
      justifyContent: 'space-around',
      overflowY: 'scroll'
    },
    modalBuySellWrapper: {
      justifyContent: 'space-around',
      overflowY: 'scroll'
    },

    modalInputBlock: {
      marginRight: '70px',
      maxWidth: '289px'
    },
    modalSignInInputBlock: {
      marginRight: '70px',
      maxWidth: '289px'
    },
    modalForgotPassInputBlock: {
      marginRight: '70px',
      maxWidth: '289px'
    },
    modalBuySellInputBlock: {
      marginRight: '70px',
      maxWidth: '289px'
    }
  }
}))
