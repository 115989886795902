import {makeStyles} from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  landingGraphCardWrapper: {
    width: '378px',
    height: '380px',
    boxSizing: 'border-box',
    padding: '40px 49px 63px',
    backgroundColor: theme.palette.bgColor.white,
    boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    borderRadius: '18px',
    '&:nth-child(2)': {
      margin: '0 30px'
    }
  },
  landingGraphCardTitle: {
    margin: '47px 0 46px',
    color: theme.palette.text.darkBlue,
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.total,
    lineHeight: '40px',
    letterSpacing: '-2px',
  },
  landingGraphCardText: {
    color: theme.palette.text.main,
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: theme.fontSize.text.semiTotal,
    lineHeight: '22px',
    letterSpacing: '-0.2475px',
  },

  '@media (max-width: 1200px)': {
    landingGraphCardWrapper: {
      // width: '342px',
      // height: '350px',
      // padding: '40px 35px 70px',
      '&:nth-child(2)': {
        margin: '0 10px'
      }
    },
    landingGraphCardTitle: {
      margin: '30px 0',
    }
  },
  '@media (max-width: 1080px)': {
    landingGraphCardWrapper: {
      '&:nth-child(2)': {
        margin: '20px 0'
      }
    },
  },
  '@media (max-width: 1024px)': {
    // landingGraphCardWrapper: {
    //   width: '378px',
    //   height: '380px',
    //   padding: '40px 49px 63px',
    // }
  },
  '@media (max-width: 480px)': {
    landingGraphCardWrapper: {
      width: '342px',
      height: '350px',
      padding: '40px 35px 70px',
      '&:nth-child(2)': {
        margin: '20px 0'
      }
    },
  },
  '@media (max-width: 375px)': {
    landingGraphCardWrapper: {
      width: '300px',
      padding: '40px 35px 70px',
      '&:nth-child(2)': {
        margin: '20px 0'
      }
    },
    landingGraphCardTitle: {
      fontSize: '32px'
    },
    landingGraphCardText: {
      fontSize: '14px',
    }
  }
}))
