import React from 'react';
import {useStyles} from "./style";
import BlueLabel from "../BlueLabel/BlueLabel";

const TextCard = ({blueTitle, textTitle, text}) => {
  const classes = useStyles();

  return (
      <div className={classes.landingGraphCardWrapper}>
        <BlueLabel
          labelClassName={classes.landingGraphCardBlueText}
          text={blueTitle}
        />
        <div className={classes.landingGraphCardTitle}>
          {textTitle}
        </div>
        <div className={classes.landingGraphCardText}>
          {text}
        </div>
      </div>
  );
}

export default TextCard;
