import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.header,
    lineHeight: '72px',
    letterSpacing: '-1.5px',
  },
  '@media (max-width: 480px)': {
    mainTitle: {
      fontSize: '40px',
      lineHeight: '40px',
      letterSpacing: '-0.468px',
    },
  },
}))
