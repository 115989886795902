import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { useStyles } from '../style';
import ModalAddAdmin from '../../../components/ModalAddAdmin';
import { useAppState } from '../../../../marketplace/app-state';
import AdminItem, { AdminItemPreloader } from '../../../components/AdminItem';
import useApi from '../../../hooks/useApi';
import { useCustomSnackbar } from '../../../../common/hooks/custom-snackbars';
import ViewMoreButton from '../../../components/ViewMoreButton';
import { injectProperty } from '../../../../common/util';

const preloaderItems = [1, 2, 3];
const initState = {
    isLoading: false,
    isLoadingMore: false,
    isAddingAdmin: false,
    hasMore: false,
    recordsLeft: 0,
    records: [],
    showAdminDialog: false,
    recordToEdit: null,
    page: 1,
};

const AdminsTab = () => {
    const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), initState);
    const classes = useStyles();
    const api = useApi();
    const { showError, showInfo } = useCustomSnackbar();

    const currentEmails = useMemo(() => {
        return state.records.map((record) => record.email);
    }, [state.records]);

    useEffect(() => {
        state.page > 1 ? setState({ isLoadingMore: true }) : setState({ isLoading: true });
        api.getAdmins(state.page)
            .then((data) => {
                setState({
                    hasMore: data.hasMore,
                    recordsLeft: data.recordsLeft,
                    records: state.records?.length ? state.records.concat(data.records) : data.records,
                });
            })
            .catch((err) => showError('Could not get admins', err))
            .finally(() => {
                state.page > 1 ? setState({ isLoadingMore: false }) : setState({ isLoading: false });
            });
    }, [state.page]);

    const handleAdminEdit = (selectedRecord) => {
        setState({ recordToEdit: selectedRecord, showAdminDialog: true });
    };

    const handleAdminDelete = (selectedRecord) => {
        const { records, injectedIndex } = injectProperty(state.records, selectedRecord, { isDeleting: true });
        setState({ records });

        api.deleteAdmin(selectedRecord.id)
            .then(() => {
                records.splice(injectedIndex, 1);
                setState({ records });
            })
            .catch((err) => showError('Could not delete admin', err))
            .finally(() => {
                records.splice(injectedIndex, 1, selectedRecord);
                setState({ records });
            });
    };

    const addNewAdmin = (isEdit, email) => {
        if (isEdit) {
            const { records, injectedIndex } = injectProperty(state.records, state.recordToEdit, { isEditing: true });
            setState({ records });

            api.editAdmin(state.recordToEdit.id, email)
                .then(() => {
                    records.splice(injectedIndex, 1, { ...state.recordToEdit, email });
                    setState({
                        records,
                        showAdminDialog: false,
                        recordToEdit: null,
                    });
                })
                .catch((err) => {
                    records.splice(injectedIndex, 1, state.recordToEdit);
                    setState({
                        records,
                        showAdminDialog: false,
                        recordToEdit: null,
                    });
                    showError('Could not edit admin', err);
                });
            return;
        }

        setState({ isAddingAdmin: true });
        api.addAdmin(email)
            .then((admin) => {
                setState({ records: state.records.concat([admin]), showAdminDialog: false });
            })
            .catch((err) => {
                showError('Error happened', err);
            })
            .finally(() => setState({ isAddingAdmin: false }));
    };

    return (
        <>
            <div className={classes.adminTabTitleWrapper}>
                <p className={classes.adminTabTitle}>Admins</p>
                <p
                    // disabled={state.isLoading}
                    className={classes.adminTabTitleAdd}
                    onClick={() => setState({ showAdminDialog: true })}
                >
                    Add
                </p>
            </div>
            <span className={classes.adminTabLine} />
            {state.isLoading && preloaderItems.map((item) => <AdminItemPreloader key={item} />)}
            {!state.isLoading &&
                state.records.map((admin) => (
                    <AdminItem
                        key={admin.id}
                        data={admin}
                        isDeleting={admin.isDeleting}
                        isEditing={admin.isEditing}
                        onEdit={handleAdminEdit}
                        onDelete={handleAdminDelete}
                    />
                ))}
            {state.hasMore && (
                <ViewMoreButton
                    isLoading={state.isLoadingMore}
                    recordsLeft={state.recordsLeft}
                    onClick={() => setState({ page: state.page + 1 })}
                />
            )}
            {state.showAdminDialog && (
                <ModalAddAdmin
                    open={state.showAdminDialog}
                    isLoading={state.isAddingAdmin}
                    emailToEdit={state.recordToEdit?.email}
                    onClose={() => setState({ showAdminDialog: false })}
                    currentEmails={currentEmails}
                    onConfirm={addNewAdmin}
                />
            )}
        </>
    );
};

export default React.memo(AdminsTab);
