import React, { Component } from 'react';

class ErrorBoundary extends Component {
    state = {
        error: null,
        errorInfo: null,
    };

    componentDidCatch(error, errorInfo) {
        console.error(error);
        // this.setState({ error, errorInfo });
    }

    render() {
        return (
            <>
                {this.state.error
                    ? <h1>{this.state.error.name}: {this.state.error.message}</h1>
                    : this.props.children}
            </>
        );
    }
}

export default ErrorBoundary;
