import React from 'react';
import Container from '@material-ui/core/Container';
import {useStyles} from "./style";

const ContentContainer = ({children, className}) => {
  const classes = useStyles();

  return (
    <Container fixed className={`${classes.container} ${className}`}>
      {children}
    </Container>
  );
};

export default ContentContainer;
