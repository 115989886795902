import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dropDownWrapper: {
    height: '48px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dropDownTitle: {
    marginRight: '20px',
    fontFamily: theme.typography.fontFamily.bold,
    color: theme.palette.text.blue,
    fontSize: theme.fontSize.text.medium,
    lineHeight: '32px',
  },
  dropDownImg: {
    position: 'absolute',
    right: '19px',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
  },
  dropDownSelectField: {
    width: '238px',
    display: 'flex',
    position: 'relative',
    justifyContent: 'space-between',
    padding: '14px 20px',
    backgroundColor: theme.palette.bgColor.grey,
    cursor: 'pointer',
  },
  dropDownBtn: {
    // padding: '15px 10px',
    // fontFamily: theme.typography.fontFamily.black,
    // fontSize: theme.fontSize.text.medium,
    // whiteSpace: 'nowrap',
    // textAlign: 'left',
    // border: 'none',
    // borderBottom: '1px solid #E5E5E5',
    // outline: 'none',
    // backgroundColor: theme.palette.bgColor.grey,
    // color: theme.palette.text.main,
    // cursor: 'pointer',
  },
  dropDownClose: {
    display: 'none'
  }
}))
