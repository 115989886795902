import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  header: {
    height: 'calc(100vh - 80px)',
    position: 'relative',
    backgroundColor: theme.palette.bgColor.white,
    color: theme.palette.text.main,
  },
  homePageContainer: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
    marginTop: '80px',
    padding: '0 20px'
  },
  landingHeroContentInfo: {
    width: '50%',
    // paddingTop: '149px',
  },
  // headerLabel: {
  //   margin: 0,
  //   fontFamily: theme.typography.fontFamily.black,
  //   fontSize: theme.fontSize.text.small,
  //   lineHeight: '16px',
  //   letterSpacing: '1.2px'
  // },
  headerTitle: {
    margin: '0 0 30px',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.header,
    lineHeight: '80px',
    letterSpacing: '-1.5px'
  },
  headerText: {
    maxWidth: '514px',
    margin: 0,
    fontFamily: theme.typography.fontFamily.medium,
    letterSpacing: '-0.35px',
    lineHeight: '30px',
    fontSize: theme.fontSize.text.subHeader
  },
  headerBtn: {
    display: 'inline-flex',
    boxSizing: 'border-box',
    marginTop: '30px',
    textTransform: 'uppercase',
    textAlign: 'center',
    backgroundColor: theme.palette.bgColor.orange,
    outline: 'none',
    padding: '20px 70px',
    color: theme.palette.text.white,
    fontSize: theme.fontSize.text.small,
    fontFamily: theme.typography.fontFamily.black,
    lineHeight: '16px',
    letterSpacing: '1.2px',
    cursor: 'pointer',
  },
  landingHeroBtnLink: {
    boxSizing: 'border-box',
    color: theme.palette.text.main,
    fontSize: theme.fontSize.text.small,
    textTransform: 'uppercase',
    borderBottom: '2px solid black',
    marginLeft: '30px',
    letterSpacing: '1px',
  },
  headerImg: {
    position: 'absolute',
    bottom: 0,
    right: 0
  },
  headerTooltip: {
    width: '168px',
  },
  tooltipLink: {
    textDecoration: "underline",
    color: theme.palette.bgColor.white,
  },

  '@media (max-width: 1280px)': {
    homePageContainer: {
      padding: '0 20px'
    },
  },
  '@media (max-width: 960px)': {
    header: {
      paddingTop: '111px',
      height: '100%'
    },
    homePageContainer: {
      position: 'inherit',
      top: '0',
      transform: 'translateY(0)',
      padding: '0 20px',
      marginTop: '0',
    },
    landingHeroContentInfo: {
      width: '100%',
    },
    headerTitle: {
      fontSize: '60px',
      lineHeight: '60px'
    },
    headerText: {
      fontSize: '22px'
    },
  },
  '@media (max-width: 480px)': {
    header: {
      paddingTop: '110px'
    },
    headerInfo: {
      position: 'relative',
      zIndex: 1
    },
    headerTitle: {
      fontSize: '45px',
      lineHeight: '1em',
      marginBottom: '45px',
    },
    headerText: {
      fontSize: '18px',
      lineHeight: '1.4em',
      marginBottom: '22px',
    },
    landingHeroBtnWrapper: {
      display: 'flex',
      flexFlow: 'column',
    },
    headerBtn: {
      width: '100%',
      display: 'block',
      margin: '30px auto 40px',
      padding: '20px 40px',
      textAlign: 'center',
    },
    landingHeroBtnLink: {
      margin: '0 auto',
    },
    headerImg: {
      width: '67%',
      marginRight: '13px'
    },
  },
  '@media (max-width: 380px)': {
    // contentTitle: {
    //   lineHeight: '50px'
    // }
  },
  // '@media (max-width: 320px)': {
  //     header : {
  //         height: '100%'
  //     },
  //     headerTitle : {
  //         fontSize: '30px',
  //         lineHeight: '40px'
  //     },
  //     headerText : {
  //         fontSize: '12px'
  //     },
  //     headerBtn : {
  //         marginBottom: '100px'
  //     },
  //     headerImg : {
  //         width: '60%',
  //         marginRighgt: '10px'
  //     },
  //     ledgerInfoValueImg : {
  //         width: '80%'
  //     },
  //     ledgerBtn : {
  //         margin: '50px auto',
  //         padding: '15px'
  //     },
  //     contentArticle : {
  //         marginTop: '40px'
  //     },
  //     contentTitle : {
  //         fontSize: '30px',
  //         width: '200px'
  //     },
  //     investorsTitle : {
  //         fontSize: '30px'
  //     }
  // }
}))
