import React from "react";
import { useLocation, Link } from "react-router-dom";
import Container from "@material-ui/core/Container";
import { useStyles } from '../../style.js';

const Consent = () => {
    const classes = useStyles();
    const location = useLocation();

    return (
        <section className={classes.contentSection}>
            <Container fixed className={classes.contentContainer}>
                <h4 className={classes.contentPageTitle}>HAUSCOIN, LLC CONSENT TO RECEIVE ELECTRONIC DISCLOSURES</h4>
                {location.hash !== "#print" &&
                  <div className={classes.contentText}><Link to="/consent-to-electronic-disclosure#print">print view</Link></div>
                }
                <div>Date of Last Revision: October 27, 2021</div>
                <div className={classes.contentText}>
                    In connection with obtaining services through the platform administered by HausCoin, LLC
                    (“HausCoin”, “we”, “us”), you consent to receive and view communications, disclosures, notices,
                    statements, policies, agreements and other communications we are required by law to provide to you
                    or may otherwise provide to you for any product or service you obtain from us (collectively,
                    “Disclosures”) relating to your eligible account on the HausCoin platform (“Account”) electronically
                    by any of the following means:
                    <ul className={classes.contentTextList}>
                        <li>
                            Text to your mobile phone number which may include a link to a new Disclosure on the
                            HausCoin website (“Website”);
                        </li>
                        <li>
                            To your email;
                        </li>
                        <li>
                            Notifications from our website (“Application”).
                        </li>
                    </ul>
                </div>
                <p className={classes.contentText}>
                    Your consent covers all Disclosures relating to any product we offer through the website at
                    hauscoin.com and remains in effect until you give us notice that you are withdrawing it. Delivery by
                    any of these means will constitute proper notice to you under applicable law.
                </p>
                <div className={classes.contentText}>
                    You acknowledge that Disclosures will include, but may not be limited to, the following:
                    <ul className={classes.contentTextList}>
                        <li>
                            The website, Privacy Policy, and Terms of Service and other documentation we provide through
                            the website (“Policies and Agreements”);
                        </li>
                        <li>
                            Disclosures and/or amendments we may provide you under our Policies and Agreements;
                        </li>
                        <li>
                            Balance, activity and any other information on your Account;
                        </li>
                        <li>
                            Periodic statements, receipts, confirmations, authorizations and transaction history for your Account;
                        </li>
                        <li>
                            Disclosures regarding the resolution of any claimed error on your periodic statements;
                        </li>
                        <li>
                            Disclosures required or permitted by law or regulation.
                        </li>
                    </ul>
                </div>
                <p className={classes.contentText}>
                    <span className={classes.contentTextSpan}>Your Right to Revoke Consent: </span>
                    Your consent is effective until further notice by us or until you revoke your consent to receive
                    electronic Disclosures. You may revoke your consent to receive electronic Disclosures at any time
                    either by clicking the “Contact Us” button at the bottom of this page or submitting your request in
                    writing to: Legal Department, HausCoin, LLC, 660 4th Street, #418, San Francisco, CA 94107. Your
                    withdrawal of consent will become effective after we have had a reasonable opportunity to act upon
                    it.
                </p>
                <p className={classes.contentText}>
                    If you do not consent or if you withdraw your consent, we reserve the right to refuse to accept your
                    Account application, to cancel your Account, place your Account on inactive status, or to provide a
                    paper copy of Disclosures. If you request a paper copy of a Disclosure within 180 days of the date
                    of the Disclosure and we elect to send you a paper copy, we will waive our standard Disclosure
                    Request Fee for the first two requests. After that, any additional Disclosure Requests may be
                    subject to fees. We will only provide paper copies upon your request if your current mailing address
                    is in your Account profile.
                </p>
                <div className={classes.contentText}>
                    <span className={classes.contentTextSpan}>System Requirements: </span>
                    In order to receive Disclosures, whether by text or email, you need to have a means of printing or
                    storing them. So, in addition to having and email address and phone number you must have the
                    following:
                    <ul className={classes.contentTextList}>
                        <li>
                            a computer or mobile device with Internet connection;
                        </li>
                        <li>
                            a current web browser with cookies enabled;
                        </li>
                        <li>
                            a valid email address on file in your Account profile;
                        </li>
                        <li>
                            the ability to store or print the Disclosures;
                        </li>
                        <li>
                            if you use a spam blocker, you must add info@HausCoin.com to your email address book or whitelist
                        </li>
                    </ul>
                </div>
                <p className={classes.contentText}>
                    By giving your consent you are confirming that you have access to the necessary equipment and are
                    able to receive, open, and print or download a copy of any Disclosure for your records. We reserve
                    the right to change these System Requirements and will provide you with a Disclosure when we make a
                    material change to the System Requirements.
                </p>
                <p className={classes.contentText}>
                    <span className={classes.contentTextSpan}>Receiving Texts and Emails: </span>
                    In order to receive Disclosures, you must ensure that the primary mobile phone and/or email address
                    that you provide us is your valid, current phone number or email address, and you are able to
                    receive at that address texts or email messages containing Disclosures including attached electronic
                    documents and that such Disclosures, including portions that are attached documents are available
                    for viewing and storing or printing by you. You agree to promptly update your email address by
                    updating your Account profile if your email address changes. You acknowledge that our ability to
                    notify you of the availability of your Disclosures is contingent on the validity of mobile phone
                    number and email address in our records. If your mobile phone or email address is no longer valid,
                    we reserve the right to determine your Account is inactive or take other actions as set forth in the
                    Terms of Service. You will not be able to conduct any transactions in your Account until you update
                    your mobile phone or email address in your Account profile.
                </p>
                <p className={classes.contentText}>
                    <span className={classes.contentTextSpan}>Reservation of Rights: </span>
                    We reserve the right to provide you with any Disclosure in writing, rather than electronically, or
                    to withdraw the right to receive Disclosures electronically at any time. You agree to maintain on
                    file with us your current street address and to promptly update your address in the event it changes
                    by updating your Account profile. Although we may waive our fee for delivery of paper Disclosures,
                    we reserve the right to charge the Disclosure Request Fee and to increase this fee in our
                    discretion.
                </p>
                <p className={classes.contentText}>
                    <span className={classes.contentTextSpan}>Communications in Writing: </span>
                    We recommend that you print a copy of this Consent and any Disclosure that you view electronically
                    for your records as the Disclosure may not be accessible online at a later date. All Disclosures
                    from us to you will be considered "in writing" and shall have the same meaning and effect as a paper
                    Disclosure. You acknowledge and agree that Disclosures are considered received by you within 24
                    hours of the time posted to the Website, or within 24 hours of the time emailed or sent via text to
                    you unless HausCoin receives notice that the Disclosure was not delivered.
                </p>
                <p className={classes.contentText}>
                    <span className={classes.contentTextSpan}>General: </span>
                    You understand and agree that we are responsible for sending the Disclosures to you electronically
                    by email or text to the address in your Account profile or through the Service. We are not
                    responsible for any delay or failure in your receipt of the email or text notices and whether or not
                    you choose to view the Disclosure, subject to your right to revoke your consent to receive
                    Disclosures electronically.
                </p>
                <p className={classes.contentText}>
                    <span className={classes.contentTextSpan}>Contact Us: </span>
                    Except as otherwise provided by law or other agreement between you and us, you may provide us with
                    notices regarding your Accounts by clicking the “Contact Us” link at the bottom of this page,
                    contacting info@hauscoin.com or mailing us at Legal Department, HausCoin, LLC, 660 4th Street, #418,
                    San Francisco, CA 94107.
                </p>
            </Container>
        </section>
    )
};

export default Consent;
