import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({

    footerWrapper : {
        backgroundColor: theme.palette.bgColor.black,
        padding: '60px 0 40px',
        fontFamily: theme.typography.fontFamily.bold,
    },
    footerContainer : {
        padding: '0 20px'
    },
    footer : {
        color: theme.palette.text.white
    },
    footerBlock : {
        display: 'flex',
        justifyContent: 'space-between'
    },
    footerForm : {
        display: 'flex',
        flexDirection: 'column'
    },
    footerFormTitle : {
        marginBottom: '14px',
        fontSize: theme.fontSize.text.semiHeader,
        lineHeight: '24px',
        letterSpacing: '-0.2px'
},
    footerInputField : {
        '&::after' : {
            content: '""',
            display: 'block',
            width: '100%',
            height: '12px',
            borderRight: '4px solid white',
            borderLeft: '4px solid white',
            borderBottom: '4px solid white',
        }
    },
    footerFormInput : {
        minWidth: '278px',
        backgroundColor: 'transparent',
        border: 'none',
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        outline: 'none',
        fontFamily: theme.typography.fontFamily.bold,
        fontWeight: theme.fontWeight.text.semiBold,
        lineHeight: '24px',
        letterSpacing: '-0.16px',
        fontSize: theme.fontSize.text.medium,
        padding: '0 15px',
        color: theme.palette.text.white,
        '&::placeholder': {
            color: theme.palette.text.grey
        }
    },
    footerFormBtn : {
        width: '130px',
        marginTop: '24px',
        fontFamily: theme.typography.fontFamily.black,
        fontSize: theme.fontSize.text.small,
        lineHeight: '16px',
        letterSpacing: '1.2px',
        textTransform: 'uppercase',
        backgroundColor: theme.palette.bgColor.orange,
        outline: 'none',
        border: 'none',
        padding: '20px 25px',
        color: theme.palette.text.white,
        cursor: 'pointer',
    },
    footerSocialBlock : {
        marginTop: '50px',
        marginBottom: '80px'
    },
    footerBlockSocials : {
        marginRight: '20px'
    },
    footerNav : {
        display: 'flex'
    },
    footerNavLists : {
        marginLeft: '100px'
    },
    footerNavList : {
        lineHeight: '20px',
        marginBottom: '27px',
        letterSpacing: '-0.16px',
        '&:first-child' : {
            fontSize: theme.fontSize.text.semiHeader,
            letterSpacing: '-0.2px'
        }
    },
    footerNavListItem : {
        fontSize: theme.fontSize.text.medium,
        borderBottom: '1px solid #fff',
        color: theme.palette.text.white
    },
    footerText : {
        fontWeight: theme.fontWeight.text.semiBold,
        lineHeight: '16px',
        fontSize: theme.fontSize.text.small,
        marginTop: 0,
        marginBottom: '38px'
    },
    footerIdBlock : {
        display: 'flex'
    },
    footerIdText : {
        marginLeft: '29px',
        fontSize: theme.fontSize.text.small
    },
    '@media (max-width: 1280px)': {
        footerWrapper : {
            padding: '60px 0px 40px',
        }
    },
    '@media (max-width: 1024px)': {
        footerNavLists : {
            marginLeft: '80px'
        },
        footerNavList : {
            fontSize: '18px'
        },
        footerNavListItem : {
            fontSize: '14px'
        }
    },
    '@media (max-width: 960px)': {
        footerBlock : {
            flexDirection: 'column'
        },
        footerNavLists : {
            margin: '0 auto'
        },
        footerText : {
            '&:first-child': {
                marginTop: '0'
            }
        }
    },
    '@media (max-width: 420px)': {
        footerWrapper : {
            padding: '40px 0 30px',
        },
        footerFormInput : {
            padding: '0 5px'
        },
        footerInputField : {
            width: '90%'
        },
        footerSocialBlock : {
            marginBottom: '50px'
        },
        footerBlockSocialsImg : {
            width: '15%'
        },
        footerNavList: {
            marginBottom: '10px',
            '&:first-child': {
                fontSize: '15px',
            }
        },
        footerNavListItem : {
            fontSize: '13px'
        },
        footerText : {
            marginTop: '20px',
            fontSize: '12px'
        }
    }
}))