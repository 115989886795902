import React from 'react';
import {useAppState} from "../../app-state";
import {isValidated} from "../../util/helpers";
import {NavLink} from "react-router-dom";
import {ROUTES} from "../../constans";
import ToolTipBtn from "../TooltipBtn/ToolTipBtn";
import house from "../../../assets/img/houseHeader.svg";
import LandingHeroSlider from "../LandingHeroSlider/LandingHeroSlider";
import ContentContainer from "../ContentContainer/ContentContainer";

import {useStyles} from "../LandingHero/style";

const LandingHero = ({buySellHandler, signInHandler}) => {
  const classes = useStyles();

  const { state, actions } = useAppState();

  function TooltipText(props) {
    return (
      <React.Fragment>
        To purchase HausCoin, validate your accreditation <a href={'/account'} className={classes.tooltipLink}>here</a>.
      </React.Fragment>
    );
  }

  return (
    <header className={classes.header}>
      <ContentContainer className={classes.homePageContainer}>
          <div className={classes.landingHeroContentInfo}>
            <h1 className={classes.headerTitle}>
              The smart way to invest in real estate
            </h1>
            <p className={classes.headerText}>
              Tokens backed 1:1 by home equity in the most attractive markets. Empowering affordable home ownership.
            </p>
            <div className={classes.landingHeroBtnWrapper}>
              {
                state.isAuth && !isValidated(state.currentUser) ?
                  <ToolTipBtn
                    className={classes.headerTooltip}
                    title={<TooltipText/>}
                    placement="top"
                  >
                    <a className={classes.headerBtn} onClick={buySellHandler}>
                      BUY
                    </a>
                  </ToolTipBtn> :
                  (
                    <a className={classes.headerBtn} onClick={signInHandler}>
                      BUY
                    </a>
                  )
              }
              <NavLink className={classes.landingHeroBtnLink} to={ROUTES.TOKENS}>View all cities</NavLink>
            </div>
          </div>
      </ContentContainer>
      <LandingHeroSlider />
    </header>
  );
};

export default LandingHero;
