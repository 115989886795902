import { uniqBy, sortBy } from "lodash";
import { getBackendURL } from 'common/config';
import BaseApiClient from 'common/lib/BaseApiClient';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import CONFIG from 'common/config';
import CONFIG_COIN from "../CoinConfig/CoinConfig";
import { formatTokenData } from "../lib/tokens";

const categoryOrder = {
  "General fund": 1,
  "Impact fund": 2,
  "CA": 3,
  "OR": 4,
  "WA": 5,
  "CO": 6,
};

export default class ApiClient extends BaseApiClient {
    constructor(redirectOnError) {
        const poolData = {
            UserPoolId: CONFIG.INVESTOR_POOL_ID,
            ClientId: CONFIG.INVESTOR_POOL_CLIENT_ID,
        };
        super(getBackendURL(), redirectOnError, new CognitoUserPool(poolData));
    }

    useUserId(investorID) {
        this.investorID = investorID;
    }

    async getHomeStats(coinType) {
        const url = `/home-stats?coin-type=${coinType}`;
        const response = await this.publicClient.get(url);
        return response.data;
    }

    async getActiveTokens() {
        const activeTokens = CONFIG_COIN.filter(coin => coin.active);
        const coinData = await this.getHomeStats(
            uniqBy(activeTokens.map(coin => coin.coinType)).join(',')
        );
        return sortBy(
          formatTokenData(coinData, CONFIG_COIN),
          coin => categoryOrder[coin.stateCode],
        );
    }

    async getLedgerRecords(filter, sort, page) {
        const coin = CONFIG_COIN.find(c => c.id === this.coinId);
        const url = `/ledger-records?page=${page}&coin-type=${coin.coinType}`;
        const response = await this.publicClient.post(url, {
            filter,
            sort,
        });

        return response.data;
    }

    async getLedgerRecord(recordId) {
        const url = `/ledger-records/${recordId}`;
        const response = await this.request({ url });
        return response.data;
    }

    async getLedgerRecordActivity(recordId, page = 1) {
        const url = `/ledger-records/${recordId}/activity?page=${page}`;
        const response = await this.request({ url });
        return response.data;
    }

    async getLedgerCityFilter() {
        const url = `/ledger-records/filter-options`;
        const response = await this.publicClient.get(url);
        return response.data;
    }

    /**
     * @typedef {Object} SellTransactionResponse
     * @property {string} status
     * @property {string} coinPrice
     * @property {string} coinsAmount
     * @property {string} type
     */

    /**
     * @typedef {Object} BuyTransactionResponse
     * @property {string} signUrl
     */

    /**
     * @param transactionType {TRANSACTION_TYPES}
     * @param coinsAmount {number}
     * @param coinPrice {number}
     * @param coinId {string}
     * @returns {Promise<SellTransactionResponse | BuyTransactionResponse>}
     */
    async createMarketplaceTransaction(transactionType, coinsAmount, coinPrice, coinId) {
        const coin = CONFIG_COIN.find(c => c.id === coinId);
        const url = '/marketplace/transactions';
        const response = await this.request({
            url,
            method: 'POST',
            data: {
                type: transactionType,
                coinsAmount,
                coinPrice,
                coinType: coin.coinType,
                transactable: coin.transactable,
            },
        });
        return response.data;
    }
    
    /**
     * @param email {string}
     * @param coinType {string}
     * @returns {Promise<Waitlist>}
     */
    async waitlistEnroll(email, coinType) {
        const url = '/waitlist';
        const response = await this.publicClient.request({
            url,
            method: 'POST',
            data: {
                email: email,
                coinType: coinType,
            },
        });
        return response.data;
    }

    /**
     * @typedef {Object} MarketplaceState
     * @property {string} coinsAmount
     * @property {string} coinPrice
     * @property {string} investorHoldings
     */

    /**
     * @param {string} coinId 
     * @returns {Promise<MarketplaceState>}
     */
    async getMarketplaceState(coinId = this.coinId) {
        // find coin based on id
        const coin = CONFIG_COIN.find(c => c.id === coinId);
        const url = `/marketplace/state?coin-type=${coin.coinType}`;
        const response = await this.request({ url });
        return response.data;
    }

    /**
     * @typedef {Object} InvestorDashboardData
     * @property {string} hausCoinEquityValue
     * @property {string} currentHoldings
     * @property {string} forecast
     * @property {string} hausCoinOwned
     * @property {string} equityValue
     * @property {string} averagePurchasePrice
     */

    /**
     * @returns {Promise<InvestorDashboardData>}
     */
    async getInvestorDashboard(coinType) {
        const url = `/investors/${this.investorID}/dashboard?coin-type=${coinType}`;
        const response = await this.request({ url });
        return response?.data;
    }

    /**
     * @param {number=} page
     * @param {string=} status - PENDING || APPROVED || CANCELED
     * @returns {Promise<*>}
     */
    async getInvestorActivity(page = 1, status) {
        let url = `/investors/${this.investorID}/activity?page=${page}`;
        if (status) {
            url = url + `&status=${status}`
        }

        const response = await this.request({ url });
        return response?.data;
    }

    async getGraph(coinType) {
      const url = `/investors/graph?coin-type=${coinType}`;
      const response = await this.publicClient.get(url);
      return response.data;
    }

    /** @returns {Promise<{ppmAcceptedAt}>} */
    async acceptPPM() {
        const url = `/investors/${this.investorID}/accept-ppm`;
        const response = await this.request({ url, method: 'POST' });
        return response.data;
    }

    // {
    //     id: 'some_id',
    //     firstName: 'Troll',
    //     lastName: 'Hunter',
    //     ppmAcceptedAt: null, // null - not accepted, some date - accepted at this date
    //     kycProviders: [
    //         {
    //             id: 'some_provider_id',
    //             platform: 'securitize',
    //             isVerified: true, // or false :)
    //             createdAt: 'date_when_record_was_created',
    //             updatedAt: 'date_when_record was_updated_or_verified',
    //         },
    //     ],
    //     bankAccount: {
    //         brand: 'visa',
    //         country: 'US',
    //         exp_month: 7,
    //         exp_year: 2022,
    //         last4: '1234',
    //     },
    // }

    async getCurrentInvestor() {
        const url = `/investors/${this.investorID}`;
        const response = await this.request({ url });
        return response?.data;
    }

    /**
     * @typedef {Object} RegisterData
     * @property {string} firstName
     * @property {string} lastName
     * @property {string} email
     */

    /**
     * @typedef {Object} RegisterRes
     * @property {string} message
     * @property {string} code
     */

    /**
     * @param {RegisterData} data
     * @returns {Promise<RegisterRes>}
     */
    async register(data) {
        const url = `/register`;
        try {
            const response = await this.publicClient.post(url, data);
            return { message: response.data, code: response.status };
        } catch (err) {
            if (err.response.status === 400) {
                return {
                    message: err.response.data,
                    code: err.response.status,
                };
            }
            throw err;
        }
    }

    /**
     * @typedef {Object} ResetPasswordData
     * @property {string} email
     */

    /**
     * @param {ResetPasswordData} data
     * @returns {Promise<RegisterRes>}
     */
    async resetPassword(data) {
        const url = `/reset-password`;
        try {
            const response = await this.publicClient.post(url, data);
            return { message: response.data, code: response.status };
        } catch (err) {
            if (err.response.status === 400) {
                return {
                    message: err.response.data,
                    code: err.response.status,
                };
            }
            throw err;
        }
    }

    /**
     * @typedef ConnectKYCData
     * @property {string} provider
     * @property {object} data
     * @property {string} data.code
     */

    /**
     *
     * @param {ConnectKYCData} data
     * @returns {Promise<*>}
     */
    async connectKYC(data) {
        const url = `/investors/${this.investorID}/kyc-providers`;
        const response = await this.request({
            url,
            method: 'POST',
            data,
        });
        return response.data;
    }

}
