import React, { useEffect, useMemo, useState } from 'react';
import closeBtn from '../../../assets/img/close-btn.svg';
import { useStyles } from './style.js';
import { useAppState } from '../../app-state';
import useApi from '../../hooks/useApi';
import { COIN_TYPE, TRANSACTION_TYPES } from '../../../common/constants';
import CircularProgress from '@material-ui/core/CircularProgress';
import { roundAndFormatNumber, roundFourAndFormatNumber } from '../../../common/util';
import { validateNumber } from '../../../common/validator';
import CONFIG from '../../../common/config';
import { getHelloSignTestMode } from '../../../common/config';
import { useStylesConfMsg } from './Messages/style';
import { ROUTES } from '../../constans';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import SvgIcon from '@material-ui/core/SvgIcon';
import Fade from '@material-ui/core/Fade';
import NumberInput from '../NumberInput/NumberInput';
import CONFIG_COIN from '../../CoinConfig/CoinConfig';

const ModalBuySell = () => {
    const classes = useStyles();

    const styles = useStylesConfMsg();

    const { state, actions } = useAppState();
    const [selectedOption, setSelectedOption] = useState(TRANSACTION_TYPES.BUY);
    const [selectedCoin, setSelectedCoin] = useState(localStorage.getItem('selectedCoinType'));
    const [buy, setBuy] = useState('');
    const [sell, setSell] = useState('');
    const [disabled, setDisabled] = useState(true);
    const [confirmBuy, setConfirmBuy] = useState(false);
    const [confirmSell, setConfirmSell] = useState(false);
    const [marketplaceState, setMarketplaceState] = useState();
    const [loading, setLoading] = useState(false);
    const api = useApi();
    const coins = CONFIG_COIN.filter((coin) => coin.active);

    useEffect(() => {
        api.getMarketplaceState(selectedCoin).then((data) => {
            setMarketplaceState(data);
        });
    }, []);

    const selectHandler = (e) => {
        const selected = e.target.value;
        setSelectedOption(selected);
    };

    const selectCoinHandler = (e) => {
        const coin = e.target.value;
        setSelectedCoin(coin);
        // update coin price
        api.getMarketplaceState(coin).then((data) => {
            setMarketplaceState(data);
        });
    };

    const changeBuyHandler = (value) => {
        if (value === '') {
            setBuy('');
            setDisabled(true);
            return;
        }

        const errMsg = validateNumber(value);
        if (errMsg) {
            setDisabled(true);
            return;
        }

        if (value <= parseFloat(marketplaceState.coinsAmount)) {
            setBuy(value);
            setDisabled(false);
            setConfirmBuy(false);
        }
    };

    const changeSellHandler = (value) => {
        if (value === '') {
            setSell('');
            setDisabled(true);
            return;
        }

        const errMsg = validateNumber(value);
        if (errMsg) {
            setDisabled(true);
            return;
        }

        if (value <= parseFloat(marketplaceState.coinsAmount)) {
            setSell(value);
            setDisabled(false);
            setConfirmSell(false);
        }
    };

    const clickBuyHandler = () => {
        setConfirmBuy(true);
    };

    const clickSellHandler = () => {
        setConfirmSell(true);
    };

    const cancelBuyHandler = () => {
        setConfirmBuy(false);
    };

    const cancelSellHandler = () => {
        setConfirmSell(false);
    };

    const closeMsgModal = () => {
        actions.updateState({ messages: null });
        client.close();
    };

    const confirmTransaction = async () => {
        setLoading(true);
        if (selectedOption === TRANSACTION_TYPES.BUY) {
            const coin = CONFIG_COIN.find((c) => c.id === selectedCoin);
            if (coin.transactable) {
                await confirmBuyingSignContract();
            } else {
                await confirmBuying();
            }
        } else {
            await confirmSelling();
        }
    };

    // confirm buying for waitlist
    const confirmBuying = async () => {
        const transaction = await api.createMarketplaceTransaction(
            selectedOption,
            buy,
            marketplaceState.coinPrice,
            selectedCoin,
        );
        actions.updateState({
            messages: (
                <div>
                    <h3>Purchase request received!</h3>
                    <p>
                        We have received your purchase request, but due to very high demand you are now in our Hauscoin
                        queue. As more tokens are made available, we will let you know.
                    </p>
                    <p>
                        Once your purchase request is approved, you will receive an email from Hauscoin to digitally
                        execute your purchase contract, and transfer funds. Thank you for joining us on our journey to
                        create a new way home!
                    </p>
                </div>
            ),
        });
        actions.updateState({ buySellActive: false });
    };

    // confirm buying for actual transaction with contract signing
    const confirmBuyingSignContract = async () => {
        const transaction = await api.createMarketplaceTransaction(
            selectedOption,
            buy,
            marketplaceState.coinPrice,
            selectedCoin,
        );
        actions.updateState({
            messages: (
                <div>
                    <h3>Almost done!</h3>
                    <p>
                        A subscription agreement has been sent to your email via HelloSign. Please fill out and sign all documents.
                    </p>
                    <p>
                        We have received your request to purchase {buy} HausCoin. Your purchase is pending your wire
                        transfer of ${roundAndFormatNumber(buy * marketplaceState?.coinPrice)}. Please follow the wiring
                        instructions below.
                    </p>
                </div>
            ),
            messagesCtaTo: ROUTES.INSTRUCTIONS,
            messagesCtaLabel: 'Wire Instructions',
        });
        actions.updateState({ buySellActive: false });
    };

    const confirmSelling = async () => {
        try {
            await api.createMarketplaceTransaction(selectedOption, sell, marketplaceState.coinPrice);
            actions.updateState({ messages: 'Request created!' });
        } catch (err) {
            console.error(err);
            actions.updateState({ messages: 'An error happened while creating the request!' });
        } finally {
            actions.updateState({ buySellActive: false });
            setLoading(false);
        }
    };

    function ArrowIcon(props) {
        return (
            <SvgIcon viewBox="0 0 16 13" {...props}>
                <path d="M0 0V5.3378L7.98336 13L16 5.4987V0.224299L8.01632 7.69406L0 0Z" fill="black" />
            </SvgIcon>
        );
    }

    return (
        <div>
            <Fade in={state.buySellActive} timeout={200}>
                <div className={classes.modalBuySell} style={{ padding: 0 }} onClick={(e) => e.stopPropagation()}>
                    <div className={classes.modalBuySellWrapper} onClick={(e) => e.stopPropagation()}>
                        <div
                            className={classes.modalBuySellCloseBtn}
                            onClick={() => actions.updateState({ buySellActive: false })}
                        >
                            <img className={classes.modalBuySellImg} src={closeBtn} alt="close" />
                        </div>
                        <div className={classes.modalBuySellInputBlock}>
                            <Box classes={{ root: classes.modalBuySellMUIBoxRoot }}>
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    classes={{ root: classes.modalBuySellMUIFormControlRoot }}
                                >
                                    <InputLabel
                                        classes={{ root: classes.modalBuySellMUIInputLabel }}
                                        id="buy-sell-label"
                                    >
                                        WHAT WOULD YOU LIKE TO DO?
                                    </InputLabel>
                                    <Select
                                        classes={{
                                            root: classes.modalBuySellMUISelectRoot,
                                            icon: classes.modalBuySellMUISelectIcon,
                                        }}
                                        MenuProps={{
                                            TransitionProps: {},
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            classes: { paper: classes.modalBuySellMUISelectDropdown },
                                            getContentAnchorEl: null,
                                            disableScrollLock: true,
                                        }}
                                        IconComponent={ArrowIcon}
                                        labelId="buy-sell-label"
                                        id="buy-sell-select"
                                        value={selectedOption}
                                        disabled={confirmSell}
                                        label="WHAT WOULD YOU LIKE TO DO?"
                                        onChange={selectHandler}
                                    >
                                        <MenuItem value={TRANSACTION_TYPES.BUY}>Buy</MenuItem>
                                        {/*<MenuItem value={TRANSACTION_TYPES.SELL}>Sell</MenuItem>*/}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box classes={{ root: classes.modalBuySellMUIBoxRoot }}>
                                <FormControl
                                    variant="standard"
                                    fullWidth
                                    classes={{ root: classes.modalBuySellMUICoinControlRoot }}
                                >
                                    <InputLabel classes={{ root: classes.modalBuySellMUIInputLabel }} id="select-token">
                                        Select a token
                                    </InputLabel>
                                    <Select
                                        classes={{
                                            root: classes.modalBuySellMUISelectRoot,
                                            icon: classes.modalBuySellMUISelectIcon,
                                        }}
                                        MenuProps={{
                                            TransitionProps: {},
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            },
                                            classes: { paper: classes.modalBuySellMUISelectDropdown },
                                            getContentAnchorEl: null,
                                            disableScrollLock: true,
                                        }}
                                        IconComponent={ArrowIcon}
                                        labelId="select-token"
                                        id="token-type"
                                        value={selectedCoin}
                                        // disabled={confirmSell}
                                        onChange={selectCoinHandler}
                                    >
                                        {coins.map((coin) => {
                                            return (
                                                <MenuItem
                                                    key={coin.id}
                                                    selected={coin.id === selectedCoin}
                                                    value={coin.id}
                                                >
                                                    <div className={classes.coinLogoWrapper}>
                                                        <img
                                                            className={classes.modalBuySellCityLogo}
                                                            src={coin.logo}
                                                            alt={coin.title}
                                                        />
                                                    </div>
                                                    {coin.detailTitle ? `${coin.stateCode}` : coin.title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                            <NumberInput
                                fieldClassName={classes.inputNumberWrapper}
                                labelText={'How many Hauscoin?'}
                                value={buy}
                                type={'number'}
                                placeholder={'0'}
                                disabled={!marketplaceState || confirmBuy}
                                onChange={(e) => changeBuyHandler(e.target.value)}
                            />
                            <div className={classes.modalBuySellValuesWrapper}>
                                <div className={classes.modalBuySellCurrent}>
                                    <div className={classes.modalBuySellInputLabel}>CURRENT PRICE PER HAUSCOIN</div>
                                    <div className={classes.modalBuySellTotalText}>
                                        ${' '}
                                        {roundFourAndFormatNumber(marketplaceState?.coinPrice) || (
                                            <CircularProgress size={10} color="inherit" />
                                        )}
                                    </div>
                                </div>
                                <div className={classes.modalBuySellTotal}>
                                    <div className={classes.modalBuySellInputLabel}>TOTAL COST</div>
                                    <div className={classes.modalBuySellTotalText}>
                                        $ {roundAndFormatNumber(buy * marketplaceState?.coinPrice || 0)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={confirmBuy ? classes.confirmBlockTransitioned : classes.confirmBlock}>
                            {loading ? (
                                <div
                                    className={
                                        loading
                                            ? classes.modalBuySellBtnContainerTransitioned
                                            : classes.modalBuySellBtnContainer
                                    }
                                >
                                    <button className={classes.loadingBtn}>
                                        <CircularProgress size={18} color="inherit" />
                                    </button>
                                </div>
                            ) : (
                                <>
                                    <button
                                        className={classes.modalConfirmBtn}
                                        type="submit"
                                        onClick={confirmTransaction}
                                    >
                                        Confirm
                                    </button>
                                    <button
                                        className={classes.modalConfirmCancelBtn}
                                        type="submit"
                                        onClick={cancelBuyHandler}
                                    >
                                        Cancel
                                    </button>
                                </>
                            )}
                        </div>
                        <div
                            className={
                                !confirmBuy
                                    ? classes.modalBuySellBtnContainerTransitioned
                                    : classes.modalBuySellBtnContainer
                            }
                        >
                            <button
                                disabled={disabled}
                                onClick={clickBuyHandler}
                                className={classes.modalBuySellCreatBtn}
                                type="submit"
                            >
                                BUY
                            </button>
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
    );
};
export default ModalBuySell;
