import React from 'react';
import {useStyles} from "./style";
import ContentContainer from "../../components/ContentContainer/ContentContainer";

const WhitepaperPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.whitepaperWrapper}>
      <ContentContainer>
        WhitepaperPage
      </ContentContainer>
    </div>
  );
};

export default WhitepaperPage;
