import { makeStyles } from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
    customTooltip: {
        fontSize: theme.fontSize.text.medium,
        fontWeight: theme.fontWeight.text.bold,
        fontFamily: theme.typography.fontFamily.bold,
        backgroundColor: theme.palette.bgColor.orange,
        lineHeight: '27px',
        padding: '17px 22px',
        boxSizing: 'border-box',
        borderRadius: 0
    },
    customArrow : {
        color: theme.palette.bgColor.orange
    },
}))