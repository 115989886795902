import React, {useEffect, useState} from 'react';
import Fade from "@material-ui/core/Fade";
import {useAppState} from "../../app-state";
import closeBtn from "../../../assets/img/close-btn.svg";
import emailLogo from "../../../assets/img/email-logo.svg";
import useApi from '../../hooks/useApi';

import {useStylesConfMsg} from "./Messages/style";
import {useStyles} from "./style";
import {useHistory} from "react-router-dom";
import {validateEmail} from "../../../common/validator";
import CircularProgress from '@material-ui/core/CircularProgress';
import CONFIG_COIN from "../../CoinConfig/CoinConfig";

const ModalWaitList = () => {
  const classes = useStyles();
  const styles = useStylesConfMsg();
  const {state, actions} = useAppState();
  const [email, setEmail] = useState('');
  const [isEmailValid, setEmailValid] = useState(false);
  const [resetEmailMsg, setResetEmailMsg] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const api = useApi();
  const selectedCoin = CONFIG_COIN.find(c => c.id === state.modalWaitListId);

  useEffect(() => {
    validateEmailHandler(false);
  }, [email])

  useEffect(() => {
    if (isLoading) {
      setIsDisabled(true);
      return;
    }
    setIsDisabled(!isEmailValid);
  }, [isEmailValid, isLoading])

  function validateEmailHandler(showErrorMsg= true) {
    const validationResetEmailMsg = validateEmail(email);

    if (validationResetEmailMsg) {
      showErrorMsg && setResetEmailMsg(validationResetEmailMsg);
      setEmailValid(false);
      return;
    }

    setResetEmailMsg(null);
    setEmailValid(true);
  }

  const onBlurHandler = (e) => {
    switch (e.target.name) {
      case 'email':
        validateEmailHandler();
        break;
    }
  };

  const resetWaitListHandler = (e) => {
    e.preventDefault();
    setIsLoading(true);
    // history.push(window.location.pathname);
    api.waitlistEnroll(email, selectedCoin.coinType).then(data => {
      setEmail('');
      setEmailValid(false);
      actions.updateState({
        modalWaitList: false,
        messages:
          <div className={classes.messagesWrapper}>
            <h3>You’re on the waitlist!</h3>
            <p className={styles.messagesWrapperText}>
              We have received your request and you are now on our
              Hauscoin waitlist. As we unlock new areas and new tokens, we will let you know!
            </p>
            <p className={styles.messagesWrapperText}>
              Thank you for joining us on our journey to create a new way home!
            </p>
          </div>
      })
    });
  }

  return (
    <div>
      <Fade in={state.modalWaitList} timeout={200}>
        <div
          className={classes.modalBuySell}
          style={{padding: 0}}
          onClick={e => e.stopPropagation()}>
          <div className={classes.modalWaitlistWrapper} onClick={e => e.stopPropagation()}>
            <div className={classes.modalBuySellCloseBtn}
                 onClick={() => actions.updateState({modalWaitList: false})}>
              <img className={classes.modalBuySellImg} src={closeBtn} alt='close'/>
            </div>
            <div className={classes.modalBuySellInputBlock}>
              <div className={classes.modalWaitListTitleWrapper}>
                <div className={classes.modalWaitListTitleImgWrapper}>
                  <img className={classes.modalWaitListTitleImg} src={selectedCoin.logo} alt={"logo"}/>
                </div>
                <div className={classes.modalWaitListTitle}>
                  {selectedCoin.title}, {selectedCoin.stateCode}
                  <span className={classes.modalWaitListTitleSpan}>
                    Coming Soon
                  </span>
                </div>
              </div>
              <div className={classes.modalWaitListText}>
                Be the first to know when this city goes live
              </div>
              <div className={classes.modalWaitListInputField}>
                <form className={classes.modalWaitListForm} onSubmit={resetWaitListHandler}>
                  <div className={classes.modalWaitListInputWrapper}>
                    <label className={classes.modalForgotPassInputLabel} htmlFor="email">
                      <input
                        className={classes.modalWaitListInput}
                        type="email"
                        name="email"
                        value={email}
                        autoComplete='off'
                        maxLength="255"
                        placeholder="Your email"
                        onChange={(e) => setEmail(e.target.value.replace(/\s/g, '').trim())}
                        onBlur={(e) => onBlurHandler(e)}
                        onKeyDown={(e) => {
                          if (e.key === ' ') {
                            e.preventDefault();
                          }
                        }}
                        required
                      />
                      <img className={classes.modalWaitListInputImg} src={emailLogo} alt={"email"} />
                    </label>
                  </div>
                  <div className={classes.wrapperForMsg}>
                    {!isEmailValid && <div className={classes.errMsg}>{resetEmailMsg}</div>}
                  </div>
                    {isLoading ?
                      <button className={classes.loadingBtn} >
                        <CircularProgress size={18} color='inherit'/>
                      </button>
                      :
                      <button
                        className={classes.modalWaitListBtn}
                        type="submit"
                        disabled={isDisabled}
                      >
                        Join the waitlist
                      </button>
                    }


                </form>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default ModalWaitList;
