import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    mainWrapper : {
        minHeight: 'calc(100vh - 1072px)',
    },

    // Content pages styling
    contentSection : {
        fontFamily: theme.typography.fontFamily.black,
        fontSize: theme.fontSize.text.semiHeader,
        padding: '120px 0 50px',
        color: theme.palette.text.main,
        backgroundColor: theme.palette.bgColor.white,
    },
    contentContainer: {
        padding: '0 20px'
    },
    contentPageTitle : {
        margin: '80px 0 60px',
        fontSize: theme.fontSize.text.main,
        textTransform: 'uppercase'
    },
    contentTitle : {
        margin: '10px 0'
    },
    contentText : {
        fontFamily: theme.typography.fontFamily.medium,
        fontSize: theme.fontSize.text.semiHeader,
        marginBottom: '30px'
    },
    contentTextTitle : {
        fontFamily: theme.typography.fontFamily.black,
        display: 'block',
    },
    contentTextList : {
        listStyle: 'outside',
        fontFamily: theme.typography.fontFamily.medium,
    },
    contentTextBold : {
        fontFamily: theme.typography.fontFamily.bold,
    },
    contentTextSpan : {
        fontFamily: theme.typography.fontFamily.black,
    },
    contentTableTitle : {
        fontSize: theme.fontSize.text.total,
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    contentTextUnderline : {
        textDecoration: 'underline'
    },
    '@media (max-width: 760px)': {
        contentSection : {
            padding: '80px 0 50px'
        }
    },
    '@media (max-width: 480px)': {
        contentSection : {
            fontSize: '16px'
        },
        contentPageTitle : {
            marginTop: '30px',
            fontSize: '32px',
            marginBottom: '15px'
        },
        contentText : {
            fontSize: '14px'
        }
    },
    '@media (max-width: 375px)': {
        contentPageTitle : {
            marginTop: '20px',
            marginBottom: '40px'
        },
    }
}))
