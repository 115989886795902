import React, { useEffect, useMemo, useState } from 'react';
import { useAppState } from 'marketplace/app-state';
import Container from '@material-ui/core/Container';
import NotActiveIcon from 'assets/img/notactive-icon.svg';
import ActiveIcon from 'assets/img/active-icon.svg';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import SecuritizeLogin from 'assets/img/securitize-login.png';
import ReviewPPM from './ReviewPPM';
import { format } from 'date-fns';
import { ACTION_TYPES, ROUTES } from 'marketplace/constans';
import useApi from 'marketplace/hooks/useApi';
import { useHistory } from 'react-router-dom';
import isEmpty from 'validator/es/lib/isEmpty';
import MessageModals from '../../components/Modal/Messages/MessageModals';
import CONFIG from 'common/config';
import CustomLink from "../../components/CustomLink/CustomLink";

import { useStyles } from './style.js';

const KYC_PLATFORMS = {
    SECURITIZE: 'securitize',
};

const KYC_URLS = {
    SECURITIZE: '#securitize',
};

const SECURITIZE_URL_PARAMS = {
    CODE: 'code',
    COUNTRY: 'country',
    AUTHORIZED: 'authorized',
};

const AccountSettingsPage = () => {
    const classes = useStyles();
    const { state, actions, dispatch } = useAppState();
    const [ showKYCProviders, setShowKYCProviders ] = useState(false);
    const [ showPPM, setShowPPM ] = useState(false);
    const [ isConnectingKYC, setIsConnectingKYC ] = useState(false);
    const api = useApi();
    const history = useHistory();

    useEffect(() => {
        if (!state.currentUser) {
            return;
        }
        if (isEmpty(history.location.hash)) {
            return;
        }
        const [ hash, search ] = history.location.hash.split('?');
        const searchParams = new URLSearchParams(search);

        switch (hash) {
            case KYC_URLS.SECURITIZE:
                connectSecuritize(searchParams);
                break;
        }
    }, [ history.location.search ]);

    useEffect(() => {
        actions.updateState({ modalSignInActive: false, isAuth: true });
        actions.fetchInvestorData();
    }, []);

    const connectSecuritize = async (params) => {
        setIsConnectingKYC(true);
        actions.updateState({ messages: 'Connecting to Securitize.io. Please wait...' });
        api.connectKYC({
            provider: KYC_PLATFORMS.SECURITIZE,
            data: {
                code: params.get(SECURITIZE_URL_PARAMS.CODE),
            },
        }).then(kycData => {
            actions.fetchInvestorData()
                .catch(() => {
                    const updatedUser = {
                        ...state.currentUser,
                        kycProviders: [ ...state.currentUser.kycProviders, kycData ],
                    };
                    actions.updateState({ currentUser: updatedUser });
                })
                .finally(() => {
                    actions.updateState({ messages: null });
                });
        }).catch(err => {
            actions.updateState({ messages: 'An error happened while connecting to Securitize.io. Please try again later.' });
            console.error('Could not connect Securitize', err);
        }).finally(() => {
            setIsConnectingKYC(false);
            history.push(ROUTES.SETTINGS);
        });
    };

    const validatedKYC = useMemo(() => {
        return state.currentUser.kycProviders.find(kyc => kyc.isVerified);
    }, [ state.currentUser.kycProviders ]);

    const generateSecuritizeURL = (registration = false) => {
        const issuerID = CONFIG.SECURITIZE_KEY;
        const scope = 'info details verification';
        const redirecturl = window.location.origin + '/account#securitize';

        const base = `${CONFIG.SECURITIZE_BASE_URL}#/authorize?`;
        const params = `issuerId=${issuerID}&scope=${scope}&redirecturl=${redirecturl}`;

        if (registration) {
            return `${base}registration=true&${params}`;
        }
        return `${base}${params}`;
    };

    const renderKYCBlock = () => {
        if (validatedKYC) {
            return (
                <p className={classes.settingsStatus}>
                    VALIDATED {format(new Date(validatedKYC.createdAt), 'LL/d/yyyy')}
                </p>
            );
        }

        if (state.currentUser.kycProviders.length > 0) {
            return (
                <>
                    <p className={classes.validationText}>
                        Your Securitize.io account has been successfully created. To continue the validation process, please
                        complete the following sections in your Securitize.io profile:
                    </p>
                    <p className={classes.validationText}>
                        <a className={classes.validationLink} target="_blank" href={`${CONFIG.SECURITIZE_BASE_URL}/#/profile/verification/type`}>Investor Details</a>
                        <div></div>
                        <a className={classes.validationLink} target="_blank" href={`${CONFIG.SECURITIZE_BASE_URL}/accreditation`}>Accreditation/Qualification</a>
                    </p>
                    <p className={classes.validationText}>
                        Once complete, the validation process can take 24 - 48 hours. 
                    </p>
                    <a className={classes.settingsBtn} href={generateSecuritizeURL()}>
                        CHECK VALIDATION STATUS
                    </a>
                </>
            );
        }

        return (
            <div>
                <p className={classes.validationText}>
                    HausCoin uses Securitize.io to validate identity and accreditation status. This process can take 24 - 48
                    hours. The process is handled directly through Securitize, and once complete, will reflect a verified
                    status in your HausCoin account.
                </p>

                <a className={classes.settingsBtn} href={generateSecuritizeURL(true)}>
                    VALIDATE
                </a>
            </div>
        );
    };

    return (
        <>
            <section className={classes.settings}>
                <Container fixed className={classes.settingsContainer}>
                    <div className={classes.settingsWrapper}>
                        <h6 className={classes.settingsBlockTitle}>ACCREDITED INVESTOR VALIDATION</h6>
                        <div className={classes.settingsBigTitleWrapper}>
                            <h4 className={classes.settingsBigTitle}>
                                Validate your credentials
                            </h4>
                            <img className={classes.settingsImg}
                                 src={!!validatedKYC ? ActiveIcon : NotActiveIcon}
                                 alt='active or not active' />
                        </div>
                      {renderKYCBlock()}
                    </div>
                </Container>
            </section>
            <section className={classes.review}>
                <Container fixed className={classes.settingsContainer}>
                    <div className={classes.settingsWrapper}>
                        <h6 className={classes.settingsBlockTitle}>Private Placement Memorandum</h6>
                        <div className={classes.settingsBigTitleWrapper}>
                            <h4 className={classes.settingsBigTitle}>
                                Review PPM
                            </h4>
                            <img className={classes.settingsImg}
                                 src={state.currentUser?.ppmAcceptedAt ? ActiveIcon : NotActiveIcon}
                                 alt='acive or not active' />
                        </div>
                        {!state.currentUser?.ppmAcceptedAt
                            ? <a className={classes.settingsBtn} onClick={() => setShowPPM(true)}>VIEW</a>
                            : <p className={classes.settingsStatus}>
                                REVIEWED {format(new Date(state.currentUser?.ppmAcceptedAt), 'LL/d/yyyy')}
                                <CustomLink className={classes.settingsStatusLink} onClick={() => setShowPPM(true)}>(VIEW)</CustomLink>
                            </p>}
                    </div>
                </Container>
            </section>
            {/*<section className={classes.settings}>*/}
            {/*    <ContentContainer fixed className={classes.settingsContainer}>*/}
            {/*        <div className={classes.settingsWrapper}>*/}
            {/*            <h6 className={classes.settingsBlockTitle}>ACCREDITED INVESTOR VALIDATION</h6>*/}
            {/*            <div className={classes.settingsBigTitleWrapper}>*/}
            {/*                <h4 className={classes.settingsBigTitle}>*/}
            {/*                    Link your bank account*/}
            {/*                </h4>*/}
            {/*                <img className={classes.settingsImg}*/}
            {/*                     src={state.currentUser.bankAccount ? ActiveIcon : NotActiveIcon}*/}
            {/*                     alt='active or not active' />*/}
            {/*            </div>*/}
            {/*            {state.currentUser?.bankAccount*/}
            {/*                ? <p className={classes.settingsStatus}>LINKED BANK OF AMERICA XXXXXX4223</p>*/}
            {/*                : <a className={classes.settingsBtn}*/}
            {/*                     href={() => showError('Temporary disabled')}>LINK</a>}*/}
            {/*        </div>*/}
            {/*    </ContentContainer>*/}
            {/*</section>*/}
            {showKYCProviders && (
                <Dialog
                    open={showKYCProviders}
                    onClose={() => {
                        setShowKYCProviders(false);
                    }}>
                    <DialogTitle>Select KYC provider</DialogTitle>
                    <DialogContent>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}>
                            <a href={generateSecuritizeURL()}>
                                <img
                                    src={SecuritizeLogin}
                                    alt='Securitize Login'
                                    height={48}
                                />
                            </a>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setShowKYCProviders(false);
                            }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}

            {showPPM && <ReviewPPM
                open={showPPM}
                onClose={() => setShowPPM(false)}
                ppmAcceptedAt={state.currentUser?.ppmAcceptedAt}
                onPPMAccepted={() => {
                    api.getCurrentInvestor().then(investorData => {
                        dispatch({
                            type: ACTION_TYPES.UPDATE_STATE,
                            payload: { currentUser: investorData },
                        });
                    });
                }}
            />}
            <MessageModals />
        </>
    );
};

export default AccountSettingsPage;
