import React from 'react';
import clsx from 'clsx';
import { useStyles } from '../style';

const TabItem = ({ isActive, onClick, children }) => {
    const classes = useStyles();

    return (
        <li
            onClick={onClick}
            className={clsx(classes.adminTabsHeader, {
                [classes.adminTabsActive]: isActive,
            })}
        >
            {children}
        </li>
    );
};

export default React.memo(TabItem);
