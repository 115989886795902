import { useContext } from 'react';
import { AdminStateContext } from '../providers/GlobalState';

/**
 * @returns {ApiAdmin}
 */
export default function useApi() {
    const { apiClient } = useContext(AdminStateContext);
    return apiClient;
}
