import React, { useEffect } from 'react';
import useComplexState from 'common/hooks/use-complex-state';
import { useCustomSnackbar } from 'common/hooks/custom-snackbars';
import { useStyles } from '../style';
import useApi from 'admin/hooks/useApi';
import ViewMoreButton from 'admin/components/ViewMoreButton';
import EventDataPopup from 'admin/components/EventDataPopup';
import EventItem, { EventItemPreloader } from 'admin/components/EventItem';

const preloaderItems = [1, 2, 3];

const initState = {
    page: 1,
    records: [],
    hasMore: false,
    recordsLeft: 0,
    isLoading: false,
    isLoadingMore: false,
    selectedEvent: null,
};
const EventsTab = () => {
    const [state, setState] = useComplexState(initState);
    const api = useApi();
    const { showError } = useCustomSnackbar();
    const classes = useStyles();

    useEffect(() => {
        setState({ [state.page > 1 ? 'isLoadingMore' : 'isLoading']: true });
        api.getEvents(state.page)
            .then((data) => {
                setState({
                    hasMore: data.hasMore,
                    recordsLeft: data.recordsLeft,
                    records: state.records?.length ? state.records.concat(data.records) : data.records,
                });
            })
            .catch((err) => showError('Could not get pending transactions', err))
            .finally(() => {
                setState({ [state.page > 1 ? 'isLoadingMore' : 'isLoading']: false });
            });
    }, [state.page]);

    const showSelectedEventData = (event) => {
        setState({ selectedEvent: event });
    };

    return (
        <div>
            <table className={classes.adminTable} frame="none" rules="none" cellPadding="25">
                <tbody>
                    <tr className={classes.adminTableTr}>
                        <th className={classes.adminTableTrTitle}>
                            <div className={classes.adminTableTrTitleWrapper}>Type</div>
                        </th>
                        <th className={classes.adminTableTrTitle}>
                            <div className={classes.adminTableTrTitleWrapper}>Status</div>
                        </th>
                        <th className={classes.adminTableTrTitle}>
                            <div className={classes.adminTableTrTitleWrapper}>HausCoin</div>
                        </th>
                        <th className={classes.adminTableTrTitle}>
                            <div className={classes.adminTableTrTitleWrapper}>Price</div>
                        </th>
                        {/*<th className={classes.adminTableTrTitle}>*/}
                        {/*    <button*/}
                        {/*        onClick={() => {}}*/}
                        {/*        className={clsx(classes.adminTableTrChildBtn, classes.eventsFilterBtn)}*/}
                        {/*    >*/}
                        {/*        Filter*/}
                        {/*    </button>*/}
                        {/*</th>*/}
                    </tr>
                    {state.isLoading && preloaderItems.map((item) => <EventItemPreloader key={item} />)}
                    {!state.isLoading &&
                        state.records.map((event) => (
                            <EventItem key={event.id} data={event} onClick={showSelectedEventData} />
                        ))}
                </tbody>
            </table>
            {!state.isLoading && !state.records?.length && (
                <div className={classes.noRecordsLabel}>
                    <span>No Records</span>
                </div>
            )}
            {state.hasMore && (
                <ViewMoreButton
                    isLoading={state.isLoadingMore}
                    recordsLeft={state.recordsLeft}
                    onClick={() => setState({ page: state.page + 1 })}
                />
            )}
            {state.selectedEvent && (
                <EventDataPopup event={state.selectedEvent} onClose={() => setState({ selectedEvent: null })} />
            )}
        </div>
    );
};

export default React.memo(EventsTab);
