import { useContext } from 'react';
import { AppContext } from '../app-state';

/**
 * @returns {ApiClient}
 */
export default function useApi() {
    const { apiClient } = useContext(AppContext);
    return apiClient;
}
