import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        flexDirection: 'column',
    },
    eventContent: {
        minWidth: '400px',
        maxWidth: '1000px',
        maxHeight: '600px',
        overflowY: 'scroll',
    },
    popupHeader: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
    },
    eventTitle: {},
}));
