import React from 'react';
import Skeleton from "@material-ui/lab/Skeleton";
import {useStyles} from "./style";
import { useStyles as useSkeletonStyles } from "../Skeleton/style";
import CardGraph from "../Сharts/CardGraph";
import RenderTag from "../RenderTag/RenderTag";
import {ROUTES} from "../../constans";
import { useHistory, Link } from "react-router-dom";
import { useAppState } from 'marketplace/app-state';

const Card = ({
  id,
  cityLogo,
  alt,
  city,
  usState,
  stateCode,
  percent,
  circulation,
  coinCost,
  title,
  tag,
  price,
  graphColumns,
  dates,
  active,
}) => {
  const classes = useStyles();
  const skeletonClasses = useSkeletonStyles();
  const {state, actions} = useAppState();
  const history = useHistory();
  const direction = percent < 0 ? "down" : "up";
  const description = `${alt} equity value has gone ${direction} ${Math.abs(percent)}% over the past ${price.length} months.`

  const showSkeleton = (!coinCost || !price) && active;

  return (
    <div className={classes.card}>
      <Link to={`${ROUTES.CARD}/${id}`}>
        <div className={classes.cardTitle}>
            <div className={`${classes.cardTitleImgWrapper} ${!active ? classes.notActiveImg : null}`}>
              <img className={classes.cardTitleImg} src={cityLogo} alt={alt} />
            </div>
            <div className={classes.cardTitleText}>{city}<span className={classes.cardTitleTextSpan}>{stateCode}</span></div>
            {tag && <RenderTag tag={tag} />}
        </div>
      </Link>
      {!showSkeleton && active && <div className={classes.cardValuesWrapper}>
        <div className={classes.cardValuesBig}>{`${percent > 0 ? "+" : ""}${percent}%`}</div>
        <div className={classes.cardValuesSmall}>{coinCost}</div>
      </div>}
      {showSkeleton && <div className={skeletonClasses.skeletonCardValuesWrapper}>
        <Skeleton variant="rect" animation="wave" width={"50%"} height={"100%"} />
        <Skeleton variant="rect" animation="wave" width={"40%"} height={"50%"} />
      </div>}
      {!active && <div className={classes.cardComingSoon}>Coming <br/> Soon!</div>}
      {!showSkeleton && active && <div className={classes.cardDescription}>
        {description}
      </div>}
      {showSkeleton && <div className={skeletonClasses.skeletonCardDescription}>
        <Skeleton variant="rect" animation="wave" width={"100%"} height={"100%"} />
      </div>}
      <div className={classes.cardGraphWrapper}>
        {active && <CardGraph price={price} circulation={circulation} columns={price.length} dates={dates} />}
      </div>
      <div className={classes.cardGraphDescription} style={{visibility: !active ? 'hidden' : null}}>
        <div className={classes.cardGraphDescriptionText}>last {price.length} months</div>
        <div className={classes.cardGraphDescriptionText}><span className={classes.cardGraphDescriptionLine}></span>equity price</div>
        <div className={classes.cardGraphDescriptionText}><span className={classes.cardGraphDescriptionLine}></span>hauscoin issued</div>
      </div>
    </div>
  );
};

export default Card;
