import React from 'react';
import clsx from 'clsx';
import closeBtn from '../../../../assets/img/close-btn.svg';
import { useAppState } from '../../../app-state';
import Fade from '@material-ui/core/Fade';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import { useStyles } from './style.js';

const MessageModals = () => {
    const classes = useStyles();
    const { state, actions } = useAppState();
    const history = useHistory();

    const FailedMsgCloseHandler = () => {
        actions.updateState({ messages: null, messagesCtaTo: null, messagesCtaLabel: null, modalWaitList: false });
        history.push(window.location.pathname);
    };

    return (
        <div>
            <Fade in={!!state.messages} timeout={200}>
                <div
                    className={clsx(classes.modalConfirmMsg, {
                        [classes.showModalConfirmMsg]: state.messages,
                    })}
                    style={{ padding: 0 }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={classes.modalConfirmMsgWrapper} onClick={(e) => e.stopPropagation()}>
                        <div className={classes.modalConfirmMsgCloseBtn} onClick={FailedMsgCloseHandler}>
                            <div className={classes.modalConfirmMsgImgBlock}>
                                <img className={classes.modalConfirmMsgImg} src={closeBtn} alt="close" />
                            </div>
                        </div>
                        <div className={classes.modalConfirmMsgBlock}>
                            <div className={classes.modalConfirmMsgText}>{state.messages}</div>
                            {state.messagesCtaTo && state.messagesCtaLabel && (
                                <NavLink
                                    className={classes.modalRequestBtn}
                                    to={state.messagesCtaTo}
                                    onClick={FailedMsgCloseHandler}
                                >
                                    {state.messagesCtaLabel}
                                </NavLink>
                            )}
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
    );
};

export default React.memo(MessageModals);
