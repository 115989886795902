import React, {memo, useEffect, useState} from 'react';
import {Bar} from 'react-chartjs-2';

import {useStyles} from './style.js';

const ValueGraph = ({valueGraph}) => {
  const classes = useStyles();
  const [circulationData, setCirculationData] = useState({
    labels: [],
    datasets: []
  });

  const dashGraph = () => {
    const labels = [];
    const coinsValue = [];

    valueGraph?.history?.forEach(cv => {
      const date = new Date(cv.date);
      labels.push(date.toLocaleString('default', {month: 'long', year: 'numeric'}));
    });

    valueGraph?.history?.forEach(vl => {
      coinsValue.push(vl.value);
    });

    function lastPoint(radius, length, coinsValue) {
      const result = coinsValue || [radius];
      while (result.length < length) result.unshift(0);
      return result;
    }

    setCirculationData({
      labels,
      datasets: [
        {
          type: 'line',
          label: 'Value',
          data: coinsValue,
          backgroundColor: ['rgba(151, 151, 151, 100)'],
          pointRadius: lastPoint(5, coinsValue.length),
        },
      ],
    });
  };

  useEffect(() => {
    dashGraph();
  }, [valueGraph]);

  return (
    <div className={classes.landingGraphsWrapper}>
      <Bar data={circulationData}
           options={
             {
               plugins: {
                 legend: {
                   display: false
                 },
                 tooltip: {
                   enabled: true,
                   cornerRadius: '0',
                   events: ['click'],
                   callbacks: {
                     label: function (tooltipItem) {
                       let roundValue = tooltipItem.raw;
                       return '$' + Math.round(roundValue * 10000) / 10000;
                     },
                   }
                 },
               },
               responsive: true,
               maintainAspectRatio: false,
               scaleStartValue: 1,
               scales: {
                 x: {
                   ticks: {
                     display: false,
                     beginAtZero: false,
                   },
                   grid: {
                     display: false,
                     borderWidth: 3,
                     borderColor: '#000000',
                   },
                 },
                 y: {
                   beginAtZero: false,
                   min: 1,
                   ticks: {
                     display: false,
                   },
                   grid: {
                     display: false,
                     borderWidth: 3,
                     borderColor: '#000000',
                   },
                 },
               },
             }
           }
      />
    </div>
  );
};

export default memo(ValueGraph);
