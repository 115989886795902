import React from 'react';
import {NavLink, useLocation, matchPath} from 'react-router-dom';
import clsx from 'clsx';
import { useStyles } from './style';

const NavBarItem = ({ visible, label, routePath, iconImage, href }) => {
    const classes = useStyles();
    const location = useLocation();
    const path = location.pathname;
    const matchUrl = matchPath(path, { path: routePath });


    let linkElement;
    if (!visible) {
        return null;
    }

    if (routePath) {
      linkElement = (
        <NavLink
            to={routePath}
            className={classes.navMenuListItem}
            activeClassName={clsx({
                [classes.activePageBlack]: matchUrl?.isExact})}
        >
            {label}
            {iconImage && <img src={iconImage} />}
        </NavLink>
      )
    } else {
      linkElement = (
      <a
          href={href}
          className={classes.navMenuListItem}
          target='_blank'
      >
          {iconImage && <img src={iconImage} style={{ marginRight: '5px', position: 'relative', top: '3px'}} />}
          {label}
      </a>
      )
    }

    return (
      <li className={classes.navMenuList}>
        {linkElement}
      </li>
    );
};

export default React.memo(NavBarItem);
