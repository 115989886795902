import {makeStyles} from '@material-ui/core/styles';
import selectArrow from '../../../assets/img/select-arrow.svg';


export const useStyles = makeStyles((theme) => ({
  ledger: {
    backgroundColor: theme.palette.bgColor.white,
    padding: '143px 0 120px'
  },
  ledgerContainer: {
    position: 'relative',
    padding: '0 20px'
  },
  ledgerCoinBtns: {
    marginBottom: '20px',
  },
  ledgerTableTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  ledgerTitle: {
    margin: '40px 0 0',
    fontSize: theme.fontSize.text.semiMain,
    fontFamily: theme.typography.fontFamily.black,
  },
  ledgerTitleSpan: {
    color: theme.palette.text.grey
  },
  ledgerTable: {
    width: '100%',
    marginTop: '80px',
    textAlign: 'left',
  },
  ledgerTableTr: {
    borderBottom: '3px solid black',
    // whiteSpace: 'nowrap'
  },
  ledgerTableTrTitle: {
    letterSpacing: '1.2px',
    padding: '25px 0',
    fontSize: theme.fontSize.text.small,
    textTransform: 'uppercase',
    fontFamily: theme.typography.fontFamily.black,
  },
  ledgerTableTrProperty: {
    width: '475px',
    minWidth: '70px'
  },
  ledgerTableTrTitleSecond: {
    width: '200px',
    minWidth: '45px'
  },
  ledgerTableTrTitleAppreciation: {
    width: '200px',
    minWidth: '40px'
  },
  ledgerTableTrTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  ledgerTableSortImg: {
    marginLeft: '7px'
  },
  ledgerTableTrChild: {
    borderBottom: '1px solid #979797',
    fontSize: theme.fontSize.text.semiHeader,
  },
  ledgerTableTd: {
    padding: '25px 0',
    fontFamily: theme.typography.fontFamily.medium,
  },
  ledgerTableTdTag: {
    position: 'relative',
    width: 'fit-content',
  },
  ledgerTableTrChildSpan: {
    display: 'block',
    marginTop: '8px',
    color: theme.palette.text.grey,
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.small,
    letterSpacing: '1.2px',
    textTransform: 'uppercase'
  },
  ledgerTableTdBtn: {
    minWidth: '40px',
    padding: '25px 0',
    fontFamily: theme.typography.fontFamily.medium,
    textAlign: 'right'
  },
  hideLedgerTableTdBtn: {
    visibility: 'hidden'
  },
  ledgerTableTrChildBtn: {
    color: theme.palette.text.main,
    backgroundColor: 'transparent',
    border: '1px solid #979797',
    padding: '15px 35px',
    letterSpacing: '1.2px',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.small,
    cursor: 'pointer',
    transition: 'all .8s ease',
    '&:hover': {
      backgroundColor: '#E8E8E8'
    }
  },
  '@media (max-width: 1280px)': {
    ledgerTable: {
      marginTop: '60px'
    },
  },
  '@media (max-width: 960px)': {
    ledger: {
      padding: '120px 0 60px'
    },
    ledgerTitle: {
      fontSize: '32px',
      margin: '10px 0 0 0'
    },
    ledgerTable: {
      marginTop: '40px'
    },
    ledgerTableTrTitle: {
      letterSpacing: '1px',
      padding: '10px 0',
      fontSize: '10px'
    },
    ledgerTableSortImg: {
      marginLeft: '4px'
    },
    ledgerTableTd: {
      padding: '10px 0',
      fontSize: '18px'
    },
    ledgerTableTrChildSpan: {
      fontSize: '8px',
      marginTop: '4px'
    },
    ledgerTableTdBtn: {
      padding: '15px 0'
    },
    ledgerTableTrChildBtn: {
      padding: '8px 20px',
      letterSpacing: '1px',
    }
  },
  '@media (max-width: 600px)': {
    ledgerTableTrChildBtn: {
      letterSpacing: 'initial',
    }
  },
  '@media (max-width: 480px)': {
    ledger: {
      padding: '94px 0 50px'
    },
    ledgerTableMob: {
      width: '100%',
      overflow: 'scroll',
      marginTop: '40px',
      paddingBottom: '20px'
    },
    ledgerTable: {
      marginTop: '0',
    },
    ledgerTableSortImg: {
      width: '10px',
    },
    ledgerTableTrTitle: {
      padding: '10px 20px 10px 0',
      letterSpacing: '1px',
      fontSize: '10px',
      whiteSpace: 'nowrap'
    },
    ledgerTableTd: {
      padding: '15px 20px 10px 0',
      whiteSpace: 'nowrap'
    },
    ledgerTableTrChildSpan: {
      fontSize: '10px',
      marginTop: '5px',
      whiteSpace: 'nowrap'
    },
    ledgerTableTrChildBtn: {
      padding: '8px 15px',
      fontSize: '12px'
    },
    ledgerTableTdBtn: {
      padding: '15px 0'
    }
  },
  '@media (max-width: 380px)': {
    ledgerTitle: {
        fontSize: '32px'
    },
  },

  // LANDSCAPE ORIENTATION
  '@media (max-width: 815px) and (orientation: landscape)': {
    ledgerTitle: {
      // fontSize: '20px'
    },
    ledgerTableTrTitle: {
      letterSpacing: '1px',
      padding: '2px 10px',
      fontSize: '10px'
    },
    ledgerTableTd: {
      fontSize: '18px',
      padding: '2px 10px'
    },
    ledgerTableTrChildSpan: {
      fontSize: '10px',
      marginTop: '5px',
      whiteSpace: 'nowrap'
    },
    ledgerTableTrChildBtn: {
      padding: '5px 10px',
      fontSize: '10px'
    },
    ledgerTableTdBtn: {
      padding: '15px 0'
    }
  },
  '@media (max-width: 670px) and (orientation: landscape)': {
    // ledger : {
    //     padding: '130px 0 80px'
    // },
    ledgerTableMob: {
      height: '370px',
      width: '100%',
      overflow: 'scroll',
      marginTop: '40px'
    },
    ledgerTable: {
      marginTop: '0'
    },
  }
}))
