import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Footer from 'common/components/Footer';
import HomePage from './pages/HomePage/HomePage';
import NavBar from 'marketplace/components/NavBar';
import AboutPage from './pages/AboutPage/AboutPage';
import ContactPage from './pages/ContactPage/ContactPage';
import LedgerPage from './pages/LedgerPage/LedgerPage';
import ModalCreateAcc from './components/Modal/ModalCreateAcc';
import ModalBuySell from './components/Modal/ModalBuySell';
import ModalWaitList from "./components/Modal/ModalWaitlist";
import ModalSignIn from 'marketplace/components/Modal/ModalSignIn';
import ModalForgotPass from './components/Modal/ModalForgotPass';
import DashboardPage from './pages/DashboardPage/DashboardPage';
import PropertyPage from './pages/PropertyPage/PropertyPage';
import AccountSettingsPage from './pages/AccountSettingsPage/AccountSettingsPage';
import AdminPage from '../admin/pages/AdminPage/AdminPage';
import InstructionsPage from "./pages/InstructionsPage/InstructionsPage";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import Consent from "./pages/Consent/Consent";
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TokensPage from "./pages/TokensPage/TokensPage";
import FaqPage from "./pages/FaqPage/FaqPage";
import CardPage from "./pages/CardPage/CardPage";
import WhitepaperPage from "./pages/WhitepaperPage/WhitepaperPage";
import PrivatRouter from '../common/components/PrivateRouter';
import ScrollToTop from '../common/components/ScrollToTop';
import MessageModals from './components/Modal/Messages/MessageModals';
import ModalConfirmUser from './components/Modal/ModalConfirmUser';

import { ROUTES } from './constans';
import { useStyles } from './style.js';
import { useAppState } from './app-state';


function App() {
    const { state, actions, cognitoClient } = useAppState();
    const classes = useStyles();
    const location = useLocation();
    useEffect(() => {
        cognitoClient.restoreSession()
            .then(({ user }) => {
                actions.updateState({
                    currentUser: user,
                    isAuth: true,
                    modalSignInActive: false,
                    modalConfirmUserActive: false,
                });
                actions.fetchInvestorData();
            })
            // if session has expired, 
            .catch(err => {
                actions.updateState({
                    currentUser: null,
                    isAuth: false,
                });
            });
    }, []);

    // TODO: Refactor flags names of modals
    return (
            <>
            {state.modalActive && <ModalCreateAcc />}
            {state.buySellActive && <ModalBuySell />}
            {state.modalForgotPass && <ModalForgotPass />}
            {state.modalSignInActive && <ModalSignIn />}
            {state.messages && <MessageModals />}
            {state.modalConfirmUser && <ModalConfirmUser />}
            {state.modalWaitList && <ModalWaitList />}
            {location.hash !== "#print" && <NavBar />  /* don't show navbar in print views */}
            <ScrollToTop />
            <div className={classes.mainWrapper}>
                <Switch>
                    <Route exact path={ROUTES.HOME}  component={HomePage} />
                    <Route path={ROUTES.TOKENS}  component={TokensPage} />
                    <Route path={`${ROUTES.CARD}/:id`} component={CardPage} />
                    <Route path={ROUTES.FAQ}  component={FaqPage} />
                    <Route path={ROUTES.ABOUT} component={AboutPage} />
                    <Route path={ROUTES.CONTACT} component={ContactPage} />
                    <Route path={ROUTES.POLICY} component={PrivacyPolicy} />
                    <Route path={ROUTES.TERMSOFSERV} component={TermsOfService} />
                    <Route path={ROUTES.CONSENT} component={Consent} />
                    <Route path={ROUTES.WHITEPAPER} component={WhitepaperPage} />
                    <Route exact path={ROUTES.LEDGER} component={LedgerPage} />
                    <PrivatRouter path={ROUTES.PROPERTY} exact isAuth={state.isAuth} redirectPath={ROUTES.HOME + ROUTES.LOGIN}>
                        <PropertyPage />
                    </PrivatRouter>
                    <PrivatRouter path={ROUTES.DASHBOARD} exact isAuth={state.isAuth} redirectPath={ROUTES.HOME + ROUTES.LOGIN}>
                        <DashboardPage />
                    </PrivatRouter>
                    <PrivatRouter path={ROUTES.SETTINGS} exact isAuth={state.isAuth} redirectPath={ROUTES.HOME + ROUTES.LOGIN}>
                        <AccountSettingsPage />
                    </PrivatRouter>
                    <PrivatRouter path={ROUTES.INSTRUCTIONS} exact isAuth={state.isAuth} redirectPath={ROUTES.HOME + ROUTES.LOGIN}>
                        <InstructionsPage />
                    </PrivatRouter>
                    <PrivatRouter path={ROUTES.ADMIN} exact isAuth={state.isAuth} redirectPath={ROUTES.HOME + ROUTES.LOGIN}>
                        <AdminPage />
                    </PrivatRouter>
                    <Route path='*' component={() => <Redirect to={ROUTES.HOME} />} />
                </Switch>
            </div>
            <Footer />
            </>
    );
}

export default React.memo(App);
