import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    height: '505px',
    width: '378px',
    boxSizing: 'border-box',
    padding: '20px',
    backgroundColor: theme.palette.bgColor.white,
    borderRadius: '18px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
  },
  cardTitle: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
  },
  cardTitleImgWrapper: {
    position: 'relative',
    width: '120px',
    height: '120px',
    backgroundColor: theme.palette.bgColor.lightBlue,
    borderRadius: '50%',
  },
  notActiveImg: {
    backgroundColor: theme.palette.bgColor.lightGrey,
  },
  cardTitleImg: {
    width: '65%',
    height: '65%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  cardComingSoon: {
    marginTop: '20px',
    fontSize: '48px',
    lineHeight: '48px',
    letterSpacing: '-1.5px',
  },
  cardTitleText: {
    marginLeft: '20px',
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: theme.fontSize.text.subHeader,
    lineHeight: '24px',
    color: 'black',
  },
  cardTitleTextSpan: {
    display: 'block',
    lineHeight: '24px',
  },
  cardValuesWrapper: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: theme.typography.fontFamily.black,
    alignItems: 'baseline',
  },
  cardValuesBig: {
    fontSize: theme.fontSize.text.semiMain,
    lineHeight: '48px',
    letterSpacing: '-1.5px',
  },
  cardValuesSmall: {
    color: theme.palette.bgColor.lightBlue,
    fontSize: '22px',
    lineHeight: '26px',
    letterSpacing: '-0.5px',
  },
  cardDescription: {
    marginTop: '20px',
    fontFamily: theme.typography.fontFamily.medium,
    lineHeight: '22px',
    letterSpacing: '-0.2475px',
  },
  cardGraphWrapper: {
    marginTop: '30px',
    height: '144px',
  },
  cardGraphDescription: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: '8px',
    lineHeight: '9px',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
  },
  cardGraphDescriptionText: {
    display: 'flex',
    alignItems: 'center',
    '&:nth-child(2)': {
      margin: '0 10px 0 23px',
      '& span': {
        height: '2px',
        width: '30px',
        marginRight: '5px',
        display: 'inline-block',
        backgroundColor: '#468AFF'
      }
    },
    '&:nth-child(3)': {
      '& span': {
        height: '2px',
        width: '30px',
        marginRight: '5px',
        display: 'inline-block',
        backgroundColor: 'rgba(70, 138, 255, 0.25)'
      }
    }
  },
  '@media (max-width: 480px)': {
    card: {
      maxWidth: '350px',
    },
    cardValuesWrapper: {
      marginTop: '57px',
    },
    cardTitleImgWrapper: {
      width: '100px',
      height: '100px',
    },
    cardTitleText: {
      fontSize: '20px',
      marginLeft: '16px',
    },
    cardValuesBig: {
      fontSize: '40px',
      lineHeight: '40px',
    },
  },
  '@media (max-width: 375px)': {
    card: {
      maxWidth: '330px',
    },
  },
  '@media (max-width: 320px)': {
    card: {
      minWidth: '310px',
    },
  },
}))
