import React, {useEffect, useState} from 'react';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import {useAppState} from "../../app-state";
import { useRouteMatch } from "react-router-dom";
import useApi from '../../hooks/useApi';
import CONFIG_COIN from "../../CoinConfig/CoinConfig";
import { useStyles } from './style.js';
import {ROUTES} from "../../constans";

const CoinButtons = ({className, classNameCoinBtn}) => {
  const classes = useStyles();
  const { state, actions } = useAppState();
  const [selectedBtn, setSelectedBtn] = useState(localStorage.getItem('selectedCoinType'));
  let match = useRouteMatch (ROUTES.LEDGER);

  const api = useApi();

  useEffect(() => {
    if (!localStorage.selectedCoinType) {
        setSelectedBtn('HAUS')
    }
    api.setCoinId(selectedBtn);
    actions.updateState({coinType: selectedBtn});
    localStorage.setItem('selectedCoinType', selectedBtn);
  },[selectedBtn])
  const selectButtons = CONFIG_COIN.filter(coin => coin.active);

  function renderClassBtnWrapper() {
    if (!match) {
      return classes.notActiveCoinBtn;
    } else {
      return classes.notActiveCoinBtnGrey;
    }
  }

  function renderClassBtnImg() {
    if (!match) {
      return classes.coinBtnLogoImgGrey;
    } else {
      return classes.coinBtnLogoImgWhite;
    }
  }

  return (
    <div className={`${classes.coinBtnWrapper} ${className}`}>
      <ButtonGroup
        className={`${classes.coinBtnGroup}`}
        variant="text"
        size="large"
        aria-label="large outlined primary button group"
      >
      {selectButtons.map(coin => {
          return (
              <Button
                className={`${classes.coinBtn} ${classNameCoinBtn}`}
                color={coin.id === selectedBtn ? "primary" : "secondary"}
                onClick={() => setSelectedBtn(coin.id)}
                key={coin.id}
              >
                <div
                  className={`${classes.coinBtnLogoImgWrapper} ${coin.id !== selectedBtn ? renderClassBtnWrapper() : null}`}
                >
                  <img
                    className={`${classes.coinBtnLogoImg} ${coin.id !== selectedBtn ? renderClassBtnImg() : null}`}
                    src={coin.logo}
                    alt={"coin logo"}
                  />
                </div>
                {coin.id}
              </Button>
          )
      })}
      </ButtonGroup>
    </div>
  );
};

export default CoinButtons;
