import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  blueLabel: {
    color: theme.palette.text.darkBlue,
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.small,
    lineHeight: '16px',
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
}))
