import React, {useEffect, useState} from 'react';
import {ROUTES} from '../../constans';
import Container from '@material-ui/core/Container';
import useApi from '../../hooks/useApi';
import {generatePath, useHistory} from 'react-router-dom';
import {useAppState} from '../../app-state';
import SkeletonTable from '../../components/Skeleton/SkeletonTable';
import LedgerFilter from '../../components/Dropdown/LedgerFilter/LedgerDropdown';
import arrowDown from 'assets/img/select-arrow.svg';
import arrowUp from 'assets/img/sort-arrow-up.svg';
import {useStyles} from './style.js';
import DataItem from 'common/components/DataItem';
import ViewMoreButton from 'marketplace/components/ViewMoreButton';
import CoinButtons from "../../components/CoinButtons/CoinButtons";
import {ADDRESS_STATUS, COIN_TYPE} from "../../../common/constants";
import RenderTag from "../../components/RenderTag/RenderTag";
import { FEATURE_FLAGS } from '../../../common/config';

// TODO: Move state under one object using useReducer
const LedgerPage = () => {
  const classes = useStyles();
  const {state} = useAppState();
  const history = useHistory();
  const [isInitialized, setIsInitialized] = useState(false);
  const [ledgerItems, setLedgerItems] = useState([]);
  const [ledgerItemsLeft, setLedgerItemsLeft] = useState();
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [locationFilter, setLocationFilter] = useState(null);
  const [citiesArr, setCitiesArr] = useState([]);

  const [homeValue, setHomeValue] = useState('asc');
  const [hausEquity, setHausEquity] = useState('asc');
  const [appreciation, setAppreciation] = useState('asc');

  const api = useApi();

  useEffect(() => {
    setIsLoading(true);
    loadData().then((data) => {
      setLedgerItemsLeft(data);
      // filter out zero haus equity
      data.records = data.records.filter(property => {
        return property.hausEquityValue !== '0';
      });
      setLedgerItems(data.records);
    }).catch((err) => {
      console.error(err);
    }).finally(() => {
      setIsLoading(false);
      setIsInitialized(true);
    });
  }, [state.coinType]);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }
    setIsLoading(true);
    loadData().then((data) => {
      data = filterCitiesByState(data);
      setLedgerItemsLeft(data);
      setLedgerItems(data.records);
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [locationFilter, homeValue, hausEquity, appreciation]);

  const loadData = (page = currentPage) => {
    const filter = {};
    if (locationFilter && locationFilter !== 'All') {
      filter.location = locationFilter.city;
    }
    ;
    return api.getLedgerRecords(filter, {
      'homeValue': homeValue,
      'hausEquity': hausEquity,
      'appreciation': appreciation,
    }, page);
  };

  const showMoreItems = () => {
    setIsLoadingMore(true);
    const newPage = currentPage + 1;
    setCurrentPage(newPage);

    loadData(newPage).then((data) => {
      data = filterCitiesByState(data);
      setLedgerItemsLeft(data);
      setLedgerItems(data.records);
      if (ledgerItems?.length) {
        setLedgerItems(ledgerItems.concat(data.records));
      } else {
        setLedgerItems(data.records);
      }
    }).catch((err) => {
      console.log(err);
    }).finally(() => {
      setIsLoadingMore(false);
    });
  };

  const propertyView = (selectedRow) => {
    const route = generatePath(ROUTES.PROPERTY, {
      id: selectedRow,
    });
    history.push(route);
  };

  const filterCitiesByState = inputData => {
    if (!inputData || locationFilter === 'All') {
      return inputData;
    } else {
      let toReturn = {
        hasMore: inputData.hasMore,
        recordsLeft: inputData.recordsLeft,
        records: []
      };
      inputData.records.forEach(house => {
        if (house.property.stateCode !== locationFilter.stateCode) {
          return;
        } else {
          toReturn.records.push(house);
        }
      });
      return toReturn;
    }
  };

  const removeDuplicateFilterItems = cities => {
    const uniqueArray = cities.filter((city, index) => {
      const _city = JSON.stringify(city);
      return index === cities.findIndex(obj => {
        return JSON.stringify(obj) === _city;
      });
    });
    uniqueArray.sort(((a, b) => {
      if (a.stateCode === b.stateCode) {
        return a.city < b.city ? -1 : 1
      } else {
        return a.stateCode < b.stateCode ? -1 : 1
      }
    }))
    return uniqueArray;
  };

  return (
    <div className={classes.ledger}>
      <Container fixed className={classes.ledgerContainer}>
        <CoinButtons className={classes.ledgerCoinBtns}/>
        <div className={classes.ledgerTableTitle}>
          <h4 className={classes.ledgerTitle}>
            HausCoin <span className={classes.ledgerTitleSpan}>ledger</span>
          </h4>
        </div>
        <div className={classes.ledgerTableMob}>
          <table className={classes.ledgerTable} frame='none' rules='rows'>
            <tbody>
            <tr className={classes.ledgerTableTr}>
              <th className={`${classes.ledgerTableTrTitle} ${classes.ledgerTableTrProperty}`}>PROPERTY</th>
              <th className={`${classes.ledgerTableTrTitle} ${classes.ledgerTableTrTitleSecond}`}
                // onClick={() => setHomeValue(homeValue === 'asc' ? 'desc' : 'asc')}
              >
                <div className={classes.ledgerTableTrTitleWrapper}>
                  Home Value
                  {/*<img*/}
                  {/*    className={classes.ledgerTableSortImg}*/}
                  {/*    src={homeValue === 'asc' ? arrowUp : arrowDown}*/}
                  {/*    alt='arrow'*/}
                  {/*/>*/}
                </div>
              </th>
              <th className={`${classes.ledgerTableTrTitle}  ${classes.ledgerTableTrTitleSecond}`}
                // onClick={() => setHausEquity(hausEquity === 'asc' ? 'desc' : 'asc')}
              >
                <div className={classes.ledgerTableTrTitleWrapper}>
                  Haus Equity
                  {/*<img*/}
                  {/*    className={classes.ledgerTableSortImg}*/}
                  {/*    src={hausEquity === 'asc' ? arrowUp : arrowDown}*/}
                  {/*    alt='arrow'*/}
                  {/*/>*/}
                </div>
              </th>
              {/*<th className={`${classes.ledgerTableTrTitle} ${classes.ledgerTableTrTitleAppreciation}`}*/}
              {/*    onClick={() => setAppreciation(appreciation === 'asc' ? 'desc' : 'asc')}>*/}
              {/*    <div className={classes.ledgerTableTrTitleWrapper}>*/}
              {/*        FCST. APPRECIATION*/}
              {/*        <img*/}
              {/*            className={classes.ledgerTableSortImg}*/}
              {/*            src={appreciation === 'asc' ? arrowUp : arrowDown}*/}
              {/*            alt='arrow'*/}
              {/*        />*/}
              {/*    </div>*/}
              {/*</th>*/}
            </tr>
            {isLoading && <SkeletonTable/>}
            {!isLoading &&
            ledgerItems?.map((ledgerItem, {streetAddress, city, forecasts, zip, stateCode}) => (
              <tr key={ledgerItem.id} className={classes.ledgerTableTrChild}>
                <td className={classes.ledgerTableTd}>
                  {ledgerItem?.property?.streetAddress.replace(/[0-9]th|rd/g, '').replace(/[0-9]/g, '')}{' '}
                  <span className={classes.ledgerTableTrChildSpan}>
                      {ledgerItem?.property.city}, {ledgerItem?.property.stateCode} {ledgerItem?.property.zip}
                  </span>
                </td>
                <td className={classes.ledgerTableTd}>
                  <DataItem value={ledgerItem?.homeValue} isLoading={isLoading} fullRound={true}
                            startCharacter={'$'}/>
                  <span className={classes.ledgerTableTrChildSpan}>&nbsp;</span>
                </td>
                <td className={classes.ledgerTableTd}>
                  <DataItem
                    value={ledgerItem?.hausEquityValue}
                    isLoading={isLoading}
                    fullRound={true}
                    startCharacter={'$'}
                  />
                  <span className={classes.ledgerTableTrChildSpan}>
                      <DataItem
                        value={ledgerItem?.hausEquityPercent}
                        isLoading={isLoading}
                        fullRound={true}
                        endCharacter={'%'}
                      />
                  </span>
                </td>
                {FEATURE_FLAGS.PROPERTY_PAGE && 
                <>
                <td className={`${classes.ledgerTableTd} ${classes.ledgerTableTdTagWrapper}`}>
                  <RenderTag tag={ADDRESS_STATUS.INDIVIDUAL} tagClassName={classes.ledgerTableTdTag} />
                </td>
                <td className={`${classes.ledgerTableTd} ${classes.ledgerTableTdTagWrapper}`}>
                  <RenderTag tag={ADDRESS_STATUS.LOCATION} tagClassName={classes.ledgerTableTdTag} />
                </td>
                </>}

                {/*<td className={classes.ledgerTableTd}>*/}
                {/*    {ledgerItem?.appreciation}{' '}*/}
                {/*    /!*<span className={classes.ledgerTableTrChildSpan}>{getForeCast()}%</span>*!/*/}
                {/*    <span className={classes.ledgerTableTrChildSpan}>{' - '}</span>*/}
                {/*</td>*/}
                {state.isAuth ? (
                    <td className={classes.ledgerTableTdBtn}>
                      <button
                        className={classes.ledgerTableTrChildBtn}
                        onClick={() => propertyView(ledgerItem.id)}
                      >
                        VIEW
                      </button>
                    </td>
                  ) :
                  <td className={classes.hideLedgerTableTdBtn}>
                    <button
                      className={classes.ledgerTableTrChildBtn}
                      disabled
                    >
                      VIEW
                    </button>
                  </td>
                }
              </tr>
            ))}
            <tr className={classes.ledgerTableTrChild}></tr>
            </tbody>
          </table>
        </div>
        <ViewMoreButton
          variant={'ledger'}
          hasMore={ledgerItemsLeft?.hasMore}
          isLoading={isLoadingMore}
          onClick={showMoreItems}
          recordsLeft={ledgerItemsLeft?.recordsLeft}/>
      </Container>
    </div>
  );
};

export default LedgerPage;
