import React, {memo, useEffect, useState} from 'react';
import {Bar} from 'react-chartjs-2';

import {useStyles} from './style.js';

const ListGraph = () => {
  const classes = useStyles();
  const [cityData, setCityData] = useState({
    labels: [],
    datasets: []
  });

  const cityGraph = () => {
    const labels = [10, 30, 40, 60, 70, 75, 80, 90, 100, 115];
    const states = [10, 30, 40, 60, 70, 75, 80, 90, 100, 115];
    const equityValue = [5, 20, 25, 30, 40, 55, 67, 70, 88, 95, 103, 115, 135, 145]

    // houses?.history?.forEach(h => {
    //   const date = new Date(h.date);
    //   labels.push(date.toLocaleString('default', {month: 'long'}));
    // });
    //
    // houses?.history?.forEach(s => {
    //   states.push(s.value);
    // });

    setCityData({
      labels,
      datasets: [
        {
          type: 'bar',
          label: 'Homes',
          data: states,
          backgroundColor: ['rgba(70, 138, 255, .25)'],
          barThickness: 25,
        },
        {
          type: 'line',
          label: 'Coins Issued',
          data: equityValue,
          backgroundColor: ['rgba(70, 138, 255, 100)'],
          borderColor: ['rgba(70, 138, 255, 100)'],
          order: 0,
          pointRadius: 0,
          borderWidth: 2,
          yAxisID: 'y-axis',
          xAxisID: 'x-axis',
        },
      ],
    });
  };

  useEffect(() => {
    cityGraph();
  }, []);

  return (
    <div className={classes.listGraphContainer}>
      <Bar data={cityData}
           options={
             {
               plugins: {
                 legend: {
                   display: false
                 },
                 tooltip: {
                   enabled: true,
                   cornerRadius: '0',
                   events: ['click'],
                 },
               },
               responsive: true,
               maintainAspectRatio: false,
               scales: {
                 x: {
                   ticks: {
                     display: false
                   },
                   grid: {
                     display: false,
                     borderWidth: 3,
                     borderColor: 'transparent',
                   },
                 },
                 y: {
                   ticks: {
                     display: false
                   },
                   grid: {
                     display: false,
                     borderWidth: 3,
                     borderColor: 'transparent',
                   },
                 },
                 'y-axis': {
                   // beginAtZero: false,
                   display: false,
                   beginAtZero: false,
                   // min: 1,
                   ticks: {
                     display: false,
                   },
                   grid: {
                     display: false,
                   },
                 },
                 'x-axis': {
                   display: false,
                   grid: {
                     display: false,
                     borderWidth: 4,
                     borderColor: 'rgba(151, 151, 151, 100)'
                   },
                   ticks: {
                     display: false
                   }
                 }
               },
             }
           }
      />
    </div>
  );
};

export default memo(ListGraph);
