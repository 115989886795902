import sanFrancisco from "../../assets/img/SanFrancisco.jpg";
import sanDiego from "../../assets/img/SanDiego.jpg";
import seattle from "../../assets/img/Seattle.jpg";
import tacoma from "../../assets/img/Tacoma.jpg";
import oakland from "../../assets/img/Oakland.jpg";
import portland from "../../assets/img/Portland.jpg";
import sanFranciscoLogo from "../../assets/img/SanFrancisco-logo.svg"; //
import sanDiegoLogo from "../../assets/img/SanDiego-logo.svg"; //
import seattleLogo from "../../assets/img/Seattle-logo.svg"; //
import portlandLogo from "../../assets/img/Portland-logo.svg"; //
import tacomaLogo from "../../assets/img/Tacoma-logo.svg"; //
import oaklandLogo from "../../assets/img/Oakland-logo.svg"; //
import hausCoinLogo from "../../assets/img/hauscoin-logo.svg"; //
import losAngelesLogo from "../../assets/img/LosAngeles-logo.svg"; //
import tahoeLogo from "../../assets/img/Tahoe-logo.svg"; //
import sacramentoLogo from "../../assets/img/Sacramento-logo.svg"; //
import venturaLogo from "../../assets/img/Ventura-logo.svg"; //
import bendLogo from "../../assets/img/Bend-logo.svg"; //
import spokaneLogo from "../../assets/img/Spokane-logo.svg"; //
import bellevueLogo from "../../assets/img/Bellvue-logo.svg"; //
import vancouverLogo from "../../assets/img/Vancouver-logo.svg"; //
import denverLogo from "../../assets/img/Denver-logo.svg"; //
import boulderLogo from "../../assets/img/Boulder-logo.svg"; //
import miamiLogo from "../../assets/img/Miami-logo.svg"; //
import impactLogo from "../../assets/img/Impact-logo.svg"; //

import { getTransactable } from '../../common/config';
import { COIN_STATUS } from "../../common/constants";

const CONFIG_COIN = [
  {
    id: 'HAUS',
    background: sanFrancisco,
    title: "HausCoin",
    alt: "HausCoin",
    detailTitle: true,
    logo: hausCoinLogo,
    usState: "Diversified markets",
    stateCode: "General fund",
    coinType: "GEN_23",
    status: null,
    featured: true,
    description: "Home values across California, Washington, and Oregon have gone %direction% by %percent%%.",
    about: 'The General Fund HausCoin is backed 1:1 by home equity across a diverse group of individuals and locations.',
    active: true,
    // will be true for all but production, will be removed once tokens are transactable
    transactable: getTransactable(),
  },
  {
    id: 'IMPACT',
    background: oakland,
    title: "HausCoin",
    detailTitle: true,
    alt: 'HausCoin',
    logo: impactLogo,
    usState: "Diversified markets",
    stateCode: "Impact fund",
    coinType: 'IMPT_23',
    status: COIN_STATUS.NEW,
    about: 'The Impact Fund HausCoin is backed 1:1 by home equity in low and moderate income individuals.',
    featured: true,
    active: true,
    transactable: false,
  },
  {
    id: 'SF',
    background: sanFrancisco,
    title: "San Francisco",
    alt: 'San Francisco',
    logo: sanFranciscoLogo,
    usState: "California",
    stateCode: "CA",
    coinType: 'SF_23',
    status: COIN_STATUS.NEW,
    about: 'The San Francisco HausCoin is backed 1:1 by home equity in the San Francisco region.',
    featured: false,
    active: true,
    transactable: false,
  },
  {
    id: 'LA',
    background: sanDiego,
    logo: losAngelesLogo,
    alt: "Los Angeles",
    title: "Los Angeles",
    usState: "California",
    stateCode: "CA",
    coinType: 'LA_23',
    status: COIN_STATUS.NEW,
    about: 'The Los Angeles HausCoin is backed 1:1 by home equity in the Los Angeles region.',
    featured: false,
    active: true,
    transactable: false,
  },
  {
    id: 'SEA',
    background: seattle,
    logo: seattleLogo,
    alt: "Seattle",
    title: "Seattle",
    usState: "Washington",
    stateCode: "WA",
    coinType: 'SEA_23',
    status: COIN_STATUS.NEW,
    about: 'The Seattle HausCoin is backed 1:1 by home equity in the Seattle region.',
    featured: true,
    active: true,
    transactable: false,
  },
  {
    id: 'PDX',
    background: portland,
    logo: portlandLogo,
    alt: "Portland",
    title: "Portland",
    usState: "Oregon",
    stateCode: "OR",
    coinType: 'PO_23',
    status: COIN_STATUS.NEW,
    about: 'The Portland HausCoin is backed 1:1 by home equity in the Portland region.',
    featured: true,
    active: true,
    transactable: false,
  }
];

export default CONFIG_COIN;
