import React from 'react';
import DataItem from "../../../common/components/DataItem";
import clsx from "clsx";

import {useStyles} from './style.js';

const DataBlock = ({
                     dataBlockWrapperStyle,
                     mainChildren,
                     status = false,
                     statusChildren,
                     value,
                     loadingState,
                     roundNum,
                     showSmallValue = true,
                     showDates = false,
                     datesChildren,
                     smallValue,
                     fullRound
                   }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.propertyDataBlockItems, dataBlockWrapperStyle)}>
      <div className={classes.propertyDataBlockItemsTitle}>{mainChildren}</div>
      <div className={classes.propertyDataBlockItemsText}>
        {status && statusChildren}
        {!status && <DataItem value={value} isLoading={loadingState} roundNum={roundNum} startCharacter={'$'}/>}
        {showSmallValue &&
          <span className={classes.propertyDataBlockItemsTextSpan}>
              <DataItem value={smallValue} isLoading={loadingState} fullRound={fullRound} endCharacter={'%'}/>
          </span>
        }
      </div>
      {showDates &&
        <span className={classes.propertyDataBlockItemsDateSpan}>
          {datesChildren}
        </span>
      }
    </div>
  );
};

export default DataBlock;
