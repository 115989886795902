import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        borderBottom: '2px solid #E0E0E0',
        padding: '18px',
    },
    viewMoreBtn: {
        '& .MuiButton-root': {
            fontFamily: 'Basis Grotesque Pro',
            fontStyle: 'normal',
            fontWeight: 900,
            fontSize: '12px',
            lineHeight: '16px',
            textAlign: 'center',
            letterSpacing: '1.2px',
            textTransform: 'uppercase',
            color: '#2B2B2B',
        },
    },
}));
