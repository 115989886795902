import React from 'react';
import { useStyles } from '../../style.js';
import Container from '@material-ui/core/Container';

const FaqPage = () => {
    const classes = useStyles();

    return (
        <section className={classes.contentSection}>
            <Container className={classes.contentContainer} fixed>
                <h4 className={classes.contentPageTitle}>FAQ</h4>

                <p className={classes.contentTitle}>
                Do I need to be a homeowner to invest in HausCoin?
                </p>
                <p className={classes.contentText}>
                No. HausCoin is a real estate backed security token registered through a 506(c) with the SEC. To invest you must be an accredited investor, but there is no requirement to be a current customer of Haus.
                </p>

                <p className={classes.contentTitle}>
                Is this like a REIT?
                </p>
                <p className={classes.contentText}>
                A bit. A REIT is a corporate structure that allows you to invest in a company, similar to how you invest in other publicly traded companies. However, that structure did not work for HausCoin. With a REIT, you are investing in all the overhead, debt and cost structures for the entire company as well as their investments. With HausCoin, each token is backed 1:1 by home equity. The servicing of all the customers is handled by Haus Services, Inc., and all costs of the home, such as insurance, maintenance, HOA fees, etc… are the responsibility of each homeowner, therefore you are getting the purest form of home equity available in the market today.
                </p>

                <p className={classes.contentTitle}>
                How are HausCoin valued?
                </p>
                <p className={classes.contentText}>
                HausCoin are backed by the aggregate of all the home equity within that specific HausCoin pool. Example: HausCoin San Francisco, is backed by home equity in homes in the San Francisco Bay Area. As the homes in that market increase or decrease in value, that aggregate change is reflected directly in the equity price of each HausCoin San Francisco. The price of each home is set on a monthly basis (sometimes more), and is priced through third-party AVM’s. The AVM’s determine the price change from the previous price ongoing, and as that new price is set, it is reflected in the price of HausCoin, as well as the cost to the homeowner to purchase additional equity.
                </p>

                <p className={classes.contentTitle}>
                How is HausCoin backed by home equity?
                </p>
                <p className={classes.contentText}>
                Each customer that partners with Haus to purchase or finance their home uses our agreement. This agreement is recorded as a first deed of trust on title (similar to how a mortgage is recorded), and a memorandum of agreement is also recorded on title. These documents ensure that all home equity that backs HausCoin are properly recorded and secured.
                </p>

                <p className={classes.contentTitle}>
                How is HausCoin regulated?
                </p>
                <p className={classes.contentText}>
                As a security token, HausCoin is regulated by the SEC, and under the 506(c) filing is available only to accredited investors.
                </p>

                <p className={classes.contentTitle}>
                Is HausCoin a security?
                </p>
                <p className={classes.contentText}>
                Yes.
                </p>

                <p className={classes.contentTitle}>
                Are there limitations when buying a restricted security?
                </p>
                <p className={classes.contentText}>
                There are a number of limitations, including but not limited to the fact that you must be an accredited investor, you must also have the intent to hold for at least 12 months. As HausCoin secures additional SEC filings, the restrictions will allow for more widespread circulation and security.
                </p>

                <p className={classes.contentTitle}>
                Why is HausCoin considered a security?
                </p>
                <p className={classes.contentText}>
                To create an asset backed token without becoming a security skirts the spirit of the laws the SEC has put in place to protect investors. When HausCoin was created, we realized that following the rules and regulations of the governing body was important, especially given the assets we are investing in are people’s homes. To try and create an asset backed token that defines itself as a “utility” creates unneeded risk for investors and Haus customers.
                </p>

                <p className={classes.contentTitle}>
                Are there requirements to qualify as a buyer?
                </p>
                <p className={classes.contentText}>
                Yes, first and foremost, for this initial release you must be an accredited investor. In addition, due to high investor interest and limitations imposed by the SEC, we have limited the number of investors we are accepting into the initial offerings and will reach out once spaces open up.
                </p>

                <p className={classes.contentTitle}>
                How does it work when new homes are added?
                </p>
                <p className={classes.contentText}>
                When a new home is added, and that equity is assigned to a specific token, there is an automatic minting of additional tokens at the current price that equal the value of the equity assigned. This mechanism is what ensures the token is always backed 1:1 by home equity, and existing investors are capitalizing on the to date investment and investment into the future.
                </p>

                <p className={classes.contentTitle}>
                How does it work when people sell their homes that are backed by HausCoin?
                </p>
                <p className={classes.contentText}>
                In the reverse of when a new home is added, sometimes equity is removed from the pool that backed tokens. In this event, tokens equal to the value of the equity removed are instantly burned, and the cash value for the burned tokens are remitted to the owners of those tokens. Initially, Haus maintains a buffer account on each token, so that the likelihood of any investor tokens being burned and redeemed for currency is extremely limited.
                </p>




            </Container>
        </section>
    );
};

export default FaqPage;
