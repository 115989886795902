import React from 'react';
import { useStyles } from './styles';
import { Button, CircularProgress } from '@material-ui/core';

const ViewMoreButton = ({ isLoading, recordsLeft, onClick }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Button
                disabled={isLoading}
                startIcon={isLoading && <CircularProgress size={20} />}
                onClick={onClick}
                className={classes.viewMoreBtn}
            >
                View More ({recordsLeft})
            </Button>
        </div>
    );
};

export default ViewMoreButton;
