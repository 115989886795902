import React from 'react';

import {useStyles} from './style.js';

const NumberInput = ({
                 fieldClassName,
                 labelClassName,
                 labelText,
                 inputClassName,
                 value,
                 type,
                 placeholder,
                 disabled,
                 onChange,
               }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.modalsInputField} ${fieldClassName}`}>
      <label className={`${classes.modalsInputLabel} ${labelClassName}`}>
        {labelText}
        <input
          className={`${classes.modalsInput} ${inputClassName}`}
          value={value}
          type={type}
          autoComplete='off'
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
        />
      </label>
    </div>
  );
};

export default NumberInput;
