import React, {memo, useEffect, useState} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useStyles } from 'marketplace/components/Modal/style.js';
import closeBtn from 'assets/img/close-btn.svg';
import clsx from 'clsx';
import {validateEmail, validatePassword} from "../validator";
import Fade from '@material-ui/core/Fade';

const ModalSignIn = ({
    open,
    isLoading,
    onSubmit,
    withSignUp,
    withForgotPassword,
    onSignUpClick,
    onForgotPasswordClick,
    onClose,
    isLogError,
}) => {
    const classes = useStyles();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    const [errorEmailMsg, setErrorEmailMsg] = useState(null);
    const [errorPassMsg, setErrorPassMsg] = useState(null);

    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (!open) {
            setEmail('');
            setPassword('');
        }
    }, [open]);

    useEffect(() => {
        validEmailHandler(false);
    }, [email])

    // useEffect(() => {
    //     validPassHandler(false)
    // }, [password])

    useEffect(() => {
        if (isLoading) {
            setIsDisabled(true);
            return;
        }
        setIsDisabled(!isEmailValid );
    // || !isPasswordValid
    }, [isEmailValid, isPasswordValid, isLoading])

    function validEmailHandler(showErrorMsg= true) {
        const validationEmailMsg = validateEmail(email);

        if (validationEmailMsg) {
            showErrorMsg && setErrorEmailMsg(validationEmailMsg);
            setIsEmailValid(false);
            return;
        }

        setIsEmailValid(true);
        setErrorEmailMsg(null);
    }

    // function validPassHandler(showErrorMsg= true) {
    //     const validationPassMsg = validatePassword(password);
    //
    //     if (validationPassMsg) {
    //         showErrorMsg && setErrorPassMsg(validationPassMsg);
    //         setIsPasswordValid(false);
    //         return;
    //     }
    //
    //     setIsPasswordValid(true);
    //     showErrorMsg && setErrorPassMsg(null);
    // }

    const onBlurHandler = (e) => {
        switch (e.target.name) {
            case 'email':
                validEmailHandler();
                break;

            // case 'password':
            //     validPassHandler();
            //     break;
        }
    };

    const signInCloseHandler = () => {
        onClose();
    };

    return (
        <div>
            <Fade in={open} timeout={200}>
                <div
                    className={clsx(classes.modal, {[classes.showModal]: open})}
                    onClick={(e) => e.stopPropagation()}>
                    <div className={classes.modalSignInWrapper} onClick={(e) => e.stopPropagation()}>
                        <div className={classes.modalSignInCloseBtn} onClick={signInCloseHandler}>
                            <img className={classes.modalSignInImg} src={closeBtn} alt="close" />
                        </div>
                        <div className={classes.modalSignInInputBlock}>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setIsDisabled(true);
                                    onSubmit(email, password);
                                }}
                                className={classes.signInForm}
                            >
                                <div className={classes.modalSignInInputField}>
                                    <label className={classes.modalSignInInputLabel} htmlFor="email">
                                        Email
                                        <input
                                            className={classes.modalSignInInput}
                                            onChange={e => setEmail(e.target.value.replace(/\s/g, '').trim())}
                                            value={email}
                                            type="email"
                                            maxLength="255"
                                            name="email"
                                            onBlur={e => onBlurHandler(e)}
                                            autoComplete="off"
                                            placeholder="email@abc.com"
                                            onKeyDown={(e) => {
                                                if (e.key === " ") {
                                                    e.preventDefault();
                                                }
                                            }}
                                            required
                                        />
                                    </label>
                                </div>
                                <div className={classes.wrapperForMsg}>
                                    {!isEmailValid && <div className={classes.errMsg}>{errorEmailMsg}</div>}
                                </div>
                                <div className={classes.modalSignInInputField}>
                                    <label className={classes.modalSignInInputLabel} htmlFor="password">
                                        Password
                                        <input
                                            className={classes.modalSignInInput}
                                            onChange={e => setPassword(e.target.value.trim())}
                                            value={password}
                                            type="password"
                                            maxLength="255"
                                            name="password"
                                            // onBlur={(e) => onBlurHandler(e)}
                                            autoComplete="off"
                                            placeholder="Enter here"
                                            required
                                        />
                                    </label>
                                </div>
                                <div className={classes.wrapperForMsg}>
                                    {/*{!isPasswordValid && <div className={classes.errMsg}>{errorPassMsg}</div>}*/}
                                    <div className={classes.errMsg}>{isLogError}</div>
                                </div>
                                <button className={classes.modalSignInCreatBtn} disabled={isDisabled} type="submit">
                                    {isLoading ? <CircularProgress size={18} /> : 'Log in'}
                                </button>
                            </form>
                            {!withSignUp && !withForgotPassword ? null : (
                                <>
                                    <p className={classes.modalSignInSignInText}>
                                        {withSignUp && (
                                            <a
                                                className={classes.modalSignInSignInTextLink}
                                                onClick={onSignUpClick}
                                                href="#sign-up"
                                            >
                                                Sign up
                                            </a>
                                        )}
                                        {withSignUp && withForgotPassword && ' or '}
                                        {withForgotPassword && (
                                            <a
                                                className={classes.modalSignInSignInTextLink}
                                                onClick={onForgotPasswordClick}
                                                href="#forgot-password"
                                            >
                                                Forgot password
                                            </a>
                                        )}
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
    );
};

export default memo(ModalSignIn);
