import React from 'react';
import {useStyles} from "./style";

const LargeTitle = ({titleClassName, title}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.mainTitle} ${titleClassName}`}>
      {title}
    </div>
  );
};

export default LargeTitle;
