import React, { useMemo } from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import {formatNum, fullRoundAndFormatNum, roundAndFormatNumber, roundFourAndFormatNumber} from '../util';

const DataItem = ({
                      replaceEmptyWithDash,
                      value,
                      isLoading,
                      roundNum,
                      roundFour,
                      fullRound,
                      startCharacter,
                      endCharacter,
                      ...skeletonProps
                  }) => {
    const formattedValue = useMemo(() => {
        if (roundNum) {
            return roundAndFormatNumber(value);
        }
        if (roundFour) {
            return roundFourAndFormatNumber(value);
        }
        if (fullRound) {
            return fullRoundAndFormatNum(value);
        }
        return formatNum(value);
    }, [ value ]);

    const getValue = () => {
        if (replaceEmptyWithDash && !value) {
            return '-';
        }

        return <>
            {startCharacter || ''}
            {formattedValue}
            {endCharacter || ''}
        </>;
    };

    return (
        <>
            {isLoading ? (
                <Skeleton style={{ display: 'inline-block' }} height={12} width={32} {...skeletonProps} />
            ) : (getValue())}
        </>
    );
};

export default React.memo(DataItem);
