import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppState } from 'marketplace/app-state';
import { validateEmail } from 'common/validator';
import closeBtn from 'assets/img/close-btn.svg';
import { useStyles } from './style.js';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import { AUTH_ERRORS, MESSAGES } from 'marketplace/constans';
import Fade from '@material-ui/core/Fade';

const ModalForgotPass = () => {
    const classes = useStyles();
    const history = useHistory();
    const { state, actions, apiClient } = useAppState();
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [resetEmailMsg, setResetEmailMsg] = useState(null);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        validResetEmailHandler(false);
    }, [email])

    useEffect(() => {
        if (isLoading) {
            setIsDisabled(true);
            return;
        }
        setIsDisabled(!isEmailValid);
    }, [isEmailValid, isLoading])

    function validResetEmailHandler(showErrorMsg= true) {
        const validationResetEmailMsg = validateEmail(email);

        if (validationResetEmailMsg) {
            showErrorMsg && setResetEmailMsg(validationResetEmailMsg);
            setIsEmailValid(false);
            return;
        }

        setResetEmailMsg(null);
        setIsEmailValid(true);
    }

    const onBlurHandler = (e) => {
        switch (e.target.name) {
            case 'email':
                validResetEmailHandler();
                break;
        }
    };

    const resetPassHandler = (e) => {
        e.preventDefault();
        setIsLoading(true);
        apiClient
            .resetPassword({ email: email })
            .then((res) => {
                switch (res.code) {
                    case 202:
                        actions.updateState({ modalForgotPass: false, messages: MESSAGES.RESET_PASSWORD_ACCEPTED });
                        break;
                    case 400:
                        actions.updateState({ messages: res.message || AUTH_ERRORS.UNKNOWN_ERROR });
                }
            })
            .catch((err) => {
                actions.updateState({ messages: err.message || AUTH_ERRORS.UNKNOWN_ERROR });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const forgotPassCloseHandler = () => {
        actions.updateState({ modalForgotPass: false });
        history.push(window.location.pathname);
        setEmail('');
        setIsEmailValid(false);
    };

    return (
        <div>
            <Fade in={true} timeout={200}>
                <div
                    className={clsx(classes.modal, {
                        [classes.showModal]: state.modalForgotPass,
                    })}
                    style={{ padding: 0 }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className={classes.modalForgotPassWrapper} onClick={(e) => e.stopPropagation()}>
                        <div className={classes.modalForgotPassCloseBtn} onClick={forgotPassCloseHandler}>
                            <img className={classes.modalForgotPassImg} src={closeBtn} alt="close" />
                        </div>
                        <div className={classes.modalForgotPassInputBlock}>
                            <form onSubmit={resetPassHandler} className={classes.forgotPassForm}>
                                <div className={classes.modalForgotPassInputField}>
                                    <label className={classes.modalForgotPassInputLabel} htmlFor="email">
                                        Email
                                        <input
                                            className={classes.modalForgotPassInput}
                                            onChange={(e) => setEmail(e.target.value.replace(/\s/g, '').trim())}
                                            onBlur={(e) => onBlurHandler(e)}
                                            value={email}
                                            type="email"
                                            maxLength="255"
                                            name="email"
                                            autoComplete="off"
                                            placeholder="email@abc.com"
                                            onKeyDown={(e) => {
                                                if (e.key === ' ') {
                                                    e.preventDefault();
                                                }
                                            }}
                                            required
                                        />
                                    </label>
                                </div>
                                <div className={classes.wrapperForMsg}>
                                    {!isEmailValid && <div className={classes.errMsg}>{resetEmailMsg}</div>}
                                </div>
                                <button className={classes.modalForgotPassCreatBtn} type="submit" disabled={isDisabled}>
                                    {isLoading ? <CircularProgress size={18} color="inherit" /> : 'Reset'}
                                </button>
                            </form>
                            <p className={classes.modalSignInText}>
                                <a className={classes.modalSignInTextLink}  href="#login">
                                    Sign in
                                </a>
                                or
                                <a className={classes.modalSignInTextLink}  href="#sign-up">
                                    Sign up
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </Fade>
        </div>
    );
};
export default React.memo(ModalForgotPass);
