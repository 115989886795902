import React from 'react';
import { useStyles } from 'admin/pages/AdminPage/style';
import { CircularProgress } from '@material-ui/core';

const AdminItem = ({ data, isEditing, isDeleting, onEdit, onDelete }) => {
    const classes = useStyles();

    return (
        <div className={classes.adminTabAdminsList}>
            <p className={classes.adminTabAdminsEmail}>{data.email}</p>
            <a
                className={classes.adminTabAdminsBtn}
                onClick={() => {
                    if (!isDeleting && !isEditing) {
                        onEdit(data);
                    }
                }}
            >
                {isEditing ? <CircularProgress size={10} /> : 'Edit'}
            </a>
            <a
                className={classes.adminTabAdminsBtn}
                onClick={() => {
                    if (!isDeleting && !isEditing) {
                        onDelete(data);
                    }
                }}
            >
                {isDeleting ? <CircularProgress size={10} /> : 'Delete'}
            </a>
        </div>
    );
};

export default React.memo(AdminItem);
