import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  property: {
    padding: '180px 0 0',
    backgroundColor: theme.palette.bgColor.white,
  },
  propertyContainer: {
    padding: '0 20px'
  },
  propertyDataBlock: {
    display: 'flex',
    marginTop: '47px',
    justifyContent: 'space-between',
    flexFlow: 'wrap',
    flex: '1 1 30%',
    rowGap: '61px',
  },
  pastBlockTitle: {
    marginTop: '61px',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing:' 1.2px',
    textTransform: 'uppercase',
    color: theme.palette.text.grey,
  },
  pastBlock: {
    marginTop: '11px',
    marginBottom: '85px',
    padding: '28px 30px 31px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.bgColor.grey,
  },
  pastBlockDataTitle: {
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
  },
  pastBlockDataValue: {
    marginTop: '9px',
    fontSize: '36px',
    lineHeight: '36px',
  },
  propertyTitle: {
    fontSize: theme.fontSize.text.semiMain,
    margin: 0,
    lineHeight: '62px',
    letterSpacing: '-1.5px',
  },
  propertyText: {
    fontSize: theme.fontSize.text.small,
    letterSpacing: '1.2px',
    textTransform: 'uppercase',
    color: theme.palette.text.grey,
    marginTop: '2px',
    marginBottom: 0
  },
  propertyTagWrapper: {
    marginTop: '16px',
    display: 'flex',
    columnGap: '19px',
  },
  propertyTag: {
    position: 'relative',
  },
  propertyDataBlockItemsTextSpan: {
    display: 'block',
    fontFamily: theme.typography.fontFamily.black,
    textTransform: 'uppercase',
    fontSize: theme.fontSize.text.small,
    letterSpacing: '1.2px',
    color: theme.palette.text.grey,
    lineHeight: '22px'
  },
  skeletonPropertyGraph: {
    height: '536px',
    marginTop: '85px',
  },
  propertyCustomerDataWrapper: {
    marginTop: '48px',
    padding: '148px 0 216px',
    backgroundColor: theme.palette.bgColor.white,
  },
  propertyCustomerDataBlock: {
    display: 'flex',
  },
  propertyCustomerDataInfo: {
    '&:nth-child(2)': {
      marginLeft: '215px'
    }
  },
  propertyCustomerDataInfoText: {
    fontSize: theme.fontSize.text.main,
    alignItems: 'center'
  },
  propertyActivityWrapper: {
    backgroundColor: theme.palette.bgColor.grey,
    position: 'relative',
    padding: '200px 0',
  },
  propertyCustomerActivityImg: {
      position: 'absolute',
      top: '30px',
  },
  propertyCustomerActivityTitle: {
    fontSize: theme.fontSize.text.big,
  },
  propertyActivityTable: {
    width: '100%',
    textAlign: 'center',
    color: theme.palette.text.main,
  },
  propertyActivityHeaderWrapper: {
    width: '100%',
    fontSize: theme.fontSize.text.small,
    textTransform: 'uppercase',
    '& > th:nth-child(1)': {
      paddingLeft: '0',
      textAlign: 'left',
    },
    '& > th:nth-child(2)': {
      paddingLeft: '0',
      textAlign: 'left',
    },
    '& > th:nth-child(3)': {
      paddingRight: '0',
      textAlign: 'right',
    },
  },
  propertyActivityTableRow: {
    lineHeight: '20px',
    borderBottom: '1px solid #979797',
    '& > td:nth-child(1)': {
      fontFamily: theme.typography.fontFamily.medium,
      textAlign: 'left',
      paddingLeft: '0',
      fontSize: theme.fontSize.text.semiHeader,
    },
    '& > td:nth-child(2)': {
      fontFamily: theme.typography.fontFamily.medium,
      textAlign: 'left',
      paddingLeft: '0',
      fontSize: theme.fontSize.text.semiHeader,
    },
    '& > td:nth-child(3)': {
      fontFamily: theme.typography.fontFamily.medium,
      lineHeight: '30px',
      letterSpacing: '-1.17px',
      textAlign: 'right',
      paddingRight: '0',
      fontSize: theme.fontSize.text.mediumHeader,
      color: theme.palette.text.blue,
    },
  },
  // propertyActivityTableSpan : {
  //     display: 'block'
  // },
  propertyActivityBtnWrapper: {
    display: 'flex',
    marginTop: '40px'
  },
  '@media (max-width: 960px)': {
    propertyActivityWrapper: {
      padding: '60px 0 60px'
    },
    property: {
      padding: '120px 0 0'
    },
    propertyTitle: {
      fontSize: '30px',
      lineHeight: '35px',
      letterSpacing: '-1.5px',
    },
  },
  '@media (max-width: 600px)': {
    skeletonPropertyGraph: {
      height: '300px'
    },
    propertyCustomerDataInfoText: {
      fontSize: '40px',
      marginTop: '10px'
    },
    propertyCustomerActivityTitle: {
      fontSize: '40px'
    },
    propertyActivityWrapper: {
      padding: '30px 0 60px'
    },
    // propertyCustomerActivity : {
    //     top: '-75px'
    // },
    propertyCustomerActivityImg: {
      // width: '50%'
    },
  },
  '@media (max-width: 480px)': {
    property: {
      padding: '91px 0 0'
    },
    propertyCustomerActivityImg: {
      width: '100px',
      top: '20px',
    },
    propertyDataBlock: {
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      flexFlow: 'column',
      rowGap: '40px',
    },
    pastBlock: {
      padding: '30px',
      flexFlow: 'column',
      justifyContent: 'center',
      textAlign: 'center',
      rowGap: '40px',
    },
    propertyCustomerDataWrapper: {
      padding: '40px 0 101px',
    },
    skeletonPropertyGraph: {
      height: '250px'
    },
    propertyCustomerDataBlock: {
      justifyContent: 'space-between',
    },
    propertyCustomerDataInfo: {
      '&:nth-child(2)': {
        marginLeft: 0
      }
    },
    propertyActivityHeaderWrapper: {
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '1.2px',
      '& > th': {
        padding: '0',
        verticalAlign: 'top',
      },
    },
    propertyActivityWrapper: {
      padding: '150px 0 48px',
    },
    propertyCustomerActivityTitle: {
      fontSize: '30px',
      lineHeight: '40px',
      letterSpacing: '-1.5px',
    },
    propertyActivityTable: {
      marginTop: '24px',
    },
    propertyActivityTableRow: {
      '& > td': {
        padding: '27px 10px 35px 0',
      },
      '& > td:nth-child(1)': {
        fontSize: '14px',
        lineHeight: '16,51px',
        letterSpacing: '-0.2475px',
      },
      '& > td:nth-child(2)': {
        fontSize: '14px',
        lineHeight: '16,51px',
        letterSpacing: '-0.2475px',
        whiteSpace: 'nowrap',
      },
      '& > td:nth-child(3)': {
        fontFamily: theme.typography.fontFamily.bold,
        fontSize: '14px',
        lineHeight: '17px',
        letterSpacing: '-0.33px',
      },
    },
  },
  '@media (max-width: 380px)': {
    skeletonPropertyGraph: {
      height: '190px'
    },
  },
  '@media (max-width: 320px)': {
    propertyCustomerDataInfoText: {
      fontSize: '20px'
    },
    skeletonPropertyGraph: {
      marginTop: '30px',
    },
    propertyCustomerActivityTitle: {
      fontSize: '40px'
    },
    propertyActivityTableRow: {
      '& > td': {
        padding: '6px',
      },
      '& > td:nth-child(1)': {
        fontSize: '12px'
      },
      '& > td:nth-child(2)': {
        fontSize: '12px'
      },
      '& > td:nth-child(3)': {
        fontSize: '12px'
      },
    },
  }
}))
