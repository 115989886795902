import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  '@media (max-width: 480px)': {
    svgHouses: {
      width: '188px',
      height: '92px',
    },
  }
}))
