import {makeStyles} from '@material-ui/core/styles';


export const useStyles = makeStyles((theme) => ({
  modalConfirmMsg: {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    backgroundColor: 'rgba(0,75,210,0.85)',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: '0.5s',
    pointerEvents: 'none',
    opacity: 0,
    zIndex: 9999
  },
  showModalConfirmMsg: {
    opacity: '1',
    pointerEvents: 'all'
  },
  modalConfirmMsgWrapper: {
    backgroundColor: theme.palette.bgColor.white,
    display: 'flex',
    justifyContent: 'space-around',
    boxSizing: 'border-box',
    flexDirection: 'row-reverse',
    padding: '60px',
    borderRadius: '18px',
  },
  modalConfirmMsgCloseBtn: {
    height: '100%',
    cursor: 'pointer'
  },
  modalConfirmMsgBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '40px',
    width: '319px',
    justifyContent: 'center',
  },
  modalConfirmMsgText: {
    fontFamily: theme.typography.fontFamily.medium,
    // fontSize: theme.fontSize.text.semiHeader,
    // marginBottom: '20px'
  },
  modalRequestBtn: {
    boxSizing: 'border-box',
    display: 'block',
    margin: '10px 0 0',
    padding: '20px 60px',
    outline: 'none',
    fontSize: '13px',
    textAlign: 'center',
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    lineHeight: '16px',
    fontFamily: theme.typography.fontFamily.black,
    color: theme.palette.text.white,
    backgroundColor: theme.palette.bgColor.orange,
    cursor: 'pointer',
    transition: 'all .8s ease'
  },
  '@media (max-width: 1280px)': {
    modalConfirmMsgWrapper: {
      padding: '50px',
      width: '499px'
    }
  },

  '@media (max-width: 720px)': {
    modalConfirmMsgWrapper: {
      padding: '45px',
      width: '423px'
    },
    modalConfirmMsgBlock: {
      marginRight: '20px',
    }
  },
  // '@media (max-width: 600px)': {
  //     modalConfirmMsgWrapper : {
  //         // width: '80%',
  //         // padding: '40px 20px'
  //     },
  //     modalConfirmMsgBlock : {
  //         marginRight: '45px'
  //     },
  // },
  '@media (max-width: 480px)': {
    modalConfirmMsgWrapper: {
      width: '90%',
      padding: '30px 20px',
      justifyContent: 'space-around',
    },
    modalConfirmMsgBlock: {
      marginRight: '20px',
    },
    modalConfirmMsgText: {
      marginBottom: '10px'
    },
  },
  // '@media (max-width: 380px)' : {
  //     modalConfirmMsgWrapper : {
  //         padding: '30px 20px'
  //     },
  //     modalConfirmMsgBlock : {
  //         marginRight: '20px',
  //         fontSize: '18px'
  //     },
  // },
  // '@media (max-width: 320px)' : {
  //     modalConfirmMsgWrapper : {
  //         padding: '20px 10px'
  //     },
  //     modalConfirmMsgBlock : {
  //         marginRight: '15px',
  //         fontSize: '16px'
  //     },
  // },

  // LANDSCAPE ORIENTATION
  '@media (max-width: 1280px) and (orientation: landscape)': {
    modalConfirmMsgWrapper: {
      width: '499px'
    },
  },
  '@media (max-width: 815px) and (orientation: landscape)': {
    modalConfirmMsg: {
      overflow: 'scroll',
    },
    modalConfirmMsgWrapper: {
      width: '497px'
    },
  },
  '@media (max-width: 720px) and (orientation: landscape)': {
    modalConfirmMsgWrapper: {
      width: '473px'
    },
  },
  '@media (max-width: 670px) and (orientation: landscape)': {
    modalConfirmMsg: {}
  },
  '@media (max-width: 560px) and (orientation: landscape)': {
    modalConfirmMsgWrapper: {},
  }
}));

export const useStylesConfMsg = makeStyles((theme) => ({
  messagesWrapperHeader: {
    margin: '0 0 20px',
    fontSize: theme.fontSize.text.subHeader,
  },
  '@media (max-width: 480px)': {
    fontSize: '20px',
  },
  '@media (max-width: 380px)': {
    fontSize: '18px',
  },
  '@media (max-width: 320px)': {
    fontSize: '16px',
  },

  // LANDSCAPE ORIENTATION
  '@media (max-width: 815px) and (orientation: landscape)': {
    modalConfirmMsg: {
      overflow: 'scroll',
      padding: '30px 40px',
    }
  },
}));
