export const ROUTES = {
    HOME: '/',
    TOKENS: '/tokens',
    CARD: '/card',
    FAQ: '/faq',
    ABOUT: '/about',
    CONTACT: '/contact',
    LEDGER: '/ledger',
    PROPERTY: '/ledger/:id',
    DASHBOARD: '/dashboard',
    SETTINGS: '/account',
    INSTRUCTIONS: '/instructions',
    ADMIN: '/admin',
    POLICY: '/privacy-policy',
    WHITEPAPER: '/whitepaper',
    TERMSOFSERV: '/terms-of-use',
    CONSENT: '/consent-to-electronic-disclosure',
    CONTENT_PRINT_VIEW: '/consent-to-electronic-disclosure#print',
    ALL: '/(card|dashboard|ledger-records|account|admin|instructions|privacy-policy|terms-of-use|consent-to-electronic-disclosure)',
    LOGIN: '#login',
    FORGOT_PASSWORD: '#forgot-password',
    RESET_PASSWORD: '#reset-password',
    SIGNUP: '#sign-up',
    CONFIRM_USER: '#confirm-user',
};

export const AUTH_ERRORS = {
    INVALID_REQUEST: 'The email or password provided is not valid.',
    ACCOUNT_EXISTS: 'An account with this email already exists.',
    LOGIN_FAILED: 'Login failed. Please check your email and password and try again',
    USER_CONFIRM_FAILED: 'User confirm failed. Please try again later',
    LOGIN_REQUEST: 'Please, Sign In to continue!',
    UNKNOWN_ERROR: 'Something went wrong - please try again later',
};

export const MESSAGES = {
    REGISTER_ACCEPTED: 'Please check your mailbox',
    RESET_PASSWORD_ACCEPTED: 'Please check your mailbox'
}

export const ERROR_MSG = {
    COMMON: 'Unexpected error happened!',
};

export const ACTION_TYPES = {
    UPDATE_STATE: 'UPDATE_STATE',
    SET_CURRENT_USER: 'SET_CURRENT_USER',
};

export const TRANSACTION_TYPES = {
    BUY: 'buy',
    SELL: 'sell',
};

export const TRANSACTION_ACTIONS = {
    APPROVE: 'APPROVE',
    DENY: 'DENY',
};

export const SETTING_KEYS = {
    STARTING_COIN_PRICE: 'STARTING_COIN_PRICE',
    ADMIN_COIN_PRICE: 'ADMIN_COIN_PRICE',
    AUTO_PRICING: 'AUTO_PRICING',
};
