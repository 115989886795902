import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import useApi from '../../hooks/useApi';
import {useStyles} from "./style";
import {useAppState} from "../../app-state";

const ReviewPPM = (props) => {
    const classes = useStyles();
    const { state, actions } = useAppState();
    const api = useApi();

    const acceptPPM = () => {
        api.acceptPPM()
            .then(data => {
                // actions.updateState({ messages: 'Accepted successfully!'});
                props.onClose();
                props.onPPMAccepted(data);
            })
            .catch(error => {
                console.error(error);
                actions.updateState({ messages: 'Unexpected error happened!'});
                props.onClose();
            });
    };

    return (
        <Dialog disableScrollLock style={{zIndex: 9999}} fullScreen={true} fullWidth={true} open={props.open} onClose={props.onClose} maxWidth='lg'>
            <DialogTitle>Review PPM</DialogTitle>
            <DialogContent>
                <object type="application/pdf" className={classes.ppmViewer} data='https://cdn.hauscoin.com/HausCoin_PPM_2022.pdf'>
                    <p className={classes.dialogText}>Please download the PPM to review.</p>
                    <a target="_blank" className={classes.dialogLink} href="https://cdn.hauscoin.com/HausCoin_PPM_2022.pdf">Download PDF</a>
                </object>
                <div className={classes.mobilePPM}>
                    <p className={classes.dialogText}>Please download the PPM to review.</p>
                    <a target="_blank" className={classes.dialogLink} href="https://cdn.hauscoin.com/HausCoin_PPM_2022.pdf">Download PDF</a>
                </div>
            </DialogContent>
            <DialogActions>
                <Button style={{ backgroundColor: 'transparent' }} classes={{label: classes.modalPpmBtn}} onClick={props.onClose}>Close</Button>
                {!props.ppmAcceptedAt && <Button style={{ backgroundColor: 'transparent' }} classes={{label: classes.modalPpmBtn}} onClick={acceptPPM}>Accept</Button>}
            </DialogActions>
        </Dialog>
    );
};

export default ReviewPPM;
