import React, { useEffect, useReducer } from 'react';
import { useAppState } from 'marketplace/app-state';
import { useHistory } from 'react-router-dom';
import { validateEmail, validateUserName, validateUserLastName } from '../../../common/validator';
import closeBtn from 'assets/img/close-btn.svg';
import { useStyles } from './style.js';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import useApi from 'marketplace/hooks/useApi';
import {AUTH_ERRORS, MESSAGES, ROUTES} from '../../constans';
import Fade from '@material-ui/core/Fade';

const initState = {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    isFirstNameValid: false,
    isLastNameValid: false,
    isEmailValid: false,
    errorNameMsg: null,
    errorLastNameMsg: null,
    errorEmailMsg: null,
    isDisabled: true,
    isLoading: false,
};

const ModalCreateAcc = () => {
    const classes = useStyles();
    const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), initState);
    const history = useHistory();
    const api = useApi();
    const { state: appState, actions } = useAppState();

    useEffect(() => {
        if (state.isLoading) {
            setState({ isDisabled: true });
            return;
        }
        setState({ isDisabled: !state.isFirstNameValid || !state.isLastNameValid || !state.isEmailValid });
    }, [state.isLoading, state.isFirstNameValid, state.isLastNameValid, state.isEmailValid]);

    useEffect(() => {
        validateFirstName(false);
    }, [state.firstName])

    useEffect(() => {
        validateLastName(false);
    }, [state.lastName])

    useEffect(() => {
        validateEmailHandler(false);
    }, [state.email])

    function validateFirstName(showErrorMsg= true) {
        const validationUserNameMsg = validateUserName(state.firstName);
        if (validationUserNameMsg) {
            setState({ errorNameMsg: showErrorMsg && validationUserNameMsg, isFirstNameValid: false });
            return;
        }
        setState({ errorNameMsg: null, isFirstNameValid: true });
    }

    function validateLastName(showErrorMsg= true) {
        const validationLastNameMsg = validateUserLastName(state.lastName);
        if (validationLastNameMsg) {
            setState({ errorLastNameMsg: showErrorMsg && validationLastNameMsg, isLastNameValid: false });
            return;
        }
        setState({ errorLastNameMsg: null, isLastNameValid: true });
    }

    function validateEmailHandler(showErrorMsg= true) {
        const validationCreatEmailMsg = validateEmail(state.email);

        if (validationCreatEmailMsg) {
            setState({ errorEmailMsg: showErrorMsg && validationCreatEmailMsg, isEmailValid: false });
            return;
        }
        setState({ errorEmailMsg: null, isEmailValid: true });
    }

    function resetValues() {
        setState({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            isFirstNameValid: false,
            isLastNameValid: false,
            isEmailValid: false,
            isDisabled: true,
        });
    }

    const onBlurHandler = (e) => {
        switch (e.target.name) {
            case 'firstName':
                validateFirstName();
                break;
            case 'lastName':
                validateLastName();
                break;
            case 'email':
                validateEmailHandler();
                break;
        }
    };

    const createAccSubmit = (e) => {
        e.preventDefault();
        setState({ isLoading: true, isDisabled: true });
        api.register({
            firstName: state.firstName,
            lastName: state.lastName,
            email: state.email,
        })
            .then((res) => {
                switch (res.code) {
                    case 202:
                        actions.updateState({ modalActive: false, messages:
                            <div>
                                <h3>Almost done!</h3>
                                <p>
                                    We sent an email to you with a temporary password. Please click the link to confirm
                                    your email and set a new password.
                                </p>
                            </div>
                        });
                        break;
                    case 400:
                        actions.updateState({ messages: res.message });
                }
            })
            .catch((err) => {
                console.error(err);
                actions.updateState({ modalActive: false, messages: AUTH_ERRORS.UNKNOWN_ERROR });
            })
            .finally(() => {
                setState({ isLoading: false });
            });
    };

    const createAccCloseHandler = () => {
        setState({ isLoading: false });
        actions.updateState({ modalActive: false });
        history.push(window.location.pathname);
        resetValues();
    };

    const passLogInHandler = () => {
        actions.updateState({ modalSignInActive: true });
        actions.updateState({ modalActive: false });
        resetValues();
    };

    return (
        <div>
            <Fade in={true} timeout={200}>
            <div
                className={clsx(classes.modal, {[classes.showModal]: appState.modalActive,})}
                style={{ padding: 0 }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={classes.modalWrapper} onClick={(e) => e.stopPropagation()}>
                    <div className={classes.modalCloseBtn} onClick={createAccCloseHandler}>
                        <img className={classes.modalImg} src={closeBtn} alt="close" />
                    </div>
                    <div className={classes.modalInputBlock}>
                        <form onSubmit={createAccSubmit} className={classes.modalCreateForm}>
                            <div className={classes.modalInputField}>
                                <label className={classes.modalInputLabel} htmlFor="firstName">
                                    First name
                                    <input
                                        className={classes.modalInput}
                                        onChange={(e) => setState({ firstName: e.target.value.trim() })}
                                        onBlur={(e) => onBlurHandler(e)}
                                        value={state.firstName}
                                        name="firstName"
                                        type="text"
                                        maxLength="255"
                                        autoComplete="off"
                                        placeholder="First"
                                    />
                                </label>
                            </div>
                            <div className={classes.wrapperForMsg}>
                                {!state.isFirstNameValid && <div className={classes.errMsg}>{state.errorNameMsg}</div>}
                            </div>
                            <div className={classes.modalInputField}>
                                <label className={classes.modalInputLabel} htmlFor="lastName">
                                    Last name
                                    <input
                                        className={classes.modalInput}
                                        onChange={(e) => setState({ lastName: e.target.value.trim() })}
                                        onBlur={(e) => onBlurHandler(e)}
                                        value={state.lastName}
                                        name="lastName"
                                        type="text"
                                        maxLength="255"
                                        autoComplete="off"
                                        placeholder="Last"
                                    />
                                </label>
                            </div>
                            <div className={classes.wrapperForMsg}>
                                {!state.isLastNameValid && <div className={classes.errMsg}>{state.errorLastNameMsg}</div>}
                            </div>
                            <div className={classes.modalInputField}>
                                <label className={classes.modalInputLabel} htmlFor="email">
                                    Email
                                    <input
                                        className={classes.modalInput}
                                        onChange={(e) => setState({ email: e.target.value.trim() })}
                                        onBlur={(e) => onBlurHandler(e)}
                                        value={state.email}
                                        type="email"
                                        maxLength="255"
                                        name="email"
                                        autoComplete="off"
                                        placeholder="email@abc.com"
                                        onKeyDown={(e) => {
                                            if (e.key === ' ') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </label>
                            </div>
                            <div className={classes.wrapperForMsg}>
                                {!state.isEmailValid && <div className={classes.errMsg}>{state.errorEmailMsg}</div>}
                            </div>
                            <button className={classes.modalCreatBtn} disabled={state.isDisabled} type="submit">
                                {state.isLoading ? <CircularProgress size={18} color="inherit" /> : 'CREATE ACCOUNT'}
                            </button>
                        </form>
                        <p className={classes.modalSignInText}>
                            or
                            <a className={classes.modalSignInTextLink} onClick={passLogInHandler} href="#login">
                                Sign in
                            </a>
                        </p>
                    </div>
                </div>
            </div>
            </Fade>
        </div>
    );
};

export default ModalCreateAcc;
