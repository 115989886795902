import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import { SnackbarProvider } from 'notistack';
import ContextProvider from './providers/ContextProvider';
import theme from 'themes/theme';
import App from './App';
import ErrorBoundary from 'common/components/ErrorBoundary';
import 'common/index.css';
import {BrowserRouter as Router} from "react-router-dom";

ReactDOM.render(
    <ErrorBoundary>
        <ThemeProvider theme={theme}>
            <SnackbarProvider
                maxSnack={2}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Router>
                    <ContextProvider>
                        <App />
                    </ContextProvider>
                </Router>
            </SnackbarProvider>
        </ThemeProvider>
    </ErrorBoundary>,
    document.getElementById('root')
);

