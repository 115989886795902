import React from 'react';
import Container from '@material-ui/core/Container';
import { useStyles } from '../../style.js';

const AboutPage = () => {
    const classes = useStyles();

    return (
        <section className={classes.contentSection}>
            <Container className={classes.contentContainer} fixed>
                <h4 className={classes.contentPageTitle}>About</h4>
                <p className={classes.contentText}>
                    HausCoin is an SEC Reg D security token* backed 1:1 by a growing portfolio of home equity.
                </p>
                <p className={classes.contentText}>
                    HausCoin allows investors to invest directly in home equity, without the cost structures of debt,
                    operations or maintenance, and delivers a true representation of home equity prices. HausCoin
                    immediately reflects the value of the portfolio in each token. Assets are equity interests secured
                    through the Haus co-investment, a real-estate option contract, recorded with a deed of trust and
                    memorandum of agreement on each home. By empowering investors with access to HausCoin, Haus is able
                    to provide a completely new and affordable home financing solution, bringing stable housing within
                    reach for millions of Americans. HausCoin does this while giving investors access to an asset class
                    previously difficult and cumbersome to invest in.
                </p>
                <p className={classes.contentText}>
                    Haus and HausCoin are backed by top investors across the venture capital and investor space,
                    including Montage Ventures, Expa, BlueRun Ventures, Leap Global Partners, RIT Capital, Correlation
                    Ventures, Assurant Growth and more.
                </p>
                <p className={classes.contentText}>
                    *Registered through a 506c exception
                </p>
            </Container>
        </section>

    )
}

export default AboutPage;