import React, { createContext, memo, useCallback, useMemo, useReducer } from 'react';
import { ADMIN_STATE_ACTIONS } from '../constants';
import ApiAdmin from '../lib/ApiAdmin';
import CognitoAdminPool from '../lib/CognitoAdminPool';

const initState = {
    currentUser: null,
    modalSignInActive: false,
    modalConfirmUserActive: false,
};

function reducer(state, action) {
    switch (action.type) {
        case ADMIN_STATE_ACTIONS.UPDATE_STATE:
            return { ...state, ...action.payload };

        default:
            throw new Error(`Unknown action type ${action.type}`);
    }
}

export const AdminStateContext = createContext({
    state: initState,
    actions: {},
    dispatch: () => {},
});

const AdminStateProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initState);

    const apiClient = useMemo(() => {
        return new ApiAdmin();
    }, []);

    const cognitoClient = useMemo(() => {
        return new CognitoAdminPool(apiClient);
    }, []);

    const updateState = useCallback(
        (changedState) => {
            dispatch({
                type: ADMIN_STATE_ACTIONS.UPDATE_STATE,
                payload: changedState,
            });
        },
        [dispatch],
    );

    return (
        <AdminStateContext.Provider value={{ state, actions: { updateState }, apiClient, cognitoClient, dispatch }}>
            {children}
        </AdminStateContext.Provider>
    );
};

export default memo(AdminStateProvider);
