import React from 'react';
import Typography from '@material-ui/core/Typography';
import {useStyles} from "./style";

const Heading = ({variant, className, children}) => {
  const classes = useStyles();

  return (
    <Typography variant={variant} className={`${classes.heading} ${className}`}>
      {children}
    </Typography>
  );
};

export default Heading;
