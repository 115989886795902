import React, {memo, useMemo} from 'react';
import {COIN_STATUS, ADDRESS_STATUS} from "../../../common/constants";
import {useStyles} from "./style";

const RenderTag = ({tagClassName, tag}) => {
  const classes = useStyles();

  const renderTag = useMemo(() => {
    switch (tag) {
      case COIN_STATUS.TRENDING:
        return 'TRENDING';
      case COIN_STATUS.NEW:
        return 'NEW';
      case COIN_STATUS.SOON:
        return 'SOON';
      case ADDRESS_STATUS.INDIVIDUAL:
        return 'LMI INDIVIDUAL';
      case ADDRESS_STATUS.LOCATION:
        return 'LMI LOCATION';
    }
  }, [tag])

  return (
    <div className={`${tag ? classes.cardTitleTag : classes.cardTitleTagHide} ${tagClassName}`}>
      {renderTag}
    </div>
  );
};

export default memo(RenderTag);
