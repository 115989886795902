export function isValidated(currentUser) {
    let isValidated = true;

    if (!currentUser.ppmAcceptedAt && isValidated) {
        isValidated = false;
    }

    const hasVerifiedKYC = currentUser.kycProviders?.some(provider => {
        return provider.isVerified;
    });

    if (!hasVerifiedKYC && isValidated) {
        isValidated = false;
    }

    if (!currentUser.bankAccount && isValidated) {
        isValidated = false;
    }

    return isValidated;
}
