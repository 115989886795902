import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    typography: {
        fontFamily: {
            regular: "Basis Grotesque Pro",
            black: "Basis Grotesque Pro-Black",
            bold: "Basis Grotesque Pro-Bold",
            medium: "Basis Grotesque Pro-Medium",
        },
    },
    palette: {
        primary: {
          main: '#000000',
        },
        secondary: {
          main: '#979797'
        },
        bgColor: {
            blue: '#0650CF',
            lightBlue: '#468AFF',
            black: '#000000',
            orange: '#FF4E4A',
            lightGrey: '#979797',
            grey: '#F2F2F2',
            darkGrey: '#808080',
            white: '#FFFFFF',
            active: '#FF0600',
        },
        text: {
            main: '#000',
            white: '#FFFFFF',
            grey: '#979797',
            lightGrey: '#E5E5E5',
            darkBlue: '#004BD2',
            blue: '#0650CF',
            lightBlue: '#468AFF',
            orange: '#FF4E4A',
        },
    },
    fontSize: {
        text: {
            header: 72,
            subHeader: 24,
            bigHeader: 27,
            mediumHeader: 30,
            semiHeader: 20,
            big: 80,
            main: 60,
            semiMain: 48,
            total: 36,
            semiTotal: 18,
            medium: 16,
            mediumSmall: 14,
            small: 13,
        },
    },
    fontWeight: {
        text: {
            bold: 900,
            semiBold: 700,
            normal: 400,
        },
    },
    props: {
        MuiPopover: {
            style: {
                zIndex: 9998
            }
        }
    },
});

export default theme;
