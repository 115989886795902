import React from 'react';
import clsx from 'clsx';
import ReactJson from 'react-json-view';
import closeBtn from 'assets/img/close-btn.svg';
import { useStyles } from 'admin/components/EventDataPopup/styles';
import { useStyles as useModalClasses } from 'marketplace/components/Modal/style';

const EventDataPopup = ({ event, onClose: closePopup }) => {
    const classes = useStyles();
    const modalClasses = useModalClasses();

    return (
        <div className={clsx(modalClasses.modal, modalClasses.showModal)}>
            <div className={clsx(modalClasses.modalWrapper, classes.container)} onClick={(e) => e.stopPropagation()}>
                <div className={classes.popupHeader}>
                    <p className={classes.eventTitle}>Event</p>
                    <div className={modalClasses.modalCloseBtn} onClick={closePopup}>
                        <img className={modalClasses.modalImg} src={closeBtn} alt="close" />
                    </div>
                </div>

                <div className={classes.eventContent}>
                    <ReactJson displayObjectSize={false} displayDataTypes={false} src={event.payload} />
                </div>
            </div>
        </div>
    );
};

export default React.memo(EventDataPopup);
