import React, { useState, useEffect } from 'react';
import Skeleton from "@material-ui/lab/Skeleton";
import { useStyles } from "./style";
import { useStyles as useSkeletonStyles } from "../../components/Skeleton/style";
import CardGraph from "../../components/Сharts/CardGraph";
import ContentContainer from "../../components/ContentContainer/ContentContainer";
import cross from "../../../assets/img/close-btn.svg";
import {ROUTES} from "../../constans";
import RenderTag from "../../components/RenderTag/RenderTag";
import {NavLink, useHistory, useParams} from "react-router-dom";
import arrowDown from "../../../assets/img/select-arrow.svg";
import LedgerButton from "../../components/LedgerButton/LedgerButton";
import {isValidated} from "../../util/helpers";
import {useAppState} from "../../app-state";
import ToolTipBtn from "../../components/TooltipBtn/ToolTipBtn";
import CONFIG_COIN from "../../CoinConfig/CoinConfig";
import { formatTokenData } from "../../lib/tokens";
import DataItem from "../../../common/components/DataItem";

const CardPage = ({tag}) => {
  const classes = useStyles();
  const skeletonClasses = useSkeletonStyles();
  const {state, actions} = useAppState();
  const history = useHistory();
  const params = useParams();
  const coin = CONFIG_COIN.find(c => `${c.id}` === params.id);
  localStorage.setItem('selectedCoinType', params.id);
  const [cardCoin, setCardCoin] = useState({
    ...coin,
    price: [],
    circulation: [],
    dates: [],
  });
  const [cardPageLoading, setCardPageLoading] = useState(true);
  const [showSkeletons, setShowSkeletons] = useState(true)
  const { apiClient } = useAppState();

  const goToPrevUrl = () => {
    history.goBack();
  }

  function TooltipText(props) {
    return (
      <React.Fragment>
        To purchase HausCoin, validate your accreditation <a href={'/account'} className={classes.tooltipLink}>here</a>.
      </React.Fragment>
    );
  }

  useEffect(async () => {
    if (!cardPageLoading) {
      return;
    }
    setCardPageLoading(false)
    const graphData = await apiClient.getGraph(coin.coinType);
    setCardCoin({
        ...coin,
        ...graphData,
        price: graphData.price.map(p => parseFloat(p.value)),
        circulation: graphData.circulation.map(c => parseFloat(c.value)),
        dates: graphData.price.map(p => p.date),
        currentPrice: Number(graphData.price[graphData.price.length - 1].value),
        currentCirculation: Number(graphData.circulation[graphData.circulation.length - 1].value),
    })
    setShowSkeletons(false);
  }, [cardCoin])

  return (
    <div className={classes.cardPage}>
      <ContentContainer>
        <div className={classes.cardPageWrapper}>
          <img className={classes.cardPageCrossImg} src={cross} alt={"close"} onClick={goToPrevUrl}/>
          <div className={classes.cardPageTitle}>
            <div className={classes.cardPageTitleImgWrapper}>
              <img className={classes.cardPageTitleImg} src={cardCoin.logo} alt={"city"}/>
            </div>
            <div className={classes.cardPageTitleTextWrapper}>
              <div className={classes.cardPageTitleText}>

                <div className={classes.cardPageTitleWrapper}>
                  {cardCoin.title}&nbsp;
                  {cardCoin.detailTitle && <span className={classes.cardPageFullTitle}>{cardCoin.stateCode}</span>}
                  <RenderTag tag={cardCoin.status} tagClassName={classes.cardPageTagStyle} />
                </div>

                {!cardCoin.detailTitle && <span className={classes.cardPageTitleTextSpan}>{cardCoin.stateCode}</span>}

                <div className={classes.cardPageDescription}>
                  {cardCoin.about}
                </div>
                <RenderTag tag={cardCoin.status} tagClassName={classes.cardPageTagStyleMob} />
              </div>
            </div>
          </div>
          <div className={classes.cardPageDescriptionMob}>{cardCoin.about}</div>
          <div className={classes.cardPageHeaderInfoWrapper}>
            <div className={classes.cardPageTokenValue}>
              <div className={classes.cardPageHeaderTokenTitle}>
                Token value
                {!showSkeletons && <span className={classes.cardPageHeaderTokenTitleValue}>
                  <DataItem value={cardCoin?.currentPrice} roundFour={true} startCharacter={'$'}/>
                </span>}
                {showSkeletons && <div className={skeletonClasses.cardPageHeaderValue}>
                  <Skeleton variant="rect" width="100%" height="100%" />
                </div>}
              </div>
              <div className={classes.cardPageHeaderCirculationTitle}>
                Circulation
                {!showSkeletons && <span className={classes.cardPageHeaderBigTitle}>
                  <DataItem value={cardCoin?.currentCirculation} fullRound={true} />
                </span>}
                {showSkeletons && <div className={skeletonClasses.cardPageHeaderValue}>
                  <Skeleton variant="rect" width="100%" height="100%" />
                </div>}
              </div>
            </div>
          </div>
          <div className={classes.cardPageGraphWrapper}>
            {cardCoin &&
            <CardGraph
              price={cardCoin.price}
              circulation={cardCoin.circulation}
              dates={cardCoin.dates}
              showToolTip
            />}
            <div className={classes.dashboardGraphBottomTitles}>
              <div className={classes.dashboardGraphBottomTitle}>
                <span className={classes.dashboardGraphBottomLine}></span>
                Equity price
              </div>
              <div className={classes.dashboardGraphBottomTitle}>
                <span className={classes.dashboardGraphBottomLine}></span>
                Hauscoin issued
              </div>
            </div>
            <div className={classes.cardPageGraphBtnWrapper}>
              {
                state.isAuth && !isValidated(state.currentUser) ?
                  <ToolTipBtn
                    className={classes.cardTooltip}
                    title={<TooltipText/>}
                    placement="top"
                  >
                    <a className={classes.cardBtn} onClick={() => {
                      if (isValidated(state.currentUser)) {
                        actions.updateState({ buySellActive: true });
                      } else {
                        history.push(ROUTES.SETTINGS);
                      }
                    }}
                    >
                      BUY
                    </a>
                  </ToolTipBtn> :
                  (
                    <a className={classes.cardBtn} onClick={() => {
                      if (!state.isAuth) {
                        history.push(ROUTES.HOME + '#login');
                      } else {
                        actions.updateState({buySellActive: true});
                      }
                    }}
                    >
                      BUY
                    </a>
                  )
              }
              <LedgerButton />
            </div>
          </div>
          <div className={classes.cardPageCardInfo}>
            <div className={classes.cardPageCardInfoTitle}>
              Average annual appreciation
              {!showSkeletons && <span className={classes.cardPageCardInfoBigTitle}>
                {cardCoin.avgAppreciation.toFixed(2)}%
              </span>}
              {showSkeletons && <span className={classes.cardPageCardInfoBigTitle}>
                <Skeleton variant="text" width="100%" height="100%" />
              </span>}
            </div>
            <div className={classes.cardPageCardInfoTitle}>
              Average customer ownership
              {!showSkeletons &&
              <span className={classes.cardPageCardInfoBigTitle}>{cardCoin.avgOwnership.toFixed(2)}%</span>
              }
              {showSkeletons && <span className={classes.cardPageCardInfoBigTitle}>
                <Skeleton variant="text" width={"100%"} height={"100%"} />
              </span>}
            </div>
            <div className={classes.cardPageCardInfoTitle}>
              Average home value
              {!showSkeletons && <span className={classes.cardPageCardInfoBigTitle}>
                ${Number(parseInt(cardCoin.avgValue)).toLocaleString()}
              </span>}
              {showSkeletons && <span className={classes.cardPageCardInfoBigTitle}>
                <Skeleton variant="text" width={"100%"} height={"100%"} />
              </span>}
            </div>
          </div>
        </div>
      </ContentContainer>
    </div>
  );
};

export default CardPage;
