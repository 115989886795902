import { differenceInMinutes } from 'date-fns';

const LS_KEYS = {
    // LATEST_LOGIN_TIMESTAMP: 'latest-auth-ts',
    BASIC_USER_DATA: 'user-data',
};

// Expiration in minutes
const AUTH_TOKEN_EXPIRATION = 45;

export default class BaseCognitoPool {
    saveUserDataToLS(basicUserData) {
        // this.#createLastLoginTimestamp();
        this.#saveJSON(LS_KEYS.BASIC_USER_DATA, basicUserData);
    }

    getUserDataFromLS() {
        // const timestamp = localStorage.getItem(LS_KEYS.LATEST_LOGIN_TIMESTAMP);
        // if (!timestamp) {
        //     return this.#clearAuthData();
        // }

        // const difference = differenceInMinutes(new Date(), new Date(timestamp));
        // if (difference > AUTH_TOKEN_EXPIRATION) {
        //     return this.#clearAuthData();
        // }

        const dataStr = localStorage.getItem(LS_KEYS.BASIC_USER_DATA);
        return JSON.parse(dataStr);
    }

    clearUserDataInLS() {
        this.clearAuthData();
    }

    // #createLastLoginTimestamp() {
    //     const timestamp = new Date();
    //     localStorage.setItem(LS_KEYS.LATEST_LOGIN_TIMESTAMP, timestamp.toString());
    // }

    #saveJSON(key, json) {
        const jsonStr = JSON.stringify(json);
        localStorage.setItem(LS_KEYS.BASIC_USER_DATA, jsonStr);
    }

    clearAuthData() {
        // localStorage.removeItem(LS_KEYS.LATEST_LOGIN_TIMESTAMP);
        localStorage.removeItem(LS_KEYS.BASIC_USER_DATA);
    }
}
