import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    settings : {
        backgroundColor: theme.palette.bgColor.grey,
        padding: '180px 0 120px'
    },
    settingsContainer : {
        padding: '0 20px'
    },
    settingsBigTitleWrapper : {
        display: 'flex',
        alignItems: 'center',
        margin: '22px 0 40px'
    },
    settingsBlockTitle : {
        fontSize: theme.fontSize.text.small,
        textTransform: 'uppercase',
        margin: 0,
        letterSpacing: '1px'
    },
    settingsBigTitle : {
        fontSize: theme.fontSize.text.main,
        margin: 0
    },
    settingsImg : {
      marginLeft: '35px'
    },
    validationText : {
        fontFamily: theme.typography.fontFamily.medium,
        fontSize: theme.fontSize.text.semiHeader,
    },
    validationLink: {
        color: theme.palette.bgColor.orange,
    },
    settingsBtn : {
        display: 'block',
        width: '200px',
        textAlign: 'center',
        textTransform: 'uppercase',
        padding: '20px 40px',
        fontSize: theme.fontSize.text.small,
        color: theme.palette.text.white,
        backgroundColor: theme.palette.bgColor.orange,
        cursor: 'pointer',
        transition: 'all .8s ease',
        '&:hover' : {
            backgroundColor: theme.palette.bgColor.active,
        }
    },
    review : {
        backgroundColor: theme.palette.bgColor.white,
        padding: '120px 0'
    },
    settingsStatus : {
        fontSize: theme.fontSize.text.small,
        color: theme.palette.text.blue,
        letterSpacing: '1px'
    },
    settingsStatusLink : {
        borderBottom: '2px solid',
        borderColor: theme.palette.text.blue,
        marginLeft: '5px',
        cursor: 'pointer',
        color: theme.palette.text.blue
    },
    ppmViewer: {
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
    },
    mobilePPM: {
        display: 'none'
    },
    dialogText : {
        fontFamily: theme.typography.fontFamily.medium,
        fontSize: theme.fontSize.text.semiHeader,
        marginTop: '0'
    },
    dialogLink: {
        display: 'block',
        boxSizing: "border-box",
        width: '200px',
        textTransform: 'uppercase',
        textAlign: 'center',
        backgroundColor: theme.palette.bgColor.orange,
        outline: 'none',
        padding: '20px 60px',
        color: theme.palette.text.white,
        fontSize: theme.fontSize.text.small,
        cursor: 'pointer',
        letterSpacing: '1px',
        display: 'flex',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
    },
    modalPpmBtn : {
        padding: '5px 10px',
        border: 'none',
        borderRadius: 'none',
        color: theme.palette.text.white,
        backgroundColor: theme.palette.bgColor.orange,
        transition: 'all .8s ease',
        '&:hover': {
            backgroundColor: theme.palette.bgColor.active
        }
    },
    '@media(max-width: 600px)': {
        settings : {
            padding: '120px 0 80px'
        },
        review : {
            padding: '80px 0'
        },
        settingsBigTitle : {
            fontSize: '35px'
        },
        settingsBigTitleWrapper:{
            margin: '15px 0 25px'
        },
        ppmViewer: {
            display: 'none'
        },
        mobilePPM: {
            display: 'block'
        }
    },
    '@media(max-width: 480px)': {
        settingsBigTitleWrapper : {
            justifyContent: 'space-between'
        },
        settingsBigTitle : {
            fontSize: '25px'
        },
        validationText : {
            fontSize: '14px',
        },
        settingsBtn : {
            width: '100%',
            padding: '20px 0'
        },
        settingsImg : {
            marginLeft: 0
        }
    },
    '@media(max-width: 380px)': {
        settingsImg : {
            width: '35px',
            marginLeft: '8px'
        },
        modalPpmBtn : {
            padding: '2px 5px',
        },
    },
    '@media(max-width: 320px)': {
        settingsBigTitleWrapper : {
            margin: '22px 0'
        },
        settingsBtn : {
            padding: '10px 0',
            fontSize: '10px'
        },
        settingsStatus : {
            fontSize: '10px'
        }
    }
}))
