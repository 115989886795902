import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cardTitleTag: {
    height: 'fit-content',
    position: 'absolute',
    top: '0',
    right: '0',
    display: 'block',
    textTransform: 'uppercase',
    backgroundColor: theme.palette.bgColor.orange,
    borderRadius: '100px',
    fontFamily: theme.typography.fontFamily.black,
    fontSize: '8px',
    lineHeight: '8px',
    letterSpacing: '0.1em',
    color: theme.palette.text.white,
    padding: '8px 12px',
    textAlign: 'center'
  },
  cardTitleTagHide: {
    display: 'none',
  }
}))
