import React, { memo } from 'react';
import Container from '@material-ui/core/Container';
import facebook from 'assets/img/facebook.svg';
import twitter from 'assets/img/twitter.svg';
import instagram from 'assets/img/instagram.svg';
import lender from 'assets/img/lenderLogo.svg';
import { useStyles } from './style.js';
import {NavLink} from "react-router-dom";
import {ROUTES} from "../../../marketplace/constans";

const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.footerWrapper}>
            <Container fixed className={classes.footerContainer}>
                <footer className={classes.footer}>
                    <div className={classes.footerBlock}>
                        <div className={classes.footerBlockContacts} style={{display: 'none'}}>
                            <form className={classes.footerForm}>
                                <label className={classes.footerFormTitle}>Tips on your inbox</label>
                                <div className={classes.footerInputField}>
                                    <input
                                        className={classes.footerFormInput}
                                        type="text"
                                        autoComplete="none"
                                        placeholder="Email address"
                                    />
                                </div>
                                <button className={classes.footerFormBtn} type="submit">
                                    Subscribe
                                </button>
                            </form>
                        </div>
                        <div className={classes.footerNav}>
                            <ul className={classes.footerNavLists} style={{display: 'none'}}>
                                <li className={classes.footerNavList}>Company</li>
                                <li className={classes.footerNavList}>
                                    <a className={classes.footerNavListItem} href="#">
                                        About
                                    </a>
                                </li>
                                <li className={classes.footerNavList}>
                                    <a className={classes.footerNavListItem} href="#">
                                        Jobs
                                    </a>
                                </li>
                                <li className={classes.footerNavList}>
                                    <a className={classes.footerNavListItem} href="#">
                                        Calculator
                                    </a>
                                </li>
                            </ul>
                            <ul className={classes.footerNavLists} style={{display: 'none'}}>
                                <li className={classes.footerNavList}>Learn more</li>
                                <li className={classes.footerNavList}>
                                    <a className={classes.footerNavListItem} href="#">
                                        Resources
                                    </a>
                                </li>
                                <li className={classes.footerNavList}>
                                    <a className={classes.footerNavListItem} href="#">
                                        Faqs
                                    </a>
                                </li>
                                <li className={classes.footerNavList}>
                                    <a className={classes.footerNavListItem} href="#">
                                        Press room
                                    </a>
                                </li>
                            </ul>
                            <ul className={classes.footerNavLists} style={{margin: '0 0 10px'}}>
                                <li className={classes.footerNavList}>Info</li>
                              <li className={classes.footerNavList}>
                                <NavLink to={ROUTES.ABOUT} className={classes.footerNavListItem}>
                                  About
                                </NavLink>
                              </li>
                              <li className={classes.footerNavList}>
                                <NavLink to={ROUTES.CONTACT} className={classes.footerNavListItem}>
                                  Contact
                                </NavLink>
                              </li>
                                <li className={classes.footerNavList}>
                                    <NavLink to={ROUTES.POLICY} className={classes.footerNavListItem}>
                                        Privacy Policy
                                    </NavLink>
                                </li>
                                <li className={classes.footerNavList}>
                                    <NavLink to={ROUTES.TERMSOFSERV} className={classes.footerNavListItem}>
                                        Terms of Service
                                    </NavLink>
                                </li>
                                <li className={classes.footerNavList}>
                                    <NavLink to={ROUTES.CONSENT} className={classes.footerNavListItem}>
                                        Consent to Electronic Disclosure
                                    </NavLink>
                                </li>
                                {/*<li className={classes.footerNavList}>*/}
                                {/*    <a className={classes.footerNavListItem} href="#">*/}
                                {/*        Affiliated Business (AFBD)*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                {/*<li className={classes.footerNavList}>*/}
                                {/*    <a className={classes.footerNavListItem} href="#">*/}
                                {/*        Consumer Protection Act*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                                {/*<li className={classes.footerNavList}>*/}
                                {/*    <a className={classes.footerNavListItem} href="#">*/}
                                {/*        Cookies Settings*/}
                                {/*    </a>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </div>
                    <p className={classes.footerText}>
                        Financial projections, forecasts or forward-looking statements are not guarantees of future
                        performance and involve known and unknown risks, uncertainties and contingencies, many of which
                        are beyond our control.
                    </p>
                    <p className={classes.footerText}>
                        This content is made available for informational purposes only. HausCoin LLC does not make any
                        representations or warranties as to and is not responsible for the accuracy, applicability,
                        reliability, fitness, suitability, or completeness of the content. The content does not
                        constitute legal, tax, or investment advice in any way. We recommend seeking the advice of a
                        qualified legal, investment, or tax professional. HausCoin LLC disclaims any and all liability
                        to any party for any direct, indirect, implied, punitive, special, incidental or other
                        consequential damages arising directly or indirectly from any use of the content, which is
                        provided as is, without warranties.
                    </p>
                    {/*<p className={classes.footerText}>*/}
                    {/*    Haus Real Estate Brokerage is an Equal Housing Broker. As prohibited by federal law, we do not*/}
                    {/*    engage in business practices that discriminate on the basis of race, color, religion, national*/}
                    {/*    origin, sex, marital status, age (provided you have the capacity to enter into a binding*/}
                    {/*    contract), because all or part of your income may be derived from any public assistance program,*/}
                    {/*    or because you have, in good faith, exercised any right under the Consumer Credit Protection*/}
                    {/*    Act. The federal agency that administers our compliance with these federal laws is the Federal*/}
                    {/*    Trade Commission, Equal Credit Opportunity, Washington, DC, 20580*/}
                    {/*</p>*/}
                    <p className={classes.footerText}>
                        This content is protected by copyright laws. Reproduction and distribution without prior written
                        permission of HausCoin LLC is prohibited. <br/> © HausCoin LLC 2021. All rights reserved.
                    </p>
                </footer>
            </Container>
        </div>
    );
};

export default memo(Footer);
