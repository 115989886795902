export const COIN_TYPE = {
  MAIN: 'MAIN',
  DEMO: 'DEMO',
  OAK: 'OAK',
  HAUS: 'HAUS',
};

export const COIN_STATUS = {
  TRENDING: 'TRENDING',
  NEW: 'NEW',
  SOON: 'SOON',
};

export const ADDRESS_STATUS = {
  INDIVIDUAL: 'LMI INDIVIDUAL',
  LOCATION: 'LMI LOCATION',
};

export const TRANSACTION_TYPES = {
    BUY: 'buy',
    SELL: 'sell',
};

export const TRANSACTION_ACTIONS = {
    APPROVE: 'APPROVE',
    DENY: 'DENY',
};

export const TRANSACTION_STATUS = {
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
    CANCELLED: 'CANCELLED',
    FAILED: 'FAILED',
};

export const TRANSACTION_PROCESSING_TYPE = {
    CANCELLING: 'CANCELLING',
    APPROVING: 'APPROVING',
};

export const URL_PARAMS = {
    CONFIRMATION_CODE: 'confirmation_code',
    USER_NAME: 'user_name',
    CLIENT_ID: 'client_id',
};
