import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  slider: {
    position: 'relative',
    paddingTop: '0',
    paddingLeft: '0',
    paddingRight: '0',
    paddingBottom: '0',
    fontFamily: theme.typography.fontFamily.black
  },
  sliderLogo : {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '102px',
  },
  sliderLogoTitle : {
    fontSize: theme.fontSize.text.semiHeader,
    lineHeight: '32px',
    letterSpacing: '-0.25px',
    marginLeft: '8px',
    marginTop: 0,
    marginBottom: 0
  },
  sliderDots: {
    backgroundColor: '#0650CF',
    height: '6px',
    width: '6px',
    borderRadius: '50%',
    transition: 'all .5s ease-out',
  },
  sliderTitles: {
    fontFamily: theme.typography.fontFamily.black,
    fontSize: theme.fontSize.text.semiMain,
    lineHeight: '50px',
    alignItems: 'center',
    letterSpacing: '-1.005px',
    color: '#0650CF',
  },
  sliderTextBottom: {
    fontFamily: theme.typography.fontFamily.medium,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-0.33px',
    color: theme.palette.text.main,
  },
  '@media (max-width: 1280px)': {
    sliderContainer : {
      padding: '0 15px'
    }
  },
  '@media (max-width: 600px)': {
    slider : {
      paddingLeft: '10px',
      paddingRight: '10px'
    },
    sliderQuotesLogo : {
      marginTop: '50px'
    },
  },
  '@media (max-width: 480px)' : {
    sliderLogo : {
      marginBottom: '65px',
    },
    sliderTitles: {
      fontSize: '24px',
      lineHeight: '28px',
      alignItems: 'center',
    },
    sliderTextBottom: {
      fontSize: '16px',
      lineHeight: '19px',
    },
  },
  '@media (max-width: 320px)' : {
    slider : {
      paddingTop: '60px',
      paddingBottom: '100px'
    }
  }
}))
