import React, {useEffect, useRef, useState} from 'react';
import {useStyles} from "./style";
import selectArrow from '../../../../assets/img/select-arrow.svg';
import clsx from "clsx";

const LedgerFilter = ({prompt, value, onChange, citiesArr}) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    const reset = 'All';

    useEffect(() => {
        document.addEventListener("click", close);
        return () => document.removeEventListener("click", close);
    },[]);


    function close(e) {
        setOpen(e && e.target === ref.current)
    };

    return (
        <div className={classes.containerFilter} onClick={() => setOpen(!open)}>
            <div className={classes.filterWrapper} ref={ref}>
                {value ? (value === 'All' ? value : value.stateCode + ' - ' + value.city) : prompt}
                <img className={classes.filterBtnSpanImg} src={selectArrow} alt="arrow down"/>
            </div>
            <div className={clsx(classes.filterWrapperList, {[classes.filterClose]: !open})}>
                <button
                    className={`${classes.filterBtn} ${value === reset ? 'selected' : null}`}
                    onClick={() => {
                        onChange(reset);
                        setOpen(false);
                    }}
                >
                    {reset === 'All' ? reset : (reset.stateCode + ' - ' + reset.city)}
                </button>
                {citiesArr.map((cityItem, index) => (
                    <button
                        className={`${classes.filterBtn} ${(value && value.stateCode === cityItem.stateCode) && (value && value.city === cityItem.city) ? 'selected' : null}`}
                        key={index}
                        value={cityItem}
                        onClick={() => {
                            onChange(cityItem);
                            setOpen(false);
                        }}
                    >
                        {cityItem.stateCode + ' - ' + cityItem.city}
                    </button>
                ))}
            </div>
        </div>
    );
}

export default LedgerFilter;