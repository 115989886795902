import React, {useEffect, useState} from 'react';

import Container from '@material-ui/core/Container';
import Slider from 'react-slick';
import pressLogo from '../../../assets/img/pressLogo.svg';
import quotes from '../../../assets/img/quotesLogo.svg';
import sliderHouses from '../../../assets/img/sliderHauses.svg';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {useStyles} from './style.js';
import {useAppState} from "../../app-state";

const SliderText = ({
                      sliderClassName,
                      logoClassName,
                      sliderLogo,
                      altSliderLogo,
                      sliderLogoText,
                      quotesColor,
                      dotsBottom,
                      textClassName,
                      classNameSignature,
                      signatureAuthor,
                      signatureText,
                    }) => {
  const classes = useStyles();
  const { state, actions } = useAppState();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slides, setSlides] = useState();

  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    appendDots: dots => (
      <ul style={{
        bottom: dotsBottom,
        textAlign: window.innerWidth <= 480 ? "center" : "left"
      }}
      >
        {dots}
      </ul>
    ),
    beforeChange: (prev, next) => {
      setCurrentSlide(next);
    },
    customPaging: index => {
      return (
        <div className={classes.sliderDotsWrapper}>
          <div className={classes.sliderDots} style={index === currentSlide ? {transform: 'scale(2)'} : null}/>
        </div>
      )
    },
  };

  return (
    <div className={`${classes.slider} ${sliderClassName}`}>
        <div className={`${classes.sliderLogo} ${logoClassName}`}>
          <img className={classes.sliderLogoImg} src={sliderLogo} alt={altSliderLogo}/>
          <h4 className={classes.sliderLogoTitle}>{sliderLogoText}</h4>
        </div>
        <div className={classes.sliderQuotesLogo}>
          <svg width="72" height="48" viewBox="0 0 72 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.0272 0L0.0288 16H0V48H32V16H16.0288V0H16.0272Z" fill={quotesColor}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M56.0272 0L40.0288 16H40V48H72V16H56.0288V0H56.0272Z" fill={quotesColor}/>
          </svg>
        </div>
        <Slider {...settings}>
          <div>
            <div className={`${classes.sliderTitles} ${textClassName}`}>
              HausCoin reimagines how the home equity market functions, and 
              gives access to what has been a very illiquid asset class.
            </div>
            <div className={`${classes.sliderTextBottom} ${classNameSignature}`}>
              Matt Murphy
            </div>
            <div className={`${classes.sliderTextBottom} ${classNameSignature}`}>
              Montage Ventures
            </div>
          </div>
          <div>
            <div className={`${classes.sliderTitles} ${textClassName}`}>
              HausCoin is laying the foundation for a whole new equity driven 
              financing ecosystem, serving both investors and homeowners.
            </div>
            <div className={`${classes.sliderTextBottom} ${classNameSignature}`}>
              Jonathan Ebinger
            </div>
            <div className={`${classes.sliderTextBottom} ${classNameSignature}`}>
              BlueRun Ventures
            </div>
          </div>
          <div>
            <div className={`${classes.sliderTitles} ${textClassName}`}>
              HausCoin is an incredible example of using crypto to solve a 
              real world problem: making more community members long-term homeowners.
            </div>
            <div className={`${classes.sliderTextBottom} ${classNameSignature}`}>
              Jesse Pollak
            </div>
            <div className={`${classes.sliderTextBottom} ${classNameSignature}`}>
              Coinbase
            </div>
          </div>
          <div>
            <div className={`${classes.sliderTitles} ${textClassName}`}>
              HausCoin represents a real world case on how innovative 
              companies can leverage Blockchain technology to challenge the status quo in financial services.  This is a significant leap forward in the home financing and equity markets.
            </div>
            <div className={`${classes.sliderTextBottom} ${classNameSignature}`}>
              Roman Leal
            </div>
            <div className={`${classes.sliderTextBottom} ${classNameSignature}`}>
              Leap Global Partners
            </div>
          </div>
        </Slider>
      {/*<img className={classes.sliderHouses} src={housesLogo} alt={altHousesLogo}/>*/}
    </div>
  )
}

export default SliderText;
