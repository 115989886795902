import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  dashboardGraphBtnLedger: {
    width: '204px',
    display: 'block',
    boxSizing: "border-box",
    marginLeft: '20px',
    border: '1px solid black',
    whiteSpace: 'nowrap',
    textTransform: 'uppercase',
    textAlign: 'center',
    outline: 'none',
    padding: '20px 40px',
    backgroundColor: theme.palette.bgColor.white,
    color: theme.palette.text.main,
    fontSize: theme.fontSize.text.small,
    cursor: 'pointer',
    letterSpacing: '1px',
  },
  '@media (max-width: 768px)': {
    dashboardGraphBtnLedger: {
      width: '100%',
      marginLeft: '0',
      marginTop: '20px',
    },
  },
}))
