import CONFIG_COIN from "../CoinConfig/CoinConfig";

const tokens = {};

CONFIG_COIN.forEach(coin => {
  tokens[coin.coinType] = {
    price: [],
    circulation: [],
  }
});

export const initialCoinData = { tokens };

export const formatTokenData = (coinData, coins) => {
  return coins.map(coin => {
    let price = coin.coinType in coinData.tokens ? coinData.tokens[coin.coinType].price : [];
    let circulation = coin.coinType in coinData.tokens ? coinData.tokens[coin.coinType].circulation : [];
    //do not include coisn with missing price/circulation data
    if (!price || !circulation) {
      return null;
    }
    if (price.length > 24) {
      price = price.slice(price.length - 24);
    }
    if (circulation.length > 24) {
      circulation = circulation.slice(circulation.length - 24);
    }
    const dates = price.map(p => p.date);
    price = price.map(p => parseFloat(p.value));
    circulation = circulation.map(c => parseFloat(c.value));
    return {
      ...coin,
      price,
      circulation,
      dates,
      percent: price.length
        ? ((price[price.length - 1] - price[0])*100).toFixed(2)
        : 0,
      coinCost: price.length
        ? `$${price[price.length - 1].toFixed(6)}`
        : 0,
      title: coin.title,
    };
  }).filter(coin => coin !== null);
}
